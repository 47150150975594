import { Element } from "react-scroll";
import styled from "styled-components";
import { SubSection, SubTitle, CenterRelative } from "../../shared";

const InsuranceDesc = () => {
  const Section27 = styled(SubSection)`
    background: url(../../skin/body/sub0204/introduce_bg.jpg) fixed no-repeat
      center;
    background-size: cover;
    padding: 0;

    .sub_section6_bg {
      display: table;
      width: 100%;
      height: 100%;
      padding: 150px 0;
      background: rgba(69, 69, 71, 0.9);
    }

    .insu_title {
      color: white;
      margin-bottom: 30px;
    }

    .insu_introduce {
      margin: 0 0 50px;
    }

    .insu_introduce {
      display: table;
      width: 100%;
      border: 1px solid rgba(255, 255, 255, 0.5);
      padding: 50px;
      box-sizing: border-box;

      ul {
        display: flex;
        align-items: center;
      }

      li {
        position: relative;
        float: left;
        font-family: "Pretendard";
        color: #fff;
        list-style: none;
      }

      li:first-child {
        width: 20%;
        font-weight: 700;
        font-size: 35px;
        line-height: 40px;
        :before {
          position: absolute;
          bottom: -17px;
          left: 0;
          width: 20px;
          height: 1px;
          background: #fff;
          content: "";
          transform: rotate(-45deg);
        }
      }

      li:last-child {
        width: 80%;
        font-weight: 200;
        font-size: 22px;
        line-height: 30px;
      }
    }
  `;

  return (
    <Element name="sectionIDesc">
      <Section27 className="sub_section sub_section6">
        <div className="sub_section6_bg">
          <SubTitle className="insu_title">핵심역량</SubTitle>
          <CenterRelative className="centerdiv relative">
            <div className="insu_introduce">
              <ul>
                <li>
                  보험료 <br />
                  협상력
                </li>
                <li>
                  팀프인슈어런스는 기업보험 전문 컨설팅 업체인 동시에 각
                  보험사의 고객사 입니다.
                  <br />
                  따라서 보험사 및 재보험사와 협상이 가능하여, 최적의 보험조건,
                  최저의 보험료를
                  <br />
                  제시할 수 있습니다.
                  <br />
                </li>
              </ul>
            </div>
            <div className="insu_introduce">
              <ul>
                <li>
                  전문적 <br />
                  계약 관리
                </li>
                <li>
                  보험사 Underwriting(보험료 책정 및 보험계약 인수여부
                  판단)경험을 가진
                  <br />
                  보험전문가가 전문적으로 계약을 관리합니다.
                  <br />
                  보험조건 변경, 자산의 증감, 사고 발생 시 정확하고 신속한
                  계약관리가 가능합니다.
                </li>
              </ul>
            </div>
            <div className="insu_introduce">
              <ul>
                <li>
                  위험관리 <br />
                  컨설팅
                </li>
                <li>
                  팀프인슈어런스는 국내외 다양한 분야의 위험&안전관리 자격증
                  보유하고 있으며
                  <br />
                  다수의 고객사에 위험 분석 및 관리 컨설팅을 제공하고 있습니다.
                </li>
              </ul>
            </div>
          </CenterRelative>
        </div>
      </Section27>
    </Element>
  );
};

export default InsuranceDesc;
