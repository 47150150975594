import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import routes from "../../routes";

const SubBookMark = styled.div`
  display: table;
  position: fixed;
  width: 100%;
  min-width: 1210px;
  top: 63px;
  text-align: center;
  background: rgb(2 21 48 / 90%);
  padding: 10px 0;
  z-index: 90;
  opacity: 0;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  @media only screen and (max-width: 480px) {
    top: 180px;
  }
  li {
    position: relative;
    display: inline-block;
    width: 180px;
    font-weight: 300;
    font-size: 17px;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid rgb(255 255 255 / 10%);
    background: rgb(0 0 0 / 0%);
    margin: 0 3px;
    cursor: pointer;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    @media only screen and (max-width: 480px) {
      width: 360px;
      font-size: 28px;
      line-height: 60px;
      margin: 10px 3px;
    }
  }
  li.btnActive {
    background: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    color: rgb(2, 32, 75);
    border-color: ${({ theme }) => theme.colors.white};
  }
`;

const SubHeader = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [btnActive, setBtnActive] = useState("dawn");
  const services = [
    { id: "dawn", name: "새벽배송", link: "service" },
    { id: "fullfillment", name: "풀필먼트", link: "fulfillment" },
    { id: "forwarding", name: "화물주선", link: "transportation" },
  ];

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  const toggleActive = (event) => {
    const id = event.target.id;
    setBtnActive(id);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
  });

  return (
    //스크롤 시 sub_bookmark_fix 클래스 추가
    <SubBookMark style={{ opacity: scrollPosition > 0 ? 1 : 0 }}>
      <ul>
        {services.map((item, idx) => (
          <li
            key={"service" + idx}
            id={item.id}
            className={"btn" + (item.id === btnActive ? "Active" : "")}
            onClick={toggleActive}
          >
            <Link to={routes[item.link]} style={{ display: "block" }}>
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </SubBookMark>
  );
};

export default SubHeader;
