const ModalTM = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              백준호
              <br />
              <span>
                화물운송플랫폼본부,
                <br />
                운송관리팀(TM)
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img15.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              안녕하세요. 운송관리팀(TM)의 주 업무는 고객사의 니즈에 맞는 영업용
              냉장 차량 주선과 자사에서 개발한 플랫폼을 통해 제공하는
              화물운송종사자 및 운송사 업무 통합 시스템 관리입니다.
              <br />
              그리고 저는 고객사와 긴밀히 협업할 수 있도록 커뮤니케이션을 원활히
              하는 역할과 더불어 운송업황 분석을 하고 있어요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              의외로 갈등은 소통의 부족함에서 시작된다고 생각해요.
              <br />
              팀프레시에서 근무하면서 크고 작은 프로젝트를 진행하며 이러한
              소통의 중요성을 더욱 깊이 깨닫게 되었고, 현재는 서로의 목적을 함께
              달성하며 유연하게 대처하는 능력이 성장했다고 생각해요.
              <br />
              작은 것도 무심코 지나치지 않는 세심함이 좋은 결과를 만들어낸다고
              생각합니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’여서 TM에서 가능했던 업무는 무엇이 있었나요?
            </div>
            <div className="interview_comment_A">
              운송관리팀에서는 팀프레시를 대표하여 고객사 및 운송업 종사자
              여러분을 만나게 되는 사례가 많이 있어요.
              <br />
              사람과 사람 사이에 일이 있다는 관계중심의 생각으로 업무에 임하다
              보니 대상과의 유대관계도 더욱 깊어지고 TM의 영향력도 높아져 더욱
              원활히 소통할 수 있었습니다.
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              네, 저희 운송관리팀(TM)에는 에너지 넘치는 사원들의 열정을 옳은
              방향으로 이끌어 줄 노련한 동료분들이 많이 계세요.
              <br />
              젊은 패기와 노련미를 모두 갖추었다고 할까요?
              <br />
              열정을 가진 사람들이 모여 이루어내는 시너지는 분명 발전적인
              방향으로 모두에게 영향을 끼칠 것이라고 생각 하거든요.
              <br />
              그런 의미에서 팀프레시는 물류업계를 접하고 경험하기에 정말 좋은
              곳이랍니다.
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              저희 운송관리팀(TM)은 유연한 조직운영 덕분에 능동적인 사고방식과
              행동력을 갖춘 사람이 더욱 더 역량을 크게 발휘할 수 있는
              환경입니다.
              <br />
              서로 긍정적인 영향력을 주고받을 수 있는 동료분과 함께 팀프레시에서
              일하고 싶습니다.
            </div>
            <div className="interview_comment_Q">
              마지막으로, 한 말씀 부탁드립니다!
            </div>
            <div className="interview_comment_A">
              이 글을 보고 계신 여러분은 무한한 가능성을 품고 있는 인재라는
              사실을 스스로 인지하셨으면 해요.
              <br />
              저희 팀프레시는 작은 가능성도 결코 지나치지 않습니다.
              <br />
              자신의 능력을 마음껏 펼칠 수 있는 환경에서 함께 성장해 나가며
              일하고 싶습니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalTM;
