import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { postMyInquiry } from "../../../services";

import Pagination from "../../common/Pagination";
import styled from "styled-components";
import { Link } from "react-router-dom";
import routes from "../../../routes";

const Table = styled.table`
  tbody {
    background-color: #ffffff;
    tr {
      color: #555555;
      td {
        font-size: 16px;
        font-weight: 300;
        padding: 15px 5px;
        box-sizing: border-box;
      }

      td.head_td {
        height: 50px;
        color: #000 !important;
        font-size: 18px !important;
        font-weight: 400 !important;
        border-width: 0;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 1px 0 0 0;
      }

      td.list_subject {
        word-break: break-all;
        padding-left: 15px !important;
      }
      td.list_hr_line {
        height: 1px;
        background-color: #f4f3f1;
        padding: 0 !important;
      }
    }
  }
`;

const InquiryUserList = () => {
  const [inquiryList, setInquiryList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { state } = useLocation();

  // useEffect(() => {
  //   setTotalPage(state?.totalPages);
  //   setInquiryList(state?.res, currentPage);
  // }, [state]);

  useEffect(() => {
    getMyInquiryList();
  }, [currentPage]);

  const getMyInquiryList = async () => {
    const res = await postMyInquiry(state?.values, currentPage);
    setInquiryList(res?.data);
    setTotalPage(state?.totalPages);
  };

  return (
    <>
      <Table
        id="mainIndexTable"
        cellPadding="0"
        cellSpacing="0"
        width="100%"
        border="0"
      >
        <tbody>
          <tr align="center">
            <td className="head_td" width="3px">
              <span>&nbsp;</span>
            </td>
            <td className="head_td" style={{ width: "10%" }}>
              <nobr>
                <span className="list_no">No.</span>
              </nobr>
            </td>

            <td className="head_td" align="center" style={{ width: "70%" }}>
              <nobr>제목</nobr>
            </td>
            <td className="head_td" align="center" style={{ width: "10%" }}>
              상태
            </td>

            <td className="head_td" style={{ width: "10%" }}>
              등록일
            </td>
            <td className="head_td" width="3px">
              <span>&nbsp;</span>
            </td>
          </tr>
          {inquiryList?.map((item, index) => (
            <React.Fragment key={index}>
              <tr height="40">
                <td width="1px"></td>

                <td className="list_no">
                  <nobr>{index + 1}</nobr>
                </td>

                <td className="list_subject">
                  <Link
                    to={`/customer/inquiry/detail/${item.inquirySeq}`}
                    state={{
                      res: inquiryList,
                      values: state.values,
                      totalPages: state.totalPages,
                    }}
                    className="subjectColor"
                  >
                    <img
                      src="./skin_board/a_standard/b_img/attrib/lock.gif"
                      align="absmiddle"
                      border="0"
                      alt=""
                    />
                    <span id="opennumber88" style={{ fontWeight: "700" }}>
                      {item.title}
                    </span>
                  </Link>
                </td>

                {item.answerYn === "Y" ? (
                  <td style={{ width: "10%" }}>답변완료</td>
                ) : (
                  <td style={{ width: "10%" }}>접수완료</td>
                )}

                <td className="list_date">
                  <nobr>{item.creDt?.split(" ", 1)}</nobr>
                </td>

                <td width="1px"></td>
              </tr>
              <tr className="list_hr_tr">
                <td colSpan="8" className="list_hr_line"></td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default InquiryUserList;
