import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import routes from "../../routes";
import styled from "styled-components";

const BtnWrapper = styled.div`
  margin-top: 60px;
`;
const LinkBtn = styled(Link)`
  display: block;
  width: 100%;
`;

export default function BottomDrawer({ toggleDrawer, state }) {
  const list = (anchor) => (
    <Box
      sx={{
        zIndex: "100",
        width: anchor === "top",
        padding: "100px",
        height: "100%",
      }}
      role="presentation"
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton to={routes.business}>
            <ListItemText
              primary={"회사소개"}
              primaryTypographyProps={{ fontSize: "3rem", marginTop: "6rem" }}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider
        sx={{ backgroundColor: "rgba(255,255,255,0.3)", margin: "0 100px" }}
      />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <LinkBtn to={routes.service}>
              <ListItemText
                primary={"서비스 소개"}
                primaryTypographyProps={{ fontSize: "3rem", marginTop: "6rem" }}
              />
            </LinkBtn>
          </ListItemButton>
        </ListItem>
        <Divider
          sx={{ backgroundColor: "rgba(255,255,255,0.3)", margin: "0 100px" }}
        />
        <BtnWrapper>
          <ListItemButton>
            <LinkBtn to={routes.service}>
              <ListItemText
                primary={"물류"}
                primaryTypographyProps={{ fontSize: "2rem" }}
              />
            </LinkBtn>
          </ListItemButton>
          <ListItemButton>
            <LinkBtn to={routes.distribution}>
              <ListItemText
                primary={"유통"}
                primaryTypographyProps={{ fontSize: "2rem" }}
              />
            </LinkBtn>
          </ListItemButton>
          <ListItemButton>
            <LinkBtn to={routes.franchise}>
              <ListItemText
                primary={"프랜차이즈"}
                primaryTypographyProps={{ fontSize: "2rem" }}
              />
            </LinkBtn>
          </ListItemButton>
          <ListItemButton>
            <LinkBtn to={routes.insurance}>
              <ListItemText
                primary={"보험"}
                primaryTypographyProps={{ fontSize: "2rem" }}
              />
            </LinkBtn>
          </ListItemButton>
        </BtnWrapper>
      </List>

      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <LinkBtn to={routes.recruit}>
              <ListItemText
                primary={"인재채용"}
                primaryTypographyProps={{ fontSize: "3rem", marginTop: "6rem" }}
              />
            </LinkBtn>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider
        sx={{ backgroundColor: "rgba(255,255,255,0.3)", margin: "0 100px" }}
      />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <LinkBtn to={routes.notice}>
              <ListItemText
                primary={"고객지원"}
                primaryTypographyProps={{ fontSize: "3rem", marginTop: "6rem" }}
              />
            </LinkBtn>
          </ListItemButton>
        </ListItem>
        <Divider
          sx={{ backgroundColor: "rgba(255,255,255,0.3)", margin: "0 100px" }}
        />
        <BtnWrapper>
          <ListItemButton>
            <LinkBtn to={routes.notice}>
              <ListItemText
                primary={"공지사항"}
                primaryTypographyProps={{ fontSize: "2rem" }}
              />
            </LinkBtn>
          </ListItemButton>
          <ListItemButton>
            <LinkBtn to={routes.timfnews}>
              <ListItemText
                primary={"팀프뉴스"}
                primaryTypographyProps={{ fontSize: "2rem" }}
              />
            </LinkBtn>
          </ListItemButton>
          <ListItemButton>
            <LinkBtn to={routes.inquiry}>
              <ListItemText
                primary={"문의하기"}
                primaryTypographyProps={{ fontSize: "2rem" }}
              />
            </LinkBtn>
          </ListItemButton>
          <ListItemButton>
            <LinkBtn to={routes.faq}>
              <ListItemText
                primary={"자주 묻는 질문"}
                primaryTypographyProps={{ fontSize: "2rem" }}
              />
            </LinkBtn>
          </ListItemButton>
        </BtnWrapper>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          onClick={toggleDrawer("top", false)}
          anchor="top"
          open={state["top"]}
          sx={{
            zIndex: "100",
            height: "200vh",
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent",
            },
            "& .MuiPaper-root": {
              top: "180px",
              backgroundColor: "rgba(2, 21, 47, 0.9)",
              color: "#fff",
            },
            "& .MuiListItemButton-root": {
              textAlign: "center",
            },
          }}
        >
          {list("top")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
