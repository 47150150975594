import styled from "styled-components";
import { Arrow, SubImages, SubImgWrapper, ShadowBg } from "../shared";

const TimfAbout = () => {
  const SubImagesBg = styled.div`
    position: absolute;
    display: table;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @media only screen and (max-width: 480px) {
      height: 180vh;
    }
  `;

  const BgBlack = styled(SubImagesBg)`
    background: rgb(0 0 0 / 30%);

    .sub_images_title {
      position: absolute;
      bottom: 45%;
      left: 0;
      width: 100%;
      font-weight: 700;
      font-size: 55px;
      line-height: 65px;
      color: ${({ theme }) => theme.colors.white};
      z-index: 2;
    }
  `;

  const SubImg = styled(SubImgWrapper)`
    background: url(./skin/images/sub_images0101.jpg) no-repeat 50% 50%;
    background-size: cover;
  `;

  return (
    <SubImages>
      <BgBlack>
        <div className="sub_images_title">
          콜드체인, 그 이상의
          <br />
          가치를 만들고 있습니다
        </div>
        <Arrow className="sub_images_arrow">
          <a className="anchorLink" href="#subsectionlink1">
            <i className="xi-angle-down"></i>
          </a>
        </Arrow>
        <ShadowBg>&nbsp;</ShadowBg>
      </BgBlack>
      <SubImg>&nbsp;</SubImg>
    </SubImages>
  );
};

export default TimfAbout;
