import React, { createContext, useContext, useReducer } from "react";

const initialState = {
  userList: [],
  user: localStorage["user"] ? localStorage["user"] : null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CREATE_USER":
      return {
        ...state,
        userList: state.userList.concat(action.user),
      };
    case "LOGIN":
      return setUserFunc(state, action);
    case "LOGOUT":
      return removeUserFunc(state, action);

    default:
      return state;
  }
};

const removeUserFunc = (state, action) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user");
  return { ...state, user: action.payload };
};

const setUserFunc = (state, action) => {
  console.log("action.payload", action.payload);
  localStorage["user"] = action.payload;
  return { ...state, user: action.payload };
};

const StoreContext = createContext(initialState);
export const StoreProvider = ({ children }) => {
  // zone encore un peu sombre
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = (store) => {
  // zone encore un peu sombre
  const { state, dispatch } = useContext(StoreContext);
  return { state, dispatch };
};
