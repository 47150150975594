import styled from "styled-components";
import { Arrow2 } from "../shared";

const SubContents = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 150px 0 200px;
`;

const Title1 = styled.h1`
  width: 100%;
  font-weight: 300;
  font-size: 30px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 0 30px 0;
`;

const Title2 = styled.h2`
  width: 100%;
  font-weight: 700;
  font-size: 55px;
  line-height: 65px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 0 30px 0;
`;

const Title3 = styled.h3`
  width: 100%;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.black};
`;

const TimfMission = () => {
  return (
    <SubContents id="subsectionlink1">
      <Title1 className="title1">TimF Mission</Title1>
      <Title2 className="title2">
        초연결을 통해 만드는
        <br />
        풍요로운 세상
      </Title2>
      <Title3 className="title3">
        상품과 사람이 거미줄처럼 연결되어 있는 사회속에서
        <br />
        구멍없이 자연스럽게 이어주는 것이
        <br />
        팀프레시의 존재 이유 입니다.
        <br />
        <br />
        팀프레시는 신선함을 잇는 일을 사명이자 가치로 여깁니다.
        <br />이 가치를 위해 우리는 빠르고, 기민하게 움직입니다.
      </Title3>
      <Arrow2>
        <a className="anchorLink" href="#subsectionlink2">
          <i className="xi-angle-down"></i>
        </a>
      </Arrow2>
    </SubContents>
  );
};

export default TimfMission;
