import styled from "styled-components";
import {
  CenterDiv,
  SubSection,
  TextTitle1,
  TextTitle2,
  TextComment,
  SectionImg,
} from "../../shared";

const PPackingProcess = () => {
  const Section16 = styled(SubSection)`
    .section_text {
      position: relative;
      float: right;
      width: 45%;
      padding: 0px 20px;
      box-sizing: border-box;
    }

    .section_img {
      text-align: right;
      position: relative;
      float: right;
      width: 55%;
      padding: 0 20px;
      padding-right: 60px;
      box-sizing: border-box;
      img {
        max-width: 100%;
      }
    }
  `;

  return (
    <Section16 className="sub_section sub_section3">
      <CenterDiv className="centerdiv">
        <div className="section_text">
          <TextTitle1 className="section_text_title1">
            낱개 단위의 상품 재포장 작업
          </TextTitle1>
          <TextTitle2 className="section_text_title2">
            피/패킹 프로세스
          </TextTitle2>
          <TextComment className="section_text_title_comment">
            P/Packing Process
            <li>
              오더피킹
              <br />
              고객의 주문건별로 상품을 피킹,
              <br />
              소량의 주문 처리에 유리한 출고방식
            </li>
            <li>
              DAS(Digital Assorting System)
              <br />
              다량의 주문건별로 상품을 배분,
              <br />
              일정시간에 주문이 집중되거나
              <br />
              신선식품에 유리함
            </li>
            <li>
              DPS(Digital Picking System)
              <br />
              다량의 주문건에 맞게 상품을 피킹,
              <br />
              다품종 소량 주문에 적절한 출고 방식
            </li>
          </TextComment>
        </div>
        <SectionImg className="section_img">
          <img src="../skin/body/sub020102/sectionimg2_2.png" alt="" />
        </SectionImg>
      </CenterDiv>
    </Section16>
  );
};

export default PPackingProcess;
