export const downloadFile = (url) => {
  url = `${process.env.PUBLIC_URL}/소규모_주식교환_반대의사_통지서.pdf`;

  fetch(url, { method: "GET" })
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "소규모_주식교환_반대의사_통지서.pdf";
      document.body.appendChild(a);
      a.click();
      setTimeout((_) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch((err) => {
      console.error("err: ", err);
    });
};
