import { Element } from "react-scroll";

import styled from "styled-components";
import { CenterDiv, SubSection, SubTitle } from "../shared";

const Section8 = styled(SubSection)`
  background: url(skin/body/sub020101/process_bg.jpg) fixed no-repeat center;
  background-size: cover;
  padding: 0;
  .sub_section1_bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 90%);
    padding: 150px 0;
  }

  .section_process1 {
    clear: both;
    display: table;
    width: 100%;
    margin: 50px 0 0 0;
  }
`;

const Process = () => {
  return (
    <Element name="sectionProcess">
      <Section8 className="sub_section sub_section1" id="subsectionlink2">
        <div className="sub_section1_bg">
          <CenterDiv className="centerdiv">
            <SubTitle className="sub_title">
              <div className="sub_title_eng">Process</div>
              프로세스
              <div className="sub_title_comment">
                상품이 손에서 떠나는 순간부터 소비자 문 앞까지 냉장 상태를
                유지합니다.
                <br />
                이를 통해 냉매제 투입 비용 절감이 가능해 집니다.
              </div>
            </SubTitle>
            <div className="section_process1">
              <img src="skin/body/sub020101/process.png" alt="" />
            </div>
          </CenterDiv>
        </div>
      </Section8>
    </Element>
  );
};

export default Process;
