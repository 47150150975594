import styled from "styled-components";

import { CenterDiv, CenterRelative, SubSection, SubTitle } from "../../shared";

const Section36 = styled(SubSection)`
  background: url(skin/body/sub020101/process_bg.jpg) fixed no-repeat center;
  background-size: cover;
  padding: 0;
  .sub_section1_bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 90%);
    padding: 150px 0;
  }

  .section_process1 {
    clear: both;
    display: table;
    width: 100%;
    margin: 50px 0 0 0;
  }

  .section_partner_img {
    width: 100%;
  }
`;
const InsuarancePartner = () => {
  return (
    <Section36 className="sub_section sub_section36" id="subsectionlink2">
      <div className="sub_section1_bg">
        <CenterDiv className="centerdiv">
          <SubTitle className="sub_title">
            <div className="sub_title_eng">Network</div>
            파트너 보험사
            <div className="sub_title_comment">
              팀프인슈어런스는 고객사에 최적의 컨설팅을 위해
              <br />
              다수의 보험사와 파트너십을 유지하고 있습니다.
            </div>
          </SubTitle>
          <div className="section_process1">
            <img
              className="section_partner_img "
              src="../skin/body/sub0204/insu_partner.png"
              alt=""
            />
          </div>
        </CenterDiv>
      </div>
    </Section36>
  );
};

export default InsuarancePartner;
