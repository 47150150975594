import styled from "styled-components";

import Header from "./Header";
import SubHeader from "./SubHeader";
import Footer from "./Footer";
import SideIcons from "./SideIcons";
import { Outlet } from "react-router-dom";

const Wrapper = styled.div`
  min-width: 1210px;
  max-width: 2000px;
  margin: 0 auto;
`;

const NestedLayout = () => {
  return (
    <Wrapper>
      <Header />
      <SubHeader />
      <SideIcons />
      <Outlet />
      <Footer />
    </Wrapper>
  );
};

export default NestedLayout;
