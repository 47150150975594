import { useEffect } from "react";

import styled from "styled-components";
import KakaoMapScript from "../../hooks/KakaoMapScript";
import { SubSection, CenterDiv } from "../shared";

const Section4 = styled(SubSection)`
  li {
    list-style: none;
  }
`;

const TimfCenter = () => {
  useEffect(() => {
    KakaoMapScript();
  }, []);

  return (
    <Section4>
      <CenterDiv className="centerdiv relative">
        <div className="kakaoMap">
          <div className="menutab">
            <ul>
              <li className="active" data-page="mapcontent1">
                본사
              </li>
              <li data-page="mapcontent2">
                통과형 물류센터
                <br />
                <span>
                  (TC <strong>Transfer Center</strong>)
                </span>
                <div className="menutabsub">
                  <ul>
                  <li className="active" data-page="mapcontent2-1">
                    하남센터
                  </li>
                  <li data-page="mapcontent2-2">광주센터</li>
                  <li data-page="mapcontent2-3">일산센터</li>
                  <li data-page="mapcontent2-4">인천센터</li>
                  <li data-page="mapcontent2-5">남양주센터</li>
                  <li data-page="mapcontent2-6">대전센터</li>
                  <li data-page="mapcontent2-7">세종센터</li>
                  <li data-page="mapcontent2-8">군포센터</li>
                </ul>
              </div>
            </li>
            <li data-page="mapcontent3">
              허브 물류센터
              <br />
              <span>
                  (HC <strong>Hub Center</strong>)
                </span>
            </li>
            <li data-page="mapcontent4">
              보관 물류센터
              <br />
              <span>
                  (DC <strong>Distribution Center</strong>)
                </span>
              <div className="menutabsub">
                <ul>
                  <li className="active" data-page="mapcontent4-1">
                    서이천센터
                  </li>
                  <li data-page="mapcontent4-2">덕평센터</li>
                  <li data-page="mapcontent4-3">마장센터</li>
                  <li data-page="mapcontent4-4">천안센터</li>
                  <li data-page="mapcontent4-5">안평리센터</li>
                  <li data-page="mapcontent4-6">동이천센터</li>
                  <li data-page="mapcontent4-7">양산센터</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className="content_wr">
          <div className="mapcontent mapcontent1 active">
            <div className="mapbox">
              <div className="map" id="map1"></div>
              <div className="address">
                <div className="maptitle">본사</div>
                <li>
                  <i className="xi-maker"></i>서울 강남구 봉은사로 37길 7-9
                  글래드큐브빌딩
                </li>
                <li>
                  <i className="xi-call"></i>02-423-0525
                </li>
              </div>
            </div>
          </div>
          <div className="mapcontent mapcontent2">
            <div className="mapcontentsub mapcontent2-1 active">
              <div className="mapbox">
                <div className="map" id="map21"></div>
                <div className="address">
                  <div className="maptitle">하남센터</div>
                  <li></li>
                </div>
              </div>
            </div>
            <div className="mapcontentsub mapcontent2-2">
              <div className="mapbox">
                <div className="map" id="map22"></div>
                <div className="address">
                  <div className="maptitle">광주센터</div>
                  <li></li>
                </div>
              </div>
            </div>
            <div className="mapcontentsub mapcontent2-3">
              <div className="mapbox">
                <div className="map" id="map23"></div>
                <div className="address">
                  <div className="maptitle">일산센터</div>
                  <li></li>
                </div>
              </div>
            </div>
            <div className="mapcontentsub mapcontent2-4">
              <div className="mapbox">
                <div className="map" id="map24"></div>
                <div className="address">
                  <div className="maptitle">인천센터</div>
                  <li></li>
                </div>
              </div>
            </div>

            <div className="mapcontentsub mapcontent2-5">
              <div className="mapbox">
                <div className="map" id="map25"></div>
                <div className="address">
                  <div className="maptitle">남양주센터</div>
                  <li></li>
                </div>
              </div>
            </div>

            <div className="mapcontentsub mapcontent2-6">
              <div className="mapbox">
                <div className="map" id="map26"></div>
                <div className="address">
                  <div className="maptitle">대전센터</div>
                  <li></li>
                </div>
              </div>
            </div>

            <div className="mapcontentsub mapcontent2-7">
              <div className="mapbox">
                <div className="map" id="map27"></div>
                <div className="address">
                  <div className="maptitle">세종센터</div>
                  <li></li>
                </div>
              </div>
            </div>

            <div className="mapcontentsub mapcontent2-8">
              <div className="mapbox">
                <div className="map" id="map28"></div>
                <div className="address">
                  <div className="maptitle">군포센터</div>
                  <li></li>
                </div>
              </div>
            </div>

          </div>
          <div className="mapcontent mapcontent3">
            <div className="mapbox">
              <div className="map" id="map3"></div>
              <div className="address">
                <div className="maptitle">동탄센터</div>
                <li></li>
              </div>
            </div>
          </div>
          <div className="mapcontent mapcontent4">
            <div className="mapcontentsub mapcontent4-1 active">
              <div className="mapbox">
                <div className="map" id="map41"></div>
                <div className="address">
                  <div className="maptitle">서이천센터</div>
                  <li></li>
                </div>
              </div>
            </div>
            <div className="mapcontentsub mapcontent4-2">
              <div className="mapbox">
                <div className="map" id="map42"></div>
                <div className="address">
                  <div className="maptitle">덕평센터</div>
                  <li></li>
                </div>
              </div>
            </div>
            <div className="mapcontentsub mapcontent4-3">
              <div className="mapbox">
                <div className="map" id="map43"></div>
                <div className="address">
                  <div className="maptitle">마장센터</div>
                  <li></li>
                </div>
              </div>
            </div>
            <div className="mapcontentsub mapcontent4-4">
              <div className="mapbox">
                <div className="map" id="map44"></div>
                <div className="address">
                  <div className="maptitle">천안센터</div>
                  <li></li>
                </div>
              </div>
            </div>

            <div className="mapcontentsub mapcontent4-5">
              <div className="mapbox">
                <div className="map" id="map45"></div>
                <div className="address">
                  <div className="maptitle">안평리센터</div>
                  <li></li>
                </div>
              </div>
            </div>

            <div className="mapcontentsub mapcontent4-6">
              <div className="mapbox">
                <div className="map" id="map46"></div>
                <div className="address">
                  <div className="maptitle">동이천센터</div>
                  <li></li>
                </div>
              </div>
            </div>

            <div className="mapcontentsub mapcontent4-7">
              <div className="mapbox">
                <div className="map" id="map47"></div>
                <div className="address">
                  <div className="maptitle">양산센터</div>
                  <li></li>
                </div>
              </div>
            </div>

          </div>
        </div>
        </div>
      </CenterDiv>
    </Section4>
  );
};

export default TimfCenter;
