import { Link } from "react-scroll";
import styled from "styled-components";
import { Arrow, SubImages, SubImgWrapper, ShadowBg } from "../../shared";

const TransportationAbout = () => {
  const SubImagesBg = styled.div`
    position: absolute;
    display: table;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @media only screen and (max-width: 480px) {
      height: 180vh;
    }
  `;

  const BgBlack = styled(SubImagesBg)`
    background: rgb(0 0 0 / 30%);

    .sub_images_title {
      position: absolute;
      bottom: 45%;
      left: 0;
      width: 100%;
      font-weight: 700;
      font-size: 55px;
      line-height: 65px;
      color: ${({ theme }) => theme.colors.white};
      z-index: 2;
    }
  `;
  const SubImg = styled(SubImgWrapper)`
    background: url(../skin/images/sub_images0201_3.jpg) no-repeat 50% 50%;
    background-size: cover;
  `;

  return (
    <SubImages className="subimages subimages0201">
      <BgBlack>
        <div className="sub_images_title">
          차량, 요청사항에 맞춰
          <br />
          냉장/상온차량을 제공합니다
        </div>
        <Arrow className="sub_images_arrow">
          <Link className="anchorLink" to="sectionTrans" smooth={true}>
            <i className="xi-angle-down"></i>
          </Link>
        </Arrow>
        <ShadowBg className="shadow_bg">&nbsp;</ShadowBg>
      </BgBlack>
      <SubImg className="subimg subimg020102">&nbsp;</SubImg>
    </SubImages>
  );
};

export default TransportationAbout;
