import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { CenterDiv, SubSection } from "../shared";

const Section13 = styled(SubSection)`
  .section_text {
    margin-top: -25px;
    float: left;
    font-weight: 300;
    font-size: 50px;
    line-height: 65px;
    color: rgb(0 0 0);
    span {
      font-weight: 700;
    }
  }

  .section_btn {
    margin-top: 150px;
  }

  .section_img {
    margin-top: -25px;
    position: relative;
    float: left;
    width: 55%;
    padding: 0 20px;
    box-sizing: border-box;

    .delivery_more {
      position: relative;
      float: right;
      width: 70px;
      height: 70px;
      font-family: "Pretendard";
      text-align: center;
      font-weight: 100;
      font-size: 60px;
      line-height: 60px;
      color: rgb(255 255 255);
      background: rgb(2 32 74);
      margin: 30px 85px 30px 0;
      cursor: pointer;
      left: 100px;
      :before {
        position: absolute;
        top: 49%;
        right: 70px;
        width: 550px;
        height: 2px;
        background: rgb(2 32 74);
        content: "";
      }
    }
  }
`;

const Section13Bg = styled(Section13)`
  display: block;
  background: rgba(0, 0, 0, 0.03);
  padding: 100px;
  h1 {
    font-size: 32px;
  }
  p {
    font-size: 22px;
  }
  button {
    background: rgb(255, 255, 255);
    font-weight: 600;
    color: rgb(2, 32, 75);
    border: 1px solid rgb(2 21 48 / 90%);
    padding: 15px 10px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 18px;
    i {
      font-weight: 600;
      margin-left: 10px;
    }
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const AllDeliveryMap = styled.img`
  display: ${(props) => (props.deliveryState === "all" ? "block" : "none")};
  animation: ${fadeIn} 0.75s ease-in;
`;

const PartDeliveryMap = styled.img`
  display: ${(props) => (props.deliveryState === "part" ? "block" : "none")};
  animation: ${fadeIn} 0.75s ease-in;
`;

const Button = styled.button`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 250px;
  height: 40px;
  margin: 10px;
  padding-left: 15px;
  border-radius: 10px;
  border: none;
  background: ${(props) =>
    props.all ? "rgb(250, 247, 247)" : "rgb(2, 32, 75)"};
  color: ${(props) => (props.all ? "rgb(2, 32, 75)" : "white")};
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  font-size: 17px;
  cursor: pointer;

  img {
    width: 50px;
  }
`;

const downloadFile = (url) => {
  url = "최신_ECOLS_물류솔루션-압축됨.pdf";

  fetch(url, { method: "GET" })
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "ECOLS 물류솔루션 상품 소개서.pdf";
      document.body.appendChild(a);
      a.click();
      setTimeout((_) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch((err) => {
      console.error("err: ", err);
    });
};

const DeliveryLocation = () => {
  const [deliveryState, setDeliveryState] = useState("all");
  console.log(deliveryState);

  const onClick = (event) => {
    const target = event.target.innerText;
    if (target === "전체 배송 가능지역") {
      setDeliveryState("all");
    } else {
      setDeliveryState("part");
    }
  };
  return (
    <>
      <Section13 className="sub_section sub_section6">
        <CenterDiv className="centerdiv">
          <TopWrapper>
            <div className="section_text">
              <span>새벽배송 가능지역</span>을<br />
              확인해보세요
            </div>
            <div className="section_img">
              <div
                className="delivery_more"
                onClick={() => {
                  window.open(
                    "https://tms.teamfresh.co.kr/popup/deliveryAreaSearchPopupForCustomer",
                    "_blank",
                    "width=450px,height=900px,toolbars=no,scrollbars=no"
                  );
                }}
              >
                +
              </div>
            </div>
          </TopWrapper>

          <BottomWrapper>
            <div className="section_btn">
              <Button onClick={onClick} id="all" all>
                <span>전체 배송 가능지역</span>
                <img src="./skin/images/arrow_navy_icon.png" />
              </Button>
              <Button onClick={onClick} id="part">
                <span>부분 배송 가능지역</span>
                <img src="./skin/images/arrow_white_icon.png" />
              </Button>
            </div>

            <AllDeliveryMap
              src="./skin/images/delivery_all.png"
              className="allDeliveryImg"
              style={{ width: "700px" }}
              deliveryState={deliveryState}
            />
            <PartDeliveryMap
              src="./skin/images/delivery_part.png"
              className="partDeliveryImg"
              style={{ width: "700px" }}
              deliveryState={deliveryState}
            />
          </BottomWrapper>
        </CenterDiv>
      </Section13>
      <Section13Bg>
        <CenterDiv>
          <h1>ECOLS 물류솔루션 상품 소개서</h1>
          <p>
            팀프레시의 물류 서비스 및 사업 실적 기반으로 작성된 상품 소개서를
            열람하실 수 있습니다.
          </p>
          <div>
            <button onClick={downloadFile}>
              최신 ECOLS 물류솔루션<i class="xi-angle-down"></i>
            </button>
          </div>
        </CenterDiv>
      </Section13Bg>
    </>
  );
};

export default DeliveryLocation;
