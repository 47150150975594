import { useState } from "react";
import { Link } from "react-router-dom";

import routes from "../../routes";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  top: 70%;
  left: ${(props) => (props.visible ? "90%" : "79%")};
  z-index: 2;
  @media only screen and (max-width: 480px) {
    top: 80%;
    left: ${(props) => (props.visible ? "88%" : "70%")};
  }
`;

const Icon = styled.div`
  margin: 8px;
  cursor: pointer;
  display: ${(props) => (props.visible ? "block" : "none")};

  img {
    @media only screen and (max-width: 480px) {
      width: 100px;
    }
  }
`;

const IconList = styled.div`
  display: ${(props) => (props.visible ? "none" : "block")};
  position: relative;
  width: 330px;
  height: 190px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px;
`;

const Mascot = styled.img`
  position: absolute;
  top: -30px;
  width: 60px;
`;

const Close = styled.span`
  display: block;
  text-align: right;
  font-size: x-large;
  font-weight: 300;
  color: rgb(2 5 117);
  cursor: pointer;
`;

const Ul = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const Text = styled.li`
  z-index: 2;
  height: 50px;
  background: rgb(250, 247, 247);
  background: linear-gradient(
    90deg,
    rgba(240, 247, 247, 1) 0%,
    rgba(242, 237, 237, 1) 40%,
    rgba(238, 241, 242, 1) 100%
  );
  border-radius: 5px;
  font-size: large;
  font-weight: 700;
  text-align: center;
  line-height: 50px;
  margin: 15px 10px;
  color: rgb(2 5 117);
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  cursor: pointer;
`;

const SideIcons = () => {
  const [visible, setVisible] = useState(true);

  const handleVisible = () => {
    setVisible(!visible);
  };

  const handleScroll = (e) => {
    if (!window.scrollY) return;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Wrapper visible={visible}>
      <Icon onClick={handleScroll} visible={visible}>
        <img src="../skin/images/side_top_icon.png" alt="sideTopIcon" />
      </Icon>
      <Icon onClick={handleVisible} visible={visible}>
        <img src="../skin/images/side_qna_icon.png" alt="sideQnAIcon" />
      </Icon>
      <IconList visible={visible}>
        <Mascot src="../skin/images/side_list_icon.png" alt="sideListIcon" />
        <Close onClick={handleVisible}>⨉</Close>
        <Ul>
          <Link to={routes.inquiry}>
            <Text>문의하기</Text>
          </Link>
          <Link to={routes.faq}>
            <Text>자주 묻는 질문</Text>
          </Link>
        </Ul>
      </IconList>
    </Wrapper>
  );
};

export default SideIcons;
