import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import routes from "../../routes";
import { SectionMain, CenterDiv, MoreBtn } from "../shared";

const MainLink = styled(SectionMain)`
  * {
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    box-sizing: border-box;
  }

  .link_contents {
    &:hover {
      .link_text .link_title:before,
      .link_text .more_btn,
      .link_img {
        opacity: 1;
      }

      .link_text .link_title:before {
        top: 65px;
      }

      .link_text .more_btn {
        margin: 240px auto 0;
      }
    }
    position: relative;
    float: left;
    width: 30.33%;
    height: 500px;
    margin: 0 1.5%;
    border-radius: 15px;
    background: rgb(51 51 51);
    overflow: hidden;
    cursor: pointer;

    .link_text {
      position: absolute;
      top: 15%;
      left: 20%;
      width: 60%;
      text-align: center;
      border: 1px solid rgb(0 0 0 / 0%);
      z-index: 1;
      .link_title {
        position: relative;
        width: 100%;
        font-family: "Pretendard";
        font-size: 40px;
        font-weight: 500;
        line-height: 40px;
        color: ${({ theme }) => theme.colors.white};
        :before {
          position: absolute;
          top: 90px;
          left: 50%;
          width: 1px;
          height: 70px;
          background: ${({ theme }) => theme.colors.white};
          content: "";
          opacity: 0;
          transition: all 0.2s ease;
          -webkit-transition: all 0.2s ease;
        }
      }
      .more_btn {
        width: 40px;
        height: 40px;
        border: 1px solid ${({ theme }) => theme.colors.white};
        border-radius: 100%;
        padding: 2px 0 0 0;
        margin: 280px auto 0;
        opacity: 0;
        img {
          width: 20px;
          filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(137deg)
            brightness(104%) contrast(101%);
        }
      }
    }

    .link_img {
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }
  }

  .link01 {
    .link_img {
      background: url(./skin/images/main_linkimg01.jpg) no-repeat center;
      background-size: cover;
    }
  }
  .link02 {
    .link_img {
      background: url(./skin/images/main_linkimg02.jpg) no-repeat center;
      background-size: cover;
    }
  }
  .link03 {
    .link_img {
      background: url(./skin/images/main_linkimg03.jpg) no-repeat center;
      background-size: cover;
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 40px 0;
    .link_contents {
      margin: 0 0.3%;
      width: 32.7%;
      height: 800px;
    }
  }
`;

const Link = () => {
  const navigate = useNavigate();
  return (
    <MainLink className="main_link">
      <CenterDiv className="centerdiv relattive">
        <div
          className="link_contents link01"
          onClick={() => {
            navigate(routes.timfnews);
          }}
        >
          <div className="link_text">
            <div className="link_title">News</div>
            <MoreBtn className="more_btn">
              <img src="skin/images/arrowicon2.png" alt="" />
            </MoreBtn>
          </div>
          <div className="link_img"></div>
        </div>
        <div
          className="link_contents link02"
          onClick={() => {
            navigate(routes.recruit);
          }}
        >
          <div className="link_text">
            <div className="link_title">Recruit</div>
            <MoreBtn className="more_btn">
              <img src="skin/images/arrowicon2.png" alt="" />
            </MoreBtn>
          </div>
          <div className="link_img"></div>
        </div>
        <div
          className="link_contents link03"
          onClick={() => {
            window.open(
              "https://brunch.co.kr/@f2784a16dd71437#articles",
              "_blank"
            );
          }}
        >
          <div className="link_text">
            <div className="link_title">Timf Story</div>
            <MoreBtn className="more_btn">
              <img src="skin/images/arrowicon2.png" alt="" />
            </MoreBtn>
          </div>
          <div className="link_img"></div>
        </div>
      </CenterDiv>
    </MainLink>
  );
};

export default Link;
