import styled from "styled-components";
import {
  CenterDiv,
  SubSection,
  TextTitle1,
  TextTitle2,
  TextComment,
  SectionImg,
} from "../shared";

const Section12 = styled(SubSection)`
  .section_text {
    float: right;
    position: relative;
    width: 45%;
    padding: 0px 20px;
    box-sizing: border-box;
  }
  .section_img {
    float: right;
    margin-top: -50px;
    padding-bottom: 100px;
    position: relative;
    width: 55%;
    padding: 0 20px;
    box-sizing: border-box;
  }
`;

const Tcc = () => {
  return (
    <Section12 className="sub_section sub_section5">
      <CenterDiv className="centerdiv">
        <div className="section_text">
          <TextTitle1 className="section_text_title1">
            신속한 대처가 가능합니다
          </TextTitle1>
          <TextTitle2 className="section_text_title2">
            새벽 중앙 관제
            <br />
            24시간 연락망
          </TextTitle2>
          <TextComment className="section_text_title_comment">
            TCC(TIMF Control Center)
            <li>전문 CS 전문 인원 배치</li>
            <li>배송사고시 신속한 대처 가능</li>
          </TextComment>
        </div>
        <SectionImg className="section_img">
          <img src="skin/body/sub020101/sectionimg5_1.png" alt="" />
        </SectionImg>
      </CenterDiv>
    </Section12>
  );
};

export default Tcc;
