import styled from "styled-components";
import Footer from "../common/Footer";
import Header from "../common/Header";
import AdminIContent from "./AdminIContent";

const AdminContainer = styled.div`
  #header {
    top: 49px;
  }
`;

const AdminInquiry = () => {
  return (
    <AdminContainer>
      <Header />
      <AdminIContent />
      <Footer />
    </AdminContainer>
  );
};

export default AdminInquiry;
