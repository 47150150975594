import { useState } from "react";
import { Element } from "react-scroll";
import styled from "styled-components";

import ModalBC from "../common/Modal/ModalBC";
import ModalFO from "../common/Modal/ModalFO";
import ModalFU from "../common/Modal/ModalFU";
import ModalIS from "../common/Modal/ModalIS";
import ModalIT from "../common/Modal/ModalIT";
import ModalML from "../common/Modal/ModalML";
import ModalOA from "../common/Modal/ModalOA";
import ModalOC from "../common/Modal/ModalOC";
import ModalPP from "../common/Modal/ModalPP";
import ModalQM from "../common/Modal/ModalQM";
import ModalSA from "../common/Modal/ModalSA";
import ModalSL from "../common/Modal/ModalSL";
import ModalSP2 from "../common/Modal/ModalSP2";
import ModalTM from "../common/Modal/ModalTM";
import ModalTS from "../common/Modal/ModalTS";
import { CenterDiv, SubSection } from "../shared";

const Section32 = styled(SubSection)`
  .interview {
    width: 100%;
    height: 700px;
    font-size: 0;
    overflow: hidden;
    transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;

    .interview_contents {
      vertical-align: top;
      display: inline-block;
      width: 20%;
      padding: 20px 0;
      @media only screen and (max-width: 480px) {
        width: 25%;
      }

      .interview_img {
        width: 200px;
        height: 200px;
        overflow: hidden;
        border-radius: 100%;
        margin: 0 auto;
        cursor: pointer;

        @media only screen and (max-width: 480px) {
          border-radius: 0;
          width: 280px;
          height: 350px;
        }

        &:hover {
          img {
            transform: scale(1.05);
          }
        }

        img {
          width: 100%;
          transition: all 0.2s ease;
          -webkit-transition: all 0.2s ease;
        }
      }

      .interview_name {
        width: 100%;
        font-family: "Pretendard";
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #000;
        margin: 10px 0 0 0;
        cursor: pointer;

        span {
          font-weight: 300;
          font-size: 17px;
          line-height: 23px;
          color: #666;
          display: inline-block;
        }
        @media only screen and (max-width: 480px) {
          font-size: 28px;
          span {
            font-size: 24px;
          }
        }
      }

      .interview_box {
        position: fixed;
        top: 200%;
        left: 0;
        width: 100%;
        min-width: 1150px;
        height: 100%;
        z-index: 1;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
        transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;

        .interview_box_bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 9;
        }

        .relative {
          width: 100%;
          height: 100%;
        }
      }
    }
    @media only screen and (max-width: 480px) {
      height: auto;
    }
  }

  .partner_more {
    clear: both;
    width: 50px;
    height: 50px;
    font-family: "Pretendard";
    text-align: center;
    font-weight: 200;
    font-size: 40px;
    line-height: 43px;
    color: #28328c;
    border: 1px solid #28328c;
    margin: 100px auto 0;
    cursor: pointer;
  }

  .partner_more_hidden {
    display: none;
  }

  .interviewopen {
    height: 1105px;
    @media only screen and (max-width: 480px) {
      height: 2205px;
    }
  }

  // 모달
  .interview_box {
    position: fixed;
    top: 200%;
    left: 0;
    width: 100%;
    min-width: 1150px;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
  }

  .interview_box_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  .relative {
    width: 100%;
    height: 100%;
  }

  .interview_box_contents {
    position: absolute;
    top: 14%;
    left: 12%;
    width: 60%;
    height: 75%;
    background: #fff;
    border: 1px solid #eee;
    z-index: 10;

    @media only screen and (max-width: 480px) {
      width: 77%;
      height: 80%;
    }

    .interview_people_img {
      position: relative;
      float: left;
      width: 50%;
      height: 100%;
      overflow: hidden;

      .interview_close {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 30px;
        color: #000;
        z-index: 1;
        transition: all 0.1s ease;
        -webkit-transition: all 0.1s ease;
        cursor: pointer;
        display: none;
      }

      .interview_people_name {
        position: absolute;
        top: 20px;
        right: 20px;
        text-align: right;
        font-weight: 600;
        font-size: 17px;
        line-height: 30px;
        color: #000;
        z-index: 1;

        span {
          display: inline-block;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #333;
        }
      }

      img {
        width: 100%;
      }
    }

    .interview_comment {
      float: right;
      width: 50%;
      height: 100%;
      border: 20px solid #fff;
      padding: 0 20px 0 0;
      box-sizing: border-box;
      overflow: auto;
      .interview_comment_Q {
        position: relative;
        width: 100%;
        text-align: left;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #000;
        padding-left: 20px;
        box-sizing: border-box;
        :before {
          position: absolute;
          top: 0;
          left: 0;
          content: "Q.";
        }
      }
      .interview_comment_A {
        position: relative;
        width: 100%;
        text-align: left;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
        color: #666;
        padding-top: 5px;
        padding-left: 20px;
        padding-bottom: 30px;
        box-sizing: border-box;
        :before {
          position: absolute;
          top: 5px;
          left: 2px;
          content: "A.";
          font-weight: 300;
          font-size: 16px;
        }
      }
    }
  }
`;

const RecruitInterview = () => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const onClickOpen = () => {
    setIsActive(true);
  };

  const onClickModalOpen = (i) => {
    setIsOpen(!isOpen);
    if (i === 0) {
      setIndex(0);
    } else if (i === 1) {
      setIndex(1);
    } else if (i === 2) {
      setIndex(2);
    } else if (i === 3) {
      setIndex(3);
    } else if (i === 4) {
      setIndex(4);
    } else if (i === 5) {
      setIndex(5);
    } else if (i === 6) {
      setIndex(6);
    } else if (i === 7) {
      setIndex(7);
    } else if (i === 8) {
      setIndex(8);
    } else if (i === 9) {
      setIndex(9);
    } else if (i === 10) {
      setIndex(10);
    } else if (i === 11) {
      setIndex(11);
    } else if (i === 12) {
      setIndex(12);
    } else if (i === 13) {
      setIndex(13);
    } else if (i === 14) {
      setIndex(14);
    }
  };

  return (
    <Element name="sectionRInterview">
      <Section32 className="sub_section sub_section1">
        <CenterDiv className="centerdiv">
        </CenterDiv>

        {isOpen && index === 0 ? (
          <ModalSL isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 1 ? (
          <ModalSA isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 2 ? (
          <ModalIT isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 3 ? (
          <ModalIS isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 4 ? (
          <ModalSP2 isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 5 ? (
          <ModalQM isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}
        {isOpen && index === 6 ? (
          <ModalTS isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 7 ? (
          <ModalOA isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 8 ? (
          <ModalFU isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 9 ? (
          <ModalBC isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 10 ? (
          <ModalOC isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 11 ? (
          <ModalML isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 12 ? (
          <ModalFO isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 13 ? (
          <ModalPP isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}

        {isOpen && index === 14 ? (
          <ModalTM isOpen={isOpen} onhandleClose={onClickModalOpen} />
        ) : null}
      </Section32>
    </Element>
  );
};

export default RecruitInterview;
