import styled from "styled-components";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Faq from "../Customer/Faq";
import FaqCreate from "../Customer/FaqCreate";
import FaqModify from "../Customer/FaqModify";

const AdminContainer = styled.div`
  #header {
    top: 49px;
  }
`;

const AdminQna = ({ url }) => {
  return (
    <AdminContainer>
      <Header />
      {url?.adminPath === "faqCreate" ? (
        <FaqCreate />
      ) : url?.adminPath === "faqModify" ? (
        <FaqModify seq={url?.seq} />
      ) : (
        <Faq />
      )}

      <Footer />
    </AdminContainer>
  );
};

export default AdminQna;
