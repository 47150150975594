import { useState } from "react";

import styled from "styled-components";
import {
  CenterDiv,
  SubContent,
  TextTitle1,
  TextTitle2,
  Table,
  Form,
  Input100,
} from "../../shared";

import { useFormik } from "formik";

import { postInquiry } from "../../../services";

const InquiryBG = styled.div`
  background: url("../skin/body/sub0203/inquiry_bg.jpg") no-repeat fixed 50% 50%;
  background-size: cover;
`;

const InquiryWrapper = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  .sub_contents_title .centerdiv {
    width: 1000px;
    min-height: 500px;
    background: #fff;
    padding: 50px;
  }
`;

const ISubContent = styled(SubContent)`
  padding: 150px 0 150px;

  .franchise_comment {
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    color: #000;
  }
`;

const Border = styled.div`
  width: 1px;
  height: 80px;
  background: #fff;
  margin: 0 auto 30px;
`;

const TextArea = styled.textarea`
  border: 1px solid #dddddd;
  width: 100%;
  height: 99px;
  overflow: auto;
  line-height: 145%;
  padding: 3px;
`;

const Privacy = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: #666;
  width: 100%;
  padding: 15px;
  margin-top: 15px;
  border: 1px solid #dddddd;
  line-height: 140%;
  box-sizing: border-box;
`;

const Checkbox = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin: 20px 0;
  text-align: center;
  color: #000000;
`;

const SendBtn = styled.button`
  display: table;
  font-size: 30px;
  font-weight: 500;
  line-height: 31px;
  color: #fff;
  background: #02204a;
  margin: 10px auto 0;
  padding: 10px 60px 13px;
  border-radius: 35px 0 35px 0;
  cursor: pointer;
`;

const FranchiseInquiry = () => {
  const [brandType, setBrandType] = useState("");
  const [isValid, setIsValid] = useState(false);

  const formik = useFormik({
    initialValues: {
      categorySeq: "",
      title: "",
      companyName: "", //업체명
      contents: "",
      name: "", // 담당자/직급
      phone: {
        first: "",
        second: "",
        third: "",
      },
      email: "",
      password: "",
      agreePrivacy: false,
      useYn: "N",
      monthlyShipment: "",
      monthlyStorage: "",
      goods: "",
      pickupAddress: "",
      salesChannel: "",
      sku: "",
      unit: "",
      loadingUnloadingAddress: "", //상차지주소/하차지주소
      loadingUnloadingTime: "", //상차시간/하차시간
      workingDay: "", //근무일수
      carTonCnt: "", //차량대수/필요대수
    },
    validate: (values) => {
      const error = {};
      const regEmail =
        /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;

      const { name, phone, email, agreePrivacy } = values;

      if (brandType === "") {
        return alert("브랜드를 선택해주세요.");
      }

      if (name.length === 0) {
        error.name = "성명을 입력해 주세요";
        return alert(error.name);
      }

      if (email.length === 0) {
        error.email = "이메일을 입력해 주세요";
        return alert(error.email);
      }
      if (!regEmail.test(email)) {
        error.email = "이메일 입력값이 유효하지 않습니다";
        return alert(error.email);
      }

      if (
        phone.first.length === 0 ||
        phone.second.length === 0 ||
        phone.third.length === 0
      ) {
        error.phone = "연락처를 입력해 주세요";
        return alert(error.phone);
      }

      if (agreePrivacy === false) {
        error.agreePrivacy = "개인정보처리방침동의에 체크해 주세요";
        return alert(error.agreePrivacy);
      }
      setIsValid(true);
    },
    validateOnChange: false,
    // enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      if (isValid) {
        const res = await postInquiry(brandType, values);
        if (res) {
          alert("문의가 등록되었습니다.");
          resetForm();
        }
      }
    },
  });

  const onChangeBrandType = (e) => {
    setBrandType(e.target.value);
    formik.resetForm();
  };

  return (
    <InquiryBG>
      <InquiryWrapper>
        <ISubContent className="sub_contents_title" id="subsectionlink1">
          <TextTitle2 className="title2">창업문의</TextTitle2>
          <Border>&nbsp;</Border>
          <CenterDiv className="centerdiv">
            <div>
              <Form onSubmit={formik.handleSubmit}>
                <div className="franchise_comment">
                  궁금하신 점을 남겨주시면 담당자가 확인 후 연락드리겠습니다.
                </div>
                &nbsp;
                <Table className="write_main_table">
                  <tbody>
                    <tr>
                      <td className="write_left_td">브랜드*</td>
                      <td className="write_right_td" colSpan={2}>
                        <select
                          name="categorySeq"
                          className="write_input_select"
                          value={brandType}
                          onChange={onChangeBrandType}
                        >
                          <option value="">브랜드를 선택 해주세요</option>
                          <option value="8">파스타집이야</option>
                          <option value="9">쌀국수집이야</option>
                          <option value="10">덮밥집이야</option>
                          <option value="11">돈까스집이야</option>
                        </select>
                      </td>
                    </tr>

                    <>
                      <tr>
                        <td className="write_left_td">제목</td>
                        <td className="write_right_td" colSpan={2}>
                          <Input100
                            type="text"
                            name="title"
                            value={formik.values.title}
                            className="write_input_text"
                            onChange={formik.handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className="write_left_td">성명*</td>
                        <td className="write_right_td" colSpan={2}>
                          <Input100
                            type="text"
                            name="name"
                            value={formik.values.name}
                            size="11"
                            className="write_input_text"
                            onChange={formik.handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className="write_left_td">연락처*</td>
                        <td className="write_right_td" colSpan={2}>
                          <select
                            name="phone.first"
                            value={formik.values.phone.first}
                            className="write_input_select"
                            onChange={(e) =>
                              formik.setFieldValue(
                                "phone.first",
                                e.target.value
                              )
                            }
                          >
                            <option value=""></option>
                            <option value="010">010</option>
                            <option value="011">011</option>
                            <option value="016">016</option>
                            <option value="017">017</option>
                            <option value="018">018</option>
                            <option value="019">019</option>
                          </select>
                          &nbsp;-&nbsp;
                          <input
                            type="text"
                            size="6"
                            maxLength="4"
                            className="write_input_text"
                            name="phone.second"
                            value={formik.values.phone.second}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "phone.second",
                                e.target.value
                              )
                            }
                          />
                          &nbsp;-&nbsp;
                          <input
                            type="text"
                            size="6"
                            maxLength="4"
                            className="write_input_text"
                            name="phone.third"
                            value={formik.values.phone.third}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "phone.third",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className="write_left_td">이메일*</td>
                        <td className="write_right_td" colSpan={2}>
                          <Input100
                            type="text"
                            name="email"
                            value={formik.values.email}
                            className="write_input_text"
                            placeholder="예시)min-jae.park@timf.co.kr"
                            onChange={formik.handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className="write_left_td">내용</td>
                        <td
                          className="write_right_td"
                          colSpan={2}
                          align="center"
                        >
                          <div id="TnT_w_body_size" align="center">
                            <TextArea
                              name="contents"
                              value={formik.values.contents}
                              className="write_input_textarea"
                              onChange={formik.handleChange}
                            />
                          </div>
                        </td>
                      </tr>
                    </>

                    <tr>
                      <td colSpan={3} style={{ padding: 0 }} height={1}>
                        <Privacy>
                          <b>개인정보처리방침</b>
                          <br />
                          <br />
                          ○ 개인정보 수집/이용 목적 : 상담 및 견적문의 등의
                          다양한 정보 제공
                          <br />
                          &nbsp;&nbsp;&nbsp; 수집하는 개인정보의 항목 : 성명,
                          이메일 등<br />
                          <br />
                          ○ 개인정보의 보유 및 이용기간
                          <br />
                          &nbsp;&nbsp;&nbsp; - 보존기간은 1년이며, 정보 제공자가
                          삭제를 요청할 경우 즉시 파기합니다.
                          <br />
                          &nbsp;&nbsp;&nbsp; - 고객님의 정보는 개인정보 보호법에
                          따라 보호되며 위의 사항 외에 별도로 사용하지 않을 것을
                          약속드립니다.
                          <br />
                          <br />
                          ○ 동의 거부권리 안내 : 신청인은 본 개인정보 수집에
                          대한 동의를 거부하실 수 있으며, 이 경우 상담이
                          제한됩니다.
                          <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;본인은 개인정보 처리에 관하여
                          고지 받았으며, 이를 충분히 이해하고 동의합니다.
                        </Privacy>
                        <Checkbox>
                          <input
                            type="checkbox"
                            name="agreePrivacy"
                            checked={formik.values.agreePrivacy}
                            onChange={formik.handleChange}
                          />
                          개인정보처리방침에 동의합니다.
                        </Checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{ paddingTop: "10px" }}>
                        <a>
                          <SendBtn type="submit" className="sendbtn">
                            문의하기
                          </SendBtn>
                        </a>
                        &nbsp; &nbsp;
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </div>
          </CenterDiv>
        </ISubContent>
      </InquiryWrapper>
    </InquiryBG>
  );
};

export default FranchiseInquiry;
