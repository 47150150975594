import styled from "styled-components";
import {
  CenterDiv,
  SubSection,
  TextTitle1,
  TextTitle2,
  TextComment,
  SectionImg,
} from "../../shared";

const ManagingSystem = () => {
  const Section18 = styled(SubSection)`
    background: rgb(0 0 0 / 3%);
    padding: 0;

    .section_text {
      position: relative;
      float: left;
      width: 45%;
      padding: 150px 20px;
      box-sizing: border-box;
    }

    .section_img {
      padding: 100px 0 0;
      text-align: right;
      position: relative;
      float: left;
      width: 55%;
      // padding: 0 20px;
      box-sizing: border-box;
      img {
        max-width: 100%;
      }
    }
  `;

  return (
    <Section18 className="sub_section sub_section2">
      <CenterDiv className="centerdiv">
        <div className="section_text">
          <TextTitle1 className="section_text_title1">
            팀프레시 소속 전문 인력 투입
          </TextTitle1>
          <TextTitle2 className="section_text_title2">
            체계적인
            <br />
            인력운영 시스템
          </TextTitle2>
          <TextComment className="section_text_title_comment">
            시스템을 통한 운송 관리 서비스 제공
            <li>차량 데이터 전용 DB(TIMF Network DB) 구축하여 운영</li>
            <li>화주사 요청에 부합하는 차량 매니저 매칭</li>
          </TextComment>
        </div>
        <SectionImg className="section_img">
          <img src="../skin/body/sub020103/sectionimg2_1.png" alt="" />
        </SectionImg>
      </CenterDiv>
    </Section18>
  );
};

export default ManagingSystem;
