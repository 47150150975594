const ModalFO = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              이상욱
              <br />
              <span>
                프랜차이즈본부,
                <br />
                가맹운영팀(FO)
                <br />
                팀장
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img13.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              프랜차이즈 가맹본부의 일원으로, 가맹점 개설 및 운영 등 전반적인
              점포 운영에 대한 부분을 지원하고 있습니다. 저는 가맹운영팀
              팀장으로, 가맹점과 관련된 업무를 맡고 있습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움 주는 점은?
            </div>
            <div className="interview_comment_A">
              적은 경력에 비해 많은 권한과 기회를 부여받아 경험치를 쌓았습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’는 주니어들이 일하기 좋은 기업인가요?
            </div>
            <div className="interview_comment_A">
              네, 타 회사들에 비해서 주니어에게 정말 많은 기회를 준다는 측면에서
              좋다고 생각합니다. 사실 프랜차이즈 관련 경험은 중고신입 아니고서야
              갖기 어려운게 사실이니까요!
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              열정이 가득하고, 책임감 있는 개개인이 모여 시작한 일은 마무리하는
              팀입니다.
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              실행력이 강하고, 하고자 하는 의지가 있고, 팀워크가 좋은 사람과
              일하고 싶습니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFO;
