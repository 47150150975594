import styled from "styled-components";
import { CenterRelative, SubSection, SubTitle } from "../shared";

import CountUp from "react-countup";

const Section2 = styled(SubSection)`
  background: url(./skin/body/sub0101/map_bg.jpg) fixed no-repeat center;
  background-size: cover;

  .sub_title {
    float: left;
    width: 55%;
    font-size: 50px;
    line-height: 55px;
    text-align: left;
  }

  .highlight_map {
    float: right;
    width: 45%;
    img {
      width: 100%;
      margin-top: -70px;
    }
  }

  .highlight {
    float: left;
    width: 55%;
    margin: 20px 0 0 0;
  }

  .numberdata_section {
    float: left;
    margin: 30px 0 0;
    .numberdata_section_title {
      font-family: "Pretendard";
      text-align: left;
      font-size: 20px;
      font-weight: 300;
      line-height: 25px;
      color: rgb(0 0 0);
    }

    .numberdata_section_number {
      font-family: "Pretendard";
      text-align: left;
      font-size: 22px;
      font-weight: 300;
      line-height: 60px;
      color: rgb(0 0 0);
      span {
        position: relative;
        font-size: 45px;
        font-weight: 800;
        padding: 0 0 0 5px;
      }
    }
  }

  .numberdata_section:nth-child(1),
  .numberdata_section:nth-child(2) {
    width: 50%;
  }

  .numberdata_section:nth-child(3) {
    width: 40%;
  }
  .numberdata_section:nth-child(4) {
    width: 27%;
  }

  .numberdata_section:nth-child(6) .numberdata_section_title {
    font-size: 17px;
  }
`;

const TimfOutput = () => {
  return (
    <Section2>
      <CenterRelative className="centerdiv relative">
        <SubTitle className="sub_title">
          끊임없이 성장하는
          <br />
          사람들이 모여, 기민하게
          <br />
          유의미한 결과를 만듭니다.
        </SubTitle>
        <div className="highlight_map">
          <img src="skin/images/main_highlight_map.png" alt="" />
        </div>
        <div className="highlight">
          <div className="numberdata_section">
            <div className="numberdata_section_title">누적 출고 건수</div>
            <div className="numberdata_section_number">
              약
              <span className="counter">
                <CountUp
                  start={0}
                  end={16000000}
                  enableScrollSpy={true}
                  duration={0.5}
                  separator=","
                >
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              </span>
              건
            </div>
          </div>
          <div className="numberdata_section">
            <div className="numberdata_section_title">누적 배송 건수</div>
            <div className="numberdata_section_number">
              약
              <span className="counter">
                <CountUp
                  start={0}
                  end={25000000}
                  enableScrollSpy={true}
                  duration={0.5}
                  separator=","
                >
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              </span>
              건
            </div>
          </div>
          <div className="numberdata_section">
            <div className="numberdata_section_title">누적 매출액</div>
            <div className="numberdata_section_number">
              약
              <span className="counter">
                <CountUp
                  start={0}
                  end={7660}
                  enableScrollSpy={true}
                  duration={0.5}
                  separator=","
                >
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              </span>
              억원
            </div>
          </div>
          <div className="numberdata_section">
            <div className="numberdata_section_title">누적 거래액</div>
            <div className="numberdata_section_number">
              약
              <span className="counter">
                <CountUp
                  start={0}
                  end={8}
                  enableScrollSpy={true}
                  duration={0.5}
                  separator=","
                >
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              </span>
              조원
            </div>
          </div>
          <div className="numberdata_section">
            <div className="numberdata_section_title">물류센터 규모</div>
            <div className="numberdata_section_number">
              <span className="counter">
                <CountUp
                  start={0}
                  end={43322}
                  enableScrollSpy={true}
                  duration={0.5}
                  separator=","
                >
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              </span>
              평
            </div>
          </div>
          <div className="numberdata_section">
            <div className="numberdata_section_title">2024년 1월 기준</div>
          </div>
        </div>
      </CenterRelative>
    </Section2>
  );
};

export default TimfOutput;
