import styled from "styled-components";
import {
  CenterDiv,
  SubSection,
  TextTitle1,
  TextTitle2,
  TextComment,
  SectionImg,
} from "../shared";

const Section11 = styled(SubSection)`
  padding: 0;
  background: rgb(0 0 0 / 3%);
  .section_text {
    position: relative;
    float: left;
    width: 45%;
    padding: 150px 20px;
    box-sizing: border-box;
  }
`;

const FixedTransport = () => {
  return (
    <Section11 className="sub_section sub_section4">
      <CenterDiv className="centerdiv">
        <div className="section_text">
          <TextTitle1 className="section_text_title1">
            최적의 온도를 유지합니다
          </TextTitle1>
          <TextTitle2 className="section_text_title2">고정 차량</TextTitle2>
          <TextComment className="section_text_title_comment">
            <li>온도 트래킹 장치 탑재</li>
            <li>타코미터(Tachometer)장치 탑재</li>
            <li>배송 숙련도 및 업무 노하우를 보유한 배송 매니저</li>
            <li>냉장(0 - 5℃) 상품 최적 온도 유지 가능</li>
          </TextComment>
        </div>
        <SectionImg className="section_img">
          <img src="skin/body/sub020101/sectionimg4_1.png" alt="" />
        </SectionImg>
      </CenterDiv>
    </Section11>
  );
};

export default FixedTransport;
