import React from "react";
import styled from "styled-components";
import { CenterDiv, SubContent } from "../shared";

const Section = styled(SubContent)`
  text-align: left;
  li {
    list-style: none;
  }
  .title1,
  .title2 {
    text-align: left;
  }

  .privacy_title {
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: #000;
  }

  .sub_section {
    width: 100%;
    padding: 150px 0;
  }
  .sub_section .privacy_comment {
    font-family: "NanumGothic";
    font-size: 14px;
    line-height: 20px;
    color: #666;
  }

  .sub_section .privacy_box {
    clear: both;
    width: 100%;
    margin: 50px 0;
    .privacy_section_title {
      font-family: "Pretendard";
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      color: #000;
    }
    .privacy_section_comment {
      width: 100%;
      font-family: "NanumGothic";
      font-size: 14px;
      line-height: 25px;
      color: #666;
      padding: 20px;
      margin: 5px 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      ul {
        position: relative;
        padding-left: 17px;
      }
      li {
        position: relative;
        padding-left: 15px;
        line-height: 20px;
        margin: 10px 0;
      }
      li:after {
        position: absolute;
        top: 0;
        left: 0;
      }
      li:nth-child(1):after {
        content: "1.";
      }
      li:nth-child(2):after {
        content: "2.";
      }
      li:nth-child(3):after {
        content: "3.";
      }
      li:nth-child(4):after {
        content: "4.";
      }
      li:nth-child(5):after {
        content: "5.";
      }
      li:nth-child(6):after {
        content: "6.";
      }
      li:nth-child(7):after {
        content: "7.";
      }
    }
  }

  .privacy_box .privacy_section_comment table {
    width: 100%;
    border-top: 1px solid #000;
    margin: 5px 0 0;
    td {
      font-family: "NanumGothic";
      font-size: 14px;
      line-height: 20px;
      color: #666;
      padding: 20px;
      padding: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-width: 0 1px 1px 0;
      :nth-child(1) {
        width: 15%;
        border-left-width: 1px;
      }
      :nth-child(2) {
        width: 85%;
      }
    }
  }
  .list_open {
    cursor: pointer;
  }
  .list_close {
    cursor: pointer;
    display: none;
  }
  .partner_list {
    display: none;
  }
`;

const TextBlue = styled.span`
  color: blue;
`;

const TimfPassTerms = () => {
  return (
    <Section className="sub_contents_title" id="subsectionlink1">
      <CenterDiv className="centerdiv">
        <div className="title1">Terms of Use</div>
        <div className="title2">위치기반 서비스 이용약관</div>

        <div className="sub_section">
          <div className="privacy_box">
            <div className="privacy_section_title">제1조(목적) </div>
            <div className="privacy_section_comment">
              본 약관은 회원(주시획사 팀프레시가 제공하는 팀프패스(TimF PASS)
              서비스 약관에 동의한 개인위치정보주체를 말합니다. 이하
              “회원”이라고 합니다)이 주식회사 팀프레시(이하 “회사”라고 합니다)가
              제공하는 팀프패스 서비스(이하 “서비스”라고 합니다)를 이용함에 있어
              회사와 회원의 권리·의무 및 기타 필요한 사항을 규정함을 목적으로
              합니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제2조(이용약관의 효력 및 변경)
            </div>
            <div className="privacy_section_comment">
              ① 본 약관은 서비스를 신청한 고객 또는 개인위치정보주체가 본 약관에
              동의하고 회사가 정한 소정의 절차에 따라 서비스의 이용자로
              등록함으로써 효력이 발생합니다.
              <br />
              <br />
              ② 회원이 온라인에서 본 약관의 "동의하기" 버튼을 클릭하였을 경우 본
              약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한
              것으로 봅니다.
              <br />
              <br />
              ③ 회사는 위치기반서비스의 변경사항을 반영하기 위하여 필요한 경우
              등 에는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법,
              전자 상거래 등에서의 소비자보호에 관한 법률, 소비자기본법, 약관의
              규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을
              개정할 수 있습니다.
              <br />
              <br />
              ④ 회사가 약관을 개정할 경우에는 적용일자, 개정사유, 현행약관 및
              개정 약관의 내용과 개정약관 적용일까지 동의 또는 거부의 의사표시를
              하지 아니하면 개정약관에 동의한 것으로 본다는 내용을 각 명시하여
              다음과 같은 방법으로 게시 및 통지합니다.
              <br />
              <ul>
                <li>
                  서비스 홈페이지 등 게시 : 개정약관 적용일 30일 전부터 적용일
                  이후 상당한 기간(10일 기간)
                </li>
                <li>
                  회원에게 전자적 형태(전자우편, SMS 등)로 개별 통지 : 개정약관
                  적용 일로부터 30일 전
                </li>
              </ul>
              <br />⑤ 회사의 전항에 따른 게시 및 통지 후에도 회원이 개정약관
              적용일까지 개정약관에 대해 동의 또는 거부의 의사표시를 하지 않을
              때에는 회원이 해당 개정약관에 동의한 것으로 봅니다.
              <br />
              <br />⑥ 회원이 개정약관에 동의하지 않을 경우 (회사 또는) 회원은
              이용계약을 해지할 수 있습니다. 이 때, 회사는 계약해지로 인하여
              회원이 입은 손해를 배상합니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">제3조(관계법령의 적용)</div>
            <div className="privacy_section_comment">
              본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에
              명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따릅니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">제4조(서비스의 내용 등)</div>
            <div className="privacy_section_comment">
              ① 회사가 제공하는{" "}
              <TextBlue>
                위치기반서비스 및 개인위치정보의 보유목적과 기간
              </TextBlue>
              은 아래와 같습니다.
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td>서비스 명</td>
                    <td>서비스 내용 및 목적</td>
                  </tr>
                  <tr>
                    <td>
                      팀프패스
                      <br />
                      (TimF PASS)
                    </td>
                    <td>
                      개인 휴대전화의 위치 정보를 활용한 이용자의 출퇴근 내역
                      관리
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              ② 회사는 「위치정보의 보호 및 이용 등에 관한 법률」 제16조제2항에
              따라 위치정보 이용 · 제공사실 확인자료를 자동 기록 · 보존하며,
              해당 자료는 6개월 보관합니다.
              <br />
              <br />③ 회사는{" "}
              <TextBlue>
                회원 본인의 근태 내역 확인을 위해 45일간 개인위치정보를 보관 후
                파기
              </TextBlue>
              합니다. 다만, 다른 법률에 따라 보유하여야 하거나 회원이
              개인위치정보의 보유에 별도로 동의한 경우에는 회원이 동의한
              때로부터 최대 1년간 이를 보유할 수 있습니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              <TextBlue> 제5조(서비스 이용요금 및 조건)</TextBlue>
            </div>
            <div className="privacy_section_comment">
              <TextBlue>
                {" "}
                ① 회사가 제공하는 서비스는 기본적으로 무료입니다. 단, 별도의
                유료 서비스의 경우 해당 서비스에 명시된 요금을 지불하여야 사용
                가능합니다.
                <br />
                <br />
                ② 회사는 유료 서비스 이용요금을 회사와 계약한 전자지불업체에서
                정한 방법에 의하거나 회사가 정한 청구서에 합산하여 청구할 수
                있습니다.
                <br />
                <br />
                ③ 유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은
                회사의 결제 이용약관 등 관계법에 따릅니다.
                <br />
                <br />④ 회원의 개인정보도용 및 결제사기로 인한 환불요청 또는
                결제자의 개인정보 요구는 법률이 정한 경우 외에는 거절될 수
                있습니다.
              </TextBlue>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제6조(서비스 추가 · 변경)
            </div>
            <div className="privacy_section_comment">
              회사가 서비스의 추가 · 변경이 필요한 경우, 제4조에 그 내용을
              반영하여 제2조 제4항 내지 제5항에 따라 게시 및 통지하여야 합니다.
              <br />
              <br />① 회사가 서비스 내용을 변경하거나 종료하는 경우 회사는
              회원의 등록된 전자우편 주소로 이메일을 통하여 서비스 내용의 변경
              사항 또는 종료를 통지할 수 있습니다.
              <br />
              <br />② ①항의 경우 불특정 다수인을 상대로 통지를 함에 있어서는
              웹사이트 등 기타 회사의 공지사항을 통하여 회원들에게 통지할 수
              있습니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제7조(서비스이용의 제한 및 중지)
            </div>
            <div className="privacy_section_comment">
              ① 회사는 아래 각 호에 해당하는 사유가 발생한 경우 서비스의 일부
              혹은 전부의 이용을 제한하거나 중지할 수 있습니다.
              <ul>
                <li>
                  회원이 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우
                </li>
                <li>
                  서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우
                </li>
                <li>
                  전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
                  중지했을 경우
                </li>
                <li>
                  국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주
                  등으로 서비스 이용에 지장이 있는 경우
                </li>
                <li>
                  기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이
                  부적당하다고 인정하는 경우
                </li>
              </ul>
              <br />② 회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나
              중지한 때 에는 그 사유 및 제한기간 등을 서비스 홈페이지 등에
              게시하고 전자적 형태(전자우편, SMS 등)로 개별 통지하여야 합니다.
              <br />
              <br />③ 회사가 전항에 따른 게시 및 통지를 할 수 없는 부득이한
              사유가 있는 경우 사후에 게시 및 통지할 수 있습니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제8조(개인위치정보의 이용 또는 제공)
            </div>
            <div className="privacy_section_comment">
              ① 회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는
              미리 이용약관에 명시한 후 개인위치정보주체의 동의를 얻어야 합니다.
              <br />
              <br />
              ② 회사는 개인위치정보를 회원이 지정하는 제3자에게 제공하는
              경우에는 제공받는 자 및 제공목적을 사전에 회원에게 고지하고 동의를
              받습니다.
              <br />
              <br />
              ③ 제2항에 따라 개인위치정보를 회원이 지정하는 제3자에게 제공하는
              경우에는 개인위치정보를 수집한 해당 통신 단말장치 또는
              전자우편주소 등으로 매회 회원에게 제공받는 자, 제공일시 및
              제공목적(이하 “정보제공내역”이라 합니다)을 즉시 통보합니다.
              <br />
              <br />④ 단, 아래 각 호에 해당하는 경우에는 회원이 미리 특정하여
              지정한 통신단말장치 또는 전자우편주소 등으로 통보합니다.
              <ul>
                <li>
                  개인위치정보를 수집한 해당 통신단말장치가 문자, 음성 또는
                  영상의 수 신기능을 갖추지 아니한 경우
                </li>
                <li>
                  회원이 개인위치정보를 수집한 해당 통신단말장치 외의
                  통신단말장치 또는 전자우편주소 등으로 통보할 것을 미리 요청한
                  경우
                </li>
              </ul>
              <br />⑤ 제3항에도 불구하고 회원은 위치정보법 시행령 제24조에 따라{" "}
              <TextBlue>
                정보제공내역을 30일마다 모아서 통보 받는 방법을 선택
              </TextBlue>
              할 수 있으며, 회원이 회사의 절차에 따라 요청할 경우 전항에 따른
              즉시 통보 방법으로 변경할 수 있습니다.
              <br />
              <br />⑥ 회원은 제1항·제2항·제5항에 따른 동의를 하는 경우
              이용·제공목적, 제공받는 자의 범위 및 위치기반서비스 이용약관의
              내용 중 일부와 회원의 개인위치정보에 대한 제3자 제공의 경우
              통보방법에 대하여 동의를 유보할 수 있습니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제9조(개인위치정보의 이용 또는 제공의 제한)
            </div>
            <div className="privacy_section_comment">
              회사는 회원의 동의가 있거나 다음 각 호의 어느 하나에 해당하는
              경우를 제외하고는 개인위치정보 또는 위치정보 이용·제공사실
              확인자료를 이용약관에 명시 또는 고지한 범위를 넘어 이용하거나
              제3자에게 제공할 수 없습니다.
              <br />
              <br />
              <ul>
                <li>
                  위치기반서비스의 제공에 따른 요금정산을 위하여 위치정보 이용 ·
                  제공사실 확인자료가 필요한 경우
                </li>
                <li>
                  통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼
                  수 없는 형태로 가공하여 제공하는 경우
                </li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제10조(개인위치정보주체의 권리 및 행사방법)
            </div>
            <div className="privacy_section_comment">
              ① 회원은 회사에 대하여 언제든지 개인위치정보를 이용한
              위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의의
              전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 제공받은
              개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다. 단,
              동의의 일부를 철회하는 경우에는 철회하는 부분의 개인위치정보 및
              위치정보 이용 · 제공사실 확인자료에 한합니다.
              <br />
              <br />② 회원은 회사에 대하여 언제든지 개인위치정보의 이용 또는
              제공의 일시적인 중지를 요구할 수 있고, 회사는 이를 거절할 수
              없으며 이를 위한 기술적 수단을 갖추고 있습니다.
              <br />
              <br />③ 회원은 회사에 대하여 아래 각 호의 자료에 대한 열람 또는
              고지를 요구할 수 있고, 해당 자료에 오류가 있는 경우에는 그 정정을
              요구할 수 있습니다. 이 경우 회사는 정당한 사유 없이 회원의 요구를
              거절할 수 없습니다.
              <ul>
                <li>본인에 대한 위치정보 이용, 제공사실 확인자료</li>
                <li>
                  본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률
                  또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용
                </li>
              </ul>
              <br />④ 회원은 제1항 내지 제3항의 권리행사를 위해 회사의 소정의
              절차를 통해 요구할 수 있습니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제11조(법정대리인의 권리 및 행사방법)
            </div>
            <div className="privacy_section_comment">
              ① 회사는 14세 미만의 회원의 경우, 개인위치정보를 이용한
              위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대하여 해당
              회원과 그 법정대리인 모두의 동의를 받아야 합니다. 이 경우
              법정대리인은 제10조에 의한 회원의 권리를 모두 가집니다.
              <br />
              <br />
              ② 회사는 다음 각 호의 어느 하나에 해당하는 방법으로 법정대리인이
              동의했는지를 확인합니다.
              <br />
              <ul>
                <li>
                  동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를
                  표시하도록 하고 위치정보사업자등이 그 동의 표시를 확인했음을
                  법정대리인의 휴대전화 문자메시지로 알리는 방법
                </li>
                <li>
                  동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를
                  표시하도록 하고 법정대리인의 신용카드ᆞ직불카드 등의 카드정보를
                  제공받는 방법
                </li>
                <li>
                  동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를
                  표시하도록 하고 법정대리인의 휴대전화 본인인증 등을 통해 본인
                  여부를 확인하는 방법
                </li>
                <li>
                  동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나, 우편
                  또는 팩스를 통하여 전달하고 법정대리인이 동의 내용에 대하여
                  서명날인 후 제출하도록 하는 방법
                </li>
                <li>
                  동의 내용이 적힌 전자우편을 발송하여 법정대리인으로부터 동의의
                  의사표시가 적힌 전자우편을 전송받는 방법
                </li>
                <li>
                  전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 얻거나
                  인터넷주소 등 동의 내용을 확인할 수 있는 방법을 안내하고 재차
                  전화 통화를 통하여 동의를 얻는 방법
                </li>
                <li>
                  그 밖에 제1호부터 제6호까지의 규정에 따른 방법에 준하는
                  방법으로 법정대리인에게 동의 내용을 알리고 동의의 의사표시를
                  확인하는 방법
                </li>
              </ul>
              <br />③ 회원 및 법정대리인의 권리와 그 행사방법은 제소 당시의
              이용자의 주소에 의하며, 주소가 없는 경우에는 거소를 관할하는
              지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는
              거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의
              관할법원에 제기합니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제12조(8세 이하의 아동 등의 보호의무자의 권리 ・ 의무 및 행사방법)
            </div>
            <div className="privacy_section_comment">
              ① 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동등”이라
              합니다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를
              위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의
              동의가 있는 것으로 봅니다.
              <br />
              <ul>
                <li>8세 이하의 아동</li>
                <li>피성년후견인</li>
                <li>
                  「장애인복지법」제2조제2항제2호의 규정에 의한 정신적 장애를
                  가진 자로서 「장애인고용촉진 및 직업재활법」 제2조제2호의
                  규정에 의한 중증 장애인에 해당하는 자(「장애인복지법」
                  제32조의 규정에 의하여 장애인 등록을 한 자에 한합니다)
                </li>
              </ul>
              <br />② 제1항에 따른 8세 이하의 아동등의 보호의무자는 8세 이하의
              아동등을 사실상 보호하는 자로서 다음 각 호의 어느 하나에 해당하는
              자를 말합니다.
              <ul>
                <li>
                  8세 이하의 아동의 법정대리인 또는 「보호시설에 있는 미성년자의
                  후견 직무에 관한 법률」 제3조에 따른 후견인
                </li>
                <li>피성년후견인의 법정대리인</li>
                <li>
                  제1항제3호의 자의 법정대리인 또는 「장애인복지법」
                  제58조제1항제1호에 따른 장애인 거주시설(국가 또는
                  지방자치단체가 설치 · 운영하는 시설로 한정합니다)의 장,
                  「정신건강증진 및 정신질환자 복지서비스 지원에 관한 법률」
                  제22조에 따른 정신요양시설의 장 및 같은 법 제26조에 따른
                  정신재활시설(국가 또는 지방자치단체가 설치 · 운영하는 시설로
                  한정합니다)의 장
                </li>
              </ul>
              <br />③ 제1항에 따른 동의를 하고자 하는 8세 이하 아동등의
              보호의무자는 각 호의 사항을 기재하여 기명날인 또는 서명한
              서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에
              제출하여야 합니다.
              <ul>
                <li>8세 이하의 아동등의 성명, 주소 및 생년월일</li>
                <li>보호의무자의 성명, 주소 및 연락처</li>
                <li>
                  개인위치정보 이용 또는 제공의 목적이 8세 이하의 아동등의 생명
                  또는 신체의 보호에 한정된다는 사실
                </li>
                <li>동의의 연월일</li>
              </ul>
              <br />④ 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는
              제공에 동의하는 경우 제10조에 의한 회원의 권리 전부를 행사할 수
              있습니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제13조 (위치정보관리책임자의 지정)
            </div>
            <div className="privacy_section_comment">
              ① 회사는 위치정보를 적절히 관리 · 보호하고 개인위치정보주체의
              불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에
              있는 자를 위치정보관리책임자로 지정해 운영합니다.
              <br />
              <br />② 위치정보관리책임자는 위치기반서비스를 제공하는 부서의
              부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">제14조(손해배상)</div>
            <div className="privacy_section_comment">
              회사가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의
              규정을 위반한 행위로 회원에게 손해가 발생한 경우 회원 은 회사에
              대하여 손해배상 청구를 할 수 있습니다. 이 경우 회사는 고의, 과실이
              없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제15조(준거법 및 재판관할)
            </div>
            <div className="privacy_section_comment">
              ① 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.
              <br />
              <br />② 회원 및 법정대리인의 권리와 그 행사방법은 제소 당시의
              이용자의 주 소에 의하며, 주소가 없는 경우에는 거소를 관할하는
              지방법원의 전속관할 로 합니다. 다만, 제소 당시 이용자의 주소 또는
              거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의
              관할법원에 제기합니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제16조(분쟁의 조정 및 기타)
            </div>
            <div className="privacy_section_comment">
              ① 회사는 위치정보와 관련된 분쟁에 대해 당 사자간 협의가 이루어지지
              아니하거나 협의를 할 수 없는 경우에는 「위치 정보의 보호 및 이용
              등에 관한 법률」 제28조의 규정에 의해 방송통신위 원회에 재정을
              신청할 수 있습니다.
              <br />
              <br />② 회사 또는 회원은 위치정보와 관련된 분쟁에 대해 당사자간
              협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는
              「개인정보보호법」 제43 조의 규정에 의한 개인정보분쟁조정위원회에
              조정을 신청할 수 있습니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">제17조(회사의 연락처)</div>
            <div className="privacy_section_comment">
              회사의 상호 및 주소 등은 다음과 같습니다.
              <ul>
                <li>상호 : 주식회사 팀프레시</li>
                <li>대표자 : 이성일</li>
                <li>주소 : 서울 강남구 봉은사로 37길 7-9 글래드큐브빌딩</li>
                <li>대표전화 : 02-423-0529</li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">부칙</div>
            <div className="privacy_section_comment">
              제1조(시행일) 이 약관은 2023년 05월 01일부터 시행한다.
              <br />
              제2조 위치정보관리책임자는 2022년 10월을 기준으로 다음과 같이
              지정합니다.
              <ul>
                <li>소속 : 기술본부/본부장</li>
                <li>이름 : 서영락</li>
                <li>대표전화 : 02-423-0529</li>
              </ul>
              제3조 이용약관의 변경 사항은 글자 색상을 활용하여
              <span style={{ color: "blue" }}> 푸른색</span>으로 표시합니다.
            </div>
          </div>
        </div>
      </CenterDiv>
    </Section>
  );
};

export default TimfPassTerms;
