import styled from "styled-components";
import { CenterRelative, SubSection, SubTitle, CardContents } from "../shared";

const Section1 = styled(SubSection)`
  background: linear-gradient(
    to bottom,
    rgb(0 0 0 / 3%) 0% 63%,
    rgb(0 0 0 / 0%) 63% 100%
  );

  .contents_box:nth-child(1) .contents_icon {
    background: url(./skin/images/timfpointimg01.jpg) no-repeat center;
    background-size: cover;
  }

  .contents_box:nth-child(2) .contents_icon {
    background: url(./skin/images/timfpointimg02.jpg) no-repeat center;
    background-size: cover;
  }
  .contents_box:nth-child(3) .contents_icon {
    background: url(./skin/images/timfpointimg03.jpg) no-repeat center;
    background-size: cover;
  }
  .contents_box:nth-child(4) .contents_icon {
    background: url(./skin/images/timfpointimg04.jpg) no-repeat center;
    background-size: cover;
  }
`;

const TimfService = () => {
  return (
    <Section1 className="sub_section sub_section1">
      <CenterRelative className="relative">
        <SubTitle className="sub_title">
          <div className="sub_title_eng">Timf Service</div>
          팀프레시 서비스
          <div className="sub_title_comment">
            삶의 질을 높이는 서비스로 콜드체인 혁신을 이끌어 가고자 합니다.
          </div>
        </SubTitle>
        <CardContents className="section1_contents">
          <div className="contents_box">
            <div className="contents_icon">
              <div className="contents_icon_bg">
                <img src="skin/images/icon16.png" alt="" />
              </div>
            </div>
            <div className="contents_title">원스톱 풀필먼트</div>
            <div className="contents_comment">
              맞춤 물류 서비스는
              <br />
              발주부터 출고까지
              <br />
              안정성과 편의성을 보장합니다.
            </div>
          </div>
          <div className="contents_box">
            <div className="contents_icon">
              <div className="contents_icon_bg">
                <img src="skin/images/icon05.png" alt="" />
              </div>
            </div>
            <div className="contents_title">B2B 식자재이커머스</div>
            <div className="contents_comment">
              식자재 유통,
              <br />
              구매와 물류 경쟁력이 관건입니다.
            </div>
          </div>
          <div className="contents_box">
            <div className="contents_icon">
              <div className="contents_icon_bg">
                <img src="skin/images/icon06.png" alt="" />
              </div>
            </div>
            <div className="contents_title">원스톱 창업솔루션</div>
            <div className="contents_comment">
              점주들에게 완벽한 창업 조건을
              <br />
              제시합니다.
            </div>
          </div>
          <div className="contents_box">
            <div className="contents_icon">
              <div className="contents_icon_bg">
                <img src="skin/images/icon07.png" alt="" />
              </div>
            </div>
            <div className="contents_title">팀프인슈어런스</div>
            <div className="contents_comment">
              마음놓고
              <br />
              기업 보험 들어보세요.
            </div>
          </div>
        </CardContents>
      </CenterRelative>
    </Section1>
  );
};

export default TimfService;
