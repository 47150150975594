import { Element, Link } from "react-scroll";
import styled from "styled-components";
import { Arrow, ShadowBg, SubContent } from "../shared";

const SubContentWH = styled(SubContent)`
  background: url(../skin/body/sub0301/inquiry_bg.jpg) no-repeat center;
  margin-top: 100px;
  padding: 0;

  .sub_contents_title_bg {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 150px 0;
    // background: rgba(255, 255, 255, 0.5);
  }

  .shadow_bg1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    font-size: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    z-index: 1;
  }

  .title_textbox {
    position: relative;
    width: 1210px;
    // background: rgba(0, 0, 0, 0.7);
    padding: 150px 0 180px;
    margin: 0 auto;
    border-radius: 10px;
  }

  .title2,
  .title3 {
    color: #fff;
  }

  .title3 {
    font-weight: 400;
  }

  .shadow_bg2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    font-size: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    z-index: 1;
  }
`;

const Arrow50 = styled(Arrow)`
  top: 395px;
`;

const SubImagesBg = styled.div`
  position: absolute;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @media only screen and (max-width: 480px) {
    height: 180vh;
  }
`;

const BgBlack = styled(SubImagesBg)`
  background: rgb(0 0 0 / 30%);

  .sub_images_title {
    position: absolute;
    bottom: 45%;
    left: 0;
    width: 100%;
    font-weight: 700;
    font-size: 55px;
    line-height: 65px;
    color: ${({ theme }) => theme.colors.white};
    z-index: 2;
  }
`;

const ShadowBgBlack = styled(ShadowBg)`
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @media only screen and (max-width: 480px) {
    height: 180vh;
  }
`;

const RecruitIntro = () => {
  return (
    <div id="sectionRecruit">
      <SubContentWH className="sub_contents_title" id="subsectionlink1">
        <ShadowBgBlack className="sub_contents_title_bg">
          <div className="shadow_bg1">&nbsp;</div>
          <div className="title_textbox">
            <div className="title2">신뢰 · 기민 · 성장</div>
            <div className="title3">
              팀원들 스스로 끊임없이 노력하고 성장하지 않았다면,
              <br />
              팀프레시는 없었을 것 입니다.
              <br />
              언제나 팀프레시는 모든 구성원들을 신뢰하고
              <br />
              존중하겠습니다.
            </div>
            <Arrow50 className="sub_images_arrow sub_images_arrow2">
              <Link className="anchorLink" to="sectionRInterview" smooth={true}>
                <i className="xi-angle-down"></i>
              </Link>
            </Arrow50>
          </div>

          <ShadowBg className="shadow_bg2">&nbsp;</ShadowBg>
        </ShadowBgBlack>
      </SubContentWH>
    </div>
  );
};

export default RecruitIntro;
