import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import routes from "../../routes";
import { getTimfNewsDetail, putTimfNewsModify } from "../../services";
import {
  CenterDiv,
  CSubContent,
  Table,
  Form,
  Input,
  Input100,
} from "../shared";

const NSubContent = styled(CSubContent)`
  background: rgba(0, 0, 0, 0.03);

  .sub_contents_title .snslink {
    display: table;
    margin: 50px auto 0;
  }
  .sub_contents_title .snslink li {
    display: inline-block;
    float: none;
    width: 60px;
    height: 60px;
    padding: 10px 0 0 0;
    margin: 0 20px 0 0;
    background: #02204a;
  }
  .sub_contents_title .snslink li i {
    font-size: 37px;
  }
  .sub_contents_title .snslink li img {
    height: 37px;
  }

  .title1,
  .title2 {
    text-align: left;
  }

  .write_check_td {
    border-bottom: 1px solid #e5e5e5;
  }
`;

const TimfNewsModify = ({ seq }) => {
  const url = useLocation();

  const { newsSeq } = useParams();
  const navigate = useNavigate();

  const [newsContents, setNewsContents] = useState({
    title: "",
    url: "",
    author: "",
  });

  const getNewsContents = async () => {
    let res = undefined;
    if (seq) {
      res = await getTimfNewsDetail(seq);
    } else {
      res = await getTimfNewsDetail(newsSeq);
    }

    setNewsContents(res);
  };

  const onClickNewModify = async () => {
    let res = undefined;
    if (seq) {
      res = await putTimfNewsModify(seq, newsContents);
    } else {
      res = await putTimfNewsModify(newsSeq, newsContents);
    }

    if (res) {
      alert("수정되었습니다.");
      navigate(
        url.pathname === "/admin" ? `${routes.admin}` : "/customer/timfnews"
      );
    }
  };
  useEffect(() => {
    getNewsContents();
  }, []);
  return (
    <NSubContent className="sub_contents_title" id="subsectionlink1">
      <CenterDiv className="centerdiv">
        <div className="title1">TimF News</div>
        <div className="title2">팀프뉴스</div>
      </CenterDiv>
      <CenterDiv className="centerdiv">
        <div>
          <Form
            method="post"
            name="TechnoteWriteForm"
            id="TechnoteWriteForm_id"
            action="/php/board.php"
            encType="multipart/form-data"
            target="wrhiddenframe"
          >
            <Table
              cellSpacing="0"
              cellPadding="2"
              border="0"
              className="write_main_table"
            >
              <tbody>
                <tr>
                  <td className="write_left_td">제목</td>
                  <td className="write_check_td">
                    <img
                      src="../../skin_board/a_standard/b_img/icons/write_check1.gif"
                      alt=""
                    />
                  </td>
                  <td className="write_right_td">
                    <Input100
                      type="text"
                      name="subject"
                      size="45"
                      maxLength="200"
                      value={newsContents.title}
                      className="write_input_text"
                      onChange={(e) =>
                        setNewsContents({
                          ...newsContents,
                          title: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td className="write_left_td">홈페이지</td>
                  <td className="write_check_td">
                    <img
                      src="../../../skin_board/a_standard/b_img/icons/write_check1.gif"
                      alt="'"
                    />
                  </td>
                  <td className="write_right_td">
                    <Input100
                      type="text"
                      name="home"
                      size="30"
                      maxLength="350"
                      value={newsContents.url}
                      onChange={(e) =>
                        setNewsContents({
                          ...newsContents,
                          url: e.target.value,
                        })
                      }
                      className="write_input_text"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="write_left_td">출처</td>
                  <td className="write_check_td">
                    <img
                      src="../../skin_board/a_standard/b_img/icons/write_check2.gif"
                      alt=""
                    />
                  </td>
                  <td className="write_right_td">
                    <Input
                      type="text"
                      name="user_add1"
                      size="30"
                      maxLength="20"
                      value={newsContents.author}
                      onChange={(e) =>
                        setNewsContents({
                          ...newsContents,
                          author: e.target.value,
                        })
                      }
                      className="write_input_text"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="write_left_td">기사일자</td>
                  <td className="write_check_td">
                    <img
                      src="../../skin_board/a_standard/b_img/icons/write_check2.gif"
                      alt=""
                    />
                  </td>
                  <td className="write_right_td">
                    <Input
                      type="text"
                      name="user_add2"
                      size="30"
                      maxLength="20"
                      value={newsContents.publishedDate}
                      className="write_input_text"
                    />
                  </td>
                </tr>

                <tr>
                  <td height="1">
                    <span></span>
                  </td>
                </tr>

                <tr>
                  <td colSpan="3" align="center" style={{ paddingTop: "10px" }}>
                    <Link to="#" onClick={onClickNewModify}>
                      <img
                        src="../../../skin_board/a_standard/b_img/i_button/write_save.gif"
                        border="0"
                        align="absmiddle"
                        alt=""
                      />
                    </Link>
                    &nbsp; &nbsp;
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form>
        </div>
      </CenterDiv>
    </NSubContent>
  );
};

export default TimfNewsModify;
