import $ from "jquery";
const { kakao } = window;

export default function KakaoMapScript() {
  $(".menutab li").on("click", function () {
    var menuClass = $(this).attr("data-page");
    $(this).addClass("active").siblings("li").removeClass("active");
    $(".content_wr ." + menuClass + "")
      .addClass("active")
      .siblings("div")
      .removeClass("active");
    map1.relayout();
    map1.setCenter(new kakao.maps.LatLng(37.5082, 127.0363));
    map21.relayout();
    map21.setCenter(new kakao.maps.LatLng(37.523342, 127.191278));
    map22.relayout();
    map22.setCenter(new kakao.maps.LatLng(37.394297, 127.218349));
    map23.relayout();
    map23.setCenter(new kakao.maps.LatLng(37.695338, 126.821806));
    map24.relayout();
    map24.setCenter(new kakao.maps.LatLng(37.477984, 126.664492));
    map25.relayout(); //남양주센터
    map25.setCenter(new kakao.maps.LatLng(37.701100133545324, 127.17963937549995)); //남양주센터
    map26.relayout();
    map26.setCenter(new kakao.maps.LatLng(36.44479209402351, 127.40656457951344));
    map27.relayout();
    map27.setCenter(new kakao.maps.LatLng(36.54797181001643, 127.35124710719118));
    map28.relayout();
    map28.setCenter(new kakao.maps.LatLng(37.33097133189732, 126.93721818242182));


    map3.relayout();
    map3.setCenter(new kakao.maps.LatLng(37.15396, 127.115479));
    map41.relayout();
    map41.setCenter(new kakao.maps.LatLng(37.284362, 127.394693));
    map42.relayout();
    map42.setCenter(new kakao.maps.LatLng(37.24422, 127.361254));
    map43.relayout();
    map43.setCenter(new kakao.maps.LatLng(37.260995, 127.37115));
    map44.relayout();
    map44.setCenter(new kakao.maps.LatLng(36.92911337856404, 127.18551696534264));
    map45.relayout();
    map45.setCenter(new kakao.maps.LatLng(37.2381986148341, 127.41843012658599));
    map46.relayout();
    map46.setCenter(new kakao.maps.LatLng(37.22697017121761, 127.51121326990649));
    map47.relayout();
    map47.setCenter(new kakao.maps.LatLng(35.382970657829446, 129.0184022601795));

  });

  //지도1
  var mapContainer1 = document.getElementById("map1"),
    mapOption1 = {
      center: new kakao.maps.LatLng(37.5082, 127.0363),
      level: 5,
    };
  var map1 = new kakao.maps.Map(mapContainer1, mapOption1);
  var markerPosition1 = new kakao.maps.LatLng(37.5082, 127.0363);
  var marker1 = new kakao.maps.Marker({
    position: markerPosition1,
  });
  marker1.setMap(map1);

  //지도2-1
  var mapContainer21 = document.getElementById("map21"),
    mapOption21 = {
      center: new kakao.maps.LatLng(37.523342, 127.191278),
      level: 6,
    };
  var map21 = new kakao.maps.Map(mapContainer21, mapOption21);
  var markerPosition21 = new kakao.maps.LatLng(37.523342, 127.191278);
  var marker21 = new kakao.maps.Marker({
    position: markerPosition21,
  });
  marker21.setMap(map21);

  //지도2-2
  var mapContainer22 = document.getElementById("map22"),
    mapOption22 = {
      center: new kakao.maps.LatLng(37.394297, 127.218349),
      level: 6,
    };
  var map22 = new kakao.maps.Map(mapContainer22, mapOption22);
  var markerPosition22 = new kakao.maps.LatLng(37.394297, 127.218349);
  var marker22 = new kakao.maps.Marker({
    position: markerPosition22,
  });
  marker22.setMap(map22);

  //지도2-3
  var mapContainer23 = document.getElementById("map23"),
    mapOption23 = {
      center: new kakao.maps.LatLng(37.695338, 126.821806),
      level: 6,
    };
  var map23 = new kakao.maps.Map(mapContainer23, mapOption23);
  var markerPosition23 = new kakao.maps.LatLng(37.695338, 126.821806);
  var marker23 = new kakao.maps.Marker({
    position: markerPosition23,
  });
  marker23.setMap(map23);

  //지도2-4
  var mapContainer24 = document.getElementById("map24"),
    mapOption24 = {
      center: new kakao.maps.LatLng(37.477984, 126.664492),
      level: 6,
    };
  var map24 = new kakao.maps.Map(mapContainer24, mapOption24);
  var markerPosition24 = new kakao.maps.LatLng(37.477984, 126.664492);
  var marker24 = new kakao.maps.Marker({
    position: markerPosition24,
  });
  marker24.setMap(map24);

  //지도2-5
  var mapContainer25 = document.getElementById("map25"),
    mapOption25 = {
      center: new kakao.maps.LatLng(37.701100133545324, 127.17963937549995),
      level: 6,
    };
  var map25 = new kakao.maps.Map(mapContainer25, mapOption25);
  var markerPosition25 = new kakao.maps.LatLng(
    37.701100133545324,
    127.17963937549995
  );
  var marker25 = new kakao.maps.Marker({
    position: markerPosition25,
  });
  marker25.setMap(map25);

  //지도2-6
  var mapContainer26 = document.getElementById("map26"),
      mapOption26 = {
        center: new kakao.maps.LatLng(36.44479209402351, 127.40656457951344),
        level: 6,
      };
  var map26 = new kakao.maps.Map(mapContainer26, mapOption26);
  var markerPosition26 = new kakao.maps.LatLng(
      36.44479209402351, 127.40656457951344
  );
  var marker26 = new kakao.maps.Marker({
    position: markerPosition26,
  });
  marker26.setMap(map26);

  //지도2-7
  var mapContainer27 = document.getElementById("map27"),
      mapOption27 = {
        center: new kakao.maps.LatLng(36.54797181001643, 127.35124710719118),
        level: 6,
      };
  var map27 = new kakao.maps.Map(mapContainer27, mapOption27);
  var markerPosition27 = new kakao.maps.LatLng(
      36.54797181001643, 127.35124710719118
  );
  var marker27 = new kakao.maps.Marker({
    position: markerPosition27,
  });
  marker27.setMap(map27);

  //지도2-8
  var mapContainer28 = document.getElementById("map28"),
      mapOption28 = {
        center: new kakao.maps.LatLng(37.33097133189732, 126.93721818242182),
        level: 6,
      };
  var map28 = new kakao.maps.Map(mapContainer28, mapOption28);
  var markerPosition28 = new kakao.maps.LatLng(
      37.33097133189732, 126.93721818242182
  );
  var marker28 = new kakao.maps.Marker({
    position: markerPosition28,
  });
  marker28.setMap(map28);

  //지도3
  var mapContainer3 = document.getElementById("map3"),
    mapOption3 = {
      center: new kakao.maps.LatLng(37.15396, 127.115479),
      level: 6,
    };
  var map3 = new kakao.maps.Map(mapContainer3, mapOption3);
  var markerPosition3 = new kakao.maps.LatLng(37.15396, 127.115479);
  var marker3 = new kakao.maps.Marker({
    position: markerPosition3,
  });
  marker3.setMap(map3);

  //지도4-1
  var mapContainer41 = document.getElementById("map41"),
    mapOption41 = {
      center: new kakao.maps.LatLng(37.284362, 127.394693),
      level: 6,
    };
  var map41 = new kakao.maps.Map(mapContainer41, mapOption41);
  var markerPosition41 = new kakao.maps.LatLng(37.284362, 127.394693);
  var marker41 = new kakao.maps.Marker({
    position: markerPosition41,
  });
  marker41.setMap(map41);

  //지도4-2
  var mapContainer42 = document.getElementById("map42"),
    mapOption42 = {
      center: new kakao.maps.LatLng(37.24422, 127.361254),
      level: 6,
    };
  var map42 = new kakao.maps.Map(mapContainer42, mapOption42);
  var markerPosition42 = new kakao.maps.LatLng(37.24422, 127.361254);
  var marker42 = new kakao.maps.Marker({
    position: markerPosition42,
  });
  marker42.setMap(map42);

  //지도4-3
  var mapContainer43 = document.getElementById("map43"),
    mapOption43 = {
      center: new kakao.maps.LatLng(37.260995, 127.37115),
      level: 6,
    };
  var map43 = new kakao.maps.Map(mapContainer43, mapOption43);
  var markerPosition43 = new kakao.maps.LatLng(37.260995, 127.37115);
  var marker43 = new kakao.maps.Marker({
    position: markerPosition43,
  });
  marker43.setMap(map43);

  //지도4-4
  var mapContainer44 = document.getElementById("map44"),
    mapOption44 = {
      center: new kakao.maps.LatLng(36.92911337856404, 127.18551696534264),
      level: 6,
    };
  var map44 = new kakao.maps.Map(mapContainer44, mapOption44);
  var markerPosition44 = new kakao.maps.LatLng(36.92911337856404, 127.18551696534264);
  var marker44 = new kakao.maps.Marker({
    position: markerPosition44,
  });
  marker44.setMap(map44);

  //지도4-5
  var mapContainer45 = document.getElementById("map45"),
      mapOption45 = {
        center: new kakao.maps.LatLng(37.2381986148341, 127.41843012658599),
        level: 6,
      };
  var map45 = new kakao.maps.Map(mapContainer45, mapOption45);
  var markerPosition45 = new kakao.maps.LatLng(37.2381986148341, 127.41843012658599);
  var marker45 = new kakao.maps.Marker({
    position: markerPosition45,
  });
  marker45.setMap(map45);

  //지도4-6
  var mapContainer46 = document.getElementById("map46"),
      mapOption46 = {
        center: new kakao.maps.LatLng(37.22697017121761, 127.51121326990649),
        level: 6,
      };
  var map46 = new kakao.maps.Map(mapContainer46, mapOption46);
  var markerPosition46 = new kakao.maps.LatLng(37.22697017121761, 127.51121326990649);
  var marker46 = new kakao.maps.Marker({
    position: markerPosition46,
  });
  marker46.setMap(map46);

  //지도4-7
  var mapContainer47 = document.getElementById("map47"),
      mapOption47 = {
        center: new kakao.maps.LatLng(35.382970657829446, 129.0184022601795),
        level: 6,
      };
  var map47 = new kakao.maps.Map(mapContainer47, mapOption47);
  var markerPosition47 = new kakao.maps.LatLng(35.382970657829446, 129.0184022601795);
  var marker47 = new kakao.maps.Marker({
    position: markerPosition47,
  });
  marker47.setMap(map47);
}
