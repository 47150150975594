const ModalQM = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              한희원
              <br />
              <span>
                물류본부,
                <br />
                품질관리팀(QM)
                <br />
                팀장
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img06.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              품질관리팀 ( QM )은 고객의 목소리를 팀프레시 현장에 전달하여 원인
              파악 및 개선을 하는 목표를 가지고 있으며, 고객사와 팀프레시의 모든
              소통 주체인 TCC ( Timf Control Center )와 배송 컨트롤 센터인
              차량관제센터로 구성되어 있어요.
              <br />
              저는 팀프레시의 배송과 생산 VOC 이슈에 대한 모니터링 및 개선
              관리를 담당하고 있으며 정확하고 빠른 피드백을 바탕으로 서비스
              만족도를 높이고 있습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’에서의 색다른 경험이 있었나요?
            </div>
            <div className="interview_comment_A">
              팀프레시에서 B2B 서비스에 대한 'VOC 및 이슈 모니터링'의 모든
              영역을 개선해보고, '문자 발송 비용 감소 및 상담 시스템의 생산성
              향상'의 목표를 고도화하는 신선한 경험을 할 수 있었습니다.
              <br />
              VOC 전 영역에 걸쳐 생성된 VOC 기준에 대한 정리안은 고객사의
              불편사항을 지속적으로 파악하고 개선하여 만족도를 높일 수 있는
              기회를 만들어주었죠.
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              20대 중반부터 40대 초반까지 다양한 연령대가 있어요!
              <br />
              주로 배송 불만에 대한 내용만 듣다 보니 팀 분위기는 무거울거라
              생각할수도 있겠지만 어느 부서보다도 활기차고 밝은 분위기예요.
              긍정적이고 밝은 에너지를 기반으로 팀프레시 서비스를 안내하고
              있으며, VOC에 대해서는 어느 부서보다도 넓은 영역의 지식을 바탕으로
              전문적인 응대를 하고 있습니다.
            </div>
            <div className="interview_comment_Q">본인에게 ‘팀프레시’란?</div>
            <div className="interview_comment_A">
              뜨거운 열정과 끊임없는 노력으로 빠르게 성장하고 있는 회사라고
              생각합니다.
              <br />
              빠른 성장에 간혹 지치긴 하지만,항상 저를 믿어주고 힘을 실어주는
              회사와 팀원들은 제 삶의 원동력이 되어주기도 합니다.
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              긍정적이고 열정을 가진 성향으로 팀프레시 모든 영역에 호기심이 많고
              빠르게 변화하는 팀프레시에 적응할 수 있는 팀원과 같이 일하고
              싶습니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalQM;
