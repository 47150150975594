import styled from "styled-components";
import { CenterDiv, SubSection, SubTitle } from "../../shared";

const FranchiseStep = () => {
  const Section25 = styled(SubSection)`
    background: url(../skin/body/sub0203/process_bg.jpg) fixed no-repeat center;
    background-size: cover;
    padding: 0;

    .sub_section3_bg {
      position: relative;
      width: 100%;
      height: 100%;
      background: rgb(255 255 255 / 90%);
      padding: 150px 0;
    }

    .section_process {
      clear: both;
      display: table;
      width: 100%;
      margin: 50px 0 0 0;
    }
  `;

  return (
    <Section25 className="sub_section sub_section3">
      <div className="sub_section3_bg">
        <CenterDiv className="centerdiv">
          <SubTitle className="sub_title">가맹절차</SubTitle>
          <div className="section_process">
            <img src="../../skin/body/sub0203/process.png" alt="" />
          </div>
        </CenterDiv>
      </div>
    </Section25>
  );
};

export default FranchiseStep;
