import { createGlobalStyle } from "styled-components";

const colors = {
  red: "#f26462",
  black: "rgb(0, 0, 0)",
  white: "rgb(255,255,255)",
  primaryGray: "#3f4150",
  secondaryGray: "#8c8d96",
  tertiaryGray: "#b2b3b9",
  border: "#EFEFEF",
  selected: "#f2f2f2",
  bgGray: "#f7f7f7",
};

export const theme = { colors };

export const GlobalStyles = createGlobalStyle`


* {
  box-sizing:border-box;
}

* {image-rendering: -moz-crisp-edges;/* Firefox */
					image-rendering:   -o-crisp-edges;/* Opera */
					image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
					image-rendering: crisp-edges;
					-ms-interpolation-mode: nearest-neighbor;/* IE (non-standard property) */}

html,body {
  padding: 0;
  margin: 0
}
          
body {
    font-size:14px;
    font-family: "Pretendard";
}

ul {
  padding: 0;
  margin: 0;
}



a {
  text-decoration: none;
  color: inherit;
}
`;
