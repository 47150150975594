import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { getCategoryList } from "../../services";
import {
  CenterDiv,
  SubContent,
  TextTitle1,
  TextTitle2,
  CategoryBox,
} from "../shared";
import InquiryTable from "./InquiryTable";

const InquiryBG = styled.div`
  background: url("../skin/images/sub_images0402.jpg") no-repeat fixed 50% 50%;
  background-size: cover;
`;

const InquiryWrapper = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .sub_contents_title .centerdiv {
    width: 1000px;
    min-height: 500px;
    background: #fff;
    padding: 50px;
  }
`;

const ISubContent = styled(SubContent)`
  padding: 150px 0 150px;

  .title1,
  .title2 {
    color: #fff;
  }

  .franchise_comment {
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    color: #000;
  }
`;

const Border = styled.div`
  width: 1px;
  height: 80px;
  background: #fff;
  margin: 0 auto 30px;
`;

const AdminBContent = () => {
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [cateNum, setCateNum] = useState("파스타집이야");
  const [answerYn, setAnswerYn] = useState("N");
  const [categoryList, setCategoryList] = useState([]);

  const handleCategoryClick = (category) => {
    setCateNum(category);
    setCurrentPage(1);
  };

  const getList = async () => {
    const res = await getCategoryList(
      "INQUIRY",
      cateNum,
      currentPage,
      answerYn
    );
    setCategoryList(res.data);
    setTotalPage(res.totalPages);
  };

  const handleInquiryStateClick = (category) => {
    setAnswerYn(category);
    setCurrentPage(1);
  };

  useEffect(() => {
    getList();
  }, [cateNum, currentPage, answerYn]);

  return (
    <InquiryBG>
      <InquiryWrapper>
        <ISubContent className="sub_contents_title" id="subsectionlink1">
          <TextTitle1 className="title1">Inquiry</TextTitle1>
          <TextTitle2 className="title2">문의하기</TextTitle2>
          <Border>&nbsp;</Border>
          <CenterDiv className="centerdiv">
            <CategoryBox className="category-border-box">
              <ul>
                <li onClick={() => handleInquiryStateClick("N")}>
                  <div className={answerYn === "N" ? "back select" : "back"}>
                    접수완료
                  </div>
                </li>
                <li onClick={() => handleInquiryStateClick("Y")}>
                  <div className={answerYn === "Y" ? "back select" : "back"}>
                    답변완료
                  </div>
                </li>
              </ul>
              <ul>
                {/* <li onClick={() => handleCategoryClick("")}>
                  <div className={cateNum === "" ? "back select" : "back"}>
                    전체보기
                  </div>
                </li> */}
                <li onClick={() => handleCategoryClick("파스타집이야")}>
                  <div
                    className={
                      cateNum === "파스타집이야" ? "back select" : "back"
                    }
                  >
                    파스타집이야
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("쌀국수집이야")}>
                  <div
                    className={
                      cateNum === "쌀국수집이야" ? "back select" : "back"
                    }
                  >
                    쌀국수집이야
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("덮밥집이야")}>
                  <div
                    className={
                      cateNum === "덮밥집이야" ? "back select" : "back"
                    }
                  >
                    덮밥집이야
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("돈까스집이야")}>
                  <div
                    className={
                      cateNum === "돈까스집이야" ? "back select" : "back"
                    }
                  >
                    돈까스집이야
                  </div>
                </li>
              </ul>
            </CategoryBox>
            <InquiryTable
              list={categoryList}
              category={"FRANCHISE"}
              totalPage={totalPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </CenterDiv>
        </ISubContent>
      </InquiryWrapper>
    </InquiryBG>
  );
};

export default AdminBContent;
