import { NavLink } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";

import styled from "styled-components";
import { Arrow, SubImages, SubImgWrapper, ShadowBg } from "../shared";

const SubImg = styled(SubImgWrapper)`
  background: url(./skin/images/sub_images0201_1.jpg) no-repeat 50% 50%;
  background-size: cover;
`;

const ServiceLinkIcon = styled.div`
  position: absolute;
  bottom: 38%;
  left: 50%;
  width: 200px;
  padding: 13px;
  background-color: white;
  color: rgb(2, 10, 95);
  margin-left: -100px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 2px;
`;

const url = "https://dawn.teamfresh.co.kr/";

const ServiceAbout = () => {
  return (
    <SubImages className="subimages subimages0201">
      <div className="sub_images_title">
        새벽배송, 약속한 시간에
        <br />
        정확하고 안전하게 배송합니다
      </div>
      <ServiceLinkIcon onClick={() => window.open(url)}>
        서비스 상세보기 &nbsp; &#8250;
      </ServiceLinkIcon>
      <Arrow>
        <Link className="anchorLink" to="sectionMD" smooth={true}>
          <i className="xi-angle-down"></i>
        </Link>
      </Arrow>
      <ShadowBg className="shadow_bg">&nbsp;</ShadowBg>
      <SubImg className="subimg subimg020101">&nbsp;</SubImg>
    </SubImages>
  );
};

export default ServiceAbout;
