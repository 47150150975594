import styled from "styled-components";
import { SectionMain, CenterDiv, MainTitle } from "../shared";
import { useState } from "react";

const MainPoint = styled(SectionMain)`
  * {
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    box-sizing: border-box;
  }
  box-sizing: border-box;
  padding-top: 0;
  background: linear-gradient(
    to bottom,
    rgb(0 0 0 / 0%) 0% 50%,
    rgb(0 0 0 / 5%) 50% 100%
  );

  .main_point_box {
    width: 100%;
    margin: 50px 0 0 0;
    .point_contents {
      position: relative;
      float: left;
      width: 14.7%;
      height: 500px;
      margin: 0 0.15%;
      background: rgb(0 0 0);
      overflow: hidden;
      .point_text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        z-index: 1;
        .point_title {
          width: 100%;
          font-size: 25px;
          font-weight: 500;
          line-height: 25px;
          color: ${({ theme }) => theme.colors.white};
        }
        .point_comment {
          width: 0;
          height: 0;
          font-size: 17px;
          font-weight: 300;
          line-height: 20px;
          color: ${({ theme }) => theme.colors.white};
          opacity: 0;
          border-top: 1px solid ${({ theme }) => theme.colors.white};
          padding: 10px 0 0 0;
          margin: 10px 0 0 0;
          overflow: hidden;
        }
      }
      .point_img {
        width: 100%;
        height: 100%;
        opacity: 0.3;
      }
    }

    .active {
      width: 54.7%;
      .point_text {
        background: linear-gradient(to bottom, rgb(0 0 0 / 0%), rgb(0 0 0));
        padding: 40px 40px 30px 40px;
        .point_comment {
          width: 100%;
          height: 60px;
          opacity: 1;
        }
      }
      .point_img {
        opacity: 1;
      }
    }

    .point1 .point_img {
      background: url(./skin/images/main_pointimg01.jpg) no-repeat center;
    }

    .point2 .point_img {
      background: url(./skin/images/main_pointimg02.jpg) no-repeat center;
    }

    .point3 .point_img {
      background: url(./skin/images/main_pointimg03.jpg) no-repeat center;
    }

    .point4 .point_img {
      background: url(./skin/images/main_pointimg04.jpg) no-repeat center;
    }
  }
`;

const Point = () => {
  const [point1, setPoint1] = useState(true);
  const [point2, setPoint2] = useState(false);
  const [point3, setPoint3] = useState(false);
  const [point4, setPoint4] = useState(false);

  const ToggleClass = (num) => {
    switch (num) {
      case 2:
        setPoint1(false);
        setPoint2(true);
        setPoint3(false);
        setPoint4(false);
        break;

      case 3:
        setPoint1(false);
        setPoint2(false);
        setPoint3(true);
        setPoint4(false);
        break;

      case 4:
        setPoint1(false);
        setPoint2(false);
        setPoint3(false);
        setPoint4(true);
        break;

      default:
        setPoint1(true);
        setPoint2(false);
        setPoint3(false);
        setPoint4(false);
    }
  };

  return (
    <MainPoint className="main_point">
      <CenterDiv className="centerdiv">
        <MainTitle className="main_title">
          <span>팀프레시를 이끄는 힘</span>
          <br />
          핵심역량
        </MainTitle>
        <div className="main_point_box">
          <div
            className={
              point1 ? "point_contents point1 active" : "point_contents point1"
            }
            onMouseEnter={() => ToggleClass(0)}
          >
            <div className="point_text">
              <div className="point_title">특허 보유 TMS</div>
              <div className="point_comment">
                새벽배송 현황 추적에 최적화된 배차 및 고객 주문관리 시스템
                <br />* TMS : Transportation Management System
              </div>
            </div>
            <div className="point_img"></div>
          </div>
          <div
            className={
              point2 ? "point_contents point2 active" : "point_contents point2"
            }
            onMouseEnter={() => ToggleClass(2)}
          >
            <div className="point_text">
              <div className="point_title">국내 1위 새벽배송</div>
              <div className="point_comment">
                대한민국 1위 안정된 새벽배송망 구축
              </div>
            </div>
            <div className="point_img"></div>
          </div>
          <div
            className={
              point3 ? "point_contents point3 active" : "point_contents point3"
            }
            onMouseEnter={() => ToggleClass(3)}
          >
            <div className="point_text">
              <div className="point_title">24시간 관제</div>
              <div className="point_comment">
                24시간 현장 관제를 통해 상품을 안전하게 배송 완료
              </div>
            </div>
            <div className="point_img"></div>
          </div>
          <div
            className={
              point4 ? "point_contents point4 active" : "point_contents point4"
            }
            onMouseEnter={() => ToggleClass(4)}
          >
            <div className="point_text">
              <div className="point_title">라이선스 보유 차량망</div>
              <div className="point_comment">
                합법적으로 허가 받은 차량만을 섭외해 운영
              </div>
            </div>
            <div className="point_img"></div>
          </div>
        </div>
      </CenterDiv>
    </MainPoint>
  );
};

export default Point;
