import styled from "styled-components";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderContainer = styled.div`
  font-size: 0;
  height: 100%;
  .slick-slider {
    height: 100%;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
      }
    }
  }

  h3 {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: ${({ height }) => (height ? height : "641.359px")};
    vertical-align: bottom;
    margin: 0;
  }
`;

const CarouselMobile = ({
  slide,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  slide7,
  slide8,
  slide9,
  height,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <SliderContainer height={height}>
      <Slider {...settings}>
        <div style={{ height: "100%" }}>
          <h3
            id="slide1"
            style={{ backgroundImage: `url(${slide})` }}
            height={height}
          >
            1
          </h3>
        </div>
        <div>
          <h3 id="slide2" style={{ backgroundImage: `url(${slide2})` }}>
            2
          </h3>
        </div>
        <div>
          <h3 id="slide3" style={{ backgroundImage: `url(${slide3})` }}>
            3
          </h3>
        </div>
        {slide4 ? (
          <div>
            <h3 id="slide4" style={{ backgroundImage: `url(${slide4})` }}>
              4
            </h3>
          </div>
        ) : null}
        {slide5 ? (
          <div>
            <h3 id="slide5" style={{ backgroundImage: `url(${slide5})` }}>
              5
            </h3>
          </div>
        ) : null}
        {slide6 ? (
          <div>
            <h3 id="slide6" style={{ backgroundImage: `url(${slide6})` }}>
              6
            </h3>
          </div>
        ) : null}
        {slide7 ? (
          <div>
            <h3 id="slide7" style={{ backgroundImage: `url(${slide7})` }}>
              7
            </h3>
          </div>
        ) : null}
        {slide8 ? (
          <div>
            <h3 id="slide8" style={{ backgroundImage: `url(${slide8})` }}>
              8
            </h3>
          </div>
        ) : null}
        {slide9 ? (
          <div>
            <h3 id="slide9" style={{ backgroundImage: `url(${slide9})` }}>
              9
            </h3>
          </div>
        ) : null}
      </Slider>
    </SliderContainer>
  );
};

export default CarouselMobile;
