import PagieNumber from "../common/Pagination";
import { useEffect, useState } from "react";
import * as Services from "../../services/index";

import styled from "styled-components";
import { CenterRelative, SubSection, SubTitle, SnsLink } from "../shared";
import PageList from "./NewsPageList";
import { Link, useLocation } from "react-router-dom";
import routes from "../../routes";
import { useStore } from "../../UserContext";

const Section35 = styled(SubSection)`
  background: rgba(0, 0, 0, 0.03);

  @media only screen and (max-width: 480px) {
    height: 100%;
    min-height: 180vh;
    padding-top: 300px;
    .news_container > div {
      border-top: 1px solid #000;
    }
  }

  .news_container {
    display: table;
    width: 100%;
    padding: 50px 0 0 0;
    #mainIndexDiv {
      width: 100%;
      padding: 0;
      > div {
        clear: both;
        width: 100%;
        font-size: 0;
      }
    }
  }

  .sub_title {
    text-align: left;
  }

  .snslink {
    width: 100%;
    text-align: center;
    font-size: 0;
    padding: 50px 0 0 0;

    li {
      display: inline-block;
      float: none;
      width: 60px;
      height: 60px;
      padding: 10px 0 0 0;
      margin: 0 20px 0 0;
      background: #02204a;
      i {
        font-size: 37px;
      }

      img {
        height: 37px;
      }
    }
  }
`;

const TimFNews = () => {
  const { state } = useStore();

  const url = useLocation();

  const [newsList, setNewsList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const getNewsList = async () => {
    const res = await Services.getTimfNews(currentPage);
    if (res) {
      setNewsList(res.data);
      setTotalPage(res.totalPages);
    }
  };

  useEffect(() => {
    getNewsList();
  }, [currentPage]);

  return (
    <Section35>
      <CenterRelative className="centerdiv relattive">
        <SubTitle className="sub_title">
          <div className="sub_title_eng">TimF News</div>
          팀프뉴스
        </SubTitle>
        <div className="news_container">
          <div id="mainIndexDiv">
            <div>
              <PageList list={newsList} updateNewsList={getNewsList} />
              <PagieNumber
                totalPage={totalPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
        {state.user ? (
          <div
            id="mainbutton_td"
            style={{ paddingTop: "30px", textAlign: "left" }}
          >
            <Link
              to={url.pathname === "/admin" ? "#" : routes.timfnewscreate}
              state={
                url.pathname === "/admin" ? { adminPath: "newsCreate" } : null
              }
            >
              <img
                src="../../skin_board/a_standard/b_img/i_button/write.gif"
                align="absmiddle"
                border="0"
                alt=""
              />
            </Link>
          </div>
        ) : null}
        <SnsLink className="snslink">
          <ul>
            <li
              onClick={() =>
                window.open("https://www.facebook.com/teamfresh.timf/")
              }
            >
              <i className="xi-facebook"></i>
            </li>
            <li
              onClick={() =>
                window.open("https://brunch.co.kr/@f2784a16dd71437#articles")
              }
            >
              <img src="../skin/images/sns_brunch.png" alt="" />
            </li>
            <li
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCe04X8Shi4hQnUPvhE2TYHA"
                )
              }
            >
              <i className="xi-youtube-play"></i>
            </li>
          </ul>
        </SnsLink>
      </CenterRelative>
    </Section35>
  );
};

export default TimFNews;
