import { Link } from "react-scroll";
import styled from "styled-components";
import { Arrow, SubImages, SubImgWrapper, ShadowBg } from "../../shared";

const FulfillmentAbout = () => {
  const SubImagesBlack = styled(SubImages)`
    .sub_images_title {
      color: rgb(0, 0, 0);
    }
  `;

  const SubImg = styled(SubImgWrapper)`
    background: url(../skin/images/sub_images0201_2.jpg) no-repeat 50% 50%;
    background-size: cover;
  `;

  return (
    <SubImagesBlack className="subimages subimages0201">
      <div className="sub_images_title">
        상품, 보관부터 배송까지
        <br />
        완벽한 서비스로 만나보세요
      </div>
      <Arrow className="sub_images_arrow">
        <Link className="anchorLink" to="sectionFU" smooth={true}>
          <i className="xi-angle-down"></i>
        </Link>
      </Arrow>
      <ShadowBg className="shadow_bg">&nbsp;</ShadowBg>
      <SubImg className="subimg subimg020102">&nbsp;</SubImg>
    </SubImagesBlack>
  );
};

export default FulfillmentAbout;
