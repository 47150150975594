import { Link } from "react-router-dom";
import styled from "styled-components";
import routes from "../../routes";
import { CenterDiv } from "../shared";

const SFooter = styled.footer`
  display: table;
  width: 100%;
  height: 100%;
  // min-width: 1210px;
  text-align: center;
  background: rgb(25 25 25);
  padding: 50px 0;
  margin: 0;

  .companyinfo_mobile {
    display: none;
  }

  @media only screen and (max-width: 480px) {
    display: flex;
    .companyinfo_mobile {
      display: block;
    }
    .companyinfo {
      display: none;
    }

    #webAdmin {
      display: none;
    }

    #mobileAdmin {
      display: block;
    }
  }

  .footer_top {
    color: #fff;
    display: flex;
    justify-content: space-between;
    // width: 100%;
    @media only screen and (max-width: 480px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .footer_btn li {
        font-size: 32px;
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
    ul {
      display: flex;
    }
    li {
      list-style: none;
      position: relative;
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      color: #ccc;
      margin: 0 15px;
      cursor: pointer;
    }

    .footer_btn li {
      :last-child {
        :after {
          position: absolute;
          top: 7px;
          left: -15px;
          width: 1px;
          height: 10px;
          background: #ccc;
          content: "";
          opacity: 0.5;
          cursor: none;
          @media only screen and (max-width: 480px) {
            top: 0px;
            width: 2px;
            height: 25px;
          }
        }
      }
    }
  }
  .logo {
    float: left;
    margin: 0 0 30px;
    img {
      width: 100px;
    }
  }

  .snslink {
    clear: both;
    float: left;
    width: 100%;
    margin: 30px 0 0 0;
    li {
      float: left;
      width: 40px;
      height: 40px;
      text-align: center;
      background: rgb(0 0 0 / 40%);
      border-radius: 100%;
      overflow: hidden;
      padding: 6px 0 0 0;
      margin: 0 10px 0 0;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
      :nth-child(1):hover {
        background: #18539a;
      }
      :nth-child(3):hover {
        background: #ff0000;
      }
      i {
        color: rgb(255 255 255);
        font-size: 27px;
      }
      img {
        height: 27px;
        margin: 1px 0 0 0;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 70px 0;

    .centerdiv {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .logo {
        img {
          width: 200px;
        }
      }
      .snslink {
        width: auto;
        margin: 40px 0;

        li {
          width: 80px;
          height: 80px;
          margin: 0 30px 0 0;
          i {
            line-height: 1.5;
            font-size: 47px;
          }
          img {
            height: 47px;
            margin: 10px 0 0 0;
          }
        }
      }
    }
  }
`;

const CompanyInfo = styled.div`
  clear: both;
  float: left;
  width: 100%;
  text-align: left;
  font-size: 0;

  ul {
    margin: 0;
    padding: 0;
    > div {
      :last-child {
        width: 100%;
        margin: 10px 0;
      }
    }
  }

  li {
    display: inline-block;
    margin: 0 30px 0 0;
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    color: rgb(204 204 204);
  }

  @media only screen and (max-width: 480px) {
    text-align: center;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      > div:first-child {
        margin-top: 10px;
      }

      > div:last-child {
        margin-top: 20px;
      }
      li {
        font-size: 32px;
        line-height: 1.5;
      }
    }
  }
`;

const Footer = () => {
  return (
    <SFooter>
      <CenterDiv className="centerdiv">
        <div className="footer_top">
          <div className="logo">
            <img src="../skin/images/logo_w.png" alt="" />
          </div>
          <div className="footer_btn">
            <ul>
              <li>
                <Link to={routes.wmsPrivacy}>TWMS 개인(위치)정보 처리방침</Link>
              </li>
              <li>
                <Link to={routes.privacy}>TMS 개인(위치)정보 처리방침</Link>
              </li>
              <li>
                <Link to={routes.terms}>TMS 위치기반 서비스 이용약관</Link>
              </li>
              <li>
                <Link to={routes.timfPassPrivacy}>
                  TimF PASS 개인(위치)정보 처리방침
                </Link>
              </li>
              <li>
                <Link to={routes.timfPassTerms}>
                  TimF PASS 위치기반 서비스 이용약관
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <CompanyInfo className="companyinfo">
          <ul>
            <div>
              <li>(주)팀프레시</li>
              <li>사업자번호 561-88-01138</li>
              <li>대표 이성일</li>
              <li>서울 강남구 봉은사로 37길 7-9 글래드큐브빌딩</li>
              <li>전화 02-423-0525</li>
              <li>팩스 02-3432-0525</li>
            </div>

            <div>
              <li>메일 info@timf.co.kr</li>
              <li>영업 및 제휴 문의 sales@timf.co.kr</li>
            </div>
            <div>
              <li>
                Copyrightⓒ
                <Link to={routes.admin}>TeamFresh Co.,Ltd.</Link>
                All Rights Reserved.
              </li>
            </div>
          </ul>
        </CompanyInfo>
        <CompanyInfo className="companyinfo_mobile">
          <ul>
            <div>
              <li>(주)팀프레시</li>
              <li>사업자번호 561-88-01138</li>
              <li>대표 이성일</li>
            </div>
            <li>서울 강남구 봉은사로 37길 7-9 글래드큐브빌딩</li>
            <li>전화 02-423-0525</li>
            <div></div>

            <div>
              <li>팩스 02-3432-0525</li>
              <li>메일 info@timf.co.kr</li>
            </div>
            <div>
              <li>영업 및 제휴 문의 sales@timf.co.kr</li>
            </div>
            <div>
              <li>
                Copyrightⓒ
                <Link to={routes.admin} id="webAdmin">
                  TeamFresh Co.,Ltd.
                </Link>
                <Link to="#" id="mobileAdmin" style={{ display: "none" }}>
                  TeamFresh Co.,Ltd.
                </Link>
                All Rights Reserved.
              </li>
            </div>
          </ul>
        </CompanyInfo>
        <div className="snslink">
          <ul>
            <li
              onClick={() =>
                window.open("https://www.facebook.com/teamfresh.timf/")
              }
            >
              <i className="xi-facebook"></i>
            </li>
            <li
              onClick={() =>
                window.open("https://brunch.co.kr/@f2784a16dd71437#articles")
              }
            >
              <img src="../skin/images/sns_brunch.png" alt="" />
            </li>
            <li
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCe04X8Shi4hQnUPvhE2TYHA"
                )
              }
            >
              <i className="xi-youtube-play"></i>
            </li>
          </ul>
        </div>
      </CenterDiv>
    </SFooter>
  );
};

export default Footer;
