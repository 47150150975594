import styled from "styled-components";
import {
  CenterRelative,
  SubSection,
  SubTitle,
  CardContents,
} from "../../shared";

const Section23 = styled(SubSection)`
  background: linear-gradient(
    to bottom,
    rgb(0 0 0 / 3%) 0% 63%,
    rgb(0 0 0 / 0%) 63% 100%
  );

  .contents_box {
    height: 420px;
  }
`;

const FranchiseDesc = () => {
  return (
    <Section23 className="sub_section sub_section2">
      <CenterRelative className="centerdiv relative">
        <SubTitle className="sub_title">핵심역량</SubTitle>
        <CardContents className="section1_contents">
          <div className="contents_box">
            <div className="contents_icon">
              <div className="contents_icon_bg">
                <img src="../skin/images/icon16.png" alt="" />
              </div>
            </div>
            <div className="contents_title">최적의 물류 시스템</div>
            <div className="contents_comment">
              콜드체인 시스템으로
              <br />
              가맹점주님이 주문하신 식자재를
              <br />
              신선하게 배송해 드립니다.
            </div>
          </div>
          <div className="contents_box">
            <div className="contents_icon">
              <div className="contents_icon_bg">
                <img src="../skin/images/icon05.png" alt="" />
              </div>
            </div>
            <div className="contents_title">식자재 새벽배송</div>
            <div className="contents_comment">
              식자재 새벽배송 서비스를
              <br />
              도입했습니다.
              <br />
              주문금액에 관계 없이
              <br />주 6회 배송을 보장합니다.
            </div>
          </div>
          <div className="contents_box">
            <div className="contents_icon">
              <div className="contents_icon_bg">
                <img src="../skin/images/icon17.png" alt="" />
              </div>
            </div>
            <div className="contents_title">끊임없는 R&amp;D</div>
            <div className="contents_comment">
              다양한 분야의 전문가로 구성된
              <br />
              R&amp;D 팀을 꾸려, 지속적인
              <br />
              신메뉴 개발 및 품질 향상에
              <br />
              최선을 다하고 있습니다.
            </div>
          </div>
          <div className="contents_box">
            <div className="contents_icon">
              <div className="contents_icon_bg">
                <img src="../skin/images/icon18.png" alt="" />
              </div>
            </div>
            <div className="contents_title">상생 경영</div>
            <div className="contents_comment">
              물류 직영 운영을 통한
              <br />
              유통 마진 감소, 영세 신규 공급사
              <br />
              발굴을 통해 상생 가치를
              <br />
              실현합니다.
            </div>
          </div>
        </CardContents>
      </CenterRelative>
    </Section23>
  );
};

export default FranchiseDesc;
