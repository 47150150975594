import styled from "styled-components";
import Footer from "../common/Footer";
import Header from "../common/Header";
import TimFNews from "../Customer/TimfNews";
import TimfNewsCreate from "../Customer/TimfNewsCreate";
import TimfNewsModify from "../Customer/TimfNewsModify";

const AdminContainer = styled.div`
  #header {
    top: 49px;
  }
`;

const AdminNews = ({ url }) => {
  return (
    <AdminContainer>
      <Header />
      {url?.adminPath === "newsCreate" ? (
        <TimfNewsCreate />
      ) : url?.adminPath === "newsModify" ? (
        <TimfNewsModify seq={url?.seq} />
      ) : (
        <TimFNews />
      )}

      <Footer />
    </AdminContainer>
  );
};

export default AdminNews;
