import { useState } from "react";
import styled from "styled-components";
import { MainTitle, SectionMain, TabBox } from "../shared";

const MainService = styled(SectionMain)`
  width: 100%;
  overflow: hidden;
  li {
    list-style: none;
  }
  .service_img {
    float: right;
    width: 55%;
    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 480px) {
    .service_tabbtn li {
      font-size: 24px;
    }
    .service_contents .service_text .service_text_comment {
      font-size: 26px;
    }
  }
`;

const Service = () => {
  const [isActive, setIsActive] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);

  const ToggleClass = () => {
    setIsActive(true);
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(false);
  };

  const ToggleClass2 = () => {
    setIsActive(false);
    setIsActive2(true);
    setIsActive3(false);
    setIsActive4(false);
  };

  const ToggleClass3 = () => {
    setIsActive(false);
    setIsActive2(false);
    setIsActive3(true);
    setIsActive4(false);
  };

  const ToggleClass4 = () => {
    setIsActive(false);
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(true);
  };

  return (
    <MainService className="main_service">
      <MainTitle className="main_title">
        <span>Serivce Introduction</span>
        <br />
        서비스소개
      </MainTitle>
      <TabBox
        className="service_tabbox"
        id="service1"
        style={{ display: isActive ? "block" : "none" }}
      >
        <div className="service_tabbtn">
          <ul>
            <li className={isActive ? "on" : null} onClick={ToggleClass}>
              <span>
                <img src="skin/images/icon03.png" alt="물류 이미지" />
                물류
              </span>
            </li>
            <li onClick={ToggleClass2}>
              <span>
                <img src="skin/images/icon04.png" alt="유통 이미지" />
                유통
              </span>
            </li>
            <li onClick={ToggleClass3}>
              <span>
                <img src="skin/images/icon06.png" alt="프랜차이즈 이미지" />
                프랜차이즈
              </span>
            </li>
            <li onClick={ToggleClass4}>
              <span>
                <img src="skin/images/icon07.png" alt="보험 이미지" />
                보험
              </span>
            </li>
          </ul>
        </div>
        <div className="service_contents">
          <div className="service_text">
            <div className="service_text_title">
              상품의 관리,
              <br />
              보관부터 배송까지
              <br />
              완벽하게
            </div>
            <div className="service_text_comment">
              이커머스 운영에 최적화된 풀필먼트 센터와
              <br />
              새벽배송망을 보유한 팀프레시의
              <br />
              이커머스 전문 물류 서비스를 제공받으세요.
            </div>
            <div className="service_link">
              <img src="skin/images/arrowicon.png" alt="" />
            </div>
          </div>
          <div className="service_img">
            <img src="skin/images/main_business_img01.jpg" alt="" />
          </div>
        </div>
      </TabBox>
      <TabBox
        className="service_tabbox"
        id="service2"
        style={{ display: isActive2 ? "block" : "none" }}
      >
        <div className="service_tabbtn">
          <ul>
            <li onClick={ToggleClass}>
              <span>
                <img src="skin/images/icon03.png" alt="" />
                물류
              </span>
            </li>
            <li className={isActive2 ? "on" : null} onClick={ToggleClass2}>
              <span>
                <img src="skin/images/icon04.png" alt="" />
                유통
              </span>
            </li>
            <li onClick={ToggleClass3}>
              <span>
                <img src="skin/images/icon06.png" alt="" />
                프랜차이즈
              </span>
            </li>
            <li onClick={ToggleClass4}>
              <span>
                <img src="skin/images/icon07.png" alt="" />
                보험
              </span>
            </li>
          </ul>
        </div>
        <div className="service_contents">
          <div className="service_text">
            <div className="service_text_title">
              식자재 제안,
              <br />
              업장 운영 형태에 맞는
              <br />
              최적의 형태로
            </div>
            <div className="service_text_comment">
              주기적인 단가 관리 시스템을 통해
              <br />
              합리적인 가격의 상품을 제공하고,
              <br />
              자체 물류 역량으로 상품을 안정적으로 공급하고 있습니다.
            </div>
            <div className="service_link">
              <img src="skin/images/arrowicon.png" alt="" />
            </div>
          </div>
          <div className="service_img">
            <img src="skin/images/main_business_img02.jpg" alt="" />
          </div>
        </div>
      </TabBox>
      <TabBox
        className="service_tabbox"
        id="service3"
        style={{ display: isActive3 ? "block" : "none" }}
      >
        <div className="service_tabbtn">
          <ul>
            <li onClick={ToggleClass}>
              <span>
                <img src="skin/images/icon03.png" alt="" />
                물류
              </span>
            </li>
            <li onClick={ToggleClass2}>
              <span>
                <img src="skin/images/icon04.png" alt="" />
                유통
              </span>
            </li>
            <li className={isActive3 ? "on" : null} onClick={ToggleClass3}>
              <span>
                <img src="skin/images/icon06.png" alt="" />
                프랜차이즈
              </span>
            </li>
            <li onClick={ToggleClass4}>
              <span>
                <img src="skin/images/icon07.png" alt="" />
                보험
              </span>
            </li>
          </ul>
        </div>
        <div className="service_contents">
          <div className="service_text">
            <div className="service_text_title">
              외식 창업,
              <br />
              적은 자본으로
              <br />
              빠르고 간편하게
            </div>
            <div className="service_text_comment">
              외식 전문 브랜드 팀프에프앤비가
              <br />
              소형, 소자본으로도 쉽고 편리하게
              <br />
              점포를 운영할 수 있도록
              <br />
              가맹 패키지를 제안해드립니다.
            </div>
            <div className="service_link">
              <img src="skin/images/arrowicon.png" alt="" />
            </div>
          </div>
          <div className="service_img">
            <img src="skin/images/main_business_img03.jpg" alt="" />
          </div>
        </div>
      </TabBox>
      <TabBox
        className="service_tabbox"
        id="service4"
        style={{ display: isActive4 ? "block" : "none" }}
      >
        <div className="service_tabbtn">
          <ul>
            <li onClick={ToggleClass}>
              <span>
                <img src="skin/images/icon03.png" alt="" />
                물류
              </span>
            </li>
            <li onClick={ToggleClass2}>
              <span>
                <img src="skin/images/icon04.png" alt="" />
                유통
              </span>
            </li>
            <li onClick={ToggleClass3}>
              <span>
                <img src="skin/images/icon06.png" alt="" />
                프랜차이즈
              </span>
            </li>
            <li className={isActive4 ? "on" : null} onClick={ToggleClass}>
              <span>
                <img src="skin/images/icon07.png" alt="" />
                보험
              </span>
            </li>
          </ul>
        </div>
        <div className="service_contents">
          <div className="service_text">
            <div className="service_text_title">
              기업 대상 전문 보험,
              <br />
              상해보험부터 재산종합보험까지
              <br />
              똑똑하게
            </div>
            <div className="service_text_comment">
              기업 전문 보험 GA와 만나
              <br />
              팀프인슈어런스가 탄생했습니다.
              <br />
              기업 맞춤형, 최적의 보험 상품을 추천받아보세요.
            </div>
            <div className="service_link">
              <img src="skin/images/arrowicon.png" alt="" />
            </div>
          </div>
          <div className="service_img">
            <img src="skin/images/main_business_img04.jpg" alt="" />
          </div>
        </div>
      </TabBox>
    </MainService>
  );
};

export default Service;
