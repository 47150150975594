import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { number } from "yup";
import { PagingWrapper } from "../shared";

const PageNumber = styled.span`
  font-size: large;
  padding: 3px 7px;
  width: 30px;
  height: 10px;
  border: ${(props) => (props.active ? "1px solid" : "none")};
`;

const Pagination = ({
  totalPage,
  currentPage,
  setCurrentPage: setCurrentPageNum,
}) => {
  const [currentPageIdx, setCurentPageIdx] = useState(0);

  let firstNum = currentPage - (currentPage % 5) + 1;
  let lastNum = currentPage - (currentPage % 5) + 5;
  const pageNumbers = [];
  const Pages = [];

  for (let i = 1; i <= totalPage; i++) {
    pageNumbers.push(i);
  }
  while (pageNumbers.length > 0) {
    Pages.push(pageNumbers.splice(0, 5));
  }

  const handleLeftArrow = (index) => {
    setCurentPageIdx(currentPageIdx - 1);
    setCurrentPageNum(firstNum - 5);
  };

  const handleRightArrow = (index) => {
    setCurentPageIdx(currentPageIdx + 1);
    setCurrentPageNum(lastNum + 1);
  };

  const onClick = (e) => {
    setCurrentPageNum(Number(e.target.id));
  };

  return (
    <PagingWrapper>
      <tbody>
        <tr>
          {/* 왼쪽 화살표 */}
          {currentPageIdx !== 0 && ( //
            <td onClick={handleLeftArrow}>{"<"}</td>
          )}

          {Pages[currentPageIdx]?.map((num, index) => (
            <td key={num}>
              <PageNumber
                id={num}
                onClick={onClick}
                active={num === currentPage}
              >
                {num}
              </PageNumber>
            </td>
          ))}

          {/* 오른쪽 화살표 */}
          {Pages[currentPageIdx]?.length >= 5 && ( //
            <td onClick={handleRightArrow}>{">"}</td>
          )}
        </tr>

        <tr>
          <td id="mainbutton_td"></td>
          <td align="right" valign="top"></td>
        </tr>
      </tbody>
    </PagingWrapper>
  );
};

export default Pagination;
