import styled from "styled-components";
import {
  CenterDiv,
  SubSection,
  TextTitle1,
  TextTitle2,
  TextComment,
  SectionImg,
} from "../../shared";
import CarouselMobile from "../CarouselMobile";

const Section19 = styled(SubSection)`
  padding: 0;
  background: url(../skin/body/sub020103/app_bg.jpg) no-repeat top center;
  .section_text {
    float: right;
    position: relative;
    width: 45%;
    padding: 150px 20px;
    box-sizing: border-box;
    .googlebtn {
      clear: both;
      display: table;
      position: relative;
      font-family: "Pretendard";
      font-weight: 700;
      font-size: 25px;
      line-height: 65px;
      color: rgb(255 255 255);
      background: rgb(29 0 0);
      border-radius: 15px;
      padding: 0 20px 0 230px;
      margin: 120px 0 0 0;
      cursor: pointer;
      img {
        position: absolute;
        top: 12px;
        left: 20px;
      }
    }

    .handimg {
      position: absolute;
      top: 610px;
      left: 290px;
    }
  }
  .section_text_title2 {
    img {
      max-width: 100%;
    }
  }
  .phonebox {
    position: relative;
    width: 65%;
    margin: 50px auto 50px;
    border: 8px solid rgb(255 255 255);
    border-top-width: 35px;
    border-radius: 40px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
    :before {
      position: absolute;
      top: -20px;
      left: 50%;
      width: 50px;
      margin-left: -25px;
      height: 2px;
      background: rgb(0 0 0 / 10%);
      border-radius: 100px;
      content: "";
      z-index: 50;
    }
  }
  .sliders {
    overflow: hidden;
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    text-align: center;
    border-radius: 5px 5px 35px 35px;
    overflow: hidden;
    .slidesjs-container {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 641.146px;
    }
  }
`;

const Platform = () => {
  return (
    <Section19 className="sub_section sub_section3">
      <CenterDiv className="centerdiv">
        <div className="section_text">
          <TextTitle1 className="section_text_title1">
            기사전용 커뮤니티 플랫폼
          </TextTitle1>
          <TextTitle2 className="section_text_title2">
            <img src="../skin/body/sub020103/apptitle.png" alt="" />
          </TextTitle2>
          <TextComment className="section_text_title_comment">
            기사 전용 커뮤니티부터 기사관리 ERP 시스템까지
            <br />
            획기적인 화물운송 플랫폼 영차영차와 함께 하세요!
          </TextComment>
          <div
            className="googlebtn"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.yohoyoho_app",
                "_blank"
              )
            }
          >
            <img src="../skin/body/sub020103/googlelogo.png" alt="" />
            다운로드
          </div>
          <div className="handimg">
            <img src="../skin/body/sub020103/app_hand.png" alt="" />
          </div>
        </div>
        <SectionImg className="section_img">
          <div className="phonebox">
            <div id="slides3" className="sliders">
              <div className="slidesjs-container">
                <div className="slidesjs-control">
                  <CarouselMobile
                    slide={"../skin/body/sub020103/sectionimg3_1.jpg"}
                    slide2={"../skin/body/sub020103/sectionimg3_2.jpg"}
                    slide3={"../skin/body/sub020103/sectionimg3_3.jpg"}
                    slide4={"../skin/body/sub020103/sectionimg3_4.jpg"}
                    slide5={"../skin/body/sub020103/sectionimg3_5.jpg"}
                    slide6={"../skin/body/sub020103/sectionimg3_6.jpg"}
                    slide7={"../skin/body/sub020103/sectionimg3_7.jpg"}
                    slide8={"../skin/body/sub020103/sectionimg3_8.jpg"}
                    slide9={"../skin/body/sub020103/sectionimg3_9.jpg"}
                  />
                </div>
              </div>
            </div>
          </div>
        </SectionImg>
      </CenterDiv>
    </Section19>
  );
};

export default Platform;
