import { Link, animateScroll as scroll, Element } from "react-scroll";

import { SubContent, Arrow2 } from "../shared";

const MorningDelivery = () => {
  return (
    <Element name="sectionMD">
      <SubContent className="sub_contents_title" id="subsectionlink1">
        <div className="title1">Early - Morning Deliveries</div>
        <div className="title2">새벽 배송</div>
        <div className="title3">
          고객사의 상품을 오전 7시까지 배송합니다.
          <br />
          소비자에게 배송 시간을 약속해 구매율을 높일 수 있습니다.
          <br />
          <br />
          팀프레시는 고정된 배송매니저가 지역을 전담하여
          <br />
          담당 지역에 대한 높은 숙련도를 보입니다.
        </div>
        <Arrow2 className="sub_images_arrow sub_images_arrow2">
          <Link
            onClick={() => {
              scroll.scrollToBottom();
            }}
            to="sectionProcess"
            smooth={true}
          >
            <i className="xi-angle-down"></i>
          </Link>
        </Arrow2>
      </SubContent>
    </Element>
  );
};

export default MorningDelivery;
