import { Element, Link } from "react-scroll";
import { SubContent, Arrow2 } from "../../shared";

const FranchiseIntro = () => {
  return (
    <Element name="sectionFranchise">
      <SubContent className="sub_contents_title" id="subsectionlink1">
        <div className="title1">Franchise</div>
        <div className="title2">프랜차이즈</div>
        <div className="title3">
          본사 직영 식자재 물류시스템을 이용한 외식 가맹 서비스로
          <br />
          점주 최우선 정책을 기반으로 상생의 가치를 높여
          <br />
          운영에만 몰입할 수 있도록 비즈니스 환경을 만들었습니다.
        </div>
        <Arrow2 className="sub_images_arrow sub_images_arrow2">
          <Link className="anchorLink" to="sectionFList" smooth={true}>
            <i className="xi-angle-down"></i>
          </Link>
        </Arrow2>
      </SubContent>
    </Element>
  );
};

export default FranchiseIntro;
