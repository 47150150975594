import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { NewsList } from "../shared";

const NotiList = styled(NewsList)`
  overflow: hidden;
  .news_arrow {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 10px;
    text-align: left;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: #666;
    z-index: 1;
  }

  @media only screen and (max-width: 480px) {
    .news_body {
      min-height: 42px;
    }

    .news_arrow,
    .news_body {
      font-size: 32px;
    }
  }
`;

const NoticePageList = ({ list }) => {
  const url = useLocation();

  return (
    <div>
      {list.map((list, index) => (
        <NotiList
          key={index}
          className="news_list"
          style={{ width: "100%", height: "100%" }}
        >
          <Link
            to={
              url.pathname === "/admin"
                ? "#"
                : `/customer/notice/detail/${list.csCenterSeq}`
            }
            state={
              url.pathname === "/admin"
                ? { adminPath: "noticeDetail", seq: `${list.csCenterSeq}` }
                : null
            }
          >
            <div className="news_arrow">{list.updDt?.split(" ", 1)}</div>
            <div className="news_body">{list.title}</div>
          </Link>
        </NotiList>
      ))}
    </div>
  );
};

export default NoticePageList;
