import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import routes from "../../routes";
import { useStore } from "../../UserContext";
import Pagination from "../common/Pagination";

const Table = styled.table`
  tbody {
    background-color: #ffffff;
    tr {
      color: #555555;
      td {
        font-size: 16px;
        font-weight: 300;
        padding: 15px 5px;
        box-sizing: border-box;
      }

      td.head_td {
        height: 50px;
        color: #000 !important;
        font-size: 18px !important;
        font-weight: 400 !important;
        border-width: 0;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 1px 0 0 0;
        :first-child {
          border-left: 1px solid #cfcfcf;
        }
        :last-child {
          border-right: 1px solid #cfcfcf;
        }
      }

      td.list_subject {
        word-break: break-all;
        padding-left: 15px !important;
      }
      td.list_hr_line {
        height: 1px;
        background-color: #f4f3f1;
        padding: 0 !important;
      }
    }
  }
`;

const InquiryTable = ({
  list,
  category,
  totalPage,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <>
      <Table
        id="mainIndexTable"
        cellPadding="0"
        cellSpacing="0"
        width="100%"
        border="0"
      >
        <tbody>
          <tr align="center">
            <td className="head_td" width="3px">
              <span>&nbsp;</span>
            </td>
            <td className="head_td">
              <nobr>
                <span className="list_no">No.</span>
              </nobr>
            </td>
            {category !== "INQUIRY" ? (
              <td className="head_td" align="center">
                분류
              </td>
            ) : null}
            <td className="head_td" align="center">
              <nobr>제목</nobr>
            </td>
            <td className="head_td" align="center">
              작성자
            </td>

            {category === "INQUIRY" ? (
              <td className="head_td" align="center">
                이메일
              </td>
            ) : null}

            <td className="head_td">작성일</td>
            <td className="head_td">진행상태</td>
            <td className="head_td" width="3px">
              <span>&nbsp;</span>
            </td>
          </tr>
          {list.map((item, index) => (
            <React.Fragment key={index}>
              <tr height="40">
                <td width="1px"></td>

                <td className="list_no">
                  <nobr>{index + 1}</nobr>
                </td>

                {category !== "INQUIRY" ? (
                  <td className="list_wr_add">{item.category}</td>
                ) : null}

                <td className="list_subject">
                  <Link
                    to="#"
                    state={{ category, seq: `${item.inquirySeq}` }}
                    className="subjectColor"
                  >
                    <img
                      src="./skin_board/a_standard/b_img/attrib/lock.gif"
                      align="absmiddle"
                      border="0"
                      alt=""
                    />
                    <span id="opennumber88" style={{ fontWeight: "700" }}>
                      {item.title}
                    </span>
                  </Link>
                </td>

                <td className="list_name">{item.name}</td>

                {category === "INQUIRY" ? (
                  <td className="list_wr_add">{item.email}</td>
                ) : null}

                <td className="list_date">
                  <nobr>{item.creDt?.split(" ", 1)}</nobr>
                </td>

                {item.answerYn === "Y" ? (
                  <td style={{ width: "10%" }}>답변완료</td>
                ) : (
                  <td style={{ width: "10%" }}>접수완료</td>
                )}

                <td width="1px"></td>
              </tr>
              <tr className="list_hr_tr">
                <td colSpan="8" className="list_hr_line"></td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default InquiryTable;
