import { Box, Button, Modal, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import routes from "../../../routes";
import { delTimfNotice } from "../../../services";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  textAlign: "center",
  boxShadow: 24,
  p: 4,
};

const ConfirmModal = ({ seq, open, handleClose }) => {
  const url = useLocation();
  const navigate = useNavigate();
  const onClickDelete = async () => {
    if (window.confirm("해당 게시글을 삭제하시겠습니까?")) {
      const res = await delTimfNotice(seq);
      if (res) {
        alert("삭제되었습니다.");
        handleClose();
        navigate(
          url.pathname === "/admin" ? `${routes.admin}` : `${routes.notice}`
        );
      }
    } else {
    }
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 5 }}
        >
          게시글 수정/삭제
        </Typography>
        <Link
          to={
            url.pathname === "/admin" ? "#" : `/customer/notice/modify/${seq}`
          }
          state={
            url.pathname === "/admin"
              ? { adminPath: "noticeModify", seq: `${seq}` }
              : null
          }
        >
          <Button sx={{ mr: 15 }} variant="contained">
            수정
          </Button>
        </Link>
        <Button variant="outlined" onClick={onClickDelete}>
          삭제
        </Button>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
