import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import Parser from "html-react-parser";

import styled from "styled-components";
import { getTimfNoticeDetail } from "../../services";
import { CenterDiv, CSubContent, LinkStyle } from "../shared";
import { useState } from "react";
import ConfirmModal from "../common/Modal/ConfirmModal";
import { useStore } from "../../UserContext";
import { downloadFile } from "../../Utils/Utils";

const NSubContent = styled(CSubContent)`
  padding: 150px 250px;
  @media only screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
    position: static;
    float: none;
    clear: none;
    padding: 300px 50px 100px 50px;
    min-height: 180vh;
  }
  background: rgba(0, 0, 0, 0.03);

  .sub_contents_title .snslink {
    display: table;
    margin: 50px auto 0;
  }
  .sub_contents_title .snslink li {
    display: inline-block;
    float: none;
    width: 60px;
    height: 60px;
    padding: 10px 0 0 0;
    margin: 0 20px 0 0;
    background: #02204a;
  }
  .sub_contents_title .snslink li i {
    font-size: 37px;
  }
  .sub_contents_title .snslink li img {
    height: 37px;
  }

  .title1,
  .title2 {
    text-align: left;
  }

  .title2 {
    margin-bottom: 100px;
  }

  .write_check_td {
    border-bottom: 1px solid #e5e5e5;
  }

  /* CKEditor style */

  span {
    font-size: 17px;
  }
  .text-tiny {
    font-size: 0.7em;
  }
  .text-small {
    font-size: 0.85em;
  }
  .text-big {
    font-size: 1.6em;
  }
  .text-huge {
    font-size: 1.8em;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  padding: 0;
`;

const TableTop = styled.table`
  border-top: 1px solid #979797;
  margin-bottom: 4px;
  text-align: left;
  .b_detail_left {
    color: #000;
    width: 150px;
    height: 35px;
    border-bottom: 1px solid #cccccc;
    font-size: 15px;
    padding: 15px 10px;
    @media only screen and (max-width: 480px) {
      font-size: 24px;
    }
  }

  .b_detail_right {
    color: #000;
    border-bottom: 1px solid #cccccc;
    border-left: 1px dotted #cccccc;
    font-size: 15px;
    padding: 15px 10px;
  }
`;

const TableBottom = styled.table`
  padding: 20px 0;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 5px;
  table-layout: fixed;

  tbody {
    text-align: left;
  }

  td {
    color: #151515;
    line-height: 150%;
  }
`;

const Div = styled(CenterDiv)`
  width: 100%;
  // width: 1210px;
  margin: 0 auto;
  // padding: 150px;
  // @media only screen and (max-width: 480px) {

  // }
`;

const Container = styled.div`
  width: 100%;
  @media only screen and (max-width: 480px) {
    height: 100%;
    min-height: 180vh;
  }
`;

const LinkContainer = styled.div`
  text-align: left;
  padding: 20px 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
`;

const NoticeDetail = ({ seq }) => {
  const { state } = useStore();

  const url = useLocation();

  const { csCenterSeq } = useParams();

  const [notiList, setNotiList] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const getNotiList = async () => {
    let res = undefined;
    if (seq) {
      res = await getTimfNoticeDetail(seq);
    } else {
      res = await getTimfNoticeDetail(csCenterSeq);
    }

    if (res) {
      let file = [];
      if (res[0]?.files) {
        file = res[0]?.files.map((item) => {
          return item;
        });
      }

      setNotiList({ ...res[0]?.cscenter, file });
    }
  };

  const download = (fileUrl, fileName) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", fileUrl, true);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  };

  useEffect(() => {
    getNotiList();
  }, []);

  return (
    <Container>
      <NSubContent className="sub_contents_title">
        <Div className="centerdiv relative">
          <div className="title1">TimF Notice</div>
          <div className="title2">공지사항</div>
        </Div>
        <Div className="centerdiv relative">
          <TableContainer>
            <TableTop border="0" cellPadding="0" cellSpacing="0" width="100%">
              <tbody>
                <tr>
                  <td>
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td className="b_detail_left">ㆍ제목</td>
                          <td className="b_detail_right">{notiList?.title}</td>
                        </tr>

                        <tr>
                          <td className="b_detail_left">ㆍ작성일</td>
                          <td className="b_detail_right">
                            {notiList?.updDt?.split(" ", 1)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </TableTop>
            {/* 본문 */}
            <TableBottom
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="100%"
            >
              <tbody>
                <tr>
                  {typeof notiList?.contents === "string" ? (
                    <td>{Parser(notiList?.contents)}</td>
                  ) : (
                    <td>{notiList?.contents}</td>
                  )}
                </tr>
                {csCenterSeq === "83" ? (
                  <tr style={{ textAlign: "right" }}>
                    <button onClick={downloadFile} type="button">
                      소규모 주식교환 반대의사 통지서.pdf
                    </button>
                  </tr>
                ) : null}
              </tbody>

              {/*  */}
            </TableBottom>
            <LinkContainer>
              {notiList?.file?.length > 0 &&
                notiList.file.map((item) => {
                  return (
                    <div>
                      <LinkStyle
                        key={item.file_seq}
                        onClick={() => download(item.fileUrl, item.fileName)}
                      >
                        {item.fileName}
                      </LinkStyle>
                    </div>
                  );
                })}
            </LinkContainer>

            {state?.user ? (
              <table border="0" cellPadding="0" cellSpacing="0" width="100%">
                <tbody>
                  <tr>
                    <td align="left">
                      <Link to="#" onClick={handleOpen}>
                        <img
                          src="../../../skin_board/a_standard/b_img/i_button/modify.gif"
                          border="0"
                          alt=""
                        />
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : null}
          </TableContainer>
        </Div>
      </NSubContent>
      <ConfirmModal
        seq={url.pathname === "/admin" ? seq : csCenterSeq}
        open={open}
        handleClose={handleClose}
      />
    </Container>
  );
};

export default NoticeDetail;
