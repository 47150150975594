import styled from "styled-components";

import { CenterRelative, SubSection, SubTitle } from "../../shared";

const Section31 = styled(SubSection)`
  background: url(../../skin/body/sub0204/product_bg.jpg) fixed no-repeat center;
  background-size: cover;
  padding: 0;

  .sub_section5_bg {
    display: table;
    width: 100%;
    height: 100%;
    padding: 180px 0;
    background: rgba(69, 69, 71, 0.9);
  }

  .product_contents {
    float: left;
    width: 100%;
    min-height: 450px;
    margin: 0 1% 0;
    padding: 30px;
    box-sizing: border-box;

    ul {
      list-style: none;
      padding-left: 0;
      display: flex;
      justify-content: center;
    }
    li {
      margin: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .product_icon {
      padding: 5px;
    }
    .product_title {
      font-family: "Pretendard";
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      color: white;
      padding: 0 0 10px;
      border-bottom: 0.5px solid white;
    }
    .product_comment {
      position: relative;
      width: 100%;
      font-family: "Pretendard";
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: white;
      box-sizing: border-box;
      margin-top: 10px;
      text-align: center;
    }
  }
`;

const SubTitleWH = styled(SubTitle)`
  color: #fff;
`;

const InsuranceGoods = () => {
  return (
    <Section31 className="sub_section sub_section5">
      <div className="sub_section5_bg">
        <SubTitleWH className="sub_title">주요상품</SubTitleWH>
        <CenterRelative className="centerdiv relative">
          <div className="product_contents">
            <ul>
              <li>
                <img
                  className="product_icon"
                  src="../skin/body/sub0204/insugoods_icon1.png"
                  alt=""
                />
                <div className="product_title">단체상해보험</div>
                <div className="product_comment">
                  지자체상해보험
                  <br />
                  기업체상해보험
                  <br />
                  특수단체상해보험
                  <br />
                </div>
              </li>
              <li>
                <img
                  className="product_icon"
                  src="../skin/body/sub0204/insugoods_icon2.png"
                  alt=""
                />
                <div className="product_title">재물보험</div>
                <div className="product_comment">
                  재산종합보험
                  <br />
                  (국문/영문패키지)
                  <br />
                  화재보험
                  <br />
                  동산종합보험
                  <br />
                </div>
              </li>
              <li>
                <img
                  className="product_icon"
                  src="../skin/body/sub0204/insugoods_icon3.png"
                  alt=""
                />
                <div className="product_title">기술보험</div>
                <div className="product_comment">
                  건설공사보험
                  <br />
                  조립보험
                  <br />
                  기계보험
                  <br />
                  기관기계종합보험
                </div>
              </li>
              <li>
                <img
                  className="product_icon"
                  src="../skin/body/sub0204/insugoods_icon4.png"
                  alt=""
                />
                <div className="product_title">배상책임보험</div>
                <div className="product_comment">
                  영업배상책임보험
                  <br />
                  임원배상책임보험
                  <br />
                  생산물배상책임보험
                  <br />
                  전문인배상책임보험
                </div>
              </li>
            </ul>
          </div>
        </CenterRelative>
      </div>
    </Section31>
  );
};

export default InsuranceGoods;
