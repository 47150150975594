import { useEffect, useState } from "react";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Tab } from "@mui/material";
import { Box } from "@mui/system";
import AdminNoti from "./AdminNoti";
import AdminNews from "./AdminNews";
import AdminRecruit from "./AdminRecruit";
import AdminQna from "./AdminQna";
import AdminInquiry from "./AdminInquiry";
import AdminBusiness from "./AdminBusiness";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import AdminIContentDetail from "./AdminIContentDetail";
import { useStore } from "../../UserContext";

const AdminContainer = styled.div`
  #adminHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // min-width: 1210px;
    padding: 0;
    text-align: center;
    z-index: 100;
    background: rgb(247, 247, 247);
  }
`;

const AdminMain = () => {
  const navigate = useNavigate();
  const { dispatch } = useStore();

  const [value, setValue] = useState("1");

  const location = useLocation();
  const category = location.state?.category;
  const inquirySeq = location.state?.seq;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onClickLogOut = () => {
    dispatch({ type: "LOGOUT", payload: null });
    navigate("/");
  };

  useEffect(() => {
    if (value === "1") {
      location.state = undefined;
    }
    location.state = undefined;
  }, [value]);

  return (
    <AdminContainer>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            id="adminHeader"
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="공지사항" value="1" />
              <Tab label="인재채용" value="2" />
              <Tab label="보도자료" value="3" />
              <Tab label="자주하는질문" value="4" />
              <Tab label="고객문의" value="5" />
              <Tab label="창업문의" value="6" />
              <Tab label="보험컨설팅문의" value="7" />
            </TabList>
            <Button
              variant="contained"
              onClick={onClickLogOut}
              style={{
                background: "rgba(2, 21, 48, 0.9)",
                position: "absolute",
                right: "10px",
                top: "5px",
              }}
            >
              로그아웃
            </Button>
          </Box>

          <TabPanel style={{ padding: "0" }} value="1">
            <AdminNoti url={location.state} />
          </TabPanel>

          <TabPanel style={{ padding: "0" }} value="2">
            <AdminRecruit url={location.state} />
          </TabPanel>

          <TabPanel style={{ padding: "0" }} value="3">
            <AdminNews url={location.state} />
          </TabPanel>

          <TabPanel style={{ padding: "0" }} value="4">
            <AdminQna url={location.state} />
          </TabPanel>

          <TabPanel style={{ padding: "0" }} value="5">
            {category === "INQUIRY" && inquirySeq ? (
              <AdminIContentDetail category={category} seq={inquirySeq} />
            ) : (
              <AdminInquiry />
            )}
          </TabPanel>

          <TabPanel style={{ padding: "0" }} value="6">
            {category === "FRANCHISE" && inquirySeq ? (
              <AdminIContentDetail category={category} seq={inquirySeq} />
            ) : (
              <AdminBusiness category={"FRANCHISE"} />
            )}
          </TabPanel>

          <TabPanel style={{ padding: "0" }} value="7">
            {category === "INSUARANCE" && inquirySeq ? (
              <AdminIContentDetail category={category} seq={inquirySeq} />
            ) : (
              <AdminBusiness category={"INSUARANCE"} />
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </AdminContainer>
  );
};

export default AdminMain;
