import { Element } from "react-scroll";
import styled from "styled-components";
import { CenterDiv, SubSection, SubTitle } from "../../shared";

const Section14 = styled(SubSection)`
  background: url(../skin/body/sub020101/process_bg.jpg) fixed no-repeat center;
  background-size: cover;
  padding: 0;

  .sub_section1_bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 90%);
    padding: 150px 0;
  }

  .section_process1 {
    clear: both;
    display: table;
    width: 100%;
    margin: 50px 0 0 0;
  }
`;

const FulfillmentProcess = () => {
  return (
    <Element name="sectionFUProcess">
      <Section14 className="sub_section sub_section1">
        <div className="sub_section1_bg">
          <CenterDiv className="centerdiv">
            <SubTitle className="sub_title">
              <div className="sub_title_eng">Process</div>
              프로세스
              <div className="sub_title_comment">
                입고부터 배송까지 One-Stop으로 처리하는 서비스로
                <br />
                상품 특성에 맞는 최적의 재고 관리 및 출고 방법을 제안합니다.
              </div>
            </SubTitle>
            <div className="section_process1">
              <img src="../skin/body/sub020102/process.png" alt="" />
            </div>
          </CenterDiv>
        </div>
      </Section14>
    </Element>
  );
};

export default FulfillmentProcess;
