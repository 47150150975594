import styled from "styled-components";
import {
  CenterDiv,
  SubSection,
  TextTitle1,
  TextTitle2,
  TextComment,
  SectionImg,
} from "../../shared";

const DistributionCenter = () => {
  const Section15 = styled(SubSection)`
    background: rgb(0 0 0 / 3%);
    padding: 0;

    .section_text {
      position: relative;
      float: left;
      width: 45%;
      padding: 150px 20px;
      box-sizing: border-box;
    }

    .section_img {
      text-align: right;
      position: relative;
      float: left;
      width: 55%;
      padding: 0 20px;
      box-sizing: border-box;
      img {
        max-width: 100%;
      }
    }
  `;

  return (
    <Section15 className="sub_section sub_section2">
      <CenterDiv className="centerdiv">
        <div className="section_text">
          <TextTitle1 className="section_text_title1">
            재고를 보관하는 재고형 물류센터
          </TextTitle1>
          <TextTitle2 className="section_text_title2">보관형 센터</TextTitle2>
          <TextComment className="section_text_title_comment">
            <span>DC</span>(<span>D</span>istribution <span>C</span>enter)
            <li>수도권 중심으로 4개의 DC를 운영 중</li>
            <li>일 최대 50,000건 처리 가능</li>
            <li>냉장&amp;냉동&amp;상온 3온도대 보관공간 확보</li>
            <li>자동피킹 설비를 통해 다품종 소량 상품 피킹 효율화</li>
          </TextComment>
        </div>
        <SectionImg className="section_img">
          <img src="../skin/body/sub020102/sectionimg2_1.png" alt="" />
        </SectionImg>
      </CenterDiv>
    </Section15>
  );
};

export default DistributionCenter;
