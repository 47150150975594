const ModalSL = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              신형석
              <br />
              <span>
                기획본부,
                <br />
                서비스리딩팀(SL)
                <br />
                팀장
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img01.jpg?1659668705"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              서비스리딩팀은 팀프레시가 제공하는 새벽배송, 풀필먼트, 식자재
              공급과 같은 서비스들을 TMS / WMS / 식자재 발주 시스템을 기반으로
              기획,운영 및 발전시켜 나가는 역할을 하고 있어요.
              <br />
              새로운 서비스가 필요한 경우, 서비스의 초기 기획부터 오픈까지 함께
              진행하기도 하죠.
              <br />
              직무적으로 설명드리자면, 서비스 기획과 프로덕트 매니징의 역할들을
              한다고 생각해주시면 될 것 같아요!
              <br />
              저는 프로덕트 개선을 위한 프로젝트들을 직접 진행하는 서비스
              기획자의 역할과 팀원들의 프로덕트 기획/개선 업무 서포팅, 피드백 및
              방향성 설정을 하고 있어요. (웃음)
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              하나의 프로젝트를 A-Z까지 책임지고 완수할 수 있다는 점이 가장 클
              것 같은데요.
              <br />
              이 과정에서 프로젝트를 진행하는 과정에서 본인이 생각하고 원하는
              바를 충분히 개진하고 시도할 수 있다는 점이 성장에 가장 중요한
              요소라고 생각해요.
              <br />
              본인의 책임이나 권한이 넓은 편이기에 의지와 노력 여하에 따라
              달성할 수 있는 것들이 굉장히 많이 존재하기도 하구요.
              <br />
              이렇듯 주도적으로 프로젝트들을 진행하다보면, '문제를 어느 관점에서
              바라보고 분석해야 하는지', '해결을 위한 최선의 방법이 무엇일지'
              등과 같은 소위 '기획력' 을 증진하는 데에 큰 도움이 되더라구요.
              이러한 경험은 분명 스스로의 커리어의 기반이 될 중요한 요소라고
              생각해요.
              <br />
              추가로, 사업의 성장 및 다각화가 적극적으로 진행됨에 따라 기획이
              필요한 다양한 요소와 경험을 접할 수 있다는 점도 시야와 커리어의
              폭을 넓히는 데에 있어 도움이 되는 점으로 말할 수 있겠네요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’에서 이루고 싶은 개인적인 목표가 있나요?
            </div>
            <div className="interview_comment_A">
              개인과 회사의 성장이 함께 우상향 그래프를 그리는 것이 목표예요.
              <br />
              각 구성원의 성장이 더해져 회사 전체의 성장을 일궈내는 것이 매우
              의미있다고 생각하구요.
              <br />
              회사가 성장한 만큼 개인이, 개인이 성장한 만큼 회사가 성장해서
              서로에게 좋은 파트너가 되었으면 해요.
              <br />
              그러한 과정 속에서 회사와 개인 모두 그만의 '브랜드 가치'와 ‘사회적
              가치’를 가질 수 있을 것이라 생각해요.
              <br />
              덧붙여, 개인적으로 팀프레시에서 많은 것들을 수행하고 경험하면서,
              팀프레시와 함께 하는 시간을 의미있게 만들어 나가고 싶어요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’는 주니어들이 일하기 좋은 기업인가요?
            </div>
            <div className="interview_comment_A">
              기본적으로 팀프레시는 일을 '자아 실현의 도구'로 바라보는 사람에게
              충분히 좋은 기업이 될 수 있을 것이라 생각해요.
              <br />
              그러한 사람들 중 '주니어'에 포커스를 둔다면 주니어의 가장 큰
              니즈는 아무래도 성장이 아닐까 싶은데요.
              <br />
              '경험을 먹고 자란다' 라는 문장처럼, 성장은 많은 경험을 필요로
              해요.
              <br />
              그런 관점에서 바라보았을 때, 팀프레시는 동일 시간 내 집약적 성장을
              할 수 있는 곳이라 생각하구요.
              <br />
              많은 프로젝트들을 직접 수행하면서 얻는 경험들에 더해, 필요한 교육
              지원과 교육팀에서 제공하는 교육 및 컨텐츠들이 더해져 다양한
              직/간접 경험을 충분히 할 수 있는 충분한 환경이라고 생각해요.
              <br />
              팀프레시의 핵심 가치 중 하나가 '성장'인 만큼 회사는 구성원들의
              성장에 많은 지원과 관심을 두고 있고, 지원과 관심에 본인의 의지가
              더해진다면 주니어에서 일잘러가 되는 우리의 모습을 맞이할 수 있을
              것이라 생각해요. (웃음)
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              서비스 리딩팀은 20대 후반에서 30대 초반까지 젊은 인원들로 구성이
              되어 있어요.
              <br />
              기획자들이 모여 있는 만큼 스스럼없이 서로의 의견을 공유하며 많은
              이야기들을 나누고, 기획과 관련하여 보다 나은 방향을 위한 다양한
              방법론을 시도하고 도입하고자 노력해요.
              <br />
              개인의 업무를 진행할 때에는 각 구성원은 서비스에 충분히 몰입하여
              주체적으로 의사결정들을 진행하곤 하며, 필요한 경우 서로 다양한
              피드백을 부담없이 나누고 있어요.
              <br />
              업무 이외에도, 매주 컬럼이나 뉴스, 교육 정보를 공유하고 스터디를
              진행하기도 하며 월간 회고를 바탕으로 우리가 부족한 점들을 인지하고
              문제 해결을 위해 시도해 볼 Action Plan들을 설정하여 적용해보기도
              해요.
              <br />
              서비스리딩팀 구성원은 다양한 시도에 열려있고, 보다 나은 미래를
              원하는 멋진 사람들이 모여있는 팀으로 생각해주시면 좋을 것 같아요!
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              "뇌의 모양이 비슷해야해." 동료를 찾는 과정에 있어 이 문구를 잠시
              빌려 대답을 드릴 수 있을 것 같아요.
              <br />
              결국 기획은 어떻게 사고하는지가 굉장히 중요한 요소라고 생각해요.
              <br />
              문제가 정확히 무엇인지(What)를 파악하고, 그에 따른 원인(Why)을
              분석하여, 어떻게(How) 해결해 나갈 수 있을지에 대해 논리적이고도
              명확한 이슈 분석이 가능한 분들과 같이 일한다는 것은 언제나
              축복이죠.
              <br />그 이외에도 개인이 맡은 프로덕트에 오너십이 있는 사람,
              성장하고자 하는 니즈가 크고 본인의 커리어의 방향성에 대한 관심과
              고민이 많은 사람들 정도가 있을 것 같네요.
            </div>
            <div className="interview_comment_Q">
              마지막으로, 한 말씀 부탁드립니다!
            </div>
            <div className="interview_comment_A">
              ‘기획’의 직무와 ‘팀프레시’는 주체적으로 일하기를 원하고, 고민과
              생각을 즐기는 사람에게 충분히 매력있는 카드라고 생각해요.
              <br />
              특히나 물류/유통 도메인을 바탕으로 기획을 꿈꾸시는 분들에게는
              더욱이요.
              <br />
              팀프레시, 그리고 서비스리딩팀과 함께 더 나은 문화를 만들어가고,
              성장하실 분들과 함께할 날들을 기다리고 있을게요.(웃음)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSL;
