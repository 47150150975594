import { Element } from "react-scroll";
import styled from "styled-components";
import { CenterDiv, SubSection, SubTitle } from "../../shared";

const Section20 = styled(SubSection)`
  background: url(../skin/body/sub020101/process_bg.jpg) fixed no-repeat center;
  background-size: cover;
  padding: 0;

  .sub_section1_bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 90%);
    padding: 150px 0;
  }

  .section_process1 {
    clear: both;
    display: table;
    width: 100%;
    margin: 50px 0 0 0;
  }
`;

const DistributionProcess = () => {
  return (
    <Element name="sectionDProcess">
      <Section20 className="sub_section sub_section1">
        <div className="sub_section1_bg">
          <CenterDiv className="centerdiv">
            <SubTitle className="sub_title">
              <div className="sub_title_eng">Process</div>
              프로세스
              <div className="sub_title_comment">
                당일 입고 및 출고를 통해 재고 부담을 감소시켰으며,
                <br />
                여러 고객사 물량을 크로스 도킹으로 배송하는 효율적인
                배송시스템으로 운영하고 있습니다.
              </div>
            </SubTitle>
            <div className="section_process1">
              <img src="../skin/body/sub0202/process.png" alt="" />
            </div>
          </CenterDiv>
        </div>
      </Section20>
    </Element>
  );
};

export default DistributionProcess;
