const ModalOC = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              이은비
              <br />
              <span>
                유통본부,
                <br />
                온라인커머스팀(OC)
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img11.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              팀프레시 온라인커머스팀은, B2B 식자재 유통시장의 온라인화를
              선도하기 위해 식자재 이커머스를 운영합니다.
              <br />
              저는 사업전략과 전반적인 커머스 운영을 관리하고 있습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              자기 주도적으로 업무를 수행하고, 다양한 일을 경험해 볼 수 있다는
              것이 커리어 성장에 분명 도움을 준다고 생각합니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’여서 가능했던 일이 있나요?
            </div>
            <div className="interview_comment_A">
              시기적으로 사업 초기 단계 즈음인데, 실험적인 시도들이 가능합니다.
              <br />
              상품기획이나 개발,운영방식,판매 전략 등에 있어 제한 없이 도전해볼
              수 있도록 아낌없이 지원해주시는데요.
              <br />
              팀프레시여서 가능한 일이라고 생각합니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’에서 이루고 싶은 개인적인 목표가 있나요?
            </div>
            <div className="interview_comment_A">
              운 좋게 본격적인 식자재 커머스몰의 구축단계부터 투입되게
              되었는데요.
              <br />
              그렇다보니 커머스 사업의 모든 생애주기를 함께 해볼 수 있게
              되었어요.
              <br />그 생애주기에 따라 다양한 전략을 세워보고, 많은 경험과
              노하우를 쌓고 싶습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’는 주니어들이 일하기 좋은 기업인가요?
            </div>
            <div className="interview_comment_A">
              의욕과 의지만 있다면 다양한 경험을 해볼 수 있기에 좋다고
              생각합니다.
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              저희 팀은 20대부터 40대까지 구성되어 있고 그 중 절반이 20대예요.
              <br />
              분위기는 아마 저희 회사에서 제일 좋지 않을까 싶어요(웃음) 어차피
              하는 일 즐겁게 하자는 유쾌한 분위기고, 협업에 대한 태도도 좋아서,
              이렇게 즐겁게 일할 수 있다는 것에 서로 만족하고 있답니다.
              <br />
              특히 꼰대 문화가 없어요!
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              책임감있고, 진취적으로 뭔가 해보고 싶은게 많은 사람이면 좋을 것
              같아요!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalOC;
