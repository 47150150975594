import styled from "styled-components";
import { CenterDiv, SubSection } from "../../shared";

const Section20 = styled(SubSection)`
  .section3_contents {
    position: relative;
    float: left;
    width: 48%;
    height: 600px;
    margin: 1%;
    overflow: hidden;
  }

  .section3_contents .section3_close {
    position: absolute;
    bottom: -30px;
    left: 30px;
    width: 22px;
    height: 22px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #000;
    border-radius: 100%;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 3;
    overflow: hidden;
    opacity: 0;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
  }

  [class*=" xi-"],
  [class^="xi-"] {
    font-family: xeicon !important;
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xi-close-min:before {
    content: "\e922";
  }

  .section3_contents {
    > div {
      width: 100%;
      height: 100%;
    }
    .section3_img1 {
      background: url(../skin/body/sub0202/sectionimg3_1.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
    .section3_img2 {
      background: url(../skin/body/sub0202/sectionimg3_2.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
    .section3_img3 {
      background: url(../skin/body/sub0202/sectionimg3_3.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
    .section3_img4 {
      background: url(../skin/body/sub0202/sectionimg3_4.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
  }

  .section3_contents .section3_bg {
    :hover {
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(30px);

      .section3_title1 {
        display: none;
      }

      .section3_title2 {
        font-size: 30px;
        line-height: 33px;
      }

      .section3_comment {
        opacity: 1;
      }
      .section3_more {
        opacity: 0;
      }
    }
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    box-sizing: border-box;
    z-index: 2;
    cursor: pointer;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;

    .section3_title1 {
      font-family: "Pretendard";
      font-weight: 400;
      font-size: 17px;
      line-height: 27px;
      color: #000;
      padding: 0 0 10px;
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
    }

    .section3_title2 {
      font-family: "Pretendard";
      font-weight: 700;
      font-size: 35px;
      line-height: 38px;
      color: #000;
      padding: 0 0 20px;
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
    }

    .section3_more {
      width: 22px;
      height: 22px;
      text-align: center;
      font-size: 20px;
      border: 1px solid #000;
      border-radius: 100%;
      box-sizing: border-box;
    }

    .section3_comment {
      font-family: "Pretendard";
      font-weight: 300;
      font-size: 17px;
      line-height: 22px;
      color: #000;
      opacity: 0;
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;

      li {
        position: relative;
        clear: both;
        float: left;
        width: 100%;
        padding: 3px 0 0 0;
        box-sizing: border-box;
      }
    }
  }
`;

const DistributionDesc = () => {
  return (
    <Section20 className="sub_section sub_section3">
      <CenterDiv className="centerdiv">
        <div className="section3_contents section3_contents1">
          <div className="section3_close">
            <i className="xi-close-min"></i>
          </div>
          <div className="section3_bg">
            <div className="section3_title1">업계 최다 상품 코드</div>
            <div className="section3_title2">
              14만 여개 업계
              <br />
              최대 상품코드 보유
            </div>
            <div className="section3_more">
              <i className="xi-plus-min"></i>
            </div>
            <div className="section3_comment">
              <li>다양한 종류와 규격에 대한 선택 가능</li>
              <li>
                단일발주 시스템을 통해 대기업 유통사 제품 가능, 기업별
                PB상품까지 모두 이용 가능
              </li>
            </div>
          </div>
          <div className="section3_img1"></div>
        </div>
        <div className="section3_contents section3_contents2">
          <div className="section3_close">
            <i className="xi-close-min"></i>
          </div>
          <div className="section3_bg">
            <div className="section3_title1">단가관리</div>
            <div className="section3_title2">
              연 1,200억 이상의
              <br />
              식자재 매입으로
              <br />
              경쟁력 있는 단가 형성
            </div>
            <div className="section3_more">
              <i className="xi-plus-min"></i>
            </div>
            <div className="section3_comment">
              <li>
                발주 전담인력이 내역을 분석하여 동일 스펙의 경쟁력 있는 제품
                제안
              </li>
              <li>주 단위 견적을 통해 물가 상승에도 단가변동 최소화</li>
            </div>
          </div>
          <div className="section3_img2"></div>
        </div>
        <div className="section3_contents section3_contents3">
          <div className="section3_close">
            <i className="xi-close-min"></i>
          </div>
          <div className="section3_bg">
            <div className="section3_title1">유연한 발주 시스템</div>
            <div className="section3_title2">
              긴급상황 발생 시<br />
              1시간 이내로 빠르게 해결 가능
            </div>
            <div className="section3_more">
              <i className="xi-plus-min"></i>
            </div>
            <div className="section3_comment">
              <li>자사 개발 발주 시스템 ‘팀플레이스’ 운영</li>
              <li>전담 CS인원 배치: One-Stop 업무처리</li>
              <li>영업사원 배치로 책임 밀착 관리</li>
              <li>실시간 카카오톡 채널운영으로 실시간 대응</li>
            </div>
          </div>
          <div className="section3_img3"></div>
        </div>
        <div className="section3_contents section3_contents4">
          <div className="section3_close">
            <i className="xi-close-min"></i>
          </div>
          <div className="section3_bg">
            <div className="section3_title1">사고 재발 방지 프로세스</div>
            <div className="section3_title2">
              이슈 발생시,
              <br />
              원인 분석을 통해
              <br />
              확실한 개선방안 제시
            </div>
            <div className="section3_more">
              <i className="xi-plus-min"></i>
            </div>
            <div className="section3_comment">
              <li>영업물 배상 책임보험 가입으로 위생문제 발생시 적극 대응</li>
              <li>배송 오류 및 품질 보상제 운영</li>
            </div>
          </div>
          <div className="section3_img4"></div>
        </div>
      </CenterDiv>
    </Section20>
  );
};

export default DistributionDesc;
