import { Element, Link } from "react-scroll";
import { Arrow2, SubContent } from "../../shared";

const TransportationIntro = () => {
  return (
    <Element name="sectionTrans">
      <SubContent className="sub_contents_title" id="subsectionlink1">
        <div className="title1">Transportation</div>
        <div className="title2">화물 주선</div>
        <div className="title3">
          고객사의 요청에 부합하는 영업용 냉장/상온차량을
          <br />
          제공 및 관리해주는 서비스로,
          <br />
          <br />
          ‘Timf Network DB’를 통해 차량을
          <br />
          원활하게 수급하고 있습니다.
        </div>
        <Arrow2 className="sub_images_arrow sub_images_arrow2">
          <Link className="anchorLink" to="sectionTProcess" smooth={true}>
            <i className="xi-angle-down"></i>
          </Link>
        </Arrow2>
      </SubContent>
    </Element>
  );
};

export default TransportationIntro;
