import React, { useState, useEffect } from "react";
import {
  getCategoryList,
  getInquiryReply,
  postMyInquiry,
} from "../../../services";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../common/Pagination";
import styled from "styled-components";
import { WhiteButton } from "../../shared";
import Parser from "html-react-parser";
import routes from "../../../routes";

const Table = styled.table`
  tbody {
    background-color: #ffffff;
    tr {
      color: #555555;
      td {
        font-size: 16px;
        font-weight: 300;
        padding: 15px 5px;
        box-sizing: border-box;
      }

      td.head_td {
        height: 50px;
        // color: #000 !important;
        font-size: 18px !important;
        font-weight: 400 !important;
        border-width: 0;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 1px 0 0 0;
      }

      td.list_subject {
        word-break: break-all;
        padding-left: 15px !important;
      }
      td.list_hr_line {
        height: 1px;
        background-color: #f4f3f1;
        padding: 0 !important;
      }
    }
  }
`;

const InquiryUserDetail = () => {
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [inquiryList, setInquiryList] = useState([]);
  const [replyList, setReplyList] = useState([]);

  const { state } = useLocation();
  const { inquirySeq } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const list = state?.res?.filter((item) => {
      if (item.inquirySeq === parseInt(inquirySeq)) return item;
    });

    setInquiryList(list);
    getMyInquiryReplyList();
  }, [inquirySeq, state]);

  // useEffect(() => {
  //   getMyInquiryList();
  // }, [currentPage]);

  // const getMyInquiryList = async () => {
  //   const res = await postMyInquiry(state?.values, currentPage);
  //   setInquiryList(res?.data);
  //   setTotalPage(res?.totalPages);
  // };

  const getMyInquiryReplyList = async () => {
    const res = await getInquiryReply(parseInt(inquirySeq));

    setReplyList(res);
  };

  const goToInquiryList = async () => {
    const res = await postMyInquiry(state?.values, 1);

    navigate(routes.inquiryList, {
      state: {
        res: state?.res,
        values: state?.values,
        totalPages: state?.totalPages,
      },
    });
  };

  return (
    <>
      <h1 style={{ margin: "50px" }}>나의 문의내역</h1>
      <Table
        id="mainIndexTable"
        cellPadding="0"
        cellSpacing="0"
        width="100%"
        border="0"
      >
        <tbody>
          {inquiryList?.map((item, index) => (
            <React.Fragment key={index}>
              <tr height="50">
                <td width="1px" className="head_td"></td>

                <td className="head_td" style={{ width: "10%" }}>
                  <nobr>{index + 1}</nobr>
                </td>

                <td
                  className="head_td"
                  style={{ width: "70%", padding: "10px" }}
                  align="left"
                >
                  <span
                    id="opennumber88"
                    style={{
                      fontWeight: "700",
                      color: "skyblue",
                      fontSize: 14,
                    }}
                  >
                    {item.category}
                  </span>
                  <br />
                  <span id="opennumber88" style={{ fontWeight: "700" }}>
                    {item.title}
                  </span>
                </td>

                {item.answerYn === "Y" ? (
                  <td className="head_td" style={{ width: "10%" }}>
                    답변완료
                  </td>
                ) : (
                  <td className="head_td" style={{ width: "10%" }}>
                    접수완료
                  </td>
                )}

                <td className="head_td">
                  <nobr>{item.creDt?.split(" ", 1)}</nobr>
                </td>

                <td width="1px"></td>
              </tr>
              {/* 질문내역 */}
              <tr className="head_td">
                <td colSpan="8" align="left">
                  {item.contents}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <section style={{ borderTop: "1px solid #ccc", textAlign: "left" }}>
        {replyList?.map((item) => (
          <div>
            {/* 답변 */}

            <div>{Parser(item?.contents)}</div>
          </div>
        ))}
      </section>

      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <WhiteButton onClick={goToInquiryList}>목록보기</WhiteButton>
    </>
  );
};

export default InquiryUserDetail;
