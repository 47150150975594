import styled, { keyframes } from "styled-components";

export const CenterDiv = styled.div`
  width: 1450px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
`;

export const CenterRelative = styled(CenterDiv)`
  position: relative;
  left: 0;
  top: 0;
`;

export const SectionMain = styled.div`
  position: relative;
  display: table;
  width: 100%;
  padding: 150px 0;
  word-break: keep-all;
`;

export const MainTitle = styled.div`
  width: 100%;
  font-family: "Pretendard";
  text-align: center;
  font-weight: 700;
  font-size: 55px;
  line-height: 45px;
  color: ${({ theme }) => theme.colors.black};

  span {
    font-weight: 300;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const TabBox = styled.div`
  position: relative;
  width: 1210px;
  margin: 50px auto 0;
  padding: 0 30px;
  box-sizing: border-box;

  .service_tabbtn {
    position: absolute;
    top: 50px;
    left: 30px;
    display: table;
    float: left;
    width: 45%;
    background: rgb(245 245 245);
    padding: 0;
    box-sizing: border-box;
    z-index: 1;

    .on {
      color: rgb(255 255 255);
      background: rgb(2 32 75);
      display: block;
      visibility: visible;
      img {
        filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(244deg)
          brightness(104%) contrast(104%);
      }
    }

    ul {
      width: 100%;
      padding: 0;
      margin: 0 auto;
    }

    li {
      position: relative;
      float: left;
      width: 25%;
      text-align: center;
      font-family: "Pretendard";
      font-weight: 300;
      font-size: 20px;
      line-height: 40px;
      color: rgb(0 0 0);
      cursor: pointer;
      padding: 75px 0 15px;
      box-sizing: border-box;
    }

    img {
      position: absolute;
      top: 15px;
      left: 50%;
      width: 60px;
      margin-left: -30px;
    }
  }

  .service_contents {
    clear: left;
    float: left;
    position: relative;
    width: 100%;
    margin: 0 auto 0;

    .service_text {
      float: left;
      width: 40%;
      margin-top: 230px;

      .service_text_title {
        font-family: "Pretendard";
        text-align: left;
        font-weight: 700;
        font-size: 35px;
        line-height: 45px;
        color: rgb(2 32 75);
      }

      .service_text_comment {
        font-family: "Pretendard";
        text-align: left;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        color: rgb(102 102 102);
        margin: 30px 0 0 0;
      }

      .service_link {
        clear: both;
        float: left;
        cursor: pointer;
        margin: 30px 0 0 0;
        opacity: 0.3;
      }
    }
  }
`;

export const MoreBtn = styled.div`
  font-family: "Pretendard";
  font-size: 17px;
  font-weight: 300;
  line-height: 25px;
  color: rgb(0 0 0);
  cursor: pointer;
`;

export const SubImages = styled.div`
  position: relative;
  display: table;
  width: 100%;
  min-width: 1210px;
  height: 100vh;
  text-align: center;
  background: rgb(0 0 0);
  overflow: hidden;
  @media only screen and (max-width: 480px) {
    height: 180vh;
  }

  .sub_images_title {
    position: absolute;
    bottom: 45%;
    left: 0;
    width: 100%;
    font-family: "Pretendard";
    font-weight: 700;
    font-size: 55px;
    line-height: 65px;
    color: #fff;
    z-index: 2;
  }

  .sub_serviceDetail_icon {
    position: absolute;
    left: 44%;
    bottom: 37%;
    z-index: 2;
    cursor: pointer;
  }
`;

export const ShadowBg = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  font-size: 0;
  background: linear-gradient(
    to bottom,
    rgb(255 255 255 / 0%),
    rgb(255 255 255)
  );
  z-index: 1;
`;

export const SubImgWrapper = styled.div`
  display: table;
  width: 100%;
  height: 100vh;
  font-size: 0;
  opacity: 1;
  @media only screen and (max-width: 480px) {
    height: 180vh;
  }
`;

const ArrowMoving = keyframes`
	0%	{ bottom:25%;}
	20% { bottom:24%; opacity:0;}
	30% { bottom:24%; opacity:0;}
	70%	{ bottom:25%;}
	100% { bottom:25%;}
`;

export const Arrow = styled.div`
  position: absolute;
  bottom: 20%;
  left: 50%;
  width: 50px;
  margin-left: -25px;
  color: rgb(255 255 255);
  z-index: 2;
  animation: ${ArrowMoving} 2s linear infinite;
  cursor: pointer;
  a {
    font-size: 40px;
    color: rgb(255 255 255);
  }
`;

const ArrowMoving2 = keyframes`
	0%	{ bottom:15%;}
	20% { bottom:13%; opacity:0;}
	30% { bottom:13%; opacity:0;}
	70%	{ bottom:15%;}
	100% { bottom:15%;}
`;

export const Arrow2 = styled(Arrow)`
  animation: ${ArrowMoving2} 2s linear infinite;
  a {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const SubSection = styled.section`
  position: relative;
  display: table;
  width: 100%;
  padding: 150px 0;
  overflow: hidden;
`;

export const SubTitle = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 55px;
  line-height: 65px;
  color: ${({ theme }) => theme.colors.black};
  .sub_title_eng {
    width: 100%;
    font-weight: 300;
    font-size: 30px;
    line-height: 25px;
    margin: 0 0 30px 0;
  }
  .sub_title_comment {
    width: 100%;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    color: rgb(0 0 0);
    margin: 30px 0 0 0;
  }
`;

export const TextTitle1 = styled.div`
  position: relative;
  width: 100%;
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: rgb(0 0 0);
  padding: 0 0 15px;
`;
export const TextTitle2 = styled.div`
  width: 100%;
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 55px;
  line-height: 60px;
  color: rgb(0 0 0);
`;
export const TextComment = styled.div`
  width: 100%;
  font-family: "Pretendard";
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  color: rgb(0 0 0);
  margin: 50px 0 0 0;
  span {
    font-weight: 500;
  }
  li {
    list-style: none;
    position: relative;
    clear: both;
    float: left;
    width: 100%;
    color: rgb(0 0 0);
    font-size: 18px;
    line-height: 23px;
    padding: 10px 0 0 30px;
    box-sizing: border-box;
    :before {
      position: absolute;
      top: 15px;
      left: 10px;
      width: 7px;
      height: 7px;
      background: rgb(255 255 255);
      border: 3px solid rgb(2 32 74);
      border-radius: 100%;
      content: "";
    }
    // @media only screen and (max-width: 480px) {
    //   font-size: 26px;
    //   line-height: 32px;
    //   :before {
    //     top: 20px;
    //   }
    // }
  }
`;
export const TextComment2 = styled.div`
  width: 100%;
  font-family: "Pretendard";
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  color: rgb(0 0 0);
  margin: 150px 0px;
`;

export const SectionImg = styled.div`
  position: relative;
  float: left;
  width: 55%;
  padding: 0 20px;
  box-sizing: border-box;
`;

export const NewsList = styled.div`
  position: relative;
  clear: both;
  width: 100%;
  border-bottom: 1px solid #eee;
  padding: 30px 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;

  &:hover {
    .news_arrow {
      right: 0px;
    }

    .news_source,
    /* .news_date, */
    .news_body {
      margin-left: -10px;
    }
  }
  .news_arrow {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 10px;
    font-size: 25px;
    color: #ccc;
    z-index: 1;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
  }
  [class*=" xi-"],
  [class^="xi-"] {
    font-family: xeicon !important;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .news_source,
  .news_date,
  .news_body {
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
  }
  .news_source {
    float: left;
    font-family: "Pretendard";
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    color: #02204b;
  }

  .news_date {
    float: left;
    font-family: "Pretendard";
    font-weight: 300;
    font-size: 17px;
    line-height: 25px;
    color: #666;
    padding-left: 10px;
  }

  .news_body {
    clear: both;
    width: 90%;
    font-family: "Pretendard";
    text-align: left;
    font-weight: 500;
    font-size: 23px;
    line-height: 25px;
    color: #333;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding-top: 10px;
  }
`;

export const PagingWrapper = styled.table`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  text-align: center;

  td {
    cursor: pointer;
    /* width: 100%; */
  }

  .page_number {
    color: #000;
    font-size: 17px !important;
    font-family: "Pretendard" !important;
    font-weight: 700;
    padding: 3px 9px;
    margin: 2px;
  }

  .page_number2 {
    background: #fff;
    border: 1px solid #171c61;
    color: #000;
  }
`;

export const SnsLink = styled.div`
  li {
    float: left;
    width: 40px;
    height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 100%;
    overflow: hidden;
    padding: 6px 0 0 0;
    margin: 0 10px 0 0;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;

    :nth-child(1):hover {
      background: #18539a;
    }

    :nth-child(2):hover {
      background: #000;
    }

    :nth-child(3):hover {
      background: #ff0000;
    }

    i {
      color: #fff;
      font-size: 27px;
    }

    img {
      height: 27px;
      margin: 1px 0 0 0;
    }

    :nth-child(1):hover {
      background: #18539a;
    }
  }
`;

export const SubContent = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 150px 0 200px;

  .title1 {
    width: 100%;
    font-family: "Pretendard";
    font-weight: 300;
    font-size: 30px;
    line-height: 25px;
    color: #000;
    margin: 0 0 30px 0;
  }

  .title2 {
    width: 100%;
    font-family: "Pretendard";
    font-weight: 700;
    font-size: 55px;
    line-height: 65px;
    color: #000;
    margin: 0 0 30px 0;
  }

  .title3 {
    width: 100%;
    font-family: "Pretendard";
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    color: #000;
  }
`;

export const CardContents = styled.div`
  .section1_contents {
    clear: both;
    width: 100%;
    padding: 50px 0 0 0;
  }

  .contents_box {
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
    float: left;
    width: 23%;
    height: 370px;
    background: rgb(255 255 255);
    margin: 0 1%;
    padding: 0 10px;
    border-radius: 15px;
    box-shadow: 3px 3px 5px rgb(0 0 0 / 10%);
    box-sizing: border-box;
    .contents_icon {
      width: 140px;
      height: 140px;
      text-align: center;
      border-radius: 100%;
      margin: 50px auto 40px;
      box-sizing: border-box;
      overflow: hidden;
    }

    .contents_icon_bg {
      width: 100%;
      height: 100%;
      background: rgb(2 32 74 / 90%);
      padding: 15px 0 0 0;
    }

    img {
      width: 73%;
      filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(244deg)
        brightness(104%) contrast(104%);
      transition: all 0.4s ease;
      -webkit-transition: all 0.4s ease;
    }

    .contents_title {
      width: 100%;
      font-family: "Pretendard";
      text-align: center;
      font-weight: 700;
      font-size: 25px;
      line-height: 35px;
      color: rgb(2 32 74);
      padding: 10px 0;
    }

    .contents_comment {
      width: 100%;
      font-family: "Pretendard";
      text-align: center;
      font-weight: 300;
      font-size: 17px;
      line-height: 23px;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const SectionText = styled.div`
  position: relative;
  width: 45%;
  padding: 150px 20px;
  box-sizing: border-box;
`;

export const Photobox = styled.div`
  position: relative;
  width: 65%;
  margin: 30px auto 30px;
  border: 8px solid rgb(255 255 255);
  border-top-width: 35px;
  border-radius: 40px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
  :before {
    position: absolute;
    top: -20px;
    left: 50%;
    width: 50px;
    margin-left: -25px;
    height: 2px;
    background: rgb(0 0 0 / 10%);
    border-radius: 100px;
    content: "";
    z-index: 50;
  }
`;

export const Sliders = styled.div`
  overflow: hidden;
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  border-radius: 5px 5px 35px 35px;
  overflow: hidden;
  .slidesjs-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export const CustomerWrapper = styled.div`
  .sub_contents_title {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 150px 0 150px;
  }

  .search_contents {
    position: relative;
    display: table;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding: 0 0 5px 0;
    margin: 0 0 50px 0;
    box-sizing: border-box;

    .search_wordbox {
      float: left;
      width: 100%;
      box-sizing: border-box;
      ul {
        padding: 0;
        margin: 0;
      }
      li {
        position: relative;
        width: 100%;
        float: left;
        list-style: none;
        text-align: left;
        line-height: 40px;
        padding-left: 25px;
        box-sizing: border-box;
      }
      .searth_box {
        float: left;
        width: 100%;
        font-family: "Pretendard";
        font-size: 20px;
        line-height: 40px;
        color: #000;
        background: transparent;
        border-width: 0;
        @media only screen and (max-width: 480px) {
          font-size: 32px;
        }
      }
    }
  }
`;

export const CSubContent = styled(SubContent)`
  padding: 150px 0 150px;
`;

export const Form = styled.form`
  margin: 0;
  padding: 0;
  text-align: left;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  border-top: 2px solid #000;
  width: 100%;
  .write_left_td {
    width: 15%;
    height: 40px;
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 17px;
    color: #000;
    font-weight: 300;
    box-sizing: border-box;
    vertical-align: middle;
    @media only screen and (max-width: 480px) {
      font-size: 28px;
      width: 18%;
    }
  }

  .write_right_td {
    letter-spacing: -1;
    border-bottom: 1px solid #e5e5e5;
    font-size: 17px;
    font-weight: 300;
    padding: 15px;
    box-sizing: border-box;
    @media only screen and (max-width: 480px) {
      font-size: 28px;
    }
  }

  .write_input_select {
    border: 1px solid #dddddd;
    letter-spacing: 0;
    color: #666666;
    font-size: 17px;
    font-weight: 300;
    border: 1px solid #dddddd;
    height: 40px;
  }

  .write_input_text {
    border: 1px solid #dddddd;
    color: #666666;
    font-size: 17px;
    font-weight: 300;
    height: 40px;
    @media only screen and (max-width: 480px) {
      font-size: 28px;
      height: 50px;
    }
  }

  .write_input_textarea {
    color: #666666;
    font-size: 17px;
    font-family: "Pretendard";
    font-weight: 300;
  }
`;

export const Input = styled.input`
  border: 1px solid #dddddd;
  color: #666666;
  font-size: 17px;
  font-family: "Pretendard";
  font-weight: 300;
  height: 40px;
`;

export const Input100 = styled(Input)`
  width: 100%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const CategoryBox = styled.div`
  width: 100%;
  margin: 0 0 30px;
  ul {
    padding: 0;
    margin: 0;
    line-height: 0;
    font-size: 0;
    li {
      list-style: none;
      display: inline-block;
    }
  }
  .back {
    font-family: "Pretendard";
    font-weight: 300;
    font-size: 16px;
    line-height: 35px;
    color: #000;
    padding: 0 0;
    margin: 0 20px 0 0;
    cursor: pointer;
  }
  .select {
    font-weight: 500;
    color: #000;
    border-bottom: 1px solid #000;
  }
`;

export const WhiteButton = styled.button`
  width: 150px;
  text-align: center;
  padding: 13px;
  background-color: white;
  color: rgb(2, 10, 95);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #dddddd;
`;

export const NavyButton = styled.button`
  width: 200px;
  padding: 13px;
  background-color: rgb(2, 10, 95);
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
`;

export const LinkStyle = styled.span`
  color: blue;
  cursor: pointer;
  margin-right: 10px;
`;
