import { useEffect, useState } from "react";
import styled from "styled-components";
import { getTimfRecruitList } from "../../services";

import { SubSection, CenterRelative, SubTitle } from "../shared";
import RecruitPageList from "./RecruitPageList";
import PageNumber from "../common/Pagination";
import { Link, useLocation } from "react-router-dom";
import routes from "../../routes";
import { useStore } from "../../UserContext";

const Section33 = styled(SubSection)`
  background: rgba(0, 0, 0, 0.03);
`;

const RecruitBoard = () => {
  const { state } = useStore();

  const url = useLocation();

  const [recruitList, setRecruitList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const getRecruitList = async () => {
    const res = await getTimfRecruitList(currentPage);
    if (res) {
      setRecruitList(res.data);
      setTotalPage(res.totalPages);
    }
  };

  useEffect(() => {
    getRecruitList();
  }, [currentPage]);

  return (
    <Section33 className="sub_section sub_section2">
      <CenterRelative className="centerdiv relattive">
        <SubTitle className="sub_title">인재채용</SubTitle>
        <RecruitPageList
          list={recruitList}
          updateRecruitList={getRecruitList}
        />
        <PageNumber
          totalPage={totalPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        {state.user ? (
          <div
            id="mainbutton_td"
            style={{ paddingTop: "30px", textAlign: "left" }}
          >
            <Link
              to={url.pathname === "/admin" ? "#" : routes.recruitcreate}
              state={
                url.pathname === "/admin"
                  ? { adminPath: "recruitCreate" }
                  : null
              }
            >
              <img
                src="../../skin_board/a_standard/b_img/i_button/write.gif"
                align="absmiddle"
                border="0"
                alt=""
              />
            </Link>
          </div>
        ) : null}
      </CenterRelative>
    </Section33>
  );
};

export default RecruitBoard;
