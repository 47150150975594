import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import routes from "../../routes";
import { postCreateFaq } from "../../services";
import { CenterDiv, CSubContent, Table, Form, Input100 } from "../shared";

const NSubContent = styled(CSubContent)`
  background: rgba(0, 0, 0, 0.03);

  .sub_contents_title .snslink {
    display: table;
    margin: 50px auto 0;
  }
  .sub_contents_title .snslink li {
    display: inline-block;
    float: none;
    width: 60px;
    height: 60px;
    padding: 10px 0 0 0;
    margin: 0 20px 0 0;
    background: #02204a;
  }
  .sub_contents_title .snslink li i {
    font-size: 37px;
  }
  .sub_contents_title .snslink li img {
    height: 37px;
  }

  .title1,
  .title2 {
    text-align: left;
  }

  .title2 {
    margin-bottom: 100px;
  }

  .write_check_td {
    border-bottom: 1px solid #e5e5e5;
  }
`;

const FaqCreate = () => {
  const url = useLocation();
  const navigate = useNavigate();

  const [faqContents, setFaqContents] = useState({
    title: "",
    service: "",
    contents: "",
    categoryName: "",
  });

  const onClickFaqCreate = async () => {
    const res = await postCreateFaq(faqContents);
    if (res) {
      alert("등록되었습니다.");
      navigate(url.pathname === "/admin" ? `${routes.admin}` : `${routes.faq}`);
    }
  };

  return (
    <NSubContent className="sub_contents_title" id="subsectionlink1">
      <CenterDiv className="centerdiv">
        <div className="title1">FAQ</div>
        <div className="title2">자주 묻는 질문</div>
      </CenterDiv>
      <CenterDiv className="centerdiv">
        <div>
          <Form
            method="post"
            name="TechnoteWriteForm"
            id="TechnoteWriteForm_id"
            action="/php/board.php"
            encType="multipart/form-data"
            target="wrhiddenframe"
          >
            <Table
              cellSpacing="0"
              cellPadding="2"
              border="0"
              className="write_main_table"
            >
              <tbody>
                <tr>
                  <td className="write_left_td">제목</td>
                  <td className="write_check_td">
                    <img
                      src="../../skin_board/a_standard/b_img/icons/write_check1.gif"
                      alt=""
                    />
                  </td>
                  <td className="write_right_td">
                    <Input100
                      type="text"
                      name="subject"
                      size="45"
                      value={faqContents.title}
                      className="write_input_text"
                      onChange={(e) =>
                        setFaqContents({
                          ...faqContents,
                          title: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td className="write_left_td">서비스</td>
                  <td className="write_check_td">
                    <img
                      src="../../skin_board/a_standard/b_img/icons/write_check1.gif"
                      alt=""
                    />
                  </td>
                  <td className="write_right_td">
                    <select
                      name="categorySeq"
                      className="write_input_select"
                      value={faqContents.categoryName}
                      onChange={(e) =>
                        setFaqContents({
                          ...faqContents,
                          categoryName: e.target.value,
                        })
                      }
                    >
                      <option value="">서비스를 선택 해주세요</option>
                      <option value="새벽배송">새벽배송</option>
                      <option value="풀필먼트">풀필먼트</option>
                      <option value="화물주선">화물주선</option>
                      <option value="식자재유통">식자재유통</option>
                      <option value="프랜차이즈">프랜차이즈</option>
                      <option value="보험">보험</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td className="write_left_td">내용</td>
                  <td className="write_check_td">
                    <img
                      src="../../../skin_board/a_standard/b_img/icons/write_check2.gif"
                      alt="'"
                    />
                  </td>
                  <td className="write_right_td">
                    <textarea
                      type="text"
                      name="home"
                      size="30"
                      style={{ height: "300px", width: "100%" }}
                      value={faqContents.url}
                      onChange={(e) =>
                        setFaqContents({
                          ...faqContents,
                          contents: e.target.value,
                        })
                      }
                      className="write_input_text"
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan="3" align="center" style={{ paddingTop: "10px" }}>
                    <Link to="#" onClick={onClickFaqCreate}>
                      <img
                        src="../../../skin_board/a_standard/b_img/i_button/write_save.gif"
                        border="0"
                        align="absmiddle"
                        alt=""
                      />
                    </Link>
                    &nbsp; &nbsp;
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form>
        </div>
      </CenterDiv>
    </NSubContent>
  );
};

export default FaqCreate;
