import styled from "styled-components";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  min-width: 1210px;
  overflow: hidden;
  @media only screen and (max-width: 480px) {
    height: 180vh;
  }

  h3 {
    margin: 0;
  }

  .slick-slider {
    height: 100%;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
      }
    }
  }

  #slide1 {
    background: url(./skin/images/main_images01.jpg) no-repeat 50% 50%;
    background-size: cover;
    height: 100vh;
    @media only screen and (max-width: 480px) {
      height: 180vh;
    }
  }

  #slide2 {
    background: url(./skin/images/main_images02.jpg) no-repeat 50% 50%;
    background-size: cover;
    height: 100vh;
    @media only screen and (max-width: 480px) {
      height: 180vh;
    }
  }

  #slide3 {
    background: url(./skin/images/main_images03.jpg) no-repeat 50% 50%;
    background-size: cover;
    height: 100vh;
    @media only screen and (max-width: 480px) {
      height: 180vh;
    }
  }

  .slick-dots {
    position: absolute;
    top: 50%;
    left: 6%;
    margin-left: -575px;
    font-size: 0;
    z-index: 90;
    margin-top: 120px;

    li {
      width: 45px;
      height: 7px;
      margin: 0 3px;
    }

    li button {
      position: relative;
      display: inline-block;
      width: 45px;
      height: 7px;
      padding: 0;
      cursor: pointer;
    }

    li button,
    li button:before {
      color: white;
      opacity: 1;
      background-color: white;
      width: 45px;
      height: 7px;
      border-radius: 10px;
      content: none;
    }
    li.slick-active,
    li.slick-active button {
      color: rgb(0 92 223);
      opacity: 1;
      background-color: rgb(0 92 223);
      width: 45px;
      height: 7px;
      border-radius: 10px;
      content: none;
    }

    .slick-dots li button:before {
      background-color: rgb(0 92 223);
    }
  }
`;

const SliderText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: left;
  z-index: 20;
  margin-left: -575px;
  margin-top: -150px;

  > div.main_images_title_k {
    clear: both;
    font-family: "Pretendard";
    font-weight: 700;
    font-size: 55px;
    line-height: 65px;
    color: rgb(255 255 255);
    margin: 20px 0 30px;
  }
  > div.main_images_comment {
    clear: both;
    font-family: "Pretendard";
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    color: rgb(255 255 255);
  }
`;

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <SliderContainer>
      <SliderText className="main_images_text">
        <div className="main_images_title_k">
          대한민국 No.1
          <br />
          Cold-chain platform
        </div>
        <div className="main_images_comment">
          팀프레시는 신선함을 이어 풍요로운 세상을 만듭니다.
        </div>
      </SliderText>

      <Slider {...settings}>
        <div>
          <h3 id="slide1">1</h3>
        </div>
        <div>
          <h3 id="slide2">2</h3>
        </div>
        <div>
          <h3 id="slide3">3</h3>
        </div>
      </Slider>
    </SliderContainer>
  );
};

export default Carousel;
