const ModalIS = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              편무현
              <br />
              <span>
                인프라전략팀(IS)
                <br />
                팀장
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img04.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              인프라전략팀은 인프라 구축과 총무업무를 담당하고 있어요.
              <br />
              따라서 다양한 사업을 영위하고 있는 팀프레시가 가지고 있는 모든
              물류센터,오피스,프렌차이즈 체인점 등 자산을 구축/도입하고
              효율적으로 개선하는 일, 유지/보수, 심지어 화물차량, 각종 영업권 및
              인허가 취득, 구매/입찰 업무까지 다양한 업무를 진행하고 있어요.
              <br />
              앞에서 설명한 것을 보면 다소 어려워 보일 수 있지만 다양한 업무를
              진행하는만큼 실생활에 필요한 기술/지식도 많이 배울 수 있는
              팀이에요.
              <br />
              저는 다재다능한 인프라전략팀의 팀장 직무를 맡아 위에서 설명드린
              업무들을 수행하는 저희 팀원들의 도우미로써 팀 총괄을 담당하고
              있습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              팀프레시는 각 실무자에게 많은 결정권을 주고 프로젝트의 계획 및
              실행을 할 수 있도록 직원들을 믿어준다고 생각해요.
              <br />
              따라서 개인적으로 '경험이 최고의 커리어'라고 생각하는 바, 실무자는
              다양한 업무를 직접 처음부터 끝까지 수행하며 주니어가 빠르게
              시니어까지 성장할 수 있는 기회의 장을 열어줄 수 있는 곳이라고
              느끼며 일하는 중이에요!
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’는 주니어들이 일하기 좋은 기업인가요?
            </div>
            <div className="interview_comment_A">
              보통 주니어는 맡은 일만 할 수 있고 지시 또는 성과가 있어야
              업무영역을 넓혀갈 수 있다고 생각하는데, 앞서 말씀드린 것과 같이
              팀프레시는 많은 경험과 기회가 주어지는 곳입니다.
              <br />
              커리어 성장에 있어 ‘경험과 기회’를 빼 놓을 수 없다고 생각해요.
              <br />
              더불어 교육팀에서 진행하는 각종 자체 내 교육 프로그램 및 아낌없는
              교육비 지원제도 등은 팀프레시의 자랑입니다.
              <br />
              직원들은 실무와 교육을 빠르게 경험하여 PM의 역량을 누구나 갖춰나갈
              수 있기에 [팀프레시 내에서 그리고 나중에 나는 어떤 일을 할
              것인가]에 대해 고민하며 성장할 수 있는 것 같아요.
              <br />
              물류 새내기였던 저에게도 입사 2년 남짓 한 시간에 팀장과 같은
              직무까지 경험해 볼 수 있는 기회를 주셔서 감사하고요!
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              팀프레시가 젊은 조직인 것처럼 저희 팀도 아주 젊은 대부분 20대
              연령층의 친구들이 모여 하고싶은 말,하고 싶은 것 다하며 즐겁게
              일하고 있습니다.
              <br />
              팀만의 분위기라고 하자면 저는 [시트콤 보는 것 같다]라고 말하고
              싶어요.
              <br />
              각자 캐릭터들이 확고해 가끔 서로 다른 생각과 다른 얘기를 하고 있는
              것 같지만 결국 오래보고 지낸 사람처럼 한마음 한 뜻으로 챙기고
              있더라고요.
              <br />
              어떤 팀원이 오더라도 재미있게 스며들 수 있는 조직이라고 생각해요.
            </div>
            <div className="interview_comment_Q">본인에게 ‘팀프레시’란?</div>
            <div className="interview_comment_A">
              팀프레시에 입사하고 제일 많이 하는 말이 "이게 맞아?"인 것 같아요.
              <br />
              모든 일에 의심을 하는 것처럼 보일 수 있지만 더 나은 방향에 대한
              고민이었어요.
              <br />
              그만큼 내가 하고 있는 업무뿐 아니라 삶에 좋은 방향이 무엇일까에
              대해 많은 생각을 할 수 있게 해준 회사라고 생각해요.
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              말싸움을 잘 할 수 있는 사람이 필요해요.
              <br />
              (다음에 말씀드리는 부분을 잘 봐주시고 오해는 말아주세요.)
              <br />
              그 말은 목적을 인지하고 방향을 명확하게 하는 사람인 것 같아요.
              <br />
              더불어 문제 인식이 되었을 때 두려워하지 않고 주관을 논리 있게
              정리하여 피드백을 요청하여 발전하는 사람을 찾고 있습니다.
              <br />
              본인이 특이하다는 소리를 많이 들어본 사람도 적극 환영입니다.(웃음)
              (저희 팀원 모두 그렇거든요!)
            </div>
            <div className="interview_comment_Q">
              마지막으로, 한 말씀 부탁드립니다!
            </div>
            <div className="interview_comment_A">
              많은 사람들이 [인프라전략팀]이라는 이름을 들으면 어떤 일을 할까?
              어렵지 않을까? 배경지식이 없는데 가능할까?와 같은 고민들을 많이
              하고 저 또한 입사 당시 그랬던 것 같아요.
              <br />
              하지만 제가 설명드린 것처럼 누구든 치열하게 또한 즐겁게 배우며
              성장할 수 있는 팀이라고 생각합니다.
              <br />
              인프라 전문가로 함께 성장하실 분?
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalIS;
