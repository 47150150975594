// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import Layout from './components/common/Layout';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme } from './styles';

import Slider from './components/Main/Carousel';
import routes from './routes';
import Introduce from './components/Main/Introduce';
import Business from './components/Main/Business';
import Service from './components/Main/Service';
import Point from './components/Main/Point';
import Link from './components/Main/Link';
import TimfAbout from './components/Business/TimfAbout';
import TimfMission from './components/Business/TimfMission';
import TimfService from './components/Business/TimfService';
import TimfOutput from './components/Business/TimfOutput';
import TimfCenter from './components/Business/TimfCenter';
import BTimfNews from './components/Business/BTimfNews';
import TimfHistory from './components/Business/TimfHistory';
import TimfClient from './components/Business/TimfClient';
import TimfPartner from './components/Business/TimfPartner';
import MorningDelivery from './components/Service/MorningDelivery';
import ServiceAbout from './components/Service/ServiceAbout';
import Process from './components/Service/Process';
import TransferCenter from './components/Service/TransferCenter';
import Tms from './components/Service/Tms';
import FixedTransport from './components/Service/FixedTransport';
import Tcc from './components/Service/Tcc';
import DeliveryLocation from './components/Service/DeliveryLocation';

import NestedLayout from './components/common/NestedLayout';
import FulfillmentAbout from './components/Service/Fulfillment/FulfillmentAbout';
import FulfillmentIntro from './components/Service/Fulfillment/FulfillmentIntro';
import FulfillmentProcess from './components/Service/Fulfillment/FulfillmentProcess';
import PPackingProcess from './components/Service/Fulfillment/PPackingProcess';
import DistributionCenter from './components/Service/Fulfillment/DistributionCenter';
import TransportationIntro from './components/Service/Transportation/TransportationIntro';
import TransportationAbout from './components/Service/Transportation/TransportationAbout';
import TransportationProcess from './components/Service/Transportation/TransportationProcess';
import ManagingSystem from './components/Service/Transportation/ManagingSystem';
import Platform from './components/Service/Transportation/Platform';

import DistributionProcess from './components/Service/Distribution/DistributionProcess';
import DistributionAbout from './components/Service/Distribution/DistributionAbout';
import DistributionIntro from './components/Service/Distribution/DistributionIntro';
import DistributionNewwork from './components/Service/Distribution/DistributionNewwork';
import DistributionDesc from './components/Service/Distribution/DistributionDesc';
import FranchiseAbout from './components/Service/Franchise/FranchiseAbout';
import FranchiseIntro from './components/Service/Franchise/FranchiseIntro';
import FranchiseList from './components/Service/Franchise/FranchiseList';
import FranchiseDesc from './components/Service/Franchise/FranchiseDesc';
import FranchiseReview from './components/Service/Franchise/FranchiseReview';
import FranchiseStep from './components/Service/Franchise/FranchiseStep';
import InsuranceAbout from './components/Service/Insurance/InsuranceAbout';
import InsuranceIntro from './components/Service/Insurance/InsuranceIntro';
import InsuranceDesc from './components/Service/Insurance/InsuranceDesc';
import InsuaranceProcess from './components/Service/Insurance/InsuaranceProcess';
import InsuranceGoods from './components/Service/Insurance/InsuranceGoods';
import InsuarancePartner from './components/Service/Insurance/InsuarancePartner';
import InsuaranceInquiry from './components/Service/Insurance/InsuaranceInquiry';
import RecruitAbout from './components/Recruit/RecruitAbout';
import RecruitIntro from './components/Recruit/RecruitIntro';
import RecruitBoard from './components/Recruit/RecruitBoard';
import RecruitInterview from './components/Recruit/RecruitInterview';

import Inquiry from './components/Customer/Inquiry/Inquiry';
import Faq from './components/Customer/Faq';
import Notfound from './components/common/Notfound';
import Login from './components/common/Login';
import Notice from './components/Customer/Notice';
import TimFNews from './components/Customer/TimfNews';
import TimfNewsModify from './components/Customer/TimfNewsModify';
import NoticeDetail from './components/Customer/NoticeDetail';
import NoticeCreate from './components/Customer/NoticeCreate';
import AdminMain from './components/Admin/AdminMain';

import NoticeModify from './components/Customer/NoticeModify';
import RecruitCreate from './components/Recruit/RecruitCreate';
import RecruitModify from './components/Recruit/RecruitModify';
import FaqCreate from './components/Customer/FaqCreate';
import FaqModify from './components/Customer/FaqModify';
import { useStore } from './UserContext';
import FranchiseInquiry from './components/Service/Franchise/FranchiseInquiry';
import Privacy from './components/common/Privacy';
import TermsOfUse from './components/common/TermsOfUse';
import TimfNewsCreate from './components/Customer/TimfNewsCreate';
import ScrollToTop from './hooks/ScrollToTop';
import PrevPrivacy from './components/common/PrevPrivacy';
import PrevTermsOfUse from './components/common/PrevTermsOfUse';
import TimfPassPrivacy from './components/common/TimfPassPrivacy';
import TimfPassTerms from './components/common/TimfPassTerms';
import { globalHistory } from './routes/history';
import PrevTimfPassPrivacy from './components/common/PrevTimfPassPrivacy';
import WmsPrivacy from './components/common/WmsPrivacy';

function App() {
    const { state } = useStore();

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />

            <HistoryRouter history={globalHistory}>
                <ScrollToTop />
                <Routes>
                    <Route
                        path="/google7326380997f5a42b.html"
                        element={
                            <iframe
                                src="/google7326380997f5a42b.html"
                                title="google-site-verification"
                                style={{ display: 'none' }}
                            />
                        }
                    />

                    <Route
                        path={routes.home}
                        element={
                            <Layout>
                                <Slider />
                                <Introduce />
                                <Business />
                                <Service />
                                <Point />
                                <Link />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.business}
                        element={
                            <Layout>
                                <TimfAbout />
                                <TimfMission />
                                <TimfService />
                                <TimfOutput />
                                <TimfHistory />
                                <TimfCenter />
                                <BTimfNews />
                                <TimfClient />
                                <TimfPartner />
                            </Layout>
                        }
                    ></Route>

                    <Route element={<NestedLayout />}>
                        <Route
                            path={routes.service}
                            element={
                                <>
                                    <ServiceAbout />
                                    <MorningDelivery />
                                    <Process />
                                    <TransferCenter />
                                    <Tms />
                                    <FixedTransport />
                                    <Tcc />
                                    <DeliveryLocation />
                                </>
                            }
                        />
                        <Route
                            path={routes.fulfillment}
                            element={
                                <>
                                    <FulfillmentAbout />
                                    <FulfillmentIntro />
                                    <FulfillmentProcess />
                                    <DistributionCenter />
                                    <PPackingProcess />
                                </>
                            }
                        />
                        <Route
                            path={routes.transportation}
                            element={
                                <>
                                    <TransportationAbout />
                                    <TransportationIntro />
                                    <TransportationProcess />
                                    <ManagingSystem />
                                    <Platform />
                                </>
                            }
                        />
                    </Route>

                    <Route
                        path={routes.distribution}
                        element={
                            <Layout>
                                <DistributionAbout />
                                <DistributionIntro />
                                <DistributionProcess />
                                <DistributionNewwork />
                                <DistributionDesc />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.franchise}
                        element={
                            <Layout>
                                <FranchiseAbout />
                                <FranchiseIntro />
                                <FranchiseList />
                                <FranchiseDesc />
                                <FranchiseReview />
                                <FranchiseStep />
                                {/* <FranchiseInterview /> */}
                                <FranchiseInquiry />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.insurance}
                        element={
                            <Layout>
                                <InsuranceAbout />
                                <InsuranceIntro />
                                <InsuranceDesc />
                                <InsuaranceProcess />
                                <InsuranceGoods />
                                <InsuarancePartner />
                                <InsuaranceInquiry />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.recruit}
                        element={
                            <Layout>
                                <RecruitAbout />
                                <RecruitIntro />
                                <RecruitInterview />
                                <RecruitBoard />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.recruitcreate}
                        element={
                            <Layout>
                                <RecruitCreate />
                            </Layout>
                        }
                    ></Route>

                    {state?.user ? (
                        <Route
                            path={routes.recruitmodify}
                            element={
                                <Layout>
                                    <RecruitModify />
                                </Layout>
                            }
                        ></Route>
                    ) : (
                        <Route path={routes.admin} element={<Login />}></Route>
                    )}

                    <Route
                        path={routes.notice}
                        element={
                            <Layout>
                                <Notice />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.noticedetail}
                        element={
                            <Layout>
                                <NoticeDetail />
                            </Layout>
                        }
                    ></Route>

                    {state?.user ? (
                        <Route
                            path={routes.noticecreate}
                            element={
                                <Layout>
                                    <NoticeCreate />
                                </Layout>
                            }
                        ></Route>
                    ) : (
                        <Route path={routes.admin} element={<Login />}></Route>
                    )}

                    {state?.user ? (
                        <Route
                            path={routes.noticemodify}
                            element={
                                <Layout>
                                    <NoticeModify />
                                </Layout>
                            }
                        ></Route>
                    ) : (
                        <Route path={routes.admin} element={<Login />}></Route>
                    )}

                    <Route
                        path={routes.timfnews}
                        element={
                            <Layout>
                                <TimFNews />
                            </Layout>
                        }
                    ></Route>

                    {state?.user ? (
                        <Route
                            path={routes.timfnewscreate}
                            element={
                                <Layout>
                                    <TimfNewsCreate />
                                </Layout>
                            }
                        ></Route>
                    ) : (
                        <Route path={routes.admin} element={<Login />}></Route>
                    )}

                    {state?.user ? (
                        <Route
                            path={routes.timfnewsmodify}
                            element={
                                <Layout>
                                    <TimfNewsModify />
                                </Layout>
                            }
                        ></Route>
                    ) : (
                        <Route path={routes.admin} element={<Login />}></Route>
                    )}

                    <Route
                        path={routes.inquiry}
                        element={
                            <Layout>
                                <Inquiry />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.inquiryList}
                        element={
                            <Layout>
                                <Inquiry />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.inquiryDetail}
                        element={
                            <Layout>
                                <Inquiry />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.faq}
                        element={
                            <Layout>
                                <Faq />
                            </Layout>
                        }
                    ></Route>

                    {state.user ? (
                        <Route
                            path={routes.faqcreate}
                            element={
                                <Layout>
                                    <FaqCreate />
                                </Layout>
                            }
                        ></Route>
                    ) : (
                        <Route path={routes.admin} element={<Login />}></Route>
                    )}

                    {state.user ? (
                        <Route
                            path={routes.faqmodify}
                            element={
                                <Layout>
                                    <FaqModify />
                                </Layout>
                            }
                        ></Route>
                    ) : (
                        <Route path={routes.admin} element={<Login />}></Route>
                    )}

                    <Route
                        path={routes.wmsPrivacy}
                        element={
                            <Layout>
                                <WmsPrivacy />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.privacy}
                        element={
                            <Layout>
                                <Privacy />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.prevPrivacy}
                        element={
                            <Layout>
                                <PrevPrivacy />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.terms}
                        element={
                            <Layout>
                                <TermsOfUse />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.prevTerms}
                        element={
                            <Layout>
                                <PrevTermsOfUse />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.prevTimfPassPrivacy}
                        element={
                            <Layout>
                                <PrevTimfPassPrivacy />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.timfPassPrivacy}
                        element={
                            <Layout>
                                <TimfPassPrivacy />
                            </Layout>
                        }
                    ></Route>

                    <Route
                        path={routes.timfPassTerms}
                        element={
                            <Layout>
                                <TimfPassTerms />
                            </Layout>
                        }
                    ></Route>

                    {state.user ? (
                        <Route path={routes.admin} element={<AdminMain />}></Route>
                    ) : (
                        <Route path={routes.admin} element={<Login />}></Route>
                    )}

                    {state.user ? (
                        <Route path={routes.adminInquiry} element={<AdminMain />}></Route>
                    ) : (
                        <Route path={routes.admin} element={<Login />}></Route>
                    )}

                    {state.user ? (
                        <Route path={routes.adminBusiness} element={<AdminMain />}></Route>
                    ) : (
                        <Route path={routes.admin} element={<Login />}></Route>
                    )}

                    <Route path={'*'} element={<Notfound />} />
                </Routes>
            </HistoryRouter>
        </ThemeProvider>
    );
}

export default App;
