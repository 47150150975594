import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import routes from "../../routes";
import { CenterRelative, SubSection, SubTitle } from "../shared";

const Section7 = styled(SubSection)`
  .sub_title {
    margin-top: 130px;
    :before {
      position: absolute;
      top: -130px;
      left: 50%;
      width: 1px;
      height: 80px;
      background: #152082;
      content: "";
    }
  }

  .inquirybtn {
    display: table;
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
    color: #fff;
    background: #02204a;
    margin: 100px auto 0;
    padding: 10px 60px 13px;
    border-radius: 35px 0 35px 0;
    cursor: pointer;
  }
`;

const TimfPartner = () => {
  const navigate = useNavigate();
  return (
    <Section7 className="sub_section sub_section8">
      <CenterRelative className="centerdiv relattive">
        <SubTitle className="sub_title">
          <div className="sub_title_eng">언제든 연락 주세요</div>
          팀프레시는 언제나 파트너를
          <br />
          기다리고 있습니다
        </SubTitle>

        <div
          className="inquirybtn"
          onClick={() => {
            navigate(routes.inquiry);
          }}
        >
          문의하기
        </div>
      </CenterRelative>
    </Section7>
  );
};

export default TimfPartner;
