import React from "react";
import styled from "styled-components";

const Header = styled.h1`
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;

const Notfound = () => {
  return (
    <div style={{ display: "block", margin: "8px" }}>
      <Header> Not Found</Header>
      <p>The requested URL was not found on this server.</p>
    </div>
  );
};

export default Notfound;
