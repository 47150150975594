import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { getCategoryList } from "../../services";
import {
  CenterDiv,
  SubContent,
  TextTitle1,
  TextTitle2,
  CategoryBox,
} from "../shared";
import InquiryTable from "./InquiryTable";

const InquiryBG = styled.div`
  background: url("../skin/images/sub_images0402.jpg") no-repeat fixed 50% 50%;
  background-size: cover;
`;

const InquiryWrapper = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .sub_contents_title .centerdiv {
    width: 1000px;
    min-height: 500px;
    background: #fff;
    padding: 50px;
  }
`;

const ISubContent = styled(SubContent)`
  padding: 150px 0 150px;

  .title1,
  .title2 {
    color: #fff;
  }

  .franchise_comment {
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    color: #000;
  }
`;

const Border = styled.div`
  width: 1px;
  height: 80px;
  background: #fff;
  margin: 0 auto 30px;
`;

const AdminInsuaranceContent = () => {
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [cateName, setCateName] = useState("단체상해보험");
  const [answerYn, setAnswerYn] = useState("N");
  const [categoryList, setCategoryList] = useState([]);

  const handleCategoryClick = (category) => {
    setCateName(category);
    setCurrentPage(1);
  };

  const getList = async () => {
    const res = await getCategoryList(
      "INQUIRY",
      cateName,
      currentPage,
      answerYn
    );
    setCategoryList(res.data);
    setTotalPage(res.totalPages);
  };

  const handleInquiryStateClick = (category) => {
    setAnswerYn(category);
    setCurrentPage(1);
  };

  useEffect(() => {
    getList();
  }, [cateName, currentPage, answerYn]);

  return (
    <InquiryBG>
      <InquiryWrapper>
        <ISubContent className="sub_contents_title" id="subsectionlink1">
          <TextTitle1 className="title1">Inquiry</TextTitle1>
          <TextTitle2 className="title2">문의하기</TextTitle2>
          <Border>&nbsp;</Border>
          <CenterDiv className="centerdiv">
            <CategoryBox className="category-border-box">
              <ul>
                <li onClick={() => handleInquiryStateClick("N")}>
                  <div className={answerYn === "N" ? "back select" : "back"}>
                    접수완료
                  </div>
                </li>
                <li onClick={() => handleInquiryStateClick("Y")}>
                  <div className={answerYn === "Y" ? "back select" : "back"}>
                    답변완료
                  </div>
                </li>
              </ul>
              <ul>
                {/* <li onClick={() => handleCategoryClick("")}>
                  <div className={cateName === "" ? "back select" : "back"}>
                    전체보기
                  </div>
                </li> */}
                <li onClick={() => handleCategoryClick("단체상해보험")}>
                  <div
                    className={
                      cateName === "단체상해보험" ? "back select" : "back"
                    }
                  >
                    단체상해보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("재산종합보험")}>
                  <div
                    className={
                      cateName === "재산종합보험" ? "back select" : "back"
                    }
                  >
                    재산종합보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("화재보험")}>
                  <div
                    className={cateName === "화재보험" ? "back select" : "back"}
                  >
                    화재보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("동산종합보험")}>
                  <div
                    className={
                      cateName === "동산종합보험" ? "back select" : "back"
                    }
                  >
                    동산종합보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("건설공사보험")}>
                  <div
                    className={
                      cateName === "건설공사보험" ? "back select" : "back"
                    }
                  >
                    건설공사보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("조립보험")}>
                  <div
                    className={cateName === "조립보험" ? "back select" : "back"}
                  >
                    조립보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("기계보험")}>
                  <div
                    className={cateName === "기계보험" ? "back select" : "back"}
                  >
                    기계보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("기관기계종합보험")}>
                  <div
                    className={
                      cateName === "기관기계종합보험" ? "back select" : "back"
                    }
                  >
                    기관기계종합보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("영업배상책임보험")}>
                  <div
                    className={
                      cateName === "영업배상책임보험" ? "back select" : "back"
                    }
                  >
                    영업배상책임보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("임원배상책임보험")}>
                  <div
                    className={
                      cateName === "임원배상책임보험" ? "back select" : "back"
                    }
                  >
                    임원배상책임보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("생산물배상책임보험")}>
                  <div
                    className={
                      cateName === "생산물배상책임보험" ? "back select" : "back"
                    }
                  >
                    생산물배상책임보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("전문인배상책임보험")}>
                  <div
                    className={
                      cateName === "전문인배상책임보험" ? "back select" : "back"
                    }
                  >
                    전문인배상책임보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("택배/퀵서비스보험")}>
                  <div
                    className={
                      cateName === "택배/퀵서비스보험" ? "back select" : "back"
                    }
                  >
                    택배/퀵서비스보험
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("기타보험")}>
                  <div
                    className={cateName === "기타보험" ? "back select" : "back"}
                  >
                    기타보험
                  </div>
                </li>
              </ul>
            </CategoryBox>
            <InquiryTable
              list={categoryList}
              category={"INSUARANCE"}
              totalPage={totalPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </CenterDiv>
        </ISubContent>
      </InquiryWrapper>
    </InquiryBG>
  );
};

export default AdminInsuaranceContent;
