import axios from "axios";

/**
 * api example
 * @param {*} page
 * @param
 * @returns
 */

// export const getTimfNews = async (page) => {
//   try {
//     const res = await axios.get("https://jsonplaceholder.typicode.com/posts");

//     return res;
//   } catch (err) {
//     console.log("err : ", err);
//   }
// };

//로그인
export const getUserInfo = async ({ email, password }) => {
  // const dispatch = useDispatch();
  let res = undefined;
  try {
    res = await axios
      .post("/api/v1/login", {
        email: email,
        pwd: password,
      })
      .then((res) => {
        const { data } = res;
        if (data && data.code === "E01102") {
          alert(data.message);
          return false;
        }
        if (data && data.code === "E01106") {
          alert(data.message);
          return false;
        } else {
          localStorage.setItem("accessToken", data.accessToken);
          // axios.defaults.headers.common[
          //   "Authorization"
          // ] = `Bearer ${data.accessToken}`;

          return true;
        }
      });
    return res;
  } catch (e) {
    console.log(e);
  }
};

/**
 * 팀프뉴스
 * @param {*} page
 * @param
 * @returns
 */
export const getTimfNewsDetail = async (seq) => {
  try {
    const { data } = await axios.get(`/api/v1/public/news/${seq}`);

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 팀프뉴스 글 조회
 * @param {*} page
 * @param
 * @returns
 *
 */
export const getTimfNews = async (page) => {
  const currentPage = page - 1;
  try {
    const { data } = await axios.get(`/api/v1/public/news?page=${currentPage}`);

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 팀프뉴스 글 생성 API
 * @param {*} page
 * @param
 * @returns
 */

export const postCreateTimfNews = async (list) => {
  try {
    const { data } = await axios.post(
      `/api/v1/news`,
      {
        title: list.title,
        url: list.url,
        author: list.author,
        publishedDate: list.publishedDate,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 팀프뉴스 글 수정
 * @param {*} page
 * @param
 * @returns
 *
 */
export const putTimfNewsModify = async (seq, list) => {
  try {
    const { data } = await axios.put(
      `/api/v1/news/${seq}`,
      {
        title: list.title,
        url: list.url,
        author: list.author,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 팀프뉴스 글 삭제
 * @param {*} page
 * @param
 * @returns
 *
 */
export const delTimfNews = async (seq) => {
  try {
    const { data } = await axios.delete(`/api/v1/news/${seq}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      data: {},
    });

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 공지사항 글목록 조회 API
 * @param {*} page
 * @param
 * @returns
 */

export const getTimfNotice = async (page) => {
  const currentPage = page - 1;
  try {
    const { data } = await axios.get(
      `/api/v1/public/csCenters?page=${currentPage}`,
      {
        params: {
          boardCategory: "NOTICE",
        },
      }
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 공지사항 글작성 API
 * @param {*} page
 * @param
 * @returns
 */

export const postCreateNotice = async (board, file) => {
  try {
    const formData = new FormData();

    for (var k in file) {
      if (k === "data") {
        const files = file[k];
        for (var j in files) {
          formData.append("data", files[j]);
        }
      } else {
        formData.append("data", file[k]);
      }
    }

    // formData.append("files", file[0]); // 첫번째 인자에 변수명, 두번째 인자에 파일 데이터, 세번째 인자에 파일명을 넣어줍니다.
    formData.append("boardCategory", "NOTICE");
    formData.append("title", board.title);
    formData.append("contents", board.contents);

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const res = await axios.post("/api/v1/csCenters", formData, config);

    return res;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 공지사항 글 수정
 * @param {*} page
 * @param
 * @returns
 *
 */
export const putTimfNoticeModify = async (seq, list, file) => {
  try {
    const formData = new FormData();

    if (file) {
      for (var k in file) {
        if (k === "data") {
          const files = file[k];
          for (var j in files) {
            formData.append("data", files[j]);
          }
        } else {
          formData.append("data", file[k]);
        }
      }
    }

    if (list && list?.file) {
      const fileSeq = list.file.map((item) => {
        return item.fileSeq;
      });
      formData.append("fileSeq", fileSeq);
    }

    // formData.append("files", file[0]); // 첫번째 인자에 변수명, 두번째 인자에 파일 데이터, 세번째 인자에 파일명을 넣어줍니다.
    formData.append("boardCategory", "NOTICE");
    formData.append("title", list.title);
    formData.append("contents", list.contents);

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const { data } = await axios.put(
      `/api/v1/csCenters/${seq}`,
      formData,
      config
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 공지사항 글 삭제
 * @param {*} page
 * @param
 * @returns
 *
 */
export const delTimfNotice = async (seq) => {
  try {
    const { data } = await axios.delete(`/api/v1/csCenters/${seq}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      data: {},
    });

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 공지사항 글 개별조회 API
 * @param {*} page
 * @param
 * @returns
 */

export const getTimfNoticeDetail = async (seq) => {
  try {
    const { data } = await axios.get(`/api/v1/public/csCenters/${seq}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 공지사항 글 조회 API
 * @param {*} page
 * @param
 * @returns
 */

export const getTimfRecruitList = async (page) => {
  const currentPage = page - 1;
  try {
    const { data } = await axios.get(
      `/api/v1/public/recruitments?page=${currentPage}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 인재채용 글 생성 API
 * @param {*} page
 * @param
 * @returns
 */

export const postCreateRecruit = async (list) => {
  try {
    const { data } = await axios.post(
      `/api/v1/recruitments`,
      {
        title: list.title,
        url: list.url,
        subtitle: list.subtitle,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 인재채용 글 개별조회
 * @param {*} page
 * @param
 * @returns
 *
 */

export const getTimfRecruitDetail = async (seq) => {
  try {
    const { data } = await axios.get(`/api/v1/public/recruitments/${seq}`);

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 인재채용 글 수정
 * @param {*} page
 * @param
 * @returns
 *
 */
export const putTimfRecruitModify = async (seq, list) => {
  console.log("list", list);
  try {
    const { data } = await axios.put(
      `/api/v1/recruitments/${seq}`,
      {
        title: list.title,
        url: list.url,
        subtitle: list.subtitle,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 인재채용 글 삭제
 * @param {*} page
 * @param
 * @returns
 *
 */
export const delTimfRecruit = async (seq) => {
  try {
    const { data } = await axios.delete(`/api/v1/recruitments/${seq}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      data: {},
    });

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 자주묻는질문 글 조회 API
 * @param {*} page
 * @param
 * @returns
 */

export const getTimfFaqList = async (page, serviceCategory) => {
  const currentPage = page - 1;
  try {
    const { data } = await axios.get(
      `/api/v1/public/csCenters?boardCategory=FAQ&page=${currentPage}&serviceCategory=${serviceCategory}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 자주묻는질문 글 개별조회
 * @param {*} page
 * @param
 * @returns
 *
 */

export const getTimfFaqDetail = async (seq) => {
  try {
    const { data } = await axios.get(`/api/v1/public/csCenters/${seq}`);

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 인재채용 글 생성 API
 * @param {*} page
 * @param
 * @returns
 */

export const postCreateFaq = async (list) => {
  try {
    const formData = new FormData();
    formData.append("boardCategory", "FAQ");
    formData.append("title", list.title);
    formData.append("contents", list.contents);
    formData.append("categoryName", list.categoryName);

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const { data } = await axios.post(`/api/v1/csCenters`, formData, config);

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 자주묻는질문 글 수정
 * @param {*} page
 * @param
 * @returns
 *
 */
export const putTimfFaqModify = async (seq, list, file) => {
  try {
    const formData = new FormData();

    if (file) {
      for (var k in file) {
        if (k === "data") {
          const files = file[k];
          for (var j in files) {
            formData.append("data", files[j]);
          }
        } else {
          formData.append("data", file[k]);
        }
      }
    }

    if (list && list?.file) {
      const fileSeq = list.file.map((item) => {
        return item.fileSeq;
      });
      formData.append("fileSeq", fileSeq);
    }

    formData.append("boardCategory", list.boardCategory);
    formData.append("title", list.title);
    formData.append("contents", list.contents);

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const { data } = await axios.put(
      `/api/v1/csCenters/${seq}`,
      formData,
      config
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 자주묻는질문 글 삭제
 * @param {*} page
 * @param
 * @returns
 *
 */
export const delTimfFaq = async (seq) => {
  try {
    const { data } = await axios.delete(`/api/v1/csCenters/${seq}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      data: {},
    });

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 자주묻는질문 글 개별조회
 * @param {*} page
 * @param
 * @returns
 *
 */

export const getFaqSearchItem = async (keyword, serviceCategory) => {
  try {
    const { data } = await axios.get(
      `/api/v1/public/csCenters/search?boardCategory=FAQ&keyword=${keyword}&serviceCategory=${serviceCategory}`
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

/**
 * 문의하기 - 새벽배송
 * @param {*} page
 * @param
 * @returns
 *
 */

export const postInquiry = async (type, input) => {
  try {
    const res = await axios.post(`/api/v1/public/inquiry`, {
      boardCategory: "INQUIRY",
      categorySeq: type,
      title: input?.title,
      companyName: input?.companyName, //업체명
      contents: input?.contents,
      name: input?.name, // 담당자/직급
      phone: `${input?.phone.first}${input?.phone.second}${input?.phone.third}`,
      email: input?.email,
      pwd: input?.password,
      agreePrivacy: input?.agreePrivacy,
      monthlyShipment: input?.monthlyShipment,
      monthlyStorage: input?.monthlyStorage,
      goods: input?.goods,
      pickupAddress: input?.pickupAddress,
      salesChannel: input?.salesChannel,
      sku: input?.sku,
      unit: input?.unit,
      loadingUnloadingAddress: input?.loadingUnloadingAddress, //상차지주소/하차지주소
      loadingUnloadingTime: input?.loadingUnloadingTime, //상차시간/하차시간
      workingDay: input?.workingDay, //근무일수
      carTonCnt: input?.carTonCnt, //차량대수/필요대수
    });

    return res;
  } catch (err) {
    console.log("err : ", err);
  }
};

// 카테고리별 글 조회
export const getCategoryList = async (category, seq, currentPage, answerYn) => {
  const page = currentPage - 1;
  try {
    const { data } = await axios.get(`/api/v1/inquiry`, {
      params: {
        boardCategory: category,
        category: seq !== "" ? seq : null,
        page: page,
        answerYn,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

// 문의 글 상세조회
export const getInquiryDetail = async (seq) => {
  try {
    const { data } = await axios.get(`/api/v1/inquiry/${seq}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

// 나의 문의하기 아이디 패스워드 확인
export const postMyInquiry = async (input, page) => {
  try {
    const { data } = await axios.post(`/api/v1/public/inquiry/myInquiry`, {
      email: input.email,
      pwd: input.password,
      page: page - 1,
      size: 5,
    });

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

// 문의하기 답변
export const postInquiryReply = async (inquirySeq, contents) => {
  try {
    const { data } = await axios.post(
      `/api/v1/reply`,
      {
        inquirySeq,
        contents,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};

// 문의하기 답변 조회
export const getInquiryReply = async (inquiryId) => {
  try {
    const { data } = await axios.get(
      `/api/v1/public/reply/${inquiryId}`

      // {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      //   },
      // }
    );

    return data;
  } catch (err) {
    console.log("err : ", err);
  }
};
