import { Element } from "react-scroll";
import styled from "styled-components";
import { CenterRelative, SubSection } from "../../shared";

const Section22 = styled(SubSection)`
  .franchise_contents:nth-child(2):hover .franchise_title::before {
    background: #000;
  }
  .franchise_contents:nth-child(2):hover .franchise_title {
    color: #000;
  }
  .franchise_contents:nth-child(2):hover .franchise_comment {
    color: #000;
    font-weight: 500;
  }

  .franchise_contents:nth-child(3):hover .franchise_title::before {
    background: #000;
  }
  .franchise_contents:nth-child(3):hover .franchise_title {
    color: #000;
  }
  .franchise_contents:nth-child(3):hover .franchise_comment {
    color: #000;
    font-weight: 500;
  }

  .franchise_contents {
    :hover {
      width: 50% !important;
      .snslink {
        opacity: 1;
      }
    }
    position: relative;
    float: left;
    width: 25%;
    height: 800px;
    background: #000;
    overflow: hidden;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;

    .franchise_title {
      position: absolute;
      top: 100px;
      left: 30px;
      font-family: "Pretendard";
      font-size: 40px;
      font-weight: 600;
      line-height: 45px;
      color: #fff;
      z-index: 1;
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
      :before {
        position: absolute;
        top: -15px;
        left: 0;
        width: 30px;
        height: 5px;
        background: #fff;
        content: "";
        transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;
      }
    }
    .franchise_comment {
      position: absolute;
      top: 220px;
      left: 30px;
      width: 300px;
      font-family: "Pretendard";
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      color: #fff;
      z-index: 1;
      opacity: 0;
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
    }

    .snslink {
      position: absolute;
      bottom: 100px;
      left: 30px;
      z-index: 1;
      opacity: 0;
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;

      li {
        transition: all 0s ease;
        -webkit-transition: all 0s ease;
        float: left;
        width: 40px;
        height: 40px;
        text-align: center;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 100%;
        overflow: hidden;
        padding: 6px 0 0 0;
        margin: 0 10px 0 0;
        box-sizing: border-box;
        cursor: pointer;
        transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;

        :nth-child(1):hover {
          background: #18539a;
        }
        :nth-child(2):hover {
          background: radial-gradient(
              circle farthest-corner at 35% 90%,
              #fec564,
              transparent 50%
            ),
            radial-gradient(
              circle farthest-corner at 0 140%,
              #fec564,
              transparent 50%
            ),
            radial-gradient(
              ellipse farthest-corner at 0 -25%,
              #5258cf,
              transparent 50%
            ),
            radial-gradient(
              ellipse farthest-corner at 20% -50%,
              #5258cf,
              transparent 50%
            ),
            radial-gradient(
              ellipse farthest-corner at 100% 0,
              #893dc2,
              transparent 50%
            ),
            radial-gradient(
              ellipse farthest-corner at 60% -20%,
              #893dc2,
              transparent 50%
            ),
            radial-gradient(
              ellipse farthest-corner at 100% 100%,
              #d9317a,
              transparent
            ),
            linear-gradient(
              #6559ca,
              #bc318f 30%,
              #e33f5f 50%,
              #f77638 70%,
              #fec66d 100%
            );
        }

        i {
          color: #fff;
          font-size: 27px;
        }
        :last-child {
          margin: 0 !important;
        }
      }
    }

    .franchise_img {
      width: 100%;
      height: 100%;
      opacity: 0.4;
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
    }

    :nth-child(1) .franchise_img {
      background: url(../skin/body/sub0203/franchiseimg01.jpg) no-repeat left
        50%;
      background-size: cover;
    }

    :nth-child(2) .franchise_img {
      background: url(../skin/body/sub0203/franchiseimg02.jpg) no-repeat left
        50%;
      background-size: cover;
    }

    :nth-child(3) .franchise_img {
      background: url(../skin/body/sub0203/franchiseimg03.jpg) no-repeat left
        50%;
      background-size: cover;
    }

    :nth-child(4) .franchise_img {
      background: url(../skin/body/sub0203/franchiseimg04.jpg) no-repeat left
        50%;
      background-size: cover;
    }

    :hover {
      // width: 50% !important;
      .franchise_img {
        opacity: 1;
      }
      .franchise_comment {
        opacity: 1;
      }
    }
  }

  .centerdiv:hover .franchise_contents {
    width: 16.66%;
  }
`;

const FranchiseList = () => {
  return (
    <Element name="sectionFList">
      <Section22 className="sub_section sub_section1">
        <CenterRelative className="centerdiv relative">
          <div className="franchise_contents">
            <div className="franchise_title">
              파스타
              <br />
              집이야
            </div>
            <div className="franchise_comment">
              고급레스토랑에서만 즐겼던
              <br />
              음식들을 집에서도 간편하게
              <br />
              즐길 수 있는 특별한 경험을 선물합니다
            </div>
            <div className="snslink">
              <ul>
                <li onClick={() => window.open("http://pastahouse.co.kr")}>
                  <i className="xi-home-o"></i>
                </li>
                <li
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/pastahouse_official/"
                    )
                  }
                >
                  <i className="xi-instagram"></i>
                </li>
              </ul>
            </div>
            <div className="franchise_img"></div>
          </div>
          <div className="franchise_contents">
            <div className="franchise_title">
              쌀국수
              <br />
              집이야
            </div>
            <div className="franchise_comment">
              식탁으로 떠나는 홈트레블!
              <br />
              집에서도 동남아 여행을 떠난 것 처럼
              <br />
              즐겨보세요
            </div>

            <div className="franchise_img"></div>
          </div>
          <div className="franchise_contents">
            <div className="franchise_title">
              덮밥
              <br />
              집이야
            </div>
            <div className="franchise_comment">
              만족감 높은 한입,
              <br />
              집에서 간단하고 든든하게
              <br />
              한끼 하고 싶을 때 찾아주세요
            </div>

            <div className="franchise_img"></div>
          </div>
          <div className="franchise_contents">
            <div className="franchise_title">
              돈까스
              <br />
              집이야
            </div>
            <div className="franchise_comment">coming soon</div>

            <div className="franchise_img"></div>
          </div>
        </CenterRelative>
      </Section22>
    </Element>
  );
};

export default FranchiseList;
