import styled from "styled-components";
import { CenterDiv, CSubContent, Input, Input100, LinkStyle } from "../shared";

import { useCallback, useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getTimfNoticeDetail, putTimfNoticeModify } from "../../services";
import routes from "../../routes";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import * as Icon from "react-feather";

const NSubContent = styled(CSubContent)`
  background: rgba(0, 0, 0, 0.03);

  .sub_contents_title .snslink {
    display: table;
    margin: 50px auto 0;
  }
  .sub_contents_title .snslink li {
    display: inline-block;
    float: none;
    width: 60px;
    height: 60px;
    padding: 10px 0 0 0;
    margin: 0 20px 0 0;
    background: #02204a;
  }
  .sub_contents_title .snslink li i {
    font-size: 37px;
  }
  .sub_contents_title .snslink li img {
    height: 37px;
  }

  .title1,
  .title2 {
    text-align: left;
  }

  .title2 {
    margin-bottom: 100px;
  }

  .write_check_td {
    width: 13px;
    padding: 0;
    border-bottom: 1px solid #e5e5e5;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  padding: 0;
`;

const TableTop = styled.table`
  border-top: 1px solid #979797;
  margin-bottom: 4px;
  text-align: left;

  .b_detail_left {
    color: #000;
    width: 105px;
    height: 35px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 15px;
    padding: 15px 10px;
  }

  .b_detail_right {
    color: #000;
    border-bottom: 1px solid #cccccc;
    border-left: 1px dotted #cccccc;
    font-size: 15px;
    padding: 15px 10px;
  }

  .write_left_td {
    width: 120px;
    height: 40px;
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 17px;
    font-weight: 300;
    box-sizing: border-box;
    text-align: left;
  }

  .write_right_td {
    letter-spacing: -1;
    border-bottom: 1px solid #e5e5e5;
    font-size: 17px;
    font-weight: 300;
    padding: 15px 5px;
    box-sizing: border-box;
  }
`;

const TableBottom = styled.table`
  padding: 20px 0;
  // border-top: 1px solid #cccccc;
  // border-bottom: 1px solid #cccccc;
  margin-bottom: 5px;
  table-layout: fixed;

  td {
    color: #151515;
    line-height: 150%;
  }

  .write_left_td {
    width: 120px;
    height: 40px;
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 17px;
    font-weight: 300;
    box-sizing: border-box;
    text-align: left;
  }

  .write_right_td {
    letter-spacing: -1;
    border-bottom: 1px solid #e5e5e5;
    font-size: 17px;
    font-weight: 300;
    padding: 15px 5px;
    box-sizing: border-box;
  }

  .ck.ck-content:not(.ck-comment__input *) {
    height: 300px;
    overflow-y: auto;
  }
  .ck.ck-editor__editable_inline {
    border: 1px solid var(--ck-color-base-border);
  }
`;

// 파일첨부
const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Upload = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const UploadHeader = styled.div`
  width: 100px;
`;

const UploadContents = styled.div`
  border-radius: 5px;
  border: 1px solid rgb(206, 212, 218);
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84%;
`;

const UploadFileList = styled.div`
  width: 73%;
  color: blue;
  font-weight: 400;
`;

const API_URL = "hpdev.teamfresh.co.kr";
const UPLOAD_ENDPOINT = "api/v1/upload";

const NoticeModify = ({ seq }) => {
  const { modiSeq } = useParams();
  const navigate = useNavigate();

  const modiEditorRef = useRef();
  const { CKEditor, DecoupledEditor } = modiEditorRef.current || {};

  const [currentDate, setCurrentDate] = useState("");

  const [editorLoaded, setEditorLoaded] = useState(false);
  const [boardContent, setBoardContent] = useState({
    title: "",
    contents: "",
  });
  const [file, setFile] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const resultFile = acceptedFiles.map((file) =>
      Object.assign(file, {
        url: URL.createObjectURL(file),
      })
    );
    setFile((item) => [...item, ...resultFile]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleNewFileDelete = (e) => {
    const { temp } = e.target.dataset;

    const newFiles = file.filter((item) => {
      return item.name !== temp;
    });

    setFile(newFiles);
  };

  const handleCancle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { temp } = e.target.dataset;
    const newFiles = boardContent.file.filter((item) => {
      return item.fileName !== temp;
    });

    setBoardContent({ ...boardContent, file: newFiles });
  };

  const handleSaveNotice = async () => {
    let res = undefined;
    if (seq) {
      res = await putTimfNoticeModify(seq, boardContent, file);
    } else {
      res = await putTimfNoticeModify(modiSeq, boardContent, file);
    }

    if (res) {
      alert("수정되었습니다.");

      seq ? navigate(routes.admin) : navigate(routes.notice);
    }
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then((file) => {
            data.append("file", file);

            axios
              .post(`/${UPLOAD_ENDPOINT}`, data, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
              })
              .then((res) => {
                // res.json()
                resolve({
                  default: `${res.data}`,
                  // default: `${API_URL}/images/apple.png`,
                });
              })

              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function imagePlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  useEffect(() => {
    modiEditorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      DecoupledEditor: require("@ckeditor/ckeditor5-build-decoupled-document"),
    };
  }, []);

  const getNotiList = useCallback(async () => {
    let res = undefined;
    let file = [];
    if (seq) {
      res = await getTimfNoticeDetail(seq);
    } else {
      res = await getTimfNoticeDetail(modiSeq);
    }
    if (res && res[0]?.files) {
      file = res[0]?.files.map((item) => {
        return item;
      });
      setBoardContent({
        title: res[0]?.cscenter?.title,
        contents: res[0]?.cscenter?.contents,
        file,
      });
    }
  }, [modiSeq, seq]);

  const onChangeEditor = (e) => {
    setBoardContent({
      ...boardContent,
      contents: e.editor.getData(),
    });
  };

  useEffect(() => {
    getNotiList();
  }, [getNotiList]);

  useEffect(() => {
    setEditorLoaded(true);

    const today = new Date();
    // 년도
    const year = today.getFullYear();
    // 월
    const month = today.getMonth() + 1;
    // 일
    const date = today.getDate();
    setCurrentDate(`${year}-${month}-${date}`);
  }, []);

  return (
    <NSubContent className="sub_contents_title" id="subsectionlink1">
      <CenterDiv className="centerdiv">
        <div className="title1">TimF Notice</div>
        <div className="title2">공지사항</div>
      </CenterDiv>
      <CenterDiv className="centerdiv">
        <TableContainer>
          <TableTop border="0" cellPadding="0" cellSpacing="0" width="100%">
            <tbody>
              <tr>
                <td>
                  <table
                    border="0"
                    cellPadding="0"
                    cellSpacing="0"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td className="b_detail_left">제목</td>
                        <td className="write_check_td">
                          <img
                            src="../../skin_board/a_standard/b_img/icons/write_check1.gif"
                            alt=""
                          />
                        </td>
                        <td className="write_right_td">
                          <Input100
                            type="text"
                            size="45"
                            maxLength="200"
                            value={boardContent.title}
                            className="write_input_text"
                            onChange={(e) =>
                              setBoardContent({
                                ...boardContent,
                                title: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className="b_detail_left">작성일</td>
                        <td className="write_check_td">
                          <img
                            src="../../skin_board/a_standard/b_img/icons/write_check2.gif"
                            alt=""
                          />
                        </td>
                        <td className="write_right_td">
                          <Input
                            type="text"
                            name="subject"
                            size="45"
                            maxLength="200"
                            value={currentDate}
                            className="write_input_text"
                            readOnly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </TableTop>

          <TableBottom border="0" cellPadding="0" cellSpacing="0" width="100%">
            <tbody>
              <tr>
                <td className="write_left_td">내용</td>
                <td className="write_right_td" align="center">
                  <div
                    style={{
                      width: "100%",
                      height: "350px",
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                    align="center"
                    className="toolbar-container"
                  >
                    {editorLoaded ? (
                      <CKEditor
                        id="editEditor"
                        config={{
                          extraPlugins: [imagePlugin],
                        }}
                        editor={DecoupledEditor}
                        data={boardContent.contents}
                        onReady={(editor) => {
                          console.log("Editor is ready to use!", editor);

                          //   const toolbarContainer =
                          //     document.querySelector("#editEditor");
                          //   toolbarContainer.appendChild(
                          //     editor.ui.view.toolbar.element
                          //   );

                          //   window.editor = editor;
                          editor.ui
                            .getEditableElement()
                            .parentElement.append(
                              editor.ui.view.toolbar.element
                            );
                        }}
                        // onChange={onChangeEditor}
                        events={{
                          change: onChangeEditor,
                        }}
                      />
                    ) : (
                      <div>Editor loading</div>
                    )}
                  </div>
                  <hr style={{ border: "1px solid #cccccc" }} />
                  <UploadContainer>
                    <Upload>
                      <UploadHeader>첨부파일</UploadHeader>

                      <UploadContents {...getRootProps()} className="col-md-5">
                        <input {...getInputProps()} />
                        <div>첨부할 파일을 드래그하여 옮겨주세요.</div>
                      </UploadContents>
                    </Upload>

                    <UploadFileList>
                      {boardContent?.file?.length > 0 &&
                        boardContent.file.map((file) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              key={file.fileSeq}
                            >
                              {file.fileName}
                              <Icon.X
                                className="m-l-5"
                                data-temp={file.fileName}
                                onClick={handleCancle}
                              />
                            </div>
                          );
                        })}
                    </UploadFileList>
                    {/* 수정 할때 새로 업로드할 파일들 */}
                    <UploadFileList>
                      {file.map((file) => (
                        <div key={file.name}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {file.name}
                            <Icon.X
                              className="m-l-5"
                              data-temp={file.name}
                              onClick={handleNewFileDelete}
                            />
                          </div>
                        </div>
                      ))}
                    </UploadFileList>
                  </UploadContainer>
                </td>
              </tr>
            </tbody>
          </TableBottom>

          <table border="0" cellPadding="0" cellSpacing="0" width="100%">
            <tbody>
              <tr>
                <td colSpan="3" align="center" style={{ paddingTop: "10px" }}>
                  <Link to="#" onClick={handleSaveNotice}>
                    <img
                      src="../../../skin_board/a_standard/b_img/i_button/write_save.gif"
                      border="0"
                      align="absmiddle"
                      alt=""
                    />
                  </Link>
                  &nbsp; &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
        </TableContainer>
      </CenterDiv>
    </NSubContent>
  );
};

export default NoticeModify;
