import styled from "styled-components";
import {
  CenterDiv,
  SubSection,
  TextTitle1,
  TextTitle2,
  TextComment,
  TextComment2,
  SectionImg,
} from "../shared";

const Section9 = styled(SubSection)`
  background: rgb(0 0 0 / 3%);
  padding: 0;

  .section_text {
    position: relative;
    float: left;
    width: 45%;
    padding: 100px 20px;
    box-sizing: border-box;
  }

  .section_text2 {
    /* position: relative;
    float: left;
    width: 45%; */
    padding: 150px 0px;
    /* box-sizing: border-box; */
  }

  .section_img {
    text-align: right;
    position: relative;
    float: left;
    width: 55%;
    padding: 100px 20px;
    box-sizing: border-box;
    img {
      max-width: 100%;
    }
  }
`;

const TransferCenter = () => {
  return (
    <Section9 className="sub_section sub_section2">
      <CenterDiv className="centerdiv">
        <div className="section_text">
          <TextTitle1 className="section_text_title1">
            배송 단계를 줄여주는
          </TextTitle1>
          <TextTitle2 className="section_text_title2">
            콜드체인 통과형 센터
          </TextTitle2>
          <TextComment className="section_text_title_comment">
            <span>TC</span>(Cold Chain <span>T</span>ransfer <span>C</span>
            enter)
            <li>수도권 중심으로 7개 센터 운영 중</li>
            <li>일 최대 10만건 처리 가능</li>
            <li>차량 최대 1000여 대 동시 접안 및 출차 가능</li>
          </TextComment>
          <TextComment2 className="section_text_title_comment2">
            새벽배송 전용 센터 운영을 통해 배송 단계를 줄였습니다.
            <br />
            고객사의 파손 우려가 줄어듭니다.
          </TextComment2>
        </div>
        <SectionImg className="section_img">
          <img src="skin/body/sub020101/sectionimg2_1.png" alt="" />
        </SectionImg>
      </CenterDiv>
    </Section9>
  );
};

export default TransferCenter;
