import styled from "styled-components";

import { SubSection, CenterDiv, SubTitle } from "../../shared";

const InsuaranceProcess = () => {
  const Section28 = styled(SubSection)`
    background: url(skin/body/sub020101/process_bg.jpg) fixed no-repeat center;
    background-size: cover;
    padding: 0;

    .sub_section1_bg {
      position: relative;
      width: 100%;
      height: 100%;
      background: rgb(255 255 255 / 90%);
      padding: 150px 0;
    }

    .section_process1 {
      clear: both;
      display: table;
      width: 100%;
      margin: 50px 0 0 0;
    }
  `;
  return (
    <Section28>
      <div className="sub_section1_bg">
        <CenterDiv className="centerdiv">
          <SubTitle className="sub_title">
            <div className="sub_title_eng">Process</div>
            프로세스
            <div className="sub_title_comment">
              팀프인슈어런스는 고객 가치를 최우선으로 여기며,
              <br />각 보험 상품별 업계 최고의 담당자들이 국내 최고 수준의
              기업보험 컨설팅 서비스를 제공합니다.
            </div>
          </SubTitle>
          <div className="section_process1">
            <img src="../skin/body/sub0204/insu_process.png" alt="" />
          </div>
        </CenterDiv>
      </div>
    </Section28>
  );
};

export default InsuaranceProcess;
