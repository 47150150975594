const ModalML = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              이종미
              <br />
              <span>
                유통본부,
                <br />
                물류팀(ML)
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img12.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              ML은 급식, 외식, 제조 업체 대상으로 식자재 및 관련 용품의
              구매-보관-피킹-배송까지의 밀크런(Milk Run) 서비스를 진행하고
              있어요.
              <br />
              저는 해당 업무 전반의 관리 및 처리 중 오류 사항 파악, 근무환경
              개선 등의 업무를 맡고 있습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              팀프레시 입사 전에는 어찌 보면 계속 물류라는 곳은 ‘그저 딱딱한
              곳이야’라는 경험과 생각이 굳어져 있었어요.
              <br />
              새로운 팀 구조와 업무 방식 속에서 일하면서 그런데 '아, 내가 그동안
              뭘 한 거지' 하는 생각이 들더라고요.
              <br />
              새로운 업무방식, 빠른 트렌드 반영으로 항상 새로운 것을 접하며 느낄
              수 있는 것 그 자체가 매번 새로운 성장인 것 같아요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’여서 가능했던 일이 있나요?
            </div>
            <div className="interview_comment_A">
              자유로운 아이디어들과 편견 없는 의견 도출이 가능하다 점 같아요.
              <br />
              모든 동료들이 수평적으로 '그렇게도 할 수 있구나, 그럴 수도 있구나'
              라고 서로의 아이디어와 의견을 편견 없이 말하고, 업무에 적용하기
              때문에 어려운 문제들도 새로운 시각에서 빨리 해결할 수 있었습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’에서 이루고 싶은 개인적인 목표가 있나요?
            </div>
            <div className="interview_comment_A">
              배울 수 있는 리더가 되었으면 좋겠어요.
              <br />
              리더라는 자리에 있게 된 만큼 누군가 저에 대해서 평가한다면 ‘배울
              수 있는 사람이야’라고 얘기할 수 있는 리더가 되고 싶습니다.
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              나이에 구애받지 않는 팀, 20대부터 60대까지 모든 연령층이 함께 근무
              중으로 팀 내에서 나이 질문 금지!
              <br />
              "나이는 숫자 일뿐 함께하는 마인드로 즐겁게 근무 중"
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              정확한 처리를 하는 사람이요!
              <br />
              물류 업무의 특성상 팀 전체의 업무가 하나로 연결되어 있어서, 어느
              한 곳 업무에서라도 정확도가 떨어지게 되면 다른 업무도 도미노처럼
              문제가 연속 효과가 나타나게 됩니다.
              <br />
              천천히 하더라도 정확한 처리를 해주시는 분이면 좋겠어요.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalML;
