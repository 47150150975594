import styled from "styled-components";
import routes from "../../routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CenterDiv } from "../shared";
import { useState, useEffect } from "react";

import BottomDrawer from "./BottomDrawer";

const SHeader = styled.header`
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  position: fixed;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  text-align: center;
  z-index: 150;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;

  #mobileHeader {
    display: none;
  }

  @media only screen and (max-width: 480px) {
    #headerWeb {
      display: none;
    }
    display: flex;
    width: 100%;
    #mobileHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 40px;
      width: 100%;
      i {
        color: white;
        font-size: 70px;
      }
      .logo {
        img {
          width: 300px;
        }
      }
    }
  }
`;

const Logo = styled.div`
  float: left;
  margin: 20px 0 20px 0;
`;

const LinkHome = styled(Link)`
  &:visited {
    color: rgb(102 102 102);
    text-decoration: none;
    font-size: 9pt;
  }
  img {
    width: 110px;
  }
`;

const NavContent = styled.div`
  float: right;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  margin: 16px 0 0;
  ul {
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;

    li.nav-list {
      ::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background: #fff;
        content: " ";
        transition: all 0.1s ease;
        -webkit-transition: all 0.1s ease;
        &:hover {
          ::before {
            width: 100%;
          }
        }
      }
    }
    li {
      position: relative;
      display: inline-block;
      font-size: 18px;
      margin-left: 40px;
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;

      &:hover {
        ::before {
          width: 100%;
        }
        .nsubmenu {
          display: block;
          ul {
            background: rgb(2 21 48 / 90%);
            li {
              &:hover {
                background: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }

      a {
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        color: rgb(255, 255, 255);
        text-decoration: none;
        padding: 5px 0;
      }
      div {
        position: absolute;
        width: 140px;
        top: 17px;
        left: 50%;
        margin-left: -70px;
        display: none;
        ul {
          display: table;
          width: 140px;
          margin: 32.5px 0 0;
          padding: 0;
          background: rgb(0 0 0 / 50%);
          box-shadow: 0 2px 2px rgb(0 0 0 / 30%);
          transition: all 0.2s ease;
          -webkit-transition: all 0.2s ease;
          li {
            clear: both;
            width: 100%;
            text-align: center;
            padding: 5px 0;
            margin: 0;
            cursor: pointer;
            font-size: 14px;
            font-weight: 300;
            line-height: 40px;
            color: rgb(255 255 255);
            text-decoration: none;
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;

const Header = () => {
  const url = useLocation();
  let navigate = useNavigate();

  function changeLocation(placeToGo) {
    navigate(placeToGo, { replace: true });
    window.location.reload();
  }

  const [scrollPosition, setScrollPosition] = useState(0);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
  });

  return (
    <SHeader
      style={{
        borderBottom:
          scrollPosition > 0 ? "1px solid rgb(255 255 255 / 10%)" : "none",
        backgroundColor:
          scrollPosition > 0 || state.top === true
            ? "rgb(2 21 48 / 90%)"
            : url.pathname === "/" && scrollPosition === 0
            ? "transparent"
            : "rgba(0,0,0,.5)",
      }}
      id="header"
    >
      <CenterDiv className="centerdiv" id="headerWeb">
        <Logo className="logo">
          <LinkHome to={routes.home}>
            <img
              src={process.env.PUBLIC_URL + "/skin/images/logo_w.png"}
              alt="Teamfresh logo"
            />
          </LinkHome>
        </Logo>
        <NavContent className="nav-content">
          <ul>
            <li className="nav-list">
              <Link to={routes.business}>회사소개</Link>
              <div className="nsubmenu" id="submenu01"></div>
            </li>
            <li className="nav-list">
              <Link to={routes.service}>서비스소개</Link>
              <div
                className="nsubmenu"
                id="submenu02"
                // style={{ display: "none" }}
              >
                <ul
                  style={{
                    backgroundColor:
                      scrollPosition > 0
                        ? "rgb(2 21 48 / 90%)"
                        : "rgba(0,0,0,.5)",
                  }}
                >
                  <li>
                    <Link to={routes.service}>물류</Link>
                  </li>
                  <li>
                    <Link to={routes.distribution}>유통</Link>
                  </li>
                  <li>
                    <Link to={routes.franchise}>프랜차이즈</Link>
                  </li>
                  <li>
                    <Link to={routes.insurance}>보험</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-list">
              <Link to={routes.recruit}>인재채용</Link>
              <div className="nsubmenu" id="submenu03"></div>
            </li>
            <li className="nav-list">
              <Link to={routes.notice}>고객지원</Link>
              <div className="nsubmenu">
                <ul
                  style={{
                    backgroundColor:
                      scrollPosition > 0
                        ? "rgb(2 21 48 / 90%)"
                        : "rgba(0,0,0,.5)",
                  }}
                >
                  <li>
                    <Link to={routes.notice}>공지사항</Link>
                  </li>
                  <li>
                    <Link to={routes.timfnews}>팀프뉴스</Link>
                  </li>
                  <li>
                    <Link
                      to={routes.inquiry}
                      onClick={() => changeLocation(`${routes.inquiry}`)}
                    >
                      문의하기
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.faq}>자주 묻는 질문</Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </NavContent>
      </CenterDiv>

      <div id="mobileHeader">
        <div className="customer">
          <a href="tel:02-423-0525">
            <i className="xi-call"></i>
          </a>
        </div>
        <Logo className="logo">
          <LinkHome to={routes.home}>
            <img
              src={process.env.PUBLIC_URL + "/skin/images/logo_w.png"}
              alt="Teamfresh logo"
            />
          </LinkHome>
        </Logo>
        <div className="navi_btn">
          <div
            onClick={toggleDrawer("top", true)}
            style={{ display: state.top ? "none" : "block" }}
          >
            <i className="xi-bars"></i>
          </div>
          <div
            style={{ display: state.top ? "block" : "none" }}
            onClick={() =>
              setState({
                top: false,
              })
            }
          >
            <i
              className="xi-close"
              onClick={() =>
                setState({
                  top: false,
                })
              }
            ></i>
          </div>
        </div>
      </div>
      <BottomDrawer toggleDrawer={toggleDrawer} state={state} />
    </SHeader>
  );
};

export default Header;
