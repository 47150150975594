const ModalOA = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              양영진
              <br />
              <span>
                물류본부,
                <br />
                운영대행팀(OA)
                <br />
                팀장
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img08.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              저희 운영대행(OA)팀은 기존 팀프레시의 풀필먼트 팀에서 파생된
              팀이에요.
              <br />
              풀필먼트(FU)팀이 대규모의 화주사를 기준으로 팀프레시만의 정책으로
              화주사의 니즈를 충족시키는 팀이라면 운영대행(OA)팀은 각각의
              화주사만을 위한 풀필먼트 서비스를 제공하기 위해 만들어진 팀이라고
              생각해주시면 될 것 같아요. (웃음)
              <br />
              이런 OA팀에서 제가 맡은 업무는 '팀원들 각각의 업무 능력 성장을
              돕는 것'과 '이를 바탕으로 팀 자체의 업무 능력 성장을 이룬다.' 두
              가지라고 생각해요.
              <br />
              이 두가지 업무 중 첫번째 업무인 '팀원들 각각의 업무 능력 성장'이
              없으면 팀 자체의 업무 능력 성장도 없기 때문에 팀원들의 업무 능력
              성장 방법을 항상 고민하고 있어요.
              <br />
              예를 들면 타 센터 파견 및 견학, 회사와 팀의 방향성 설명, 팀 내부의
              이슈와 현재 다른 센터들의 현황 공유 등의 방식들이 있는데, 아직
              팀이 만들어진지 얼마 되지 않아 실현하지는 못하고 있어요.
              <br />
              또 팀원들이 업무를 진행할 때 업무에 대한 실행 방식을 계획하고
              수행하는 부분에 대해 항상 팀원이 목적과 방향성을 생각하고 움직일
              수 있도록 의견을 묻고 협의 후 업무를 요청하고 있어요.
              <br />
              이러한 노력들이 팀원들의 업무 능력 성장에 기여 된다면 팀 자체의
              업무 능력 성장은 자연스럽게 따라오기 때문에 팀원들 개개인의 업무
              성장을 항상 고민하고 있는 편이에요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              팀프레시는 현재에 안주하지 않는 회사라는점이 제 커리어 성장에 가장
              큰 도움을 주고있다고 생각합니다.
              <br />
              일반적인 회사는 새로운 사업, 새로운 형태의 업무에 대해 투자비가
              매우 많이 들고 투여되는 리소스에 대비해 아웃풋이 명확하지 않아
              의사결정에 있어 많이 보수적일 수 밖에 없는데 팀프레시는 새로운
              사업과 업무들에 시도하고 실패한다해도 다시 도전할 줄 아는
              회사예요.
              <br />
              이러한 실패와 반복들이 결국 당시 구성원들에게는 매우 큰 부담과
              고통으로 다가올수 밖에 없어요.
              <br />
              하지만 실패하고 회고를 반복하는 과정에서 결국 새로운 업무들은
              기존에 했던 업무가 되고 경험으로 치환되면서 점점 성장할 수 밖에
              없다고 생각해요.
              <br />
              고통을 겪고 또 겪으면서 1년 전에는 내가 생각하지도 못했던, 그리고
              할 수도 없었던 일들이 어느 순간 조금만 하더라도 할 수 있는
              업무들이라고 느껴졌을 때가 바로 제가 성장했다고 느끼는 순간이었던
              것 같아요.
              <br />
              약간 비슷한게 운동이라고 생각이 드는데 스스로 운동을 하고 근육을
              키우는 과정이 너무 힘들면 PT를 받아 원하는 몸을 만드는 것처럼,
              팀프레시도 제게는 커리어 성장의 목표를 이루게 도와주는
              헬스트레이너 같은 존재라고 생각해요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’여서 가능했던 일이 있을까요?
            </div>
            <div className="interview_comment_A">
              입사 초기에 손가락 발가락으로도 셀 수 없을 정도로 사고를 치고
              회사에 손실도 많이 선물했지만, 그럼에도 불구하고 끝까지 팀장이
              되도록 도와주고 기다려줬던것은 팀프레시여서 가능하다고 생각해요.
              <br />
              (물론 그 당시 팀장님 속이 상당히 뒤집혔을거라서 아직도 죄송한
              마음이 있습니다..)
              <br />
              동일 경력 대비 큰 규모의 업무들을 할 수 있었던 것도 '팀프레시'여서
              가능했다고 생각해요.
              <br />
              예를 들면 신규 센터 셋업, 사내 택배사 변경을 예로 들 수 있는데,
              다른 회사였다면 당시의 경력으로는 사내 택배사 변경과 신규 센터
              셋업등과 같은 실패 리스크가 매우 큰 업무는 진행하지 못했을 거라고
              생각해요.
              <br />
              경력에 대비해 커다란 규모의 업무들을 수행할 수 있게 기회가 있었던
              점들은 2번과 연결되어 제 전체적인 커리어 성장에 커다란 도움이
              되었다고 생각해요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’에서 이루고 싶은 개인적인 목표가 있나요?
            </div>
            <div className="interview_comment_A">
              모든 산업군의 물류센터 운영과 셋업을 진행해 보는 것이 목표예요.
              <br />
              물류센터는 우리나라 안에서의 모든 산업군에 존재할 수 밖에 없어요.
              <br />
              현재 팀프레시의 가장 큰 강점은 신선식품을 전문적으로 취급하고
              배송하는 원스톱콜드체인 플랫폼이라는 것에 있지만 저의 개인적인
              목표로는 이것에 만족하지 않고 의류, 식자재, 가구, 가전등에 있는
              모든 형태의 물류센터의 운영과 셋업을 진행해 보고 싶어요.
              <br />
              그리고 나중에는 물류라는 시장에 어디에도 녹아들 수 있는 만능
              키맨이 되도록 성장하는게 제 궁극적인 목표예요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’는 주니어들이 일하기 좋은 기업인가요?
            </div>
            <div className="interview_comment_A">
              주니어의 기준이 현재 업무들을 처음부터 배워야 한다라는 기준이고,
              성장을 원하고 그만큼 노력과 시간을 할애할 수 있다면 적극
              추천이에요.
              <br />
              이전 답변들과 같이 결국 지속적으로 무엇인가 새롭고 해보지 않은
              것들을 반복하게 되면 그만큼의 고통이 동반되지만 성장이 담보되기
              때문에 커리어와 경력, 그리고 업무 능력에 욕심이 있는 분이라면
              일하기 좋은 기업이라고 생각해요.
              <br />
              다만, 워라밸이 조금 더 중시되는 분들에게는 아닐 수 있어요.
              <br />
              워라밸이라는게 본인의 업무능력에 따라 밸런스를 가질 수 있는 것에
              대한 여부가 결정되기 때문에 처음부터 배우는 상태에서 워라밸을
              찾기는 사실 쉽지 않아 추천드리고 싶지는 않아요.
              <br />
              다만, 업무들이 익숙해지고 숙련도가 올라갈수록 밸런스를 지킬 수
              있는 배경은 마련이 된다고 생각해요.
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              저희 팀의 분위기는 자유로운 편입니다.
              <br />
              아무래도 센터와 본사 모두 무엇인가를 도출하고 개선하기 위해서는
              생각할 수 있는 여유가 필요하다고 결론을 내렸기 때문에 개인의
              자율성은 최대한 보장하고 있어요.
              <br />
              다만, 이러한 자율성에는 책임이 반드시 수반됩니다.
              <br />
              본인의 업무는 철저하게 마무리 해야한다라는 팀내부의 보이지 않는
              기준이 깔려있기 때문에 자율성을 보장할 수 있다고 생각하고 있어요.
              <br />
              이러한 보이지 않는 책임감은 결국 이슈가 발생되거나 문제가 생겼을
              때 스스로 고민하고 행동할줄 아는 문화를 만들었고 센터내에서도
              이슈가 발생되거나 문제가 생겼을때 최대한 할 수 있는 범위내에서
              스스로 해결해보는 문화가 고착되었어요.
              <br />이 문화들은 제가 가장 우선시하는 업무인 개인의 성장을 이루는
              것에 매우 큰 도움이 된다고 생각하고 있기 때문에 최대한 자유로운
              분위기를 만들고 또 지키고 있어요.
            </div>
            <div className="interview_comment_Q">본인에게 ‘팀프레시’란?</div>
            <div className="interview_comment_A">
              약간 애증의 관계인것 같아요.
              <br />
              가끔 심각하게 주어지는 고통이 있으면 너무 밉다가도 어느 순간
              뒤돌아서 나를 성장하게 도와주었구나 라는 생각이 들게 하더라구요.
              <br />
              항상 커다란 일들이 밀려오고 나가는 것이 반복되다보니, 어느순간
              내가 업무로 인해 스트레스를 받고 앞으로 벌어질 일들에 대해서 많이
              담대해질 수 있었어요.
              <br />
              입사 초기만 해도 조금만 스트레스가 다가오면 슬라임처럼 멘탈이
              흘러내려서 아무것도 못했었는데, 어느순간 내성이 생기면서
              능동적으로 업무에 대응하고 문제들을 해결해 나갈 수 있게 되면서
              "나를 죽이지 못하는 고통은 나를 더욱 더 강하게 만든다."라는 말이
              떠올랐어요.
              <br />
              견딜 수 없는 스트레스는 문제가 있지만 항상 견딜 수 있을 만큼,
              그리고 극복할 수 있을 만큼의 고통은 오히려 저를 더욱 더 성장하게
              만드는 것을 깨닫고 나서는 많은 부분들이 스트레스가 아니었다는 걸
              알게 되었어요.
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              스스로에 대한 객관화를 잘하고 자신의 주장을 논리적으로 펼치는
              사람과 일하고 싶어요.
              <br />
              그 중에서 가장 중요한건 결국 업무를 진행하면 팀원들 혹은
              화주사들과의 소통이 이루어질 수 밖에 없는데, 그 중에서 가장 중요한
              건 객관화와 논리적인 주장이기 때문이에요.
              <br />
              실제로도 단순히 불만과 불평을 쏟아내는 것과 해결을 위한 논리가
              있는 것은 크게 차이가 있어요.
              <br />
              관계자들에게 일방적으로 부정적인 감정을 전달하는 것들을 예방해주기
              때문입니다.
              <br />
              두번째로는 우직한 성격인 분이었으면 좋겠어요.
              <br />
              업무를 진행하다보면 뜻대로 풀리지 않는 일들이 너무 많아요.
              <br />
              이러한 일들에 스트레스를 받지 않는 것도 중요하지만, 스트레스를
              받더라도 얼마나 끈기있게 본인이 원하는 바를 이끌어내느냐가
              관건이기 때문에 우직한 성격을 가진 분들과 함께 일하고
              싶어요.(웃음)
            </div>
            <div className="interview_comment_Q">
              마지막으로, 한 말씀 부탁드립니다!
            </div>
            <div className="interview_comment_A">
              초반에는 일을 하는 가장 큰 목적 중의 하나가 급여라고 생각했어요.
              <br />
              그런데 요즘에는 다른 업체에서 지금의 연봉보다 더 높은 연봉으로
              오퍼를 받고 일을 하자고 들어오는 제의를 거절하면서 속으로는 '내가
              왜 그 제안들을 거절했지' 라는 생각을 했었는데, 결론적으로 가장
              중요한 것은 '성장'이라는 것을 깨달았기 때문이었던것 같아요.
              <br />
              한 개인이 회사에 들어가서 일 하는 것이 회사가 개인에게 급여라는
              비용을 제공하고 개인의 업무능력을 구매하는 행위라고 생각 한다면,
              지금 당장의 급여보다 더 앞선 '성장'이라는 가치를 추구하다보면
              급여는 저절로 따라온다라는 결론을 저도 모르게 도출했던 거죠.
              <br />
              지금 저는 이 인터뷰를 하면서도 '1년전의 나'와 '현재의 나'를
              비교하면서 생각했는데, 성장했다라는 것을 느낄 때마다 맛보는
              달콤함을 지울수 없어요.
              <br />이 인터뷰를 보시는 모든 분들도 사회 어느곳에서든 배우고
              경험하며 본인 스스로의 가치를 깨닫는 날이 오길 항상 응원하고
              싶어요.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalOA;
