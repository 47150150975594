import styled from "styled-components";
import { CenterRelative, SubSection, SubTitle } from "../shared";

const Section3 = styled(SubSection)`
  padding: 0;
  background: url(./skin/body/sub0101/history_img.jpg) no-repeat fixed center;
  background-size: cover;
  .sub_section4_back {
    display: table;
    width: 100%;
    padding: 150px 0;
  }

  .history_contents {
    position: relative;
    clear: both;
    width: 50%;
    box-sizing: border-box;
    padding: 20px 0;

    .history_year {
      position: relative;
      clear: both;
      width: 100%;
      font-size: 40px;
      font-weight: 800;
      line-height: 85px;
      color: ${({ theme }) => theme.colors.white};
    }

    .history_body {
      clear: both;
      float: left;
      width: 100%;
      font-size: 20px;
      font-weight: 200;
      line-height: 28px;
      color: ${({ theme }) => theme.colors.white};
      padding: 3px 0;
      .history_month {
        clear: both;
        float: left;
        width: 8%;
        text-align: left;
        font-weight: 600;
        line-height: 1.8;
      }

      .history_list {
        float: left;
        width: 92%;
        text-align: left;
        li {
          position: relative;
          clear: both;
          float: left;
          box-sizing: border-box;
          list-style: none;
          width: 100%;
          line-height: 1.8;
          span {
            font-weight: 500;
            color: rgb(255 0 0);
          }
          img {
            height: 20px;
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }

  .history_contents:after {
    position: absolute;
    top: 55px;
    width: 1px;
    height: 100%;
    background: rgb(255 255 255 / 10%);
    content: "";
    right: -1px;
  }

  .history_contents_l {
    float: left;
    text-align: right;
    padding-right: 20px;
    .history_year:after {
      right: -31px;
      position: absolute;
      top: 35px;
      width: 20px;
      height: 20px;
      background: #02204b;
      content: "";
      border-radius: 100%;
      border: 3px solid #fff;
      box-sizing: border-box;
      z-index: 2;
    }
    .history_body .history_month {
      float: right;
      text-align: right;
      line-height: 1.8;
    }
    .history_body .history_list li {
      float: right;
      padding-right: 20px;
    }
  }

  .history_contents_r {
    :after {
      left: 0;
    }
    float: right;
    text-align: left;
    padding-left: 20px;
    .history_year:after {
      left: -30px;
      position: absolute;
      top: 35px;
      width: 20px;
      height: 20px;
      background: #02204b;
      content: "";
      border-radius: 100%;
      border: 3px solid #fff;
      box-sizing: border-box;
      z-index: 2;
    }
    .history_body .history_list li {
      padding-left: 20px;
      list-style: none;
    }
  }
`;

const TimfHistory = () => {
  return (
    <Section3>
      <div className="sub_section4_back">
        <CenterRelative className="centerdiv relative">
          <SubTitle className="sub_title" style={{ color: "#FFF" }}>
            팀프레시는
            <br />
            성장을 멈추지 않습니다
          </SubTitle>
          {/* 2018Start */}
          <div className="history_contents history_contents_r">
            <div className="history_year">2018</div>
            <div className="history_body">
              <div className="history_month">05월</div>
              <div className="history_list">
                <ul>
                  <li>법인설립</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">07월</div>
              <div className="history_list">
                <ul>
                  <li>영업시작-첫 달 매출 3.5억원</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">08월</div>
              <div className="history_list">
                <ul>
                  <li>
                    기술혁신형 창업기업 지원사업 선정 (국가지원금 8,000만원)
                  </li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">09월</div>
              <div className="history_list">
                <ul>
                  <li>벤처기업 선정</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">12월</div>
              <div className="history_list">
                <ul>
                  <li>브랜드기준 30여개 고객사 운영</li>
                  <li>
                    <span>연 매출 27억</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* 2018End */}
          {/* 2019Start */}
          <div className="history_contents history_contents_l">
            <div className="history_year">2019</div>
            <div className="history_body">
              <div className="history_month">03월</div>
              <div className="history_list">
                <ul>
                  <li>
                    한국일보사 ‘2019년 운송서비스 부문 서비스만족 대상‘ 선정
                  </li>
                  <li>
                    4개 기관 <span>44.8억 시리즈 A 투자 유치</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">04월</div>
              <div className="history_list">
                <ul>
                  <li>주식회사 마켓앤 영업권 양수계약 체결 (식자재 유통)</li>
                  <li>자회사 4개사 설립 및 인수</li>
                  <li>
                    3개사 (팀프인강남, 팀프인성남, 콜드체인로지스) 출자 후 설립
                  </li>
                  <li>
                    주식회사 에네스푸드넷 주식 100% 인수계약체결 (식자재 유통)
                  </li>
                  <li>자체 TMS 구축</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">06월</div>
              <div className="history_list">
                <ul>
                  <li>브랜드기준 100여개 고객사 운영</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">08월</div>
              <div className="history_list">
                <ul>
                  <li>
                    ~ 2020년 04월 4개 기관 <span>160억 시리즈 B 투자 유치</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">09월</div>
              <div className="history_list">
                <ul>
                  <li>기업부설연구소 설립인가 취득</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">10월</div>
              <div className="history_list">
                <ul>
                  <li>새벽배송 월 배송 건수 10만건 돌파</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">12월</div>
              <div className="history_list">
                <ul>
                  <li>물류센터 4곳 운영 중</li>
                  <li>브랜드기준 130여개 고객사 운영</li>
                  <li>
                    <span>연 매출 146억</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* 2019End */}
          {/* 2020Start */}
          <div className="history_contents history_contents_r">
            <div className="history_year">2020</div>
            <div className="history_body">
              <div className="history_month">04월</div>
              <div className="history_list">
                <ul>
                  <li>팀프 인슈어런스 주식취득 (자회사)</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">07월</div>
              <div className="history_list">
                <ul>
                  <li>
                    <span>
                      2020년 기술혁신개발사업 시장확대형 R&amp;D지원사업 선정
                    </span>
                    <br />: 차주 네트워크 기반 화물 운송 플랫폼
                  </li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">09월</div>
              <div className="history_list">
                <ul>
                  <li>
                    <span>새벽배송 월 20만 건</span> (택배포함 월 출고량 32만
                    8천건)
                  </li>
                  <li>
                    자회사 ㈜팀프인강남 상호명 및 대표자 변경
                    <br />
                    ㈜팀프인강남 &gt; ㈜88더브레이브
                  </li>
                  <li>
                    자회사 ㈜콜드체인로지스 상호명 및 대표자 변경
                    <br />
                    ㈜콜드체인로지스 &gt; ㈜바잇나우
                  </li>
                  <li>
                    <span>새벽배송 월 28만 건</span> (택배포함 월 출고량 40만
                    건)
                  </li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">12월</div>
              <div className="history_list">
                <ul>
                  <li>물류센터 7곳 운영중</li>
                  <li>브랜드기준 240여개 고객사 운영중</li>
                  <li>
                    <span>310억 시리즈C투자 유치</span>
                  </li>
                  <li>
                    <span>연 매출 396억</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* 2020End */}
          {/* 2021Start */}
          <div className="history_contents history_contents_l">
            <div className="history_year">2021</div>
            <div className="history_body">
              <div className="history_month">01월</div>
              <div className="history_list">
                <ul>
                  <li>인천 새벽배송 전용 물류센터 오픈</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">02월</div>
              <div className="history_list">
                <ul>
                  <li>프랜차이즈 직영 1호점 파스타집이야 송파점 오픈</li>
                  <li>기흥 새벽배송 전용 물류센터 오픈</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">04월</div>
              <div className="history_list">
                <ul>
                  <li>
                    KT-팀프레시 합작법인,
                    <img
                      src="./skin/body/sub0101/history_lolab.png?1656575148"
                      alt="lolab로고"
                    />
                    설립
                  </li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">07월</div>
              <div className="history_list">
                <ul>
                  <li>새벽배송 월 매출 20억 원 돌파</li>
                  <li>
                    <span>예비유니콘 선정</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">08월</div>
              <div className="history_list">
                <ul>
                  <li>새벽배송 월 배송 건수 50만 건 돌파</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">09월</div>
              <div className="history_list">
                <ul>
                  <li>식자재 유통사 올담FS 인수</li>
                  <li>프랜차이즈 직영 2호점 파스타집이야 강남점 오픈</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">10월</div>
              <div className="history_list">
                <ul>
                  <li>시리즈 C 브릿지 130억 투자 유치</li>
                  <li>전사 월 매출 100억 원 돌파</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">12월</div>
              <div className="history_list">
                <ul>
                  <li>올해의 벤처상 수상</li>
                  <li>
                    <span>연 매출 904.8억</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* 2021End */}
          {/* 2022Start */}
          <div className="history_contents history_contents_r">
            <div className="history_year">2022</div>
            <div className="history_body">
              <div className="history_month">03월</div>
              <div className="history_list">
                <ul>
                  <li>전사 월 매출 152억 돌파</li>
                  <li>프랜차이즈 직영1호점 ‘쌀국수집이야’ 강남점 오픈</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">04월</div>
              <div className="history_list">
                <ul>
                  <li>식자재 팀프레시 자사몰 ‘푸드원정대‘ 앱 출시</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">05월</div>
              <div className="history_list">
                <ul>
                  <li>프랜차이즈 직영1호점 ‘덮밥집이야’ 강남점 오픈</li>
                  <li>식품 이커머스 기업 옳은 인수</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">06월</div>
              <div className="history_list">
                <ul>
                  <li>
                    <span>1,600억 시리즈D 투자 유치</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">07월</div>
              <div className="history_list">
                <ul>
                  <li>전사 월 매출 200억원 돌파</li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">11월</div>
              <div className="history_list">
                <ul>
                  <li>
                    물류진흥재단 주최 우수 중소 물류 기업 포상 - 우수상 수상
                  </li>
                </ul>
              </div>
            </div>
            <div className="history_body">
              <div className="history_month">12월</div>
              <div className="history_list">
                <ul>
                  <li>화물 플랫폼 영차영차 사용자 수 5000명 돌파</li>
                </ul>
                <li>
                  <span>연 매출 2303.7억</span>
                </li>
              </div>
            </div>
          </div>
          {/* 2022End */}
        </CenterRelative>
      </div>
    </Section3>
  );
};

export default TimfHistory;
