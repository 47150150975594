import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { getCategoryList } from "../../services";
import {
  CenterDiv,
  SubContent,
  TextTitle1,
  TextTitle2,
  CategoryBox,
} from "../shared";
import InquiryTable from "./InquiryTable";

const InquiryBG = styled.div`
  background: url("../skin/images/sub_images0402.jpg") no-repeat fixed 50% 50%;
  background-size: cover;
`;

const InquiryWrapper = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .sub_contents_title .centerdiv {
    width: 1000px;
    min-height: 500px;
    background: #fff;
    padding: 50px;
  }
`;

const ISubContent = styled(SubContent)`
  padding: 150px 0 150px;

  .title1,
  .title2 {
    color: #fff;
  }

  .franchise_comment {
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    color: #000;
  }
`;

const Border = styled.div`
  width: 1px;
  height: 80px;
  background: #fff;
  margin: 0 auto 30px;
`;

const AdminIContent = () => {
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [answerYn, setAnswerYn] = useState("N");
  const [cateName, setCateName] = useState("새벽배송");
  const [categoryList, setCategoryList] = useState([]);

  const handleInquiryStateClick = (category) => {
    setAnswerYn(category);
    setCurrentPage(1);
  };

  const handleCategoryClick = (category) => {
    setCateName(category);
    setCurrentPage(1);
  };

  const getInquiryList = async () => {
    const res = await getCategoryList(
      "INQUIRY",
      cateName,
      currentPage,
      answerYn
    );
    setCategoryList(res.data);
    setTotalPage(res.totalPages);
  };

  useEffect(() => {
    getInquiryList();
  }, [cateName, answerYn, currentPage]);

  return (
    <InquiryBG>
      <InquiryWrapper>
        <ISubContent className="sub_contents_title" id="subsectionlink1">
          <TextTitle1 className="title1">Inquiry</TextTitle1>
          <TextTitle2 className="title2">문의하기</TextTitle2>
          <Border>&nbsp;</Border>
          <CenterDiv className="centerdiv">
            <CategoryBox className="category-border-box">
              <ul>
                <li onClick={() => handleInquiryStateClick("N")}>
                  <div className={answerYn === "N" ? "back select" : "back"}>
                    접수완료
                  </div>
                </li>
                <li onClick={() => handleInquiryStateClick("Y")}>
                  <div className={answerYn === "Y" ? "back select" : "back"}>
                    답변완료
                  </div>
                </li>
              </ul>
              <ul>
                <li onClick={() => handleCategoryClick("새벽배송")}>
                  <div
                    className={cateName === "새벽배송" ? "back select" : "back"}
                  >
                    새벽배송
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("풀필먼트")}>
                  <div
                    className={cateName === "풀필먼트" ? "back select" : "back"}
                  >
                    풀필먼트
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("화물주선")}>
                  <div
                    className={cateName === "화물주선" ? "back select" : "back"}
                  >
                    화물주선
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("식자재유통")}>
                  <div
                    className={
                      cateName === "식자재유통" ? "back select" : "back"
                    }
                  >
                    식자재유통
                  </div>
                </li>
                {/* <li onClick={() => handleCategoryClick("프랜차이즈")}>
                  <div
                    className={
                      cateName === "프랜차이즈" ? "back select" : "back"
                    }
                  >
                    프랜차이즈
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("보험")}>
                  <div className={cateName === "보험" ? "back select" : "back"}>
                    보험
                  </div>
                </li> */}
                <li onClick={() => handleCategoryClick("마케팅/홍보")}>
                  <div
                    className={
                      cateName === "마케팅/홍보" ? "back select" : "back"
                    }
                  >
                    마케팅,홍보
                  </div>
                </li>
                <li onClick={() => handleCategoryClick("기타")}>
                  <div className={cateName === "8" ? "back select" : "back"}>
                    기타
                  </div>
                </li>
              </ul>
            </CategoryBox>
            <InquiryTable
              list={categoryList}
              category={"INQUIRY"}
              totalPage={totalPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </CenterDiv>
        </ISubContent>
      </InquiryWrapper>
    </InquiryBG>
  );
};

export default AdminIContent;
