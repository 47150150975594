const ModalFU = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              최준엽
              <br />
              <span>
                물류본부,
                <br />
                풀필먼트팀(FU)
                <br />
                센터장
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img09.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              저희 팀은 자체적인 물류 운영의 환경이 구비되어 있지 않은
              고객사들을 대상으로 팀프레시 물류 인프라를 통해 재고관리,
              주문처리에 해당하는 풀필먼트 서비스를 제공하고 있어요.
              <br />그 중 저는 이천시 마장면에 위치한 마장 DC(Distribution
              Center) 내 전반적인 운영을 책임지고 관리하고 있습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              이렇듯 업무를 직접적으로 수행하는 실무자가 아닌 관리자로서의
              커리어를 쌓을 수 있었어요!
              <br />
              비대면 시대, 빠르게 변화하는 물류시장 속, 성장하는 스타트업
              팀프레시였기에 가능했던 폭넓은 기회와 센터에서의 다양한 경험들은
              저를 크게 성장할 수 있게 하는 발판이 되었다고 생각해요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’였기에 가능했던 일이 있었을까요?
            </div>
            <div className="interview_comment_A">
              지난 추석에 저희 고객사 중에 이벤트를 2주간 진행하면서 주문건수가
              폭발적으로 인입되던 기간이 있었어요.
              <br />
              미처 다 처리하지 못한 누적 주문건수가 꽤 있었던 상황이었죠.
              <br />
              도급사 인력들도 피로로 지친 상황 속에서 본사에 지원 요청을
              드렸는데, 흔쾌히 많은 인원이 센터에 방문해주셔서 다행히 미출고
              없이 추석을 맞이할 수 있었던 기억이 나요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’에서 이루고 싶은 개인적인 목표가 있나요?
            </div>
            <div className="interview_comment_A">
              전 그냥 지금 제 일이 재밌는지가 그 무엇보다 중요하기에, 이
              '재밌다'는 감정이 변하지 않도록 유지하는 게 제 목표예요.
              <br />
              즐기면서 일할때 무엇이든 긍정적인 결과를 불러온다는 것을 믿고
              있어요.
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              저희 팀은 20대 중반에서 50대까지의 연령층들로 구성되어 있어요.
              좌충우돌의 느낌이 없지 않아 있죠.
              <br />
              그만큼 실수는 많이 발생하지만 똑같은 실수엔 엄격히 질책하여
              재발되지 않도록 다같이 노력하는 분위기입니다.
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              본인이 외향적인지 혹은 내향적인지는 중요하지 않은 것 같고, 배우고
              성장하려는 의지가 있는 동료를 선호하는 편이에요.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFU;
