import styled from "styled-components";

import Header from "./Header";
import Footer from "./Footer";
import SideIcons from "./SideIcons";
import { Outlet } from "react-router-dom";

const Wrapper = styled.div`
  min-width: 1210px;
  max-width: 2000px;
  margin: 0 auto;
`;

const Content = styled.main`
  margin: 0 auto;
  width: 100%;
  @media only screen and (max-width: 480px) {
    min-height: 180vh;
  }
`;

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <SideIcons />
      <Outlet />
      <Content>{children}</Content>
      <Footer />
    </Wrapper>
  );
};

export default Layout;
