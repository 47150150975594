import styled from "styled-components";
import { CenterRelative, SubSection, SubTitle } from "../shared";
import { useState } from "react";

const Section6 = styled(SubSection)`
  background: url(./skin/body/sub0101/client_bg.jpg) fixed no-repeat center;
  padding: 0;

  .sub_section_bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    padding: 150px 0;
  }

  .sub_title {
    padding-bottom: 70px;
    color: #fff;
  }

  .partner_box {
    position: relative;
    width: 100%;
    height: 220px;
    font-size: 0;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;

    li {
      display: flex;
      width: 24%;
      float: left;
      height: 100px;
      padding: 0 1.5%;
      background: #fff;
      margin: 5px 0.5%;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      img {
        max-width: 100%;
        max-height: 90px;
      }
    }
  }

  .client_more {
    clear: both;
    width: 50px;
    height: 50px;
    font-family: "Pretendard";
    text-align: center;
    font-weight: 300;
    font-size: 60px;
    line-height: 40px;
    color: #fff;
    border: 1px solid #fff;
    margin: 100px auto 0;
    cursor: pointer;
  }

  .client_more_close {
    display: none;
    clear: both;
    width: 50px;
    height: 50px;
    font-family: "Pretendard";
    text-align: center;
    font-weight: 300;
    font-size: 60px;
    line-height: 40px;
    color: #fff;
    border: 1px solid #fff;
    margin: 100px auto 0;
    cursor: pointer;
  }
`;

const TimfClient = () => {
  const [isActive, setIsActive] = useState(false);

  const ToggleOpen = () => {
    setIsActive(!isActive);
  };

  return (
    <Section6 className="sub_section sub_section7">
      <div className="sub_section_bg">
        <CenterRelative className="centerdiv relattive">
          <SubTitle className="sub_title">
            <div className="sub_title_eng">Timf Client</div>
            고객사
          </SubTitle>
          <div
            className="partner_box"
            style={{ height: isActive ? "2200px" : "220px" }}
          >
            <ul>
              <li>
                <img src="skin/body/sub0101/partner1_01.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_02.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_03.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_04.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_05.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_06.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_07.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_08.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_09.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_10.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_11.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_12.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_13.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_14.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_15.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_16.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_17.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_18.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_19.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_20.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_21.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner1_22.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_01.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_02.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_03.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_04.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_05.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_06.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_07.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_08.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_09.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_10.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_11.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_12.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_13.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_14.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_15.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_16.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_17.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_18.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_19.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner2_20.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_01.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_02.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_03.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_04.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_05.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_06.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_07.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_08.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_09.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_10.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_11.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_12.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_13.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_14.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_15.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_16.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_17.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner3_18.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_01.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_02.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_03.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_04.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_05.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_06.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_07.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_08.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_09.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_10.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_11.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_12.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_13.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_14.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_15.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_16.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_17.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_18.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_19.jpg" alt="" />
              </li>
              <li>
                <img src="skin/body/sub0101/partner4_20.jpg" alt="" />
              </li>
            </ul>
          </div>
          <div
            className="client_more_close"
            onClick={ToggleOpen}
            style={{ display: isActive ? "block" : "none" }}
          >
            -
          </div>
          <div
            className="client_more"
            onClick={ToggleOpen}
            style={{ display: isActive ? "none" : "block" }}
          >
            +
          </div>
        </CenterRelative>
      </div>
    </Section6>
  );
};

export default TimfClient;
