import React, { useState } from "react";
import styled from "styled-components";
import { CenterDiv, SubContent, SubSection } from "../shared";
import { Link } from "react-router-dom";
import routes from "../../routes";

const Section = styled(SubContent)`
  text-align: left;

  li {
    list-style: none;
  }
  .title1,
  .title2 {
    text-align: left;
  }

  .privacy_title {
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: #000;
  }
  .sub_section .privacy_comment {
    font-family: "NanumGothic";
    font-size: 14px;
    line-height: 20px;
    color: #666;
  }

  .sub_section .privacy_box {
    clear: both;
    width: 100%;
    margin: 50px 0;
    .privacy_section_title {
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      color: #000;
    }
    .privacy_section_comment {
      width: 100%;
      font-size: 14px;
      line-height: 25px;
      color: #666;
      padding: 20px;
      margin: 5px 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      ul {
        position: relative;
        padding-left: 17px;
      }
      li {
        position: relative;
        padding-left: 15px;
        line-height: 20px;
        margin: 10px 0;
      }
      li:after {
        position: absolute;
        top: 0;
        left: 0;
      }
      li:nth-child(1):after {
        content: "1.";
      }
      li:nth-child(2):after {
        content: "2.";
      }
      li:nth-child(3):after {
        content: "3.";
      }
      li:nth-child(4):after {
        content: "4.";
      }
    }
  }

  .privacy_box .privacy_section_comment table {
    width: 100%;
    border-top: 1px solid #000;
    margin: 5px 0 0;
    td {
      font-family: "NanumGothic";
      font-size: 14px;
      line-height: 20px;
      color: #666;
      padding: 20px;
      padding: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-width: 0 1px 1px 0;
      :nth-child(1) {
        width: 15%;
        border-left-width: 1px;
      }
      :nth-child(2) {
        width: 85%;
      }
    }
  }
  .list_open {
    cursor: pointer;
  }
  .list_close {
    cursor: pointer;
    display: none;
  }
  .partner_list {
    display: none;
  }
`;

const Privacy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClickList = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Section className="sub_contents_title" id="subsectionlink1">
      <CenterDiv className="centerdiv">
        <div className="title1">Privacy</div>
        <div className="title2">개인(위치)정보 처리방침</div>

        <SubSection className="sub_section">
          <div className="privacy_title">
            (주)팀프레시 개인정보 및 개인위치정보 처리방침
          </div>
          <div className="privacy_comment">
            (주)팀프레시 (이하 ‘회사’라 한다)는 개인정보 보호법 제30조 및
            위치정보의 보호 및 이용 등에 관한 법률(이하 ‘위치정보법’이라 한다)
            제21조의2에 따라 정보주체의 개인정보 및 개인위치정보를 보호하고 이와
            관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과
            같이 개인정보 및 개인위치정보 처리지침을 수립·공개합니다.
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제1조(개인정보 처리목적)
            </div>
            <div className="privacy_section_comment">
              회사는 다음의 목적을 위하여 개인정보를 처리합니다.
              <br />
              처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지
              않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에
              따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
              <ul>
                <li>
                  재화 또는 서비스 제공 물품배송을 목적으로 개인정보를
                  처리합니다.
                </li>
                <li>
                  고충처리
                  <br />
                  민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지,
                  처리결과 통보 등의 목적으로 개인정보를 처리합니다.
                </li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제2조(개인정보의 처리 및 보유기간)
            </div>
            <div className="privacy_section_comment">
              ① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
              개인정보를 수집시에 동의 받은 개인정보 보유·이용기간 내에서
              개인정보를 처리·보유합니다.
              <br />
              <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
              <ul>
                <li>
                  재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제·정산
                  완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간
                  종료시까지
                  <br />
                  1) 「전자상거래 등에서의 소비자 보호에 관한 법률」 에 따른
                  표시·광고, 계약내용 및 이행 등 거래에 관한 기록
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 표시, 광고에 관한 기록 : 6월
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 계약 또는 청약철회, 대금결제,
                  재화 등의 공급기록 : 5년
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 소비자 불만 또는 분쟁처리에
                  관한 기록 : 3년
                </li>
              </ul>
            </div>
          </div>

          <div className="privacy_box">
            <div className="privacy_section_title">
              제3조(개인정보처리 위탁)
            </div>
            <div className="privacy_section_comment">
              당사는 서비스 이행을 위해 아래와 같이 개인정보 처리 업무를
              외부업체에 위탁하여 운영하고 있습니다.
              <ul>
                <li>위탁업무 : 화물의 운송</li>
                <li>
                  수탁사(인) :
                  <table cellPadding="0" cellSpacing="0">
                    <tbody>
                      <tr>
                        <td>택배업무</td>
                        <td>씨제이대한통운㈜. 롯데글로벌로지스㈜, ㈜한진</td>
                      </tr>
                      <tr>
                        <td>운송협력</td>
                        <td className="partner_cell">
                          <span>
                            <div
                              className="list_open"
                              onClick={onClickList}
                              style={{ display: isOpen ? "none" : "block" }}
                            >
                              (리스트보기)
                            </div>
                            <div
                              className="list_close"
                              onClick={onClickList}
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              (리스트닫기)
                            </div>
                          </span>
                          <div
                            className="partner_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            덕영로지스, 개인화물(임*두), 제이엠로지스,
                            화물(TOP), 진환상운, 동흥운수, 조은운수, 코로넷,
                            진원티피엘로지스, 개인화물(박*영), 휴먼로지스,
                            한백티엘에스, 조일물류, 피엠물류, 이노물류, 짐엔콜,
                            개인화물(김*배), 대선기업운수, 아진운수,
                            개인화물(최*석), 고려건설장비, 에덴물류,
                            유에이치로지스, 에이치엔, 대복, 진환물류,
                            창호로지스, 개인화물(윤*언), 휴맥스, (주)대신통운,
                            청현, 개인화물(임*규), 서현, 호반운수, 미래,
                            삼구상운, 풍경, 개인화물(고*현), 대성, 세화,
                            개별화물(김*상), 인터로지스, 유성운수, 청은산업,
                            개인화물(서*범), 국민트랜스 , 삼진화물운수,
                            개인화물(임*태), 개인화물(신*건), 개인화물(이*용),
                            개인화물(전*배, 제이엠, 이프코 통운, 물류뱅크, 진영,
                            유비스, 일성운수, 좋은차, 세종기업물류, 효림운수,
                            UY물류, 개인화물(강*석), 개인화물(엄*용), 삼영운수,
                            우리화물, 삼백화물, 두두, 고은산업, 개인화물(백*훈),
                            통통운송, 컴퍼니 GY, 작스로직스, 개인화물(이*광),
                            유한글로지스, 개인화물(민*석), 개인화물(강*일),
                            개인화물(강*대), 개인화물(김*동), 무열운수,
                            우리F&amp;B, 미다스 에이치오, 날쥐운수, 나름티에스,
                            개인화물(이*수), 성수기업, 제일종합물류,
                            개인화물(김*민), 삼원운수, 삼원종합물류, 서울로지스,
                            경기로지스, 클로버, 대산물류, 지아팸, 일석로지스틱,
                            부성, 개인화물(최*완), 개인화물(박*호), 선일,
                            서현운수, 하나특송, 플라이물류, 개인화물(송*),
                            라온파파, 경동, 창일물류, 개인화물(강*진), 용호운수,
                            달곰화물, 진양통운, 개인화물(김*원),
                            개인화물(연*호), 소수물류, 개인화물(성*규),
                            개인화물(정*영), 남양로지스, 개인화물(송*훈),
                            아이디, 개인화물(정*희), 홍남물류, 오케이물류,
                            제이에이치케이, 화성통운, 청현운수, 부림운수,
                            평화물류, 동부, 요셉앤서울, 엘케이지엘에스,
                            개인화물(김*필), 개인화물(권*미), 현혁,
                            제이에이치로지스, 하늘개발, 덕현화물, 일원,
                            유로피로지스, 디에이치물류, (주)메쉬코리아,
                            수지상사, 이든물류, 에스엠통운, 오래운수, 현종물류,
                            경서, 제이피화물, 개인화물(황*현), 개인화물(심*열),
                            개인화물(김*석), 성우물류, 개인화물(이*우),
                            현종로지스, 나래물류, 철일엘앤디, 조이물류,
                            개인화물(손*호), 개인화물(노*홍), 88화물, 윤성물류,
                            개인화물(조*순), 하늘, 예닮운수, 시후운수, 신성,
                            성현운수, 미송물류, 덕흥물류, 세주물류,
                            김포한강용달, 베스트, 전국1톤화물연합, 진환종합물류,
                            더로지스, 성우종합물류, 오케이씨물류, 태준물류,
                            허브물류, 용달세상, 개인화물(이*규),
                            개인화물(유*철), 국민트랜스, 개인화물(이*만),
                            사방로지스틱스, 엠케이로지스, (주)롤랩,
                            새벽을여는사람들, 정유통, 종현운수, 바른물류,
                            엠이(M.E)운수, 개인화물(서*두), 성준물류,
                            개인화물(진*수), 성우로지스, 도윤물류, 코리아,
                            광천물류, 흑룡운수, 선물류, 용방통운, 개인화물(정*),
                            하늘종합물류, 개인화물(김*율), 개인화물(구*일),
                            (주)바잇나우, 한우리종합물류, 개인화물(김*현),
                            개인화물(이*범), 대양통운익스프레스,
                            개인화물(서*규), 케이엠로지스 , 약속물류, 사미운수,
                            서연글로비스, 비엔에프, 서진물류, 크린마스터, 우진,
                            개인화물(김*일), 에스엠(sm)냉탑, 광성운수,
                            오예스화물, 대영특수운수, 개인화물(박*환),
                            개인화물(전*호), 태영, 홍성화물, 개인화물(김*화),
                            개인화물(송*진), 노다지로지스, 상석운수, 에스앤샤,
                            한마음물류, 개인화물(강*원), 개인화물(엄*환),
                            개인화물(서*석), 개인화물(안*범), 개인화물(권*덕),
                            개인화물(김*선), 개인화물(송*재), 물류탱크,
                            한신물류, 88특송, 우리운수, 개인화물(김*환),
                            지원물류, 개인화물(김*영), 동국상운,
                            개인화물(이*민), 에스엠화물, 에이치앤상민통운,
                            다원특수 , (주)카우로지스틱스, 개인화물(김*철),
                            이림유통, 개인화물(신*중), 에스이물류,
                            개인화물(김*복), 제이와이로지스, 개인화물(류*화),
                            개인화물(이*호), 성윤운수, 제이푸드, 아우름,
                            개인화물(김*신), 엘케이엘에스, 개인화물(박*주),
                            삼원운송, 개인화물(이*람), 개인화물(진*범),
                            우리용달, ㈜지안서비스, 에스제이로지텍, 문화냉동,
                            개인화물(이*준), 무종로지스, 우주용달,
                            한국로지스틱스, 동서물류, 개인화물(이*연),
                            개인화물(김*훈), 개인화물(황*일), 성실,
                            디케이솔루션, 지구용달, 월계상사, 월드유니온,
                            조은생각, 개인화물(김*희), 이지물류,
                            개인화물(이*화), 창진특수냉동, 서울화물, 태평물류,
                            개인화물(최*숙), 개인화물(현*사), 개인화물(황*재),
                            선광종합운수, 브엘, 누리로직스, 주원, 가오물류,
                            백야, 하이엘 운수, 바울물류, 동원운수,
                            딜리버리아이에스(Delivery IS), 인터티엘에스,
                            제이물류, 충서엘앤디, 강산건축, 다미운수, 서남운수,
                            개인화물(황*준), 개인화물(심*만), 대신통운, 엠앤엠,
                            진양로지스틱스, 와이에이치로지스, 금영운수, 팔팔,
                            개인화물(이*영), 개인화물(한*호), 파워카, 주원통운,
                            아산지엘에스, 레드스토리(RED STORY), 주원지엘에스,
                            일학상사, 은희상사, 개인화물(김*웅),
                            개인화물(이*채), 개인화물(박*헌), 서아물류,
                            개인화물(박*욱), 영서이엔에스, 레인보우, 진원상사,
                            럭키로지스, 우경기업, 강성유통, 제일서비스,
                            개인화물(윤*상), 삼호통운, 개인화물(김*숙), 올뉴캡,
                            개인화물(박*조), 인천물류, 이루마, 제이넷로지스틱스,
                            광복화물, 개인화물(강*구), 진영운수,
                            제이케이로지스틱, 에스케이종합물류, 개인화물(신*헌),
                            개인화물(전*하), 개인화물(서*필), 개인화물(이*철),
                            개인화물(이*욱), 개인화물(노*현), 개인화물(심*미),
                            금왕물류, 강성운수, 왕자유통, 인성로지스, 부광물류,
                            개인화물(정*훈), 정오운수, 개인화물(이*현),
                            개인화물(이*성), 개인화물(김*산), 태진물류,
                            선임물류, 수호물류, 강진물류, 럭키물류,
                            개인화물(박*우), 새빛로지스, 개인화물(이*숙), 오륜,
                            오에프물류, 개인화물(손*우), 벧엘로지스,
                            개인화물(류*근), 상록운수, 개인화물(박*봉), 혜신,
                            제일지, 스카이물류, 선진에스씨엠, 에프앤엘,
                            개인화물(장*민), 이룸물류, ㈜대성에프앤디,
                            울트라운수, ㈜마린로지스틱스, (주)프레시원,
                            그리니코퍼레이션㈜, 경세, 에이딘, 캐리, 운수대통,
                            가온로지스, 스카이, 건영, 한빛, 금강, JS로지텍,
                            코리아운송, 개인화물(박*), 한국물류시스템, 신천물류,
                            개인화물(윤*석), 새암물류, 운창로지텍, 홍인로지스틱,
                            씨티로지스, 상록로지스, 개인화물(길*성), 우현운송,
                            이현통상, 도로롱
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제4조(정보주체의 권리, 의무 및 행사방법)
            </div>
            <div className="privacy_section_comment">
              ① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련
              권리를 행사할 수 있습니다.
              <ul>
                <li>개인정보 열람요구</li>
                <li>오류 등이 있을 경우 정정 요구</li>
                <li>삭제요구</li>
                <li>처리정지 요구</li>
              </ul>
              ② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편,
              모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이
              조치하겠습니다.
              <br />
              <br />
              ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
              경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를
              이용하거나 제공하지 않습니다.
              <br />
              <br />
              ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은
              자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법
              시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
              <br />
              <br />⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가
              처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을
              침해하여서는 아니됩니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제5조(처리하는 개인정보 항목)
            </div>
            <div className="privacy_section_comment">
              회사는 다음의 개인정보 항목을 처리하고 있습니다.
              <ul>
                <li>
                  재화 또는 서비스 제공
                  <br />
                  · 필수항목 : 성명, 주소, 휴대전화번호
                  <br />· 선택항목 : 정보주체의 특이사항 기재내용(공동현관
                  출입방법, 화물정보)
                </li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">제6조(개인정보의 파기)</div>
            <div className="privacy_section_comment">
              ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
              불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
              <br />
              <br />
              ② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나
              처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
              계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
              데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
              <br />
              <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
              <ul>
                <li>
                  파기절차
                  <br />
                  회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
                  보호책임자의 승인을 받아 개인정보를 파기합니다.
                </li>
                <li>
                  파기방법
                  <br />
                  회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할
                  수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여
                  파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄로 분쇄하거나
                  소각하여 파기합니다.
                </li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제7조(개인정보의 안전성 확보조치)
            </div>
            <div className="privacy_section_comment">
              회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
              있습니다.
              <ul>
                <li>
                  관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등
                </li>
                <li>
                  기술적 조치 : 개인정보처리시스템 등의 접근권한 관리,
                  접근통제시스템 설치, 고유식별정보 등의 암호화
                </li>
                <li>물리적 조치 : 전산실, 자료보관실 등의 접근통제 </li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제8조(개인정보 및 개인위치정보 보호책임자)
            </div>
            <div className="privacy_section_comment">
              ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
              처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
              같이 개인정보 보호책임자를 지정하고 있습니다.
              <ul>
                <li>
                  개인정보 및 개인위치정보 보호책임자
                  <br />
                  성명 : 서영락
                  <br />
                  직책 : 기술지원실 실장
                  <br />
                  연락처 : 02-423-0529
                  <br />
                  이메일 : youngrag.seo@timf.co.kr
                  <br />
                  팩스번호 : 02-3432-0525
                  <br />※ 개인정보 보호 담당부서로 연결됩니다.
                </li>
                <li>
                  개인정보 보호 담당부서
                  <br />
                  부서명 : 인사팀
                  <br />
                  담당자 : 유두곤
                  <br />
                  연락처 : 02-423-0529
                  <br />
                  이메일 : dugon.yoo@timf.co.kr
                  <br />
                  팩스번호 : 02-3432-0525
                  <br />※ 개인정보 보호 담당부서로 연결됩니다.
                </li>
              </ul>
              <br />
              ② 정보주체께서는 회사의 서비스(또는 사업)를 이용하시면서 발생한
              모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
              개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
              <br />
              회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴
              것입니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제9조(위치정보 처리에 관한 사항)
            </div>
            <div className="privacy_section_comment">
              회사는 위치정보의 보호 및 이용 등에 관한 법률에 따라 이용자의
              개인위치정보를 안전하게 관리합니다.
              <br />
              ① 개인위치정보의 처리목적 및 보유기간
              <br />
              회사는 운송 서비스를 제공하며 개인위치정보의 이용 또는 제공 목적을
              달성하면 파기합니다.
              <br />
              회사는 서비스를 제공하기 위해 개인위치정보를 처리하는 서비스 내용
              및 목적은 아래와 같습니다.
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td>서비스 명</td>
                    <td>서비스 내용 및 목적</td>
                  </tr>
                  <tr>
                    <td>Teamfresh TMS</td>
                    <td>수/배송 업무에 대한 배차 지시, 차량 Tracking 등</td>
                  </tr>
                </tbody>
              </table>
              ② 개인위치정보 이용·제공사실 확인자료의 보유근거 및 보유기간
              <br />
              회사는 『위치정보의 보호 및 이용 등에 관한 법률』 제16조제2항에
              따라 위치정보 이용·제공사실 확인 자료를 자동기록·보존하며, 해당
              자료는 6개월 이상 보관합니다.
              <br />
              ③ 개인위치정보의 파기 절차 및 방법
              <br />
              회사는 개인위치정보의 처리목적이 달성된 경우, 개인위치정보를
              재생이 불가능한 방법으로 안전하게 파기하고 있습니다. 전자적 파일의
              형태는 복구 및 재생이 불가능한 기술적인 방법으로 파기하며, 출력물
              등은 파쇄 하거나 소각하는 방법으로 파기합니다.
              <br />
              ④ 개인위치정보의 이용 또는 제공에 따른 통보에 관한 사항
              <br />
              회사는 개인위치정보를 이용자가 지정하는 제3자에게 제공하는
              경우에는 개인위치정보를 수집한 당해 통신단말장치로 매회 이용자에게
              제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다. 다만, 다음의
              경우는 이용자가 미리 특정하여 지정한 통신단말장치 또는
              전자우편주소 등으로 통보합니다.
              <ul>
                <li>
                  개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는
                  영상의 수신기능을 갖추지 아니한 경우
                </li>
                <li>
                  이용자가 온라인 게시 등의 방법으로 통보할 것을 미리 요청한
                  경우
                </li>
              </ul>
              ⑤ 8세 이하의 아동 등의 보호의무자 권리·의무 및 그 행사방법
              <br />
              회사는 아래의 경우 해당하는 이용자(이하 ”8세 이하의 아동 등” 이라
              함)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를
              위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의
              동의가 있는 것으로 봅니다.
              <ul>
                <li>8세 이하의 아동</li>
                <li>피성년후견인</li>
                <li>
                  장애인복지법 제2조제2항제2호의 규정에 의한 정신적 장애를 가진
                  자로서 장애인고용촉진 및 직업재활법 제2조제2호의 규정에 의한
                  중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 의하여
                  장애인등록을 한 사람에 한정)
                </li>
              </ul>
              8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의
              이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에
              보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
              <br />
              보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에
              동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.
              <br />
              ⑥ 위치정보 관리책임자의 정보
              <br />
              회사의 위치정보 관리책임자는 위 개인정보 보호책임자가 겸직하고
              있습니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제10조(개인정보 및 개인위치정보 열람청구)
            </div>
            <div className="privacy_section_comment">
              정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를
              아래의 부서에 할 수 있습니다.
              <br />
              회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록
              노력하겠습니다.
              <ul>
                <li>
                  개인정보 열람청구 접수,처리 부서
                  <br />
                  부서명 : 인사팀
                  <br />
                  담당자 : 유두곤
                  <br />
                  연락처 : 02-423-0529
                  <br />
                  이메일 : dugon.yoo@timf.co.kr
                  <br />
                  팩스번호 : 02-3432-0525
                </li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제11조(권익침해 구제방법)
            </div>
            <div className="privacy_section_comment">
              정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
              등을 문의하실 수 있습니다.
              <br />
              아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보
              불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이
              필요하시면 문의하여 주시기 바랍니다.
              <ul>
                <li>
                  개인정보 침해신고센터 (한국인터넷진흥원 운영)
                  <br />
                  - 소관업무 : 개인정보 침해사실 신고, 상담 신청
                  <br />
                  - 홈페이지 : privacy.kisa.or.kr
                  <br />
                  - 전화 : (국번없이) 118
                  <br />- 주소 : (138-950) 서울시 송파구 중대로 135
                  한국인터넷진흥원 개인정보침해신고센터
                </li>
                <li>
                  개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
                  <br />
                  - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
                  <br />
                  - 홈페이지 : privacy.kisa.or.kr
                  <br />
                  - 전화 : (국번없이) 118
                  <br />- 주소 : (138-950) 서울시 송파구 중대로 135
                  한국인터넷진흥원 개인정보침해신고센터
                </li>
                <li>
                  대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
                </li>
                <li>경찰청 사이버테러대응센터 : 1566-0112 (www.netan.go.kr)</li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제12조(개인정보 처리방침 변경)
            </div>
            <div className="privacy_section_comment">
              이 개인정보 처리방침은 2023.04.24. 부터 적용됩니다.
            </div>
          </div>
          <Link to={routes.prevPrivacy} style={{ color: "#0052a2" }}>
            변경 전 개인(위치)정보 처리방침 보기
          </Link>
        </SubSection>
      </CenterDiv>
    </Section>
  );
};

export default Privacy;
