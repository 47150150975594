import { Link } from "react-scroll";
import styled from "styled-components";
import { Arrow2, SubImages, SubImgWrapper, ShadowBg } from "../../shared";

const SubImagesBlack = styled(SubImages)`
  .sub_images_title {
    color: rgb(0, 0, 0);
  }
`;

const SubImg = styled(SubImgWrapper)`
  background: url(../skin/images/sub_images0202.jpg) no-repeat 50% 50%;
  background-size: cover;
`;

const DistributionAbout = () => {
  return (
    <SubImagesBlack className="subimages subimages0202">
      <div className="sub_images_title">
        식자재, 업장 운영에 맞는
        <br />
        최상의 조건을 제공합니다
      </div>
      <Arrow2 className="sub_images_arrow">
        <Link className="anchorLink" to="sectionDistribution" smooth={true}>
          <i className="xi-angle-down"></i>
        </Link>
      </Arrow2>
      <ShadowBg className="shadow_bg">&nbsp;</ShadowBg>
      <SubImg className="subimg subimg0202">&nbsp;</SubImg>
    </SubImagesBlack>
  );
};

export default DistributionAbout;
