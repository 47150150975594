import styled from "styled-components";
import {
  CenterDiv,
  SubSection,
  TextTitle1,
  TextTitle2,
  TextComment,
  SectionImg,
  SectionText,
  Photobox,
  Sliders,
} from "../shared";
import CarouselMobile from "./CarouselMobile";

const Section10 = styled(SubSection)`
  padding: 0;
  .section_text {
    float: right;
    position: relative;
    width: 45%;
    padding: 150px 20px;
    box-sizing: border-box;
  }
`;

const SectionRight = styled(SectionText)`
  float: right;
`;

const Slider = styled(Sliders)`
  :after {
    position: absolute;
    top: -160px;
    left: -8px;
    width: 100%;
    height: 100px;
    border: 8px solid rgb(255 255 255);
    border-radius: 40px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    background: rgb(255 255 255);
    z-index: 53;
    content: "";
  }
`;

const Tcc = () => {
  return (
    <Section10 className="sub_section sub_section3">
      <CenterDiv className="centerdiv">
        <SectionRight className="section_text">
          <TextTitle1 className="section_text_title1">
            운송수단 배정, 일정, 과정까지 관리
          </TextTitle1>
          <TextTitle2 className="section_text_title2">
            배송관리시스템
          </TextTitle2>
          <TextComment className="section_text_title_comment">
            TMS(Transportation Management System)
            <li>팀프레시 자체 제작 운송 관제 시스템</li>
            <li>배송 현황 실시간 추적 가능</li>
            <li>화주사의 시스템과 연동 가능한 API 가이드 제공</li>
            <li>배송가능지역 및 배송상태값 조회 가능</li>
          </TextComment>
        </SectionRight>
        <SectionImg className="section_img">
          <Photobox className="phonebox">
            <Slider id="slides3" className="sliders">
              <div className="slidesjs-container">
                <div className="slidesjs-control">
                  <CarouselMobile
                    slide={"./skin/body/sub020101/sectionimg3_1.jpg"}
                    slide2={"./skin/body/sub020101/sectionimg3_2.jpg"}
                    slide3={"./skin/body/sub020101/sectionimg3_3.jpg"}
                  />
                </div>
              </div>
            </Slider>
          </Photobox>
        </SectionImg>
      </CenterDiv>
    </Section10>
  );
};

export default Tcc;
