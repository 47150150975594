import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStore } from "../../UserContext";

import FaqConfirmModal from "../common/Modal/FaqConfirmModal";
import { NewsList } from "../shared";
import Parser from "html-react-parser";

const Container = styled.div`
  .news_source {
    color: #333;
    font-size: 20px;
  }

  .news_body {
    font-size: 17px;
    color: #333;
  }
  @media only screen and (max-width: 480px) {
    .news_body {
      min-height: 42px;
    }

    .news_arrow,
    .news_body {
      font-size: 32px;
    }
  }
`;

const FaqPageList = ({ list, updateFaqList }) => {
  const { state } = useStore();

  const [open, setOpen] = useState(false);
  const [reqSeq, setReqSeq] = useState("");
  const [contents, setcontents] = useState("");

  const handleOpen = (e) => {
    const val = e.target.dataset.name;
    setReqSeq(val);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // const text = ;
    // console.log(text);
    // setcontents(text);
  }, []);

  return (
    <Container>
      {list.map((list, index) => (
        <NewsList
          style={{ width: "100%", height: "100%" }}
          key={list.csCenterSeq}
          className="news_list"
          data-name={list.csCenterSeq}
          onClick={state?.user ? handleOpen : null}
        >
          <div className="news_source" data-name={list.csCenterSeq}>
            Q. {list.title}
          </div>

          <div className="news_body" data-name={list.csCenterSeq}>
            A.{" "}
            {Parser(
              list?.contents?.replaceAll(/(")|(\n|\r\n)/g, (match, p1) => {
                if (p1) {
                  // 따옴표가 있는 경우, 따옴표를 제거
                  return "";
                } else {
                  // 개행 문자를 <br> 태그로 변경
                  return "<br>";
                }
              })
            )}
          </div>
        </NewsList>
      ))}
      <FaqConfirmModal
        seq={reqSeq}
        open={open}
        handleClose={handleClose}
        updateFaqList={updateFaqList}
      />
    </Container>
  );
};

export default FaqPageList;
