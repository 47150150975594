const routes = {
  home: "/",

  login: "/admin/login",
  // 회사소개
  business: "/business",
  //서비스소개
  service: "/service",
  //서비스소개 -> 풀필먼트
  fulfillment: "/service/fulfillment",
  //서비스소개 -> 화물주선
  transportation: "/service/transportation",
  //서비스소개 -> 유통
  distribution: "/service/distribution",
  //서비스소개 -> 프랜차이즈
  franchise: "/service/franchise",
  //서비스소개 -> 보험
  insurance: "/service/insurance",
  //인재채용
  recruit: "/recruit",
  //인재채용 생성
  recruitcreate: "/recruit/create",
  //인재채용 생성
  recruitmodify: "/recruit/modify/:reqSeq",
  //고객지원
  // customer: "/customer",
  // 고객지원 -> 공지사항
  notice: "/customer",
  // 고객지원 -> 공지사항생성
  noticecreate: "/customer/notice/create",

  // 고객지원 -> 공지사항수정
  noticemodify: "/customer/notice/modify/:modiSeq",

  // 고객지원 -> 공지사항상세
  noticedetail: "/customer/notice/detail/:csCenterSeq",
  //고객지원 -> 팀프뉴스
  timfnews: "/customer/timfnews",

  //고객지원 -> 팀프뉴스생성
  timfnewscreate: "/customer/timfnews/create",

  //고객지원 -> 팀프뉴스수정
  timfnewsmodify: "/customer/timfnews/modify/:newsSeq",

  //고객지원 -> 문의하기
  inquiry: "/customer/inquiry",

  //고객지원 -> 문의하기
  inquiryList: "/customer/inquiry/list",

  //고객지원 -> 문의하기
  inquiryDetail: "/customer/inquiry/detail/:inquirySeq",

  //고객지원 -> 자주묻는질문
  faq: "/customer/faq",
  //고객지원 -> 자주묻는질문생성
  faqcreate: "/faq/create",
  //고객지원 -> 자주묻는질문수정
  faqmodify: "/faq/modify/:csCenterSeq",

  //개인정보처리방침
  privacy: "/privacy",
  //이전개인정보처리방침
  prevPrivacy: "/prevPrivacy",
  //위치기반 서비스 이용약관
  terms: "/terms",
  //이전 위치기반 서비스 이용약관
  prevTerms: "/prevTerms",
  //팀프패스 개인정보처리방침
  timfPassPrivacy: "/timfPassPrivacy",
  prevTimfPassPrivacy: "/prevTimfPassPrivacy",
  //팀프패스 위치기반 서비스 이용약관
  timfPassTerms: "/timfPassTerms",
  wmsPrivacy: "/wmsPrivacy",
  /**** 관리자 ******/
  admin: "/admin",
  /**** 관리자 고객문의 상세******/
  adminInquiry: "/admin/inquiry/:id",
  /**** 관리자 창업문의 상세 ******/
  adminBusiness: "/admin/business/:id",
};

export default routes;
