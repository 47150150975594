import styled from "styled-components";
import {
  CenterDiv,
  SubSection,
  TextTitle2,
  TextComment,
  SectionImg,
} from "../../shared";

const Section21 = styled(SubSection)`
  padding: 0 0 150px;
  background: rgba(0, 0, 0, 0.03);

  .section_text {
    position: relative;
    float: left;
    // width: 55%;
    padding: 150px 20px;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    padding-bottom: 100px;
  }

  .section_img {
    width: 100%;
    text-align: right;
    img {
      max-width: 100%;
    }
  }
`;

const DistributionNewwork = () => {
  return (
    <Section21 className="sub_section sub_section2">
      <CenterDiv className="centerdiv">
        <div className="section_text">
          <TextTitle2 className="section_text_title2">
            전국 물류망 확보
          </TextTitle2>
          <TextComment className="section_text_title_comment">
            전산발주 마감 이후, 지역별 센터에서 분류작업을 통해
            <br />
            전국 가맹점에 다음날 9시 내로 입고
          </TextComment>
        </div>
        <SectionImg className="section_img">
          <img src="../skin/body/sub0202/sectionimg2_1.png" alt="" />
        </SectionImg>
      </CenterDiv>
    </Section21>
  );
};

export default DistributionNewwork;
