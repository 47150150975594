import styled from "styled-components";

import {
  CenterDiv,
  SubSection,
  TextTitle2,
  SectionImg,
  SectionText,
  Photobox,
  Sliders,
} from "../../shared";

import CarouselMobile from "./../CarouselMobile";
import useScrollFadeIn from "../../../hooks/useScrollFadeIn";

const Section24 = styled(SubSection)`
  padding: 0;
`;

const SectionLeft = styled(SectionText)`
  float: left;
  padding-top: 350px;
`;

const TextTitle2Big = styled(TextTitle2)`
  font-size: 70px;
  line-height: 80px;
  position: relative;
  color: #02204a;
  :before {
    position: absolute;
    top: 48%;
    left: 270px;
    width: 350px;
    height: 5px;
    background: #02204a;
    content: "";
  }
`;

const Slider = styled(Sliders)`
  height: 810.297px;
`;

const FranchiseReview = () => {
  return (
    <Section24 className="sub_section sub_section5">
      <CenterDiv className="centerdiv">
        <SectionLeft className="section_text">
          <TextTitle2Big
            className="section_text_title2 animated fadeInRight"
            {...useScrollFadeIn("left", 1, 0)}
          >
            리뷰많은
            <br />
            배달맛집
          </TextTitle2Big>
        </SectionLeft>
        <SectionImg className="section_img">
          <Photobox className="phonebox">
            <Slider id="slides3" className="sliders">
              <div className="slidesjs-container">
                <div className="slidesjs-control">
                  <CarouselMobile
                    slide={"../skin/body/sub0203/sectionimg5_1.jpg"}
                    slide2={"../skin/body/sub0203/sectionimg5_2.jpg"}
                    slide3={"../skin/body/sub0203/sectionimg5_3.jpg"}
                    slide4={"../skin/body/sub0203/sectionimg5_4.jpg"}
                    slide5={"../skin/body/sub0203/sectionimg5_5.jpg"}
                    slide6={"../skin/body/sub0203/sectionimg5_6.jpg"}
                    slide7={"../skin/body/sub0203/sectionimg5_7.jpg"}
                    height={"810.570px"}
                  />
                </div>
              </div>
            </Slider>
          </Photobox>
        </SectionImg>
      </CenterDiv>
    </Section24>
  );
};

export default FranchiseReview;
