import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import styled from "styled-components";
import { CenterDiv, SubContent, TextTitle1, TextTitle2 } from "../../shared";

import { useFormik } from "formik";

import { postInquiry } from "../../../services";
import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import routes from "../../../routes";
import InquiryCreate from "./InquiryCreate";
import InquiryUser from "./InquiryUserList";
import InquiryUserCheck from "./InquiryUserCheck";
import InquiryUserList from "./InquiryUserList";
import UserInquiryDetail from "./InquiryUserDetail";
import InquiryUserDetail from "./InquiryUserDetail";

const imageURL = `${process.env.PUBLIC_URL}/skin/images/sub_images0402.jpg`;

const InquiryBG = styled.div`
  background: url(${imageURL}) no-repeat fixed 50% 50%;
  background-size: cover;
  @media only screen and (max-width: 480px) {
    min-height: 180vh;
  }
`;

const InquiryWrapper = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .sub_contents_title .centerdiv {
    width: 1000px;
    min-height: 500px;
    background: #fff;
    padding: 50px;

    @media only screen and (max-width: 480px) {
      width: 1180px;
    }
  }
`;

const ISubContent = styled(SubContent)`
  padding: 150px 0 150px;
  .title1,
  .title2 {
    color: #fff;
  }

  .franchise_comment {
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    color: #000;
  }
`;

const TabContainer = styled.div`
  > div {
    display: flex;
    justify-content: center;
  }
  #tabHeader {
    background: white;
    width: 500px;
  }
  .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-around;
  }
  button {
    font-size: 20px;
    padding: 20px 10px;
  }
`;

const Inquiry = () => {
  const location = useLocation();

  const [serviceType, setServiceType] = useState("");
  const [insuaranceType, setInsuaranceType] = useState("");
  const [brandType, setBrandType] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [value, setValue] = useState("1"); // 탭 value
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      categorySeq: "",
      title: "",
      companyName: "", //업체명
      contents: "",
      name: "", // 담당자/직급
      // phone: "",
      phone: {
        first: "",
        second: "",
        third: "",
      },
      email: "",
      password: "",
      agreePrivacy: false,
      messageAlarm: false,
      emailAlarm: false,
      useYn: "N",
      monthlyShipment: "",
      monthlyStorage: "",
      goods: "",
      pickupAddress: "",
      salesChannel: "",
      sku: "",
      unit: "",
      loadingUnloadingAddress: "", //상차지주소/하차지주소
      loadingUnloadingTime: "", //상차시간/하차시간
      workingDay: "", //근무일수
      carTonCnt: "", //차량대수/필요대수
      bizRegNum: "", // 사업자 등록번호
      insuaranceType: "", // 보험종류
      brandType: "", // 브랜드종류
    },
    validate: (values) => {
      let error = {};
      const regEmail =
        /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;

      const regPassword =
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;

      const {
        companyName,
        name,
        phone,
        email,
        password,
        monthlyShipment,
        monthlyStorage,
        sku,
        unit,
        loadingUnloadingAddress,
        agreePrivacy,
        messageAlarm,
        emailAlarm,
      } = values;

      if (serviceType.length === 0) {
        return alert("서비스유형을 선택해주세요.");
      }
      //
      else {
        if (serviceType === "5") {
          if (brandType === "") {
            return alert("브랜드를 선택해주세요.");
          }
        }

        if (serviceType === "6") {
          if (insuaranceType === "") {
            return alert("보험종류를 선택해 주세요");
          }
        }

        if (
          serviceType === "1" ||
          serviceType === "2" ||
          serviceType === "3" ||
          serviceType === "6"
        ) {
          if (companyName.length === 0) {
            error.companyName = "업체명을 입력해 주세요";
            return alert(error.companyName);
          }
        }

        if (
          serviceType === "1" ||
          serviceType === "2" ||
          serviceType === "3" ||
          serviceType === "6"
        ) {
          if (name.length === 0) {
            error.name = "담당자/직급을 입력해 주세요";
            return alert(error.name);
          }
          if (
            phone.first.length === 0 ||
            phone.second.length === 0 ||
            phone.third.length === 0
          ) {
            error.phone = "연락처를 입력해 주세요";
            return alert(error.phone);
          }
        }

        if (
          serviceType === "4" ||
          serviceType === "5" ||
          serviceType === "7" ||
          serviceType === "12"
        ) {
          if (name.length === 0) {
            error.name = "성명을 입력해 주세요";
            return alert(error.name);
          }
          if (
            phone.first.length === 0 ||
            phone.second.length === 0 ||
            phone.third.length === 0
          ) {
            error.phone = "연락처를 입력해 주세요";
            return alert(error.phone);
          }
        }

        if (email.length === 0) {
          error.email = "이메일을 입력해 주세요";
          return alert(error.email);
        }

        if (!regEmail.test(email)) {
          error.name = "이메일 입력값이 유효하지 않습니다";
          return alert(error.name);
        }

        if (/(\w)\1\1\1/.test(password)) {
          error.password = "같은 문자를 4번 이상 사용하실 수 없습니다.";

          return alert(error.password);
        }

        if (!regPassword.test(password)) {
          error.password =
            "숫자+영문자+특수문자 조합으로 8자리 이상 사용해야 합니다.";
          return alert(error.password);
        }
        // if (!repeatString.test(password)) {
        //   error.password = "같은 문자를 4번 이상 사용하실 수 없습니다.";
        //   return alert(error.password);
        // }

        if (serviceType === "2" || serviceType === "3") {
          if (monthlyShipment.length === 0) {
            error.monthlyShipment = "월 출고건수를 입력해주세요";
            return alert(error.monthlyShipment);
          }
        }

        if (serviceType === "3") {
          if (monthlyStorage.length === 0) {
            error.monthlyStorage = "월 보관량을 입력해주세요";
            return alert(error.monthlyStorage);
          }
          if (sku.length === 0) {
            error.sku = "합포율을 입력해주세요";
            return alert(error.sku);
          }

          if (unit.length === 0) {
            error.unit = "합포율을 입력해주세요";
            return alert(error.unit);
          }
        }

        if (serviceType === "1") {
          if (loadingUnloadingAddress.length === 0) {
            error.loadingUnloadingAddress =
              "상차지 주소 / 하차지 주소를 입력해 주세요.";
            return alert(error.loadingUnloadingAddress);
          }
        }

        if (agreePrivacy === false) {
          error.agreePrivacy = "개인정보처리방침동의에 체크해 주세요";
          return alert(error.agreePrivacy);
        }

        setIsValid(true);
      }
    },

    validateOnChange: false,
    // enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      if (isValid) {
        setIsSubmit(false);
        const res = await postInquiry(
          serviceType === "5"
            ? brandType
            : serviceType === "6"
            ? insuaranceType
            : serviceType,
          values
        );

        if (res) {
          alert("답변이 작성되면 메일로 알림을 드립니다.");
          resetForm();
          setIsSubmit(true);
          setIsValid(false);
        }
      }
    },
  });

  const onChangeBrandType = (e) => {
    setBrandType(e.target.value);
    formik.resetForm();
  };

  const onChangeInsuaranceType = (e) => {
    setInsuaranceType(e.target.value);
    formik.resetForm();
  };

  const onChangeServiceType = (e) => {
    setServiceType(e.target.value);
    formik.resetForm();
    setIsValid(false);
  };

  const onChangeTabValue = (e, value) => {
    setValue(value);
  };

  useEffect(() => {
    if (value === "1") navigate(routes.inquiry);
    if (value === "3") navigate(routes.faq);
  }, [navigate, value]);

  useEffect(() => {
    if (location.pathname !== "/customer/inquiry") {
      setValue("2");
    }
  }, [location]);

  return (
    <InquiryBG>
      <InquiryWrapper>
        <ISubContent className="sub_contents_title" id="subsectionlink1">
          <TextTitle1 className="title1">Inquiry</TextTitle1>
          <TextTitle2 className="title2">1:1 문의하기</TextTitle2>
          {/* <Border>&nbsp;</Border> */}

          <CenterDiv className="centerdiv">
            <TabContainer style={{ marginBottom: "30px" }}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    id="tabHeader"
                  >
                    <TabList
                      onChange={onChangeTabValue}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="문의하기" value="1" />
                      <Tab label="나의문의내역" value="2" />
                      <Tab label="자주 묻는 질문" value="3" />
                    </TabList>
                  </Box>
                </TabContext>
              </Box>
            </TabContainer>
            <div>
              {value === "1" ? (
                <InquiryCreate
                  serviceType={serviceType}
                  onChangeServiceType={onChangeServiceType}
                  formik={formik}
                  brandType={brandType}
                  onChangeBrandType={onChangeBrandType}
                  insuaranceType={insuaranceType}
                  isSubmit={isSubmit}
                  onChangeInsuaranceType={onChangeInsuaranceType}
                />
              ) : value === "2" ? (
                location.pathname === "/customer/inquiry" ? (
                  <InquiryUserCheck />
                ) : location.pathname === "/customer/inquiry/list" ? (
                  <InquiryUserList />
                ) : (
                  <InquiryUserDetail />
                )
              ) : null}
            </div>
          </CenterDiv>
        </ISubContent>
      </InquiryWrapper>
    </InquiryBG>
  );
};

export default Inquiry;
