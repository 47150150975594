import React, { useState } from "react";
import styled from "styled-components";
import { CenterDiv, SubContent, SubSection } from "../shared";
import { Link } from "react-router-dom";
import routes from "../../routes";

const Section = styled(SubContent)`
  text-align: left;

  li {
    list-style: none;
  }
  .title1,
  .title2 {
    text-align: left;
  }

  .privacy_title {
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: #000;
  }
  .sub_section .privacy_comment {
    font-family: "NanumGothic";
    font-size: 14px;
    line-height: 20px;
    color: #666;
  }

  .sub_section .privacy_box {
    clear: both;
    width: 100%;
    margin: 50px 0;
    .privacy_section_title {
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      color: #000;
    }
    .privacy_section_comment {
      width: 100%;
      font-size: 14px;
      line-height: 25px;
      color: #666;
      padding: 20px;
      margin: 5px 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      ul {
        position: relative;
        padding-left: 17px;
      }
      li {
        position: relative;
        padding-left: 15px;
        line-height: 20px;
        margin: 10px 0;
      }
      li:after {
        position: absolute;
        top: 0;
        left: 0;
      }
      li:nth-child(1):after {
        content: "1.";
      }
      li:nth-child(2):after {
        content: "2.";
      }
      li:nth-child(3):after {
        content: "3.";
      }
      li:nth-child(4):after {
        content: "4.";
      }
    }
  }

  .privacy_box .privacy_section_comment table {
    width: 100%;
    border-top: 1px solid #000;
    margin: 5px 0 0;
    td {
      font-family: "NanumGothic";
      font-size: 14px;
      line-height: 20px;
      color: #666;
      padding: 20px;
      padding: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-width: 0 1px 1px 0;
      :nth-child(1) {
        width: 15%;
        border-left-width: 1px;
      }
      :nth-child(2) {
        width: 85%;
      }
    }
  }
  .list_open {
    cursor: pointer;
  }
  .list_close {
    cursor: pointer;
    display: none;
  }
  .partner_list {
    display: none;
  }
`;

const WmsPrivacy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClickList = () => {
    setIsOpen(!isOpen);
  };
  return (
      <Section className="sub_contents_title" id="subsectionlink1">
        <CenterDiv className="centerdiv">
          <div className="title1">Privacy</div>
          <div className="title2">개인(위치)정보 처리방침</div>

          <SubSection className="sub_section">
            <div className="privacy_title">
              (주)팀프레시 개인정보 및 개인위치정보 처리방침
            </div>
            <div className="privacy_comment">
              (주)팀프레시 (이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립·공개합니다.
            </div>
            <div className="privacy_box">
              <div className="privacy_section_title">
                제1조(개인정보 처리목적)
              </div>
              <div className="privacy_section_comment">
                회사는 다음의 목적을 위하여 개인정보를 처리합니다.
                <br />
                처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                <ul>
                  <li>
                    물류서비스 제공(보관 및 배송접수) 목적으로 개인정보를 처리합니다.
                  </li>
                  <li>
                    고충처리
                    <br />
                    민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지,
                    처리결과 통보 등의 목적으로 개인정보를 처리합니다.
                  </li>
                </ul>
              </div>
            </div>
            <div className="privacy_box">
              <div className="privacy_section_title">
                제2조(개인정보의 처리 및 보유기간)
              </div>
              <div className="privacy_section_comment">
                ① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
                개인정보를 수집시에 동의 받은 개인정보 보유·이용기간 내에서
                개인정보를 처리·보유합니다.
                <br />
                <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                <ul>
                  <li>
                    물류 서비스 제공 : 물류서비스 공급완료 및 요금결제·정산 완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지
                    <br />
                    1) 「전자상거래 등에서의 소비자 보호에 관한 법률」 에 따른
                    표시·광고, 계약내용 및 이행 등 거래에 관한 기록
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 표시, 광고에 관한 기록 : 6월
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 계약 또는 청약철회, 대금결제,
                    재화 등의 공급기록 : 5년
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 소비자 불만 또는 분쟁처리에
                    관한 기록 : 3년
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 물류 서비스 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지
                  </li>
                </ul>
              </div>
            </div>

            <div className="privacy_box">
              <div className="privacy_section_title">
                제3조(개인정보처리 위탁)
              </div>
              <div className="privacy_section_comment">
                ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
                <ul>
                  <table cellPadding="0" cellSpacing="0">
                    <tbody>
                    <tr>
                      <td>위탁받는 자</td>
                      <td>씨제이대한통운㈜. 롯데글로벌로지스㈜, ㈜한진</td>
                    </tr>
                    <tr>
                      <td>위탁업무</td>
                      <td>화물의 운송(택배 업무)</td>
                    </tr>
                    </tbody>
                  </table>
                </ul>
                <br />
                <br />② 회사는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
                <br />③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
              </div>
            </div>
            <div className="privacy_box">
              <div className="privacy_section_title">
                제4조(정보주체의 권리, 의무 및 행사방법)
              </div>
              <div className="privacy_section_comment">
                ① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련
                권리를 행사할 수 있습니다.
                <ul>
                  <li>개인정보 열람요구</li>
                  <li>오류 등이 있을 경우 정정 요구</li>
                  <li>삭제요구</li>
                  <li>처리정지 요구</li>
                </ul>
                ② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편,
                모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이
                조치하겠습니다.
                <br />
                <br />
                ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
                경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를
                이용하거나 제공하지 않습니다.
                <br />
                <br />
                ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은
                자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법
                시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                <br />
                <br />⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가
                처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을
                침해하여서는 아니됩니다.
              </div>
            </div>
            <div className="privacy_box">
              <div className="privacy_section_title">
                제5조(처리하는 개인정보 항목)
              </div>
              <div className="privacy_section_comment">
                회사는 다음의 개인정보 항목을 처리하고 있습니다.
                <ul>
                  <li>
                    물류서비스 제공
                    <br />
                    · 필수항목 : 수령자 정보(성명, 주소, 휴대전화번호) 및 주문자정보(성명)
                    <br />· 선택항목 : 정보주체의 특이사항 기재내용(공동현관 출입방법, 공급사 정보-담당자명,  휴대전화번호, 이메일)
                  </li>
                  <li>
                    회원 정보
                    <br />
                    · 사용자명, 휴대폰번호, 이메일
                  </li>
                </ul>
              </div>
            </div>
            <div className="privacy_box">
              <div className="privacy_section_title">제6조(개인정보의 파기)</div>
              <div className="privacy_section_comment">
                ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
                불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
                <br />
                <br />
                ② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나
                처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
                계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
                데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
                <br />
                <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                <ul>
                  <li>
                    파기절차
                    <br />
                    회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
                    보호책임자의 승인을 받아 개인정보를 파기합니다.
                  </li>
                  <li>
                    파기방법
                    <br />
                    회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할
                    수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여
                    파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄로 분쇄하거나
                    소각하여 파기합니다.
                  </li>
                </ul>
              </div>
            </div>
            <div className="privacy_box">
              <div className="privacy_section_title">
                제7조(개인정보의 안전성 확보조치)
              </div>
              <div className="privacy_section_comment">
                회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
                있습니다.
                <ul>
                  <li>
                    관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등
                  </li>
                  <li>
                    기술적 조치 : 개인정보처리시스템 등의 접근권한 관리,
                    접근통제시스템 설치, 고유식별정보 등의 암호화
                  </li>
                  <li>물리적 조치 : 전산실, 자료보관실 등의 접근통제 </li>
                </ul>
              </div>
            </div>
            <div className="privacy_box">
              <div className="privacy_section_title">
                제8조(개인정보 보호책임자)
              </div>
              <div className="privacy_section_comment">
                ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
                처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
                같이 개인정보 보호책임자를 지정하고 있습니다.
                <ul>
                  <li>
                    개인정보 및 개인위치정보 보호책임자
                    <br />
                    성명 : 서영락
                    <br />
                    직책 : 기술지원실 실장
                    <br />
                    연락처 : 02-423-0529
                    <br />
                    이메일 : youngrag.seo@timf.co.kr
                    <br />
                    팩스번호 : 02-3432-0525
                    <br />※ 개인정보 보호 담당부서로 연결됩니다.
                  </li>
                  <li>
                    개인정보 보호 담당부서
                    <br />
                    부서명 : 인사팀
                    <br />
                    담당자 : 유두곤
                    <br />
                    연락처 : 02-423-0529
                    <br />
                    이메일 : dugon.yoo@timf.co.kr
                    <br />
                    팩스번호 : 02-3432-0525
                    <br />※ 개인정보 보호 담당부서로 연결됩니다.
                  </li>
                </ul>
                <br />
                ② 정보주체께서는 회사의 서비스(또는 사업)를 이용하시면서 발생한
                모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
                개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
                <br />
                회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴
                것입니다.
              </div>
            </div>
            <div className="privacy_box">
              <div className="privacy_section_title">
                제9조(개인정보 열람청구)
              </div>
              <div className="privacy_section_comment">
                정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를
                아래의 부서에 할 수 있습니다.
                <br />
                회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록
                노력하겠습니다.
                <ul>
                  <li>
                    개인정보 열람청구 접수,처리 부서
                    <br />
                    부서명 : 인사팀
                    <br />
                    담당자 : 유두곤
                    <br />
                    연락처 : 02-423-0529
                    <br />
                    이메일 : dugon.yoo@timf.co.kr
                    <br />
                    팩스번호 : 02-3432-0525
                  </li>
                </ul>
              </div>
            </div>
            <div className="privacy_box">
              <div className="privacy_section_title">
                제10조(권익침해 구제방법)
              </div>
              <div className="privacy_section_comment">
                정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
                등을 문의하실 수 있습니다.
                <br />
                아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보
                불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이
                필요하시면 문의하여 주시기 바랍니다.
                <ul>
                  <li>개인정보침해 신고센터: (국번없이) 118 (privacy.kisa.or.kr)</li>
                  <li>개인정보 분쟁조정위원회 : 1833-6972 (www.kopico.go.kr)</li>
                  <li>대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)</li>
                  <li>경찰청 사이버테러대응센터 : 1566-0112 (www.netan.go.kr)</li>
                </ul>
              </div>
            </div>
            <div className="privacy_box">
              <div className="privacy_section_title">
                제12조(개인정보 처리방침 변경)
              </div>
              <div className="privacy_section_comment">
                본 개인정보처리방침을 개정할 경우에는 최소 7일전에 서비스 공지사항 또는 이메일을 통해 변경 및 내용 등을 공지하도록 하겠습니다.
                <br />다만, 이용자의 소중한 권리 또는 의무에 중요한 내용 변경이 발생하는 경우 시행일로부터 최소 30일 전에 공지하도록 하겠습니다
                <br />
                <br />이 개인정보 처리방침은 2024.02.14부터 적용됩니다.
              </div>
            </div>
            {/*<Link to={routes.prevPrivacy} style={{ color: "#0052a2" }}>*/}
            {/*  변경 전 개인(위치)정보 처리방침 보기*/}
            {/*</Link>인*/}
          </SubSection>
        </CenterDiv>
      </Section>
  );
};

export default WmsPrivacy;
