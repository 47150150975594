import { Link } from "react-scroll";
import styled from "styled-components";
import { Arrow, SubImages, SubImgWrapper, ShadowBg } from "../../shared";

const SubImg = styled(SubImgWrapper)`
  background: url(../skin/images/sub_images0203.jpg) no-repeat 50% 50%;
  background-size: cover;
`;

const FranchiseAbout = () => {
  return (
    <SubImages className="subimages subimages0203">
      <div className="sub_images_title">
        외식 창업, 적은 자본으로
        <br />
        빠르고 간편하게 하세요
      </div>
      <Arrow className="sub_images_arrow">
        <Link className="anchorLink" to="sectionFranchise" smooth={true}>
          <i className="xi-angle-down"></i>
        </Link>
      </Arrow>
      <ShadowBg className="shadow_bg">&nbsp;</ShadowBg>
      <SubImg className="subimg subimg0203">&nbsp;</SubImg>
    </SubImages>
  );
};

export default FranchiseAbout;
