import styled from "styled-components";
import Footer from "../common/Footer";
import Header from "../common/Header";
import RecruitBoard from "../Recruit/RecruitBoard";
import RecruitCreate from "../Recruit/RecruitCreate";
import RecruitModify from "../Recruit/RecruitModify";

const AdminContainer = styled.div`
  #header {
    top: 49px;
  }
`;

const AdminRecruit = ({ url }) => {
  return (
    <AdminContainer>
      <Header />
      {url?.adminPath === "recruitCreate" ? (
        <RecruitCreate />
      ) : url?.adminPath === "recruitModify" ? (
        <RecruitModify seq={url?.seq} />
      ) : (
        <RecruitBoard />
      )}
      <Footer />
    </AdminContainer>
  );
};

export default AdminRecruit;
