import { Element, Link } from "react-scroll";
import { SubContent, Arrow2 } from "../../shared";

const FulfillmentIntro = () => {
  return (
    <Element name="sectionFU">
      <SubContent className="sub_contents_title" id="subsectionlink1">
        <div className="title1">Fulfillment</div>
        <div className="title2">풀필먼트</div>
        <div className="title3">
          새벽배송이 연계된 저온 특화
          <br />
          재고관리 및 출고 서비스를 제공하고 있으며,
          <br />
          <br />
          요청에 따라 초기 물류 인프라 구축
          <br />
          컨설팅도 함께 진행하고 있습니다.
        </div>
        <Arrow2 className="sub_images_arrow sub_images_arrow2">
          <Link className="anchorLink" to="sectionFUProcess" smooth={true}>
            <i className="xi-angle-down"></i>
          </Link>
        </Arrow2>
      </SubContent>
    </Element>
  );
};

export default FulfillmentIntro;
