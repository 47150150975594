import styled from "styled-components";
import Footer from "../common/Footer";
import Header from "../common/Header";
import AdminBContent from "./AdminBContent";
import AdminInsuaranceContent from "./AdminInsuaranceContent";

const AdminContainer = styled.div`
  #header {
    top: 49px;
  }
`;

const AdminBusiness = ({ category }) => {
  return (
    <AdminContainer>
      <Header />
      {category === "FRANCHISE" ? (
        <AdminBContent />
      ) : (
        <AdminInsuaranceContent />
      )}
      <Footer />
    </AdminContainer>
  );
};

export default AdminBusiness;
