import React, { useState } from "react";

import { NewsList } from "../shared";
import NewsConfirmModal from "../common/Modal/NewsConfirmModal";
import { useStore } from "../../UserContext";
import styled from "styled-components";

const Container = styled.div`
  @media only screen and (max-width: 480px) {
    .news_arrow,
    .news_body,
    .news_arrow > i {
      font-size: 32px;
    }
    .news_body {
      min-height: 42px;
    }

    .news_date,
    .news_source {
      display: none;
    }
  }
`;

const NewsPageList = ({ list, updateNewsList }) => {
  const { state } = useStore();

  const [open, setOpen] = useState(false);
  const [newsSeq, setNewsSeq] = useState("");

  const handleOpen = (e) => {
    let val = e.target.dataset.name;

    setNewsSeq(val);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <Container>
      {list.map((list, index) =>
        state?.user ? (
          <NewsList
            style={{ width: "100%", height: "100%" }}
            key={list.newsSeq}
            className="news_list"
            data-name={list.newsSeq}
            onClick={handleOpen}
          >
            <div className="news_arrow">
              <i className="xi-arrow-right" data-name={list.newsSeq}></i>
            </div>

            <div className="news_source" data-name={list.newsSeq}>
              {list.author}
            </div>
            <div className="news_date" data-name={list.newsSeq}>
              {list.publishedDate}
            </div>
            <div className="news_body" data-name={list.newsSeq}>
              {list.title}
            </div>
          </NewsList>
        ) : (
          <NewsList
            style={{ width: "100%", height: "100%" }}
            key={list.newsSeq}
            className="news_list"
            data-name={list.newsSeq}
            onClick={() => window.open(`${list.url}`)}
          >
            <div className="news_arrow">
              <i className="xi-arrow-right" data-name={list.newsSeq}></i>
            </div>

            <div className="news_source" data-name={list.newsSeq}>
              {list.author}
            </div>
            <div className="news_date" data-name={list.newsSeq}>
              {list.publishedDate}
            </div>
            <div className="news_body" data-name={list.newsSeq}>
              {list.title}
            </div>
          </NewsList>
        )
      )}

      <NewsConfirmModal
        seq={newsSeq}
        open={open}
        handleClose={handleClose}
        updateNewsList={updateNewsList}
      />
    </Container>
  );
};

export default NewsPageList;
