import { useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";

import { getInquiryDetail, postInquiryReply } from "../../services";
import {
  CenterDiv,
  NavyButton,
  SubContent,
  TextTitle1,
  TextTitle2,
} from "../shared";
import { useDropzone } from "react-dropzone";
import * as Icon from "react-feather";
import axios from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import routes from "../../routes";

const InquiryBG = styled.div`
  background: url("../skin/images/sub_images0402.jpg") no-repeat fixed 50% 50%;
  background-size: cover;
`;

const InquiryWrapper = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .sub_contents_title .centerdiv {
    width: 1000px;
    min-height: 500px;
    background: #fff;
    padding: 50px;
  }
`;

const ISubContent = styled(SubContent)`
  padding: 150px 0 150px;
  text-align: left;
  .title1,
  .title2 {
    color: #fff;
    text-align: center;
  }

  .franchise_comment {
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    color: #000;
  }
`;

const Border = styled.div`
  width: 1px;
  height: 80px;
  background: #fff;
  margin: 0 auto 30px;
`;

const TableContainer = styled.table`
  border-top: 1px solid #979797;
  margin-bottom: 4px;
`;

const TableTop = styled.table`
  text-align: left;

  .b_detail_left {
    color: #000;
    width: 150px;
    height: 35px;
    border-bottom: 1px solid #cccccc;
    font-size: 17px;
    font-family: "Pretendard";
    color: #000;
    font-weight: 300;
    padding: 25px 10px;
  }

  .b_detail_right {
    color: #000;
    background-color: #ffffff;
    border-bottom: 1px solid #cccccc;
    border-left: 1px dotted #cccccc;
    font-size: 17px;
    font-family: "Pretendard";
    color: #666;
    font-weight: 300;
    padding: 25px 10px;
  }
  .ck.ck-content:not(.ck-comment__input *) {
    height: 300px;
    overflow-y: auto;
  }
  .ck.ck-editor__editable_inline {
    border: 1px solid var(--ck-color-base-border);
  }
`;

const TableBottom = styled.table`
  padding: 20px 15px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 5px;
  table-layout: fixed;
  box-sizing: border-box;
  td {
    color: #151515;
    line-height: 150%;
    span {
      font-size: 17px;
      font-family: "Pretendard";
      font-weight: 300;
    }
  }
`;

const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Upload = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const UploadHeader = styled.div`
  width: 100px;
`;

const UploadContents = styled.div`
  border-radius: 5px;
  border: 1px solid rgb(206, 212, 218);
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84%;
`;

const UploadFileList = styled.div`
  width: 67%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
`;

const AdminIDetail = ({ category, seq }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [file, setFile] = useState([]);
  const [boardContent, setBoardContent] = useState({
    title: "",
    contents: "",
  });
  const editorRef = useRef();
  const { CKEditor, DecoupledEditor } = editorRef.current || {};

  const UPLOAD_ENDPOINT = "api/v1/upload";

  const getInquiryList = async () => {
    const res = await getInquiryDetail(seq);

    setCategoryList(res);
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);

            axios
              .post(`/${UPLOAD_ENDPOINT}`, body, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
              })
              .then((res) => {
                resolve({
                  default: res.data,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    const resultFile = acceptedFiles.map((file) =>
      Object.assign(file, {
        url: URL.createObjectURL(file),
      })
    );
    setFile((item) => [...item, ...resultFile]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  const handleCancle = (e) => {
    const { temp } = e.target.dataset;
    const newFiles = file.filter((item) => {
      return item.name !== temp;
    });
    setFile(newFiles);
  };

  const navigate = useNavigate();

  const postReply = async () => {
    const res = await postInquiryReply(parseInt(seq), boardContent.contents);
    if (res && res?.replySeq) {
      navigate(routes.admin, {
        state: {
          category: "INQUIRY",
        },
      });
    } else {
      alert("서버에 이슈가 있습니다.");
    }
  };

  useEffect(() => {
    setEditorLoaded(true);
    getInquiryList();
  }, []);

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      // ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
      DecoupledEditor: require("@ckeditor/ckeditor5-build-decoupled-document"),
    };
  }, []);

  return (
    <InquiryBG>
      <InquiryWrapper>
        <ISubContent className="sub_contents_title" id="subsectionlink1">
          <TextTitle1 className="title1">Inquiry</TextTitle1>
          <TextTitle2 className="title2">문의하기</TextTitle2>
          <Border>&nbsp;</Border>
          <CenterDiv className="centerdiv">
            <div id="mainTextBodyDiv">
              <TableContainer
                border="0"
                cellPadding="0"
                cellSpacing="0"
                width="100%"
              >
                <tbody>
                  <tr>
                    <td>
                      <TableTop
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td className="b_detail_left">ㆍ작성일</td>
                            <td className="b_detail_right">
                              {categoryList?.creDt}
                            </td>
                          </tr>

                          <tr>
                            <td className="b_detail_left">ㆍ글제목</td>
                            <td
                              className="b_detail_right"
                              style={{ position: "relative" }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  right: "150px",
                                  top: "35%",
                                  color: "#000",
                                }}
                              >
                                진행상태:{" "}
                                {categoryList?.answerYn === "Y"
                                  ? "답변완료"
                                  : "접수완료"}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="b_detail_left">ㆍ작성자</td>
                            <td className="b_detail_right">
                              {categoryList?.name}
                            </td>
                          </tr>

                          {category !== "FRANCHISE" ? (
                            <tr>
                              <td className="b_detail_left">ㆍ서비스 유형</td>
                              <td className="b_detail_right">
                                {categoryList?.category}
                              </td>
                            </tr>
                          ) : null}

                          <tr>
                            <td className="b_detail_left">ㆍ휴대폰</td>
                            <td className="b_detail_right">
                              {categoryList?.phone}
                            </td>
                          </tr>

                          {category !== "FRANCHISE" ? (
                            <tr>
                              <td className="b_detail_left">ㆍ이메일</td>
                              <td className="b_detail_right">
                                {categoryList?.email}
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td className="b_detail_left">ㆍ개인정보수집</td>
                            <td className="b_detail_right">
                              {categoryList?.agreePrivacy ? "동의" : "비동의"}
                            </td>
                          </tr>
                        </tbody>
                      </TableTop>
                    </td>
                  </tr>
                </tbody>
              </TableContainer>

              <TableBottom cellPadding="0" cellSpacing="0" width="100%">
                <tbody>
                  <tr>
                    <td>
                      <span>{categoryList?.contents}</span>
                    </td>
                  </tr>
                </tbody>
              </TableBottom>

              <TableContainer
                border="0"
                cellPadding="0"
                cellSpacing="0"
                width="100%"
              >
                <tbody>
                  <tr>
                    <td>
                      <TableTop
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                      >
                        <tbody>
                          <td className="b_detail_left">ㆍ답변</td>
                          <td className="b_detail_right">
                            <div
                              style={{
                                width: "99%",
                                height: "350px",
                                display: "flex",
                                flexDirection: "column-reverse",
                              }}
                              // align="center"
                              // id="editor"
                              // className="toolbar-container"
                            >
                              {editorLoaded ? (
                                <CKEditor
                                  config={{
                                    extraPlugins: [uploadPlugin],
                                    toolbarLocation: "top",
                                  }}
                                  // editor={ClassicEditor}
                                  editor={DecoupledEditor}
                                  data=""
                                  onReady={(editor) => {
                                    console.log(
                                      "Editor is ready to use!",
                                      editor
                                    );

                                    // const toolbarContainer =
                                    //   document.querySelector("#editor");
                                    // toolbarContainer.appendChild(
                                    //   editor.ui.view.toolbar.element
                                    // );

                                    // window.editor = editor;
                                    editor.ui
                                      .getEditableElement()
                                      .parentElement.append(
                                        editor.ui.view.toolbar.element
                                      );
                                  }}
                                  onError={(error, { willEditorRestart }) => {
                                    // If the editor is restarted, the toolbar element will be created once again.
                                    // The `onReady` callback will be called again and the new toolbar will be added.
                                    // This is why you need to remove the older toolbar.
                                    if (willEditorRestart) {
                                      this.editor.ui.view.toolbar.element.remove();
                                    }
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setBoardContent({
                                      ...boardContent,
                                      contents: data,
                                    });
                                  }}
                                />
                              ) : (
                                <div>Editor loading</div>
                              )}
                            </div>
                            {/* <hr style={{ border: "1px solid #cccccc" }} />
                            <UploadContainer>
                              <Upload>
                                <UploadHeader>첨부파일</UploadHeader>

                                <UploadContents
                                  {...getRootProps()}
                                  className="col-md-5"
                                >
                                  <input {...getInputProps()} />
                                  <div>
                                    첨부할 파일을 드래그하여 옮겨주세요.
                                  </div>
                                </UploadContents>
                              </Upload>
                              <UploadFileList>
                                {file.map((file) => (
                                  <div key={file.name}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {file.name}
                                      <Icon.X
                                        className="m-l-5"
                                        data-temp={file.name}
                                        onClick={handleCancle}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </UploadFileList>
                            </UploadContainer> */}
                          </td>
                        </tbody>
                      </TableTop>
                    </td>
                  </tr>
                </tbody>
              </TableContainer>
              <ButtonContainer>
                <NavyButton type="button" onClick={postReply}>
                  저장하기
                </NavyButton>
              </ButtonContainer>
            </div>
          </CenterDiv>
        </ISubContent>
      </InquiryWrapper>
    </InquiryBG>
  );
};

export default AdminIDetail;
