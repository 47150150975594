import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import routes from "../../routes";
import { getFaqSearchItem, getTimfFaqList } from "../../services";
import { useStore } from "../../UserContext";
import Pagination from "../common/Pagination";
import { CenterDiv, CustomerWrapper, CSubContent } from "../shared";
import FaqPageList from "./FaqPageList";

const Container = styled(CustomerWrapper)`
  @media only screen and (max-width: 480px) {
    height: 100%;
    min-height: 180vh;
    padding-top: 300px;
  }
`;

const Title = styled.div`
  text-align: left;
`;

const SubTitle = styled.div`
  text-align: left;
  margin-bottom: 50;
`;

const Service = styled.div`
  display: table;
  width: 100%;
  top: 63px;
  text-align: center;
  margin-bottom: 50px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  @media only screen and (max-width: 480px) {
    top: 180px;
  }
  li {
    position: relative;
    display: inline-block;
    width: 180px;
    font-weight: 300;
    font-size: 17px;
    line-height: 40px;
    background: ${({ theme }) => theme.colors.white};
    color: rgb(2, 32, 75);
    border: 0.5px solid rgb(2, 32, 75);
    font-weight: 600;
    margin: 0 5px;
    cursor: pointer;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    @media only screen and (max-width: 480px) {
      width: 360px;
      font-size: 28px;
      line-height: 60px;
      margin: 10px 3px;
    }
  }
  li.btnActive {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid white;
    background: rgb(2, 32, 75);
  }
`;

const SearchBtn = styled.div`
  :before {
    position: absolute;
    top: 1px;
    left: 0;
    content: "\\e97a";
    font-family: "xeicon";
    font-size: 20px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      font-size: 32px;
    }
  }
`;

const Faq = ({ width100, grid, black, padding0 }) => {
  const { state } = useStore();
  const url = useLocation();

  const [faqList, setFaqList] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [serviceCategory, setServiceCategory] = useState("새벽배송");
  const [totalPage, setTotalPage] = useState(0);
  const [btnActive, setBtnActive] = useState("dawn");
  const services = [
    { id: "dawn", name: "새벽배송" },
    { id: "fullfillment", name: "풀필먼트" },
    { id: "forwarding", name: "화물주선" },
    { id: "ingredient", name: "식자재유통" },
    { id: "franchise", name: "프랜차이즈" },
    { id: "insuarance", name: "보험" },
  ];

  const getFaqList = async () => {
    const res = await getTimfFaqList(currentPage, serviceCategory);
    if (res) {
      setFaqList(res.data);
      setTotalPage(res.totalPages);
    }
  };

  const onClickSearchItem = async () => {
    const { data } = await getFaqSearchItem(searchItem, serviceCategory);
    setFaqList(data);
  };

  const toggleActive = (event) => {
    setBtnActive(event.target.id);
    setSearchItem("");
    setServiceCategory(event.target.innerText);
  };

  useEffect(() => {
    getFaqList();
  }, [currentPage, serviceCategory]);

  return (
    <Container>
      <CSubContent
        className="sub_contents_title"
        id="subsectionlink1"
        style={padding0}
      >
        <CenterDiv className="centerdiv" style={width100}>
          <Title className="title1" style={black}>
            FAQ
          </Title>
          <SubTitle className="title2" style={black}>
            자주 묻는 질문
          </SubTitle>
          <Service>
            <ul style={grid}>
              {services.map((item, idx) => (
                <li
                  key={"service" + idx}
                  id={item.id}
                  style={width100}
                  className={"btn" + (item.id === btnActive ? "Active" : "")}
                  onClick={toggleActive}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </Service>
          <form>
            <div className="search_contents">
              <div className="search_wordbox">
                <ul>
                  <li>
                    <input
                      type="text"
                      value={searchItem}
                      onChange={(e) => setSearchItem(e.target.value)}
                      className="searth_box"
                      placeholder="무엇이든 찾아보세요"
                    />
                    <SearchBtn onClick={onClickSearchItem} />
                  </li>
                </ul>
              </div>
            </div>
          </form>
          {/* {btnActive === "dawn" ? (
            <div>
              <ul>
                <li>새벽배송가능지역</li>
                <li>VOC대처</li>
                <li>주문</li>
                <li>반품</li>
                <li>배송</li>
                <li>송장</li>
                <li>부가서비스</li>
              </ul>
            </div>
          ) : null} */}
          <div id="mainIndexDiv">
            <FaqPageList list={faqList} updateFaqList={getFaqList} />
            <Pagination
              totalPage={totalPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            {state.user ? (
              <div
                id="mainbutton_td"
                style={{ paddingTop: "30px", textAlign: "left" }}
              >
                <Link
                  to={url.pathname === "/admin" ? "#" : routes.faqcreate}
                  state={
                    url.pathname === "/admin"
                      ? { adminPath: "faqCreate" }
                      : null
                  }
                >
                  <img
                    src="../../skin_board/a_standard/b_img/i_button/write.gif"
                    align="absmiddle"
                    border="0"
                    alt=""
                  />
                </Link>
              </div>
            ) : null}
          </div>
        </CenterDiv>
      </CSubContent>
    </Container>
  );
};

export default Faq;
