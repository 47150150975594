import axios from "axios";
import { globalHistory } from "./routes/history";

export const interceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      // 요청 보내기 전 수행할 작업

      return config;
    },
    (error) => {
      // 요청 에러 직전 호출
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      // 200대 response인 경우
      return response;
    },

    (error) => {
      // 200대 이외의 오류 응답인 경우
      if (
        (error.response && error.response.status === 403) ||
        (error.response && error.response.status === 401)
      ) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");

        globalHistory.push("/");
      }
      return Promise.reject(error);
    }
  );
};
