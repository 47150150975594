import styled from "styled-components";

const InterviewBox = styled.div`
  position: fixed;
  top: 200%;
  left: 0;
  width: 100%;
  min-width: 1150px;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;

  .interview_box_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  .relative {
    width: 100%;
    height: 100%;
  }

  .interview_box_contents {
    position: absolute;
    top: 14%;
    left: 20%;
    width: 60%;
    height: 75%;
    background: #fff;
    border: 1px solid #eee;
    z-index: 10;

    .interview_people_img {
      position: relative;
      float: left;
      width: 50%;
      height: 100%;
      overflow: hidden;

      .interview_close {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 30px;
        color: #000;
        z-index: 1;
        transition: all 0.1s ease;
        -webkit-transition: all 0.1s ease;
        cursor: pointer;
        display: none;
      }

      .interview_people_name {
        position: absolute;
        top: 20px;
        right: 20px;
        font-family: "Pretendard";
        text-align: right;
        font-weight: 600;
        font-size: 17px;
        line-height: 30px;
        color: #000;
        z-index: 1;

        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #333;
        }
      }

      img {
        width: 100%;
      }
    }

    .interview_comment {
      float: right;
      width: 50%;
      height: 100%;
      border: 20px solid #fff;
      padding: 0 20px 0 0;
      box-sizing: border-box;
      overflow: auto;
      .interview_comment_Q {
        position: relative;
        width: 100%;
        text-align: left;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #000;
        padding-left: 20px;
        box-sizing: border-box;
        :before {
          position: absolute;
          top: 0;
          left: 0;
          content: "Q.";
        }
      }
      .interview_comment_A {
        position: relative;
        width: 100%;
        text-align: left;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
        color: #666;
        padding-top: 5px;
        padding-left: 20px;
        padding-bottom: 30px;
        box-sizing: border-box;
        :before {
          position: absolute;
          top: 5px;
          left: 2px;
          content: "A.";
          font-weight: 300;
          font-size: 16px;
        }
      }
    }
  }
`;

const ModalSA = ({ isOpen, onhandleClose }) => {
  return (
    <InterviewBox className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              엄성렬
              <br />
              <span>
                기술본부,
                <br />
                아키텍처팀(SA)
                <br />
                팀장
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img02.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              아키텍처팀은 전사 관리체제 차원에서의 기술 트렌드 파악 및
              프로젝트의 기술적인 의사 결정을 담당하는 팀입니다.
              <br />전 데브옵스 엔지니어링(DevOps Engineering)을 담당하고
              있습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              저는 팀프레시가 초기 온프레미스(on-premise) 환경의 모놀리식
              아키텍처(Monolithic Architecture,MA) 시스템에서 클라우드 환경
              MSA(MicroService Architecture)에 오기까지의 시스템을
              구축하기까지의 과정을 처음부터 함께했습니다.
              <br />
              이러한 과정 속에서는 많은 변수들이 존재할 수 밖에 없는데요.
              <br />
              팀프레시가 성장함에 따라 생기는 ‘대용량 트래픽 관리 및 개선
              관리’와 같은 도전적인 과제들은 아키텍처적인 관점과 방법론에 대한
              부분을 조금 더 고민하게 했고, 이러한 고민들은 결국 새로운 기술
              스택들의 도입으로 인해 불안정해진 시스템 환경을 개선할 수 있게
              하는 힘이 되었습니다.
              <br />
              팀프레시가 새로운 기술 도입에 대한 도전과 지원을 아끼지 않는
              회사이기에 가능했다고 생각합니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’에서 이루고 싶은 개인적인 목표가 있나요?
            </div>
            <div className="interview_comment_A">
              ‘팀프레시’를 물류 IT 기업으로 거듭날 수 있게 하고 싶어요.
              <br />
              현재 팀프레시 생태계에 속해진 모든 물류 시스템을 구축하는 것을
              목표로 하고 있는데, 이를 이루기 위해 새로운 기술 도입을 늘
              고민하고 있습니다.
              <br />
              기술 도입을 위해서는 많은 공부와 기술 숙달의 과정뿐만 아니라,
              팀원들의 역할들도 중요해진답니다.
              <br />
              그래서 요즘은 또 팀장으로서 팀원들의 개발 생산성 향상에도 주력하고
              있어요.
              <br />
              애자일 조직 문화를 통한 협업 프로세스 개선이 반영된 ‘개발 문화’의
              사례를 만드는게 또 다른 목표예요.
              <br />
              저는 개발과 운영을 함께할 수 있는 개발자가 되고 싶거든요. (웃음)
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’는 주니어 개발자들이 일하기 좋은 기업인가요?
            </div>
            <div className="interview_comment_A">
              호불호가 갈릴 것 같다고 생각해요.
              <br />
              사실대로 말하면 초기 소수 개발 조직에서 지금은 시스템이 많이
              증가하고 조직 규모도 확장되는 과도기로, 마냥 주니어가 일하기 좋은
              기업은 아니예요.
              <br />
              과도기인만큼 업무강도도 높으며, 새벽대응도 해야 할 수도 있거든요.
              <br />
              시니어가 많지 않기 때문에 사수 개념을 기대하면 안되고요.
              <br />
              다만 기업의 비전이 뛰어나고 급성장하는 흐름에 있기 때문에, 스스로
              성장하기를 추구하고 설득력이 뛰어난 주니어라면 매우 일하기 좋은
              시너지가 있을 것이고, 추후 거대해진 조직에서 핵심 인력으로써 많은
              권한과 책임을 가지고 이상적인 조직을 만들어 보기를 꿈꾼다면
              추천합니다!
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              자율적이고 의욕적인 분위기를 가진 팀이라고 생각합니다.
              <br />
              매일 아침에 10시에 스탠딩 미팅을 하면서 서로에 대한 일정 및
              특이사항을 공유 후에 업무를 시작하는데요.
              <br />
              연령층이 높진 않아 다들 서로 도우면서 으쌰 으쌰 하는 분위기라고
              보시면 돼요!
              <br />
              서로 코드 및 정보에 대한 공유를 confluence를 통하여 문서화하고
              슬랙을 통해 개발 지식에 대한 공유를 하며 토론을 많이 하고 또
              내부적으로 스터디도 많이 진행하여 개인이 성장할 수 있는 환경을
              제공합니다!
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              공유하는 것을 좋아하는 동료, 불편한 것을 못 참아 개선하는 것을
              좋아하는 동료, 소통을 활발히 하는 동료.(항상 개발 공부에 매진하는
              꾸준함은 기본이 된 듯합니다!)
            </div>
            <div className="interview_comment_Q">
              마지막으로, 한 말씀 부탁드립니다!
            </div>
            <div className="interview_comment_A">
              팀프레시 개발조직에 관한 정보가 많이 부족하다는 피드백이 있었는데
              이번 기회에 조금이라도 전달이 되었으면 좋겠습니다.
              <br />
              저희와 함께 성장하며 재밌게 일하실 분은 언제나 환영입니다!
              감사합니다!
            </div>
          </div>
        </div>
      </div>
    </InterviewBox>
  );
};

export default ModalSA;
