import { Link } from "react-scroll";
import styled from "styled-components";
import { Arrow2, SubImages, SubImgWrapper, ShadowBg } from "../../shared";

const InsuranceAbout = () => {
  const SubImagesBlack = styled(SubImages)`
    .sub_images_title_normal {
      font-weight: 300;
      color: rgb(0, 0, 0);
    }
    .sub_images_title_bold {
      color: rgb(0, 0, 0);
    }
  `;

  const SubImg = styled(SubImgWrapper)`
    background: url(../skin/images/sub_images0205.jpg) no-repeat 50% 50%;
    background-size: cover;
  `;

  return (
    <SubImagesBlack className="subimages subimages0205">
      <div className="sub_images_title">
        <span className="sub_images_title_normal">우리는</span>
        <br />
        <div>
          <span className="sub_images_title_bold">고객사와 보험사를</span>
          <span className="sub_images_title_normal">잇는</span>
        </div>
        <span>
          <span className="sub_images_title_bold"> 팀프인슈어런스</span>
          <span className="sub_images_title_normal">입니다</span>
        </span>
        <br />
      </div>
      <Arrow2 className="sub_images_arrow">
        <Link className="anchorLink" to="sectionInsurance" smooth={true}>
          <i className="xi-angle-down"></i>
        </Link>
      </Arrow2>
      <ShadowBg className="shadow_bg">&nbsp;</ShadowBg>
      <SubImg className="subimg subimg0205">&nbsp;</SubImg>
    </SubImagesBlack>
  );
};

export default InsuranceAbout;
