const ModalIT = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              최민우
              <br />
              <span>
                기술본부,
                <br />
                인프라팀(IT)
                <br />
                팀장
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img03.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              안녕하세요.
              <br />
              팀프레시 IT팀 최민우라고 해요.
              <br />
              IT팀은 전사 네트워크 및 서버 구축 / 유지보수 그리고 장애처리
              업무를 담당하고 있습니다. 이를 통해 임직원 및 고객에게 원활한 IT
              서비스를 제공하도록 노력하고 있어요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              이제 팀프레시에 입사한 지 대략 1년 정도 됐는데 기술적으로 많이
              성장하고 있다고 느껴요.
              <br />
              단순히 온프레미스 환경에 그치지 않고 클라우드 플랫폼과 관련된
              프로젝트를 통해 폭넓은 기술 역량을 쌓고 있는 중이에요.
              <br />
              이런 다양한 프로젝트 경험은 기술적인 커리어 성장에 도움을 준다
              생각해요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’에서 이루고 싶은 개인적인 목표가 있나요?
            </div>
            <div className="interview_comment_A">
              인프라 엔지니어로서 가능한 많은 기술 스택들을 경험하고 실무에
              적용하고 싶어요.
              <br />
              그리고 기술적 성장뿐만 아니라 리더로서 팀원들이 회사에 잘 적응하고
              성장할 수 있도록 매니징 능력도 키우고 싶습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’는 주니어들이 일하기 좋은 기업인가요?
            </div>
            <div className="interview_comment_A">
              개인의 가치관에 따라 다르겠지만 저의 경우 회사를 선택할 때 기술적
              발전과 분위기를 중요하게 생각해요.
              <br />
              열정적으로 일하는 분위기 안에서 기술적인 경험과 역량을 쌓고 싶은
              주니어들에게는 좋은 기업이라 믿어 의심치 않아요.
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              제가 생각하는 기술본부 분위기를 대표하는 3가지 단어는 '열정,
              책임감, 젊음' 이라고 표현하고 싶어요.
              <br />
              책임감 강하고 열정있는 20-30대 임직원들이 함께 성장하는 젊은
              팀이에요.
              <br />
              단순히 나이만이 아니라 사고방식이 젊은 팀이라 생각해요.
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              해당 직무에 흥미있는 사람과 같이 일하고 싶어요.
              <br />
              능동적으로 궁금한 점이 있으면 스스로 배워가며 메꾸어가려고
              노력하는 사람이 향후 발전 가능성이 높은 사람이라고 생각하거든요.
              <br />
              그리고 급변하는 IT 환경에서 새로운 트랜드를 파악하는 것은 인프라
              엔지니어로서 필수라고 생각해요.
            </div>
            <div className="interview_comment_Q">
              마지막으로, 한 말씀 부탁드립니다!
            </div>
            <div className="interview_comment_A">
              팀프레시는 빠르게 성장 중인 기업인만큼 현재 다양한 IT 프로젝트들이
              진행 중에 있어요. 물류센터/사무실 네트워크 구축, IDC 이전 등이
              있겠네요!
              <br />
              이러한 배움의 환경에서 끊임없이 기술적 역량을 쌓고 싶으신 분에게
              팀프레시 IT팀은 최고의 일터라고 생각해요.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalIT;
