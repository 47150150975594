import styled from "styled-components";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Notice from "../Customer/Notice";
import NoticeCreate from "../Customer/NoticeCreate";
import NoticeDetail from "../Customer/NoticeDetail";
import NoticeModify from "../Customer/NoticeModify";

const AdminContainer = styled.div`
  #header {
    top: 49px;
  }
`;

const AdminNoti = ({ url }) => {
  return (
    <AdminContainer>
      <Header />
      {url?.adminPath === "noticeCreate" ? (
        <NoticeCreate />
      ) : url?.adminPath === "noticeModify" ? (
        <NoticeModify seq={url?.seq} />
      ) : url?.adminPath === "noticeDetail" ? (
        <NoticeDetail seq={url?.seq} />
      ) : (
        <Notice />
      )}

      <Footer />
    </AdminContainer>
  );
};

export default AdminNoti;
