import { Element, Link } from "react-scroll";
import { SubContent, Arrow2 } from "../../shared";

const InsuranceIntro = () => {
  return (
    <Element name="sectionInsurance">
      <SubContent className="sub_contents_title" id="subsectionlink1">
        <div className="title1">Insurance</div>
        <div className="title2">보험 서비스</div>
        <div className="title3">
          팀프인슈어런스는 기업보험 전문 컨설팅업체입니다.
          <br />
          기업운영에서 발생할 수 있는 리스크의 전 분야에 대한 보험을 취급합니다.
          <br />
          약 250여개 주요 거래처 및 파트너사에 기업보험 전문컨설팅을 제공하고
          있습니다.
          <br />
          주요 거래처 및 파트너사에 물류 전문 패키지 보험 컨설팅을 제공하고
          있습니다.
        </div>
        <Arrow2 className="sub_images_arrow sub_images_arrow2">
          <Link className="anchorLink" to="sectionIDesc" smooth={true}>
            <i className="xi-angle-down"></i>
          </Link>
        </Arrow2>
      </SubContent>
    </Element>
  );
};

export default InsuranceIntro;
