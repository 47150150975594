import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import styled from "styled-components";
import { Form, Input100, WhiteButton } from "../../shared";
import { postMyInquiry } from "../../../services";
import routes from "../../../routes";
const CheckUserForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormHeader = styled.h2`
  margin-bottom: 50px;
  text-spacing: 5px;
  letter-spacing: 1px;
`;

const Label = styled.label`
  color: rgb(2, 10, 95);
  font-weight: 600;
`;
const InputContainer = styled.div`
  padding: 10px;
`;

const Input = styled(Input100)`
  color: rgb(2, 10, 95);
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 5px;
`;

const Button = styled(WhiteButton)`
  margin-top: 20px;
`;
const InquiryUserCheck = () => {
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validate: (values) => {
      const { email, password } = values;
      if (email === "") {
        return alert("이메일을 입력해주세요.");
      }
      if (password === "") {
        return alert("비밀번호를 입력해주세요.");
      }
    },
    validateOnChange: false,
    // enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const res = await postMyInquiry(values, 1);
      if (res && res.data.length > 0) {
        // 나의문의내역보기
        navigate(routes.inquiryList, {
          state: { res: res.data, values, totalPages: res.totalPages },
        });
      } else {
        alert("등록된 문의내역이 없습니다.");
      }
    },
  });

  return (
    <div>
      <FormHeader>
        문의 접수 시에 등록한 이메일/비밀번호를 입력하세요.
      </FormHeader>
      <CheckUserForm onSubmit={formik.handleSubmit}>
        <InputContainer>
          <Label htmlFor="uname">이메일</Label>
          <Input
            type="text"
            name="email"
            value={formik.values.email}
            placeholder="예시)min-jae.park@timf.co.kr"
            onChange={formik.handleChange}
          />
          <Label htmlFor="uname">패스워드</Label>
          <Input
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
        </InputContainer>

        <div>
          <Button type="submit">확인</Button>
        </div>
      </CheckUserForm>
    </div>
  );
};

export default InquiryUserCheck;
