import styled from "styled-components";
import { SectionMain } from "../shared";

const MainIntroduce = styled(SectionMain)`
  width: 100%;
  padding: 200px 0;
  background: ${({ theme }) => theme.colors.bgGray};
  overflow: hidden;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  color: ${({ theme }) => theme.colors.black};
`;

const Introduce = () => {
  return (
    <MainIntroduce className="main_introduce">
      안전하고 신속하게 고객에 먹거리를 제공하고 있습니다.
      <br />
      국내 유일 Door to Door Cold Chain Service, 팀프레시를 만나보세요.
    </MainIntroduce>
  );
};

export default Introduce;
