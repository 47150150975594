const ModalBC = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              서세진
              <br />
              <span>
                유통본부,
                <br />
                B2B영업팀(BC)
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img10.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              안녕하세요!
              <br />
              팀프레시 유통운영본부에서 B2B식자재 영업 직무를 수행하고 있는
              서세진입니다.
              <br />
              제가 속한 BC 팀은 (B2B Commerce) 급식/외식 등을 포함한 다양한 기업
              단위의 거래처에 식자재 및 소모품 등을 공급하는 서비스를 제공하고
              있습니다.
              <br />
              거래처 키맨과 직접 소통하며 니즈를 파악하고, 경쟁력 있는 단가와
              맞춤 물류 서비스를 제공함으로써 지속적인 유대관계를 형성합니다.
              <br />
              본부의 매출을 책임지는 중추적인 역할을 하는 팀이라고도 할 수 있죠!
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              팀프레시는 ‘내가 가진 역량을 나만의 색으로 실현할 수 있는
              곳’이라고 생각해요.
              <br />
              제가 그림을 그리고 있으면, 더 예쁜 그림을 그릴 수 있도록 함께
              피드백 해주고 지원해주거든요.
              <br />
              이러한 점에서 양질의 커리어 성장을 할 수 있는 곳이라고 표현하고
              싶어요. 영업 직무는 본인만의 영업 방식을 찾고, 이를 적절히
              활용하는 것이 중요하다고 생각해요.
              <br />
              그리고 이를 찾기 위해서는 다방면으로 도전해보고 부딪히는 과정이
              필요하구요.
              <br />
              이러한 관점에서 팀프레시는 도전을 지향하고, 그 기회가 정말 활짝
              열린 곳이기에, 개인이 성장할 수 있는 기회가 무한한 곳이라고
              생각합니다.
              <br />
              저 같은 경우, 신규 거래처 미팅 전에 항상 해당 기업과 관련된 기사나
              운영 현황 등을 꼼꼼히 공부해가는 편이에요.
              <br />
              그러다보니 주도적으로 대화를 이어 나갈 수 있었고, 긍정적인 첫
              인상을 줄 수 있었어요.
              <br />
              또한, 뻔하고 정형화된 제안서가 아닌, 고객사의 이미지를 살린
              디자인과 고객사 맞춤 제안서 제작하여 전달하여 적극성을 어필하기도
              하구요. 그 결과, 지난해에는 팀 내 신규영업 매출 1위를 달성하기도
              하구, 올해는 신규 영업 책임자가 되어 매출 향상 이끌어 나가고
              있답니다.
            </div>
            <div className="interview_comment_Q">
              3. ‘팀프레시’였기에 가능했던 일이 있었을까요?
            </div>
            <div className="interview_comment_A">
              ‘B2B 식자재 새벽배송’이라고 들어보셨나요?
              <br />
              지난해에 시작하여 성공적으로 활용하고 있는 신규 서비스랍니다!
              <br />
              하지만 이 서비스도 처음엔 ‘팀프레시가 새벽배송으로 성장한
              회사이니까 택배로 식자재를 보내면 시너지 효과를 낼 수 있지
              않을까?’ 라는 호기심에 불과했어요.
              <br />
              그러나 구성원들과 자유롭게 호기심을 발전시킨 결과, 더 많은
              고객사들이 팀프레시를 경험할 수 있고, 물류 효율 또한 낼 수 있는
              성공적인 서비스 모델로 재탄생하게 되었어요.
              <br />
              이처럼 저희 본부가 55조원 규모의 B2B 식자재 시장에서 매년 3배 이상
              성장할 수 있었던 이유는 레드오션 속의 블루오션을 찾고, 빠르게
              개척하기 때문이라 생각해요.
              <br />
              식자재는 가까운 거리의 마트, 심지어는 핸드폰 터치 몇 번으로도 쉽게
              구입할 수 있기 때문에 수많은 경쟁사들이 ‘가격’으로 경쟁을 하는
              것이 특징입니다.
              <br />
              하지만, 팀프레시는 단가는 물론, 그 이상의 새로운 것을 제공하기
              위해 함께 고민하고 도전하고 있어요.
              <br />
              팀프레시었기에 가능했던 B2B 식자재 새벽배송 서비스처럼, 앞으로도
              이 세상에 없었던 신선하고 기발한 서비스를 선보일 예정이니까
              기대해주세요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’에서 이루고 싶은 개인적인 목표가 있나요?
            </div>
            <div className="interview_comment_A">
              단기적으로는 제가 하고 있는 직무인 ‘영업 전문가’가 되는 것입니다.
              <br />
              부끄럽지만, 올해 제 목표는 ‘Amazing sales woman’ 으로 인정받는
              것이거든요.(웃음)
              <br />
              신년 발표회에서도 당당하게 외쳐 버렸기에 달성해야만 하는 목표죠.
              앞으로 꾸준히 시장을 바라보는 눈을 기르고, 직무 전문성과 노하우를
              갖춘 뒤에 공유할 수 있는 든든한 리더가 되고 싶어요.
              <br />
              팀원들과 복닥복닥하게 소통하고, 회사의 성장에 지속적으로
              이바지하는 팀을 꾸리고 싶습니다.장기적으로는 어떤 조직에서든 ‘믿고
              맡길 수 있는 사람’이 되는 것이에요.
              <br />
              이를 위해서는 회사 내외부의 신뢰를 얻는 것이 선행되어야한다고
              생각하구요.
              <br />
              너무 러프하게 들릴 수도 있지만, 고객사, 유관부서, 팀원 등 다양한
              이해관계자들과 소통해야하는 직무이기에 꾸준한 관심과 노력이 필요한
              부분인 것 같아요.
              <br />
              항상 잘해왔더라도 한 번 실수하면 깨지는 것이 신뢰이기도 하니까요.
              <br />한 문장으로 요약하면 ‘일 잘하고, 성과 잘 내고, 믿고 맡길 수
              있는 사람, 좋은 조직원!’
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’는 주니어들이 일하기 좋은 기업인가요?
            </div>
            <div className="interview_comment_A">
              저는 무조건 Yes !<br />
              팀프레시는 18년 설립 이래 매출 36배 상승하며 전례없이 빠르게
              성장하고 있어요.
              <br />
              빠른 성장에도 불구하고 지속적인 성장을 유지하고 있는 이유는 각
              팀의 리더의 역량과 탄탄한 팀 구조문화라고 생각해요.
              <br />
              이처럼 회사가 빠르게 성장하고 있는 만큼 주니어들 또한 빠르게
              성장할 수 있는 기업이라고 생각합니다.
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              팀프레시 전사가 ‘님’ 문화를 사용하고 있기 때문에 경력과 나이에
              상관없이 수평적인 문화를 가지고 있는 것이 특징이에요.
              <br />
              따라서 표현이 자유롭고, 모르는 것이나 궁금한 것을 자유롭게
              물어보고 배울 수 있답니다.
              <br />
              특히 제가 속한 BC팀은 전공도 다양하고 연령대도 다양하지만 그 어느
              팀보다도 더 수평적인 것 같아요.
              <br />
              저는 가끔 제 옆사람이 저보다 3살 많다는 것을 까먹곤 하거든요.
              헤헤. 그만큼 유쾌하고 편안한 분위기의 팀이랍니다 !
            </div>
            <div className="interview_comment_Q">본인에게 ‘팀프레시’란? </div>
            <div className="interview_comment_A">
              ’핫식스?’ 저에게 팀프레시는 일상을 열정적으로 보낼 수 있는 원동력
              같아요.
              <br />
              입사 이후부터 일이 지루하다는 것을 느껴보지 못했을 정도로요!
              <br />
              회사가 빠르게 성장하고 늘 새로운 것에 도전하는 것처럼, 저 또한
              언제부터인지 문제를 다양한 관점으로 바라보고 신선한 결과물을
              도출하기 위해 고민하고 있더라구요.
              <br />
              더 큰 성과를 위해 프로젝트를 직접 기획하고 추진해 나가기도 하구요.
              <br />
              그럴 때마다 ‘나 팀프인 다 됐네~’ 생각하기도 한답니다. (웃음)
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              BC팀은 본부의 목표 매출을 달성하고, 매출처의 컨디션을 가장 잘 알고
              있어야 하며, 그들의 니즈에 맞는 서비스를 기획하여 제공하는
              팀입니다.
              <br />
              따라서 요청을 하는 경우도 많고, 요청을 받는 일도 많아요.
              <br />
              유연한 소통력과 긍정적인 마인드를 가진 분이면 너무 좋을 것 같네요!
              <br />
              또한 변화가 잦을 수 밖에 없는 조직이기에 변화에 긍정적이며, 틀에
              박힌 사고보다는 자유롭게 사고하고 표현할 수 있는 분이라면 더할
              나위 없겠어요!
            </div>
            <div className="interview_comment_Q">
              마지막으로, 한 말씀 부탁드립니다!
            </div>
            <div className="interview_comment_A">
              팀프레시의 조직원으로서 눈에 보이는 매출, 성장률 모두 유의미한
              성과이지만, 가장 의미 있는 것은 회사, 고객, 나 자신 모두가 함께
              성장하고 있다는 점이에요.
              <br />
              앞서 말씀드렸듯이 주도적으로 참여할 수 있는 기회가 많이 주어지고,
              그 기회를 스스로 만들어 실현할 수도 있는 곳이기 때문에, 그동안
              몰랐던 자신만의 장점을 발견할 수도 있고, 내가 원하는 커리어를 직접
              만들어 갈 수 있답니다.
              <br />
              아! 무엇보다도 'BC팀'의 일원이 된다면 더 즐거운 직장생활을 하실 수
              있을 것으로 확신합니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBC;
