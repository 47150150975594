import { Element } from "react-scroll";
import styled from "styled-components";
import { CenterDiv, SubSection, SubTitle } from "../../shared";

const Section17 = styled(SubSection)`
  background: url(../skin/body/sub020101/process_bg.jpg) fixed no-repeat center;
  background-size: cover;
  padding: 0;

  .sub_section1_bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 90%);
    padding: 150px 0;
  }

  .section_process1 {
    clear: both;
    display: table;
    width: 100%;
    margin: 50px 0 0 0;
  }
`;

const TransportationProcess = () => {
  return (
    <Element name="sectionTProcess">
      <Section17 className="sub_section sub_section1">
        <div className="sub_section1_bg">
          <CenterDiv className="centerdiv">
            <SubTitle className="sub_title">
              <div className="sub_title_eng">Process</div>
              프로세스
              <div className="sub_title_comment">
                TIMF Network DB를 통해 고객사의 요구조건에 적합한
                <br />
                배송매니저를 매칭하여 차량 수급 및 섭외를 진행합니다.
              </div>
            </SubTitle>
            <div className="section_process1">
              <img src="../skin/body/sub020103/process.png" alt="" />
            </div>
          </CenterDiv>
        </div>
      </Section17>
    </Element>
  );
};

export default TransportationProcess;
