const ModalTS = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              서형원
              <br />
              <span>
                물류본부,
                <br />
                새벽배송팀(TS)
                <br />
                팀장
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img07.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              새벽배송팀은 당일 입고 및 출고를 원칙으로 상품을 오전7시까지
              고객에게 배송하는 확정배송 서비스를 제공하며, 지역별로 주요
              거점들을 활용하여 고객사와 고객에게 다양한 배송 서비스와 고객의
              니즈에 맞게 만족도향상을 목표로 업무를 진행하고 있습니다.
              <br />
              저는 앞서 말씀드린 목표에 있어 팀원이 목표를 달성할 수 있도록
              방향성 설정 및 코칭 업무를 수행하고 있습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움 주는 점은?
            </div>
            <div className="interview_comment_A">
              4년 전, 저는 물류라는 단어조차 생소할 정도로 물류에 대한 지식이
              없었어요.
              <br />
              신입 레벨부터 시작해서 지금의 팀장까지 수많은 배움과 성장의 시간을
              집중할 수 있었던 건, 구성원 누구나 제한 없는 성장기회를 마련해준
              조직의 문화가 있었기 때문이라고 생각합니다.
              <br />
              팀프레시에서는 자유롭고 주도적인 참여 및 토론이 활성화되어 있는데,
              이를 통해 구성원들과 계속해서 함께 성장하였으면 좋겠습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’여서 TS에서 가능했던 업무는?
            </div>
            <div className="interview_comment_A">
              지난해, 전체 유통 시장의 40%에 가까운 수치로 이커머스 시장이
              확대되고 앞으로도 시장의 확대가 꾸준히 이어질 전망인 것을 보았을
              때 전체 적인 각 사업부의 업무량도 엄청나게 증가됐었어요. 이때 팀의
              구성원들이 버텨 낼 수 있을 까 하는 고민이 많았었는데, 팀프레시의
              물류 인프라와 TMS로 물류서비스의 효율성을 증대시킬 수 있었습니다.
              이게 현재까지 성장하게 된 원인 인 것 같아요. 또, 팀프레시만의
              배송매니저 관리시스템을 통해 안정적인 입/출고 관리의 기반을 만들
              수 있었다고 생각해요.
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              나이를 떠나서 적극적이고 활발하며 자신감 넘치는 성향의 문화를
              가졌다고 생각합니다. 힘든 일이 있을 때는 서로 독려하고 누구든
              나서서 도와주려 하는 분위기예요.
              <br />
              취향, 식성, 가치관까지 비슷한 이런 팀을 또 어디서 만날 수 있을까
              생각하게 되는 팀이랄까요?
              <br />
              그냥 간단히 말해서 ‘팀원 이스 마이 라이프’ 라고 생각 합니다! 우리
              함께 성장해요!
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              성공할 수 있다는 자신감으로 뭉친 긍정적인 마인드와 도전 의지가
              강하게 있는 사람과 같이 일을 하고 싶어요.
              <br />
              인생은 살아가다 보면 내가 원하는 방향과 반대로 흘러갈때도 있지만
              그 또한 배움의 시간이라 생각해요.
              <br />
              발전은 수많은 시행착오를 겪으며 조금 더 나은 방향성으로 나아갈때
              찾아오니까요.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalTS;
