import { Element, Link } from "react-scroll";
import { SubContent, Arrow2 } from "../../shared";

const DistributionIntro = () => {
  return (
    <Element name="sectionDistribution">
      <SubContent className="sub_contents_title" id="subsectionlink1">
        <div className="title1">Distribution</div>
        <div className="title2">식자재 유통</div>
        <div className="title3">
          비즈니스 모델에 적합한 식자재 큐레이션을
          <br />
          제공하는 서비스로,
          <br />
          <br />
          고객사 맞춤형 구매를 통해 운반 전 과정에서
          <br />
          최첨단 콜드체인 시스템으로 신선도를 지키고 있습니다.
        </div>
        <Arrow2 className="sub_images_arrow sub_images_arrow2">
          <Link className="anchorLink" to="sectionDProcess" smooth={true}>
            <i className="xi-angle-down"></i>
          </Link>
        </Arrow2>
      </SubContent>
    </Element>
  );
};

export default DistributionIntro;
