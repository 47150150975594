import { Link } from "react-scroll";
import styled from "styled-components";
import { Arrow, SubImages, SubImgWrapper, ShadowBg } from "../shared";

const SubImg = styled(SubImgWrapper)`
  background: url(../skin/images/sub_images0301.jpg) no-repeat 50% 50%;
  background-size: cover;
`;

const ShadowBgBlack = styled(ShadowBg)`
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @media only screen and (max-width: 480px) {
    height: 180vh;
  }
`;

const RecruitAbout = () => {
  return (
    <SubImages className="subimages subimages0301">
      <ShadowBgBlack className="subimages_bg subimages_bg_black">
        <div className="sub_images_title">
          신선함을 잇기 위해
          <br />
          오늘도 우리는 존재합니다
        </div>
        <Arrow className="sub_images_arrow">
          <Link className="anchorLink" to="sectionRecruit" smooth={true}>
            <i className="xi-angle-down"></i>
          </Link>
        </Arrow>
        <ShadowBg className="shadow_bg">&nbsp;</ShadowBg>
      </ShadowBgBlack>
      <SubImg className="subimg subimg0301">&nbsp;</SubImg>
    </SubImages>
  );
};

export default RecruitAbout;
