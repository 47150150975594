import styled from "styled-components";
import Footer from "../common/Footer";
import Header from "../common/Header";
import AdminIDetail from "./AdminIDetail";

const AdminContainer = styled.div`
  #header {
    top: 49px;
  }
`;

const AdminIContentDetail = ({ category, seq }) => {
  return (
    <AdminContainer>
      <Header />
      <AdminIDetail category={category} seq={seq} />
      <Footer />
    </AdminContainer>
  );
};

export default AdminIContentDetail;
