const ModalSP2 = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              이도의
              <br />
              <span>
                물류본부,
                <br />
                영업기획2팀(SP2)
                <br />
                팀장
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img05.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              영업기획팀은 고객사의 니즈를 파악하고 이에 맞는 팀프레시의
              서비스를 판매하는 역할을 하고 있습니다.
              <br />
              단순히 영업적인 활동이 아닌 고객사의 니즈를 충족시키기 위하여
              커스터마이징, 서비스와 서비스 간의 연계, 신규 사업군 개발 등
              기획적인 업무를 진행하고 있기 때문에 일반적인 영업직군과는 다소
              차이가 있고 보다 많은 기회와 경험을 할 수 있는 것 같네요.(웃음)
              <br />
              영업기획팀에서 저의 궁극적인 역할은 ‘방향성을 잡아주는 사람’ 인 것
              같아요.
              <br />
              팀의 관점에서 보면, 영업기획팀에 속해있는 개개인이 원하는 목표와
              성장을 할 수 있도록 방향성을 설정하고, 전사적인 관점에서 보면
              회사의 매출액 증대와 더불어 손해액을 최소화하기 위하여 방향성을
              잡아야하기 때문이에요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              팀프레시가 커리어에 도움을 주는 점은 단기, 중기, 장기 세가지
              측면으로 설명드리는게 쉬울 것 같아요.
              <br />
              (제가 느끼기에도 해당 부분이 커리어측면에서 가장 좋을 거라는
              생각이 드네요) <br />
              단기적으로, 콜드체인 물류 산업을 누구보다 빠르고 명확하게 배울 수
              있다는 점이에요.
              <br />
              식품 산업과 라스트 마일에 대한 니즈가 점점 강해지면서, 물류 산업도
              식품 산업에 대응하기 위하여 다양한 기업들이 끊임없이 투자를
              진행하고 있어요.
              <br />
              이러한 투자를 진행하는 과정속에서 팀프레시는 다른 기업보다 빠른
              대응으로 해당 시장에 선점을 하였고, 그로 인하여 더 많은 인프라와
              경험을 갖추었기 때문에 콜드체인 물류 전문가로 가는 가장 빠른
              지름길이라고 생각이 들어요.
              <br />
              중기적으로, 다양한 물류 산업군을 경험할 수 있는 회사라고 생각이
              드네요.
              <br />
              콜드체인으로 시작하였지만, 콜드체인 이외의 물류 분야에서 끊임없는
              도전을 하고 기회가 주어지는 회사인 것 같아요.
              <br />
              따라서, 초기에는 콜드체인을 경험하지만 추후에는 전반적인 물류
              분야를 경험함으로써 특정분야의 스페셜리스트가 아닌 제너럴리스트로
              커리어를 쌓을 수 있을 거예요.
              <br />
              장기적으로는 팀프레시에서 제너럴리스트로 성장하여, 자신이 생각하는
              방향성에 맞춰 원하는 기업에 이직을할때 그 분야에서 스페셜리스트로
              인정받는 사회 구성원 중 한명이 될 수 있을 거란 확신이 드네요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’여서 가능했던게 있나요?
            </div>
            <div className="interview_comment_A">
              저뿐만아니라 다른분들도 동일할 것 같은데, ‘또래에 비해 잘난 사람이
              된 것’이라고 생각합니다.
              <br />
              20대 초중반에 대기업에 입사했다면, 주어진 업무만 하는 수동적인
              사람이 될 확률이 높을 거라 생각하고 실제로 주변 친구들을 보면 그런
              사람들이 대다수 인 거 같더라구요.
              <br />
              반대로, 팀프레시는 능동적으로 끊임없이 생각하고 도전해야하는
              일들이 많아요.
              <br />
              황무지 같은 회사에서 ‘무’에서 ‘유’를 창출하기 위해 보다 많은
              권한과 기회를 받고, 이를 통해서 많은 경험을 할 수 있기 때문이에요.
              <br />
              실제로 영업기획팀에 있는 팀원들은 젊은 나이에 몇 십억 규모의
              고객사 영업에 성공하여 안정적인 물류 세팅을 하고 있고, 고객사에
              니즈에 맞춰 물류 컨설팅을 제공하고 신규 물류 세팅을 경험하고
              있어요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’에서 이루고 싶은 개인적인 목표가 있나요?
            </div>
            <div className="interview_comment_A">
              1) 당일배송망 세팅
              <br />
              2) 수도권 이외의 새벽배송망 세팅
              <br />
              3) 다양한 산업군의 물류 산업을 경험하는 것 이 세가지 입니다.
              <br />
              고객사 입장에서 택배, 새벽배송, 당일배송 모든 배송망이 깔리게 되면
              이원화, 삼원화 운영이 아닌 통합물류서비스를 제공받을 수 있고,
              고객의 입장에서는 한정적인 지역에서만 서비스를 제공받지 않기
              때문에 편리해질 수 있어요.
              <br />
              궁극적으로는 고객사와 고객이 편리해지는 것이겠지만, 결국
              팀프레시가 시장 경쟁력을 확보하기 위한 방향성이기도 합니다.
              <br />
              다양한 산업군의 물류 산업을 경험하고 싶은 이유는 통합 물류
              전문가가 되고 싶어서예요.
              <br />
              단순히 한 분야의 스페셜리스트가 되는 것도 경쟁력이 있다고 생각이
              들지만, 제너럴리스트가 되어 모든 물류에 대한 최적의 물류 솔류션을
              제공하는 사람이 되는 것이 목표랍니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시‘는 주니어들이 일하기 좋은 기업인가요?
            </div>
            <div className="interview_comment_A">
              팀프레시는 주니어, 시니어 모두가 일하기 좋은 기업인 것 같아요.
              <br />
              주니어에게는 다양한 경험을 통하여 폭발적인 성장을 이루어 낼 수
              있는 기업이고, 시니어에게는 자신이 겪었던 경험을 통하여 회사를
              보다 빠르게 발전시켜 성취감을 얻을 수 있는 기업입니다.
              <br />
              모두에게 좋은 기업이라는 것이 약간 과장되어 보일 순 있지만, 자신의
              방향성과 목표만 뚜렷하다면 그것을 이룰 수 있는 회사인 것은
              보장드릴 수 있을 것 같아요.
              <br />
              다만, 목표를 향해 끊임없이 달려가는 과정속에서 어느정도 성장통이
              수반되니 그 점은 참고해주세요.(웃음)
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              영업기획팀은 흔히 말하는 ‘꼰대’가 없는 자유로운 분위기 인 것
              같아요. 20대에서 30대 초반까지 각양각색의 사람들이 모여 서로의
              생각을 공유하고 함께 성장하는 전형적으로 밝은 스타트업이랄까…?
              <br />
              ‘성장에 목마른 사람들이 모인 곳’이라는 생각이 강하게 들어요. 다들
              개인의 성장을 위하여 끊임없이 노력하고 발전하려고 하는 것 같아요.
              그래서 자유로운 분위기이지만 자신이 맡은 업무에 대하여 책임감이
              강하고 공과 사가 확실한 편 입니다.
              <br />
              가끔은 재밌거나 큰 영업권이 나오게 되면 자신들이 하고 싶다고
              어필을 하거나, 자기가 하지 못해서 아쉬워하는 사람들도
              있답니다…(웃음)
            </div>
            <div className="interview_comment_Q">본인에게 ‘팀프레시’란?</div>
            <div className="interview_comment_A">
              팀프레시를 한 단어로 표현하면, “물음표” 일 것 같네요. <br />
              먼저, “물음표”란 부정적인 의문이 아닌 긍정적인 의문이라고
              말씀드립니다.
              <br />
              지난 3년간 급성장한 회사가 향후 5년, 10년동안 얼마나 더 성장할지가
              궁금해지는 기업이기 때문이에요.
              <br />
              때문에 앞으로도 끊임없이 그 성장에 도움이 되는 구성원이 되고
              싶어요.
              <br />
              또, 팀프레시는 제 인생의 방향성에 변화를 준 회사입니다.
              <br />
              보다 많은 생각을 하게 하고 능동적인 사람이 되어가고 있고, 무엇보다
              어떠한 업무가 주어져도 두려워하지 않는 자신감을 갖게 되었습니다.
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              의견을 논리적으로 얘기하고, 목표가 뚜렷한 솔직하고 우직한
              사람이었으면 좋겠어요.
              <br />
              저희는 특정 영업권이 들어올 경우 대부분의 의사결정과 권한을
              팀원들에게 주는 편이에요. 대신, 그만큼의 권한이 주어지기 때문에
              제게 검토나 결재를 받을 때는 그 사업 혹은 해당 고객사를
              유치해야하는 명확한 사유가 있어야 한다고 생각해요. 따라서 논리적인
              의견이 필요하고, 그 사업을 가져와야 한다는 정확한 목표가 있는
              사람이었으면 좋겠어요. (영업적인 것 이외에도 자신이 이루고 싶은
              뚜렷한 목표가 있는 사람이었으면 좋겠어요)
              <br />
              <br />
              또, 거리낌없이 힘들거나 불만이 있었을 때 말해줬으면 좋겠어요.
              업무적으로도 피드백이 마음에 들지않거나 명확하지 않으면
              말씀주세요!
              <br />
              <br />
              마지막으로 우직한 사람이라는 건, 조금은 고통을 인내할 수 있는
              사람이었으면 좋겠어요. 영업직군이다 보니 고객사 혹은 현장으로부터
              오늘 스트레스를 어느정도 우직하게 견디고 이겨내는 사람이 필요한 것
              같아요. (우직하게 견디라고해서 뒤에서 울고 있으라는것은 아니니
              힘든건 힘들다고 꼭 말씀하세요..)
            </div>
            <div className="interview_comment_Q">
              마지막으로, 한 말씀 부탁드립니다!
            </div>
            <div className="interview_comment_A">
              팀프레시에 입사하시면 누구보다 많은 경험과 엄청난 성장을 할 수
              있다고 확신합니다.
              <br />
              다만, 성취를 위해서는 어느정도 워라벨은 포기하고 오셔야 한다고
              말씀드리고 싶어요.
              <br />
              고도화되는 물류산업 속에서 끊임없이 성장하고 있는 팀프레시에서
              함께 성장하고 물류전문가가 되고 싶은 분들은 영업기획팀으로 오시면
              됩니다!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSP2;
