const ModalPP = ({ isOpen, onhandleClose }) => {
  return (
    <div className="interview_box" style={{ top: isOpen ? "0" : "" }}>
      <div className="interview_box_bg" onClick={onhandleClose}></div>
      <div className="relative">
        <div className="interview_box_contents">
          <div className="interview_people_img">
            <div className="interview_close">
              <i className="xi-close-thin"></i>
            </div>
            <div className="interview_people_name">
              유아름
              <br />
              <span>
                화물운송플랫폼본부,
                <br />
                플랫폼프로덕트팀(PP)
                <br />
                팀장
              </span>
            </div>
            <img
              src="skin/body/sub0301/interview_img14.jpg?1659593419"
              alt=""
            />
          </div>
          <div className="interview_comment">
            <div className="interview_comment_Q">
              팀에 대한 간단한 소개와, 본인이 맡은 업무에 대해 설명해주세요!
            </div>
            <div className="interview_comment_A">
              안녕하세요. 저희 플랫폼 프로덕트팀은 ‘화물 주선 플랫폼’ 프로덕트를
              기획, 구축, 마케팅하는 팀입니다.
              <br />
              저희 프로덕트는 팀프레시 내부적으론 안정적인 화물주선을 지원하고,
              외부적으로 화물기사와 운송사를 연결해 신사업을 꾀하는
              서비스입니다.
              <br />
              하나의 프로덕트를 직접 기획하고, 개발팀과 협업해 구축, 운영 및
              마케팅으로 성장시키고 있어요.
              <br />
              저는 팀장이자 프로덕트 오너를 맡고 있어요.
              <br />
              방향성을 설정해 프로덕트의 1년 로드맵을 세우고, 요구사항을 정리해
              서비스 기획을 하기도 해요.
              <br />
              데이터 지표를 보기위해 쿼리를 치기도 하고, 팀장으로 팀 목표 관리와
              팀원들의 업무 관리도 합니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’가 커리어 성장에 도움을 주었나요?
            </div>
            <div className="interview_comment_A">
              의사결정권이 많아 다양한 시도를 할 수 있었습니다.
              <br />
              스타트업답게 개인에게 많은 주도권이 주어집니다.
              <br />
              처음부터 끝까지 모두 해볼 수 있는 점을 기회로 받아들이는 분들에게
              큰 성장의 발판이 될 거에요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’여서 가능했던 일이 있나요?
            </div>
            <div className="interview_comment_A">
              대표님과 자유롭게 얘기할 수 있어요.
              <br />
              팀 내부의 시선으로만 문제를 고민하다가 전사의 넓은 시선으로
              바라보면 또 다른 해결책이 생겨요.
              <br />
              대표님과 오고 가며 나누는 대화 속에서 종종 힌트를 얻곤 했습니다.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’에서 이루고 싶은 개인적인 목표가 있나요?
            </div>
            <div className="interview_comment_A">
              저는 지금 맡은 프로덕트의 처음부터 함께 했어요.
              <br />
              일정 성과가 날 때까지 프로덕트를 잘 키워가고 싶습니다.
              <br />
              만족할만한 성과에 대한 기준은 아직 미정이에요.
              <br />
              점점 높아져서 탈이네요.
            </div>
            <div className="interview_comment_Q">
              ‘팀프레시’는 주니어들이 일하기 좋은 기업인가요?
            </div>
            <div className="interview_comment_A">
              성장, 학습, 주도적인 것에 매료되는 주니어들에게는 좋은 기업이에요.
              <br />
              원하는 만큼 해 볼 수 있다고 느껴져요.
              <br />
              직접 고군분투하며 배운 것은 자기만의 지식으로 체화됩니다.
            </div>
            <div className="interview_comment_Q">팀의 분위기는 어떤가요?</div>
            <div className="interview_comment_A">
              다들 2030이고, 서비스 기획자와 마케터로 구성되어 있답니다.
              <br />
              저는 밝은 팀 분위기를 좋아해요.
              <br />
              다행히 팀원 분들도 비슷한 성향이라 화기애애하게 지내요.
              <br />
              종종 티타임을 가질 때 ‘티키타카’가 잘 되는데 다른 분들이 구경하는
              재미가 있다네요.
            </div>
            <div className="interview_comment_Q">본인에게 ‘팀프레시’란? </div>
            <div className="interview_comment_A">
              스타트업에 대한 편견을 긍정적으로 바꿔준 회사입니다.
              <br />
              저는 사실 스타트업에 그리 좋지만은 않은 인식을 갖고 있었어요.
              <br />
              막상 팀프레시에 속해보니 ‘스타트업 편견’들이 제게는 ‘장점’으로
              다가온 경우가 많았습니다.
              <br />
              도전의식을 자극해 '뭘 더 할 수 있지?'생각하게 만들어요.
              <br />
              이런 것들이 저를 결국 성장시킨다고 믿습니다.
            </div>
            <div className="interview_comment_Q">
              같이 일하고 싶은 사람은 어떤 사람인가요?
            </div>
            <div className="interview_comment_A">
              당연히 일 잘하는 사람이죠. 또는 일 잘 할 가능성을 갖춘 사람.
              <br />
              특징으로 논리적인 사람, 긍정적인 사람, 학습 의지가 많은 사람 이
              3가지를 꼽고 싶어요.
              <br />
              일 잘 할 가능성은 이 3가지에서 나온다고 생각해요.
              <br />
              우리팀은 신사업을 하는 팀이기 때문에 어떤 상황을 마주하더라도
              긍정적인 부분을 찾아내 집중할 수 있어야 해요.
              <br />안 되는 것 투성이인 환경이라, 안 되는 것만 바라보고 있는
              사람은 지양해요. 커리어적으론 이미 커리어 패스에 확신이 있는
              사람을 선호합니다.
            </div>
            <div className="interview_comment_Q">
              마지막으로, 한 말씀 부탁드립니다!
            </div>
            <div className="interview_comment_A">
              우리 팀과 핏을 맞추는 과정은 제가 도와드리겠습니다.
              <br />
              함께 할 동료들을 모아가는 일은 제게 재밌는 일입니다. 지금 팀도 한
              명, 한 명마다 각 스토리가 있지요.
              <br />
              개인이 모여 팀이 되었을 때 시너지가 나는 것을 많이 봐왔어요.
              <br />
              어떤 사람이 또 어떤 영향을 끼치게 될 지 두근두근합니다.
              <br />
              인재를 기다리고 있습니다.
              <br />
              주저말고 지원해주세요 :)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPP;
