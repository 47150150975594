import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import RecruitConfirmModal from "../common/Modal/RecruitConfirmModal";
import { NewsList } from "../shared";
import { useStore } from "../../UserContext";
import { useNavigate } from "react-router";

const RecruitPageList = ({ list, updateNewsList }) => {
  const [open, setOpen] = useState(false);
  const [reqSeq, setReqSeq] = useState("");
  const { state } = useStore();

  const handleOpen = (e) => {
    let val = e.target.dataset.name;

    setReqSeq(val);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const goToUrl = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      {list.map((list, index) => (
        <NewsList
          style={{ width: "100%", height: "100%" }}
          key={list.reqSeq}
          className="news_list"
          data-name={list.reqSeq}
          onClick={state.user ? handleOpen : () => goToUrl(list?.url)}
        >
          <div className="news_arrow">
            <i className="xi-arrow-right" data-name={list.reqSeq}></i>
          </div>

          <div className="news_source" data-name={list.reqSeq}>
            {list.subtitle}
          </div>
          <div className="news_date" data-name={list.reqSeq}>
            {list.publishedDate}
          </div>
          <div className="news_body" data-name={list.reqSeq}>
            {list.title}
          </div>
        </NewsList>
      ))}
      <RecruitConfirmModal
        seq={reqSeq}
        open={open}
        handleClose={handleClose}
        updateNewsList={updateNewsList}
      />
    </>
  );
};

export default RecruitPageList;
