import React, { useState } from "react";
import styled from "styled-components";
import { CenterDiv, SubContent, SubSection } from "../shared";
import { MenuItem, Select } from "@mui/material";
import { Link } from "react-router-dom";
import routes from "../../routes";

const Section = styled(SubContent)`
  text-align: left;

  li {
    list-style: none;
  }
  .title1,
  .title2 {
    text-align: left;
  }

  .privacy_title {
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: #000;
  }
  .sub_section .privacy_comment {
    font-family: "NanumGothic";
    font-size: 14px;
    line-height: 20px;
    color: #666;
  }

  .sub_section .privacy_box {
    clear: both;
    width: 100%;
    margin: 50px 0;
    .privacy_section_title {
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      color: #000;
    }
    .privacy_section_comment {
      width: 100%;
      font-size: 14px;
      line-height: 25px;
      color: #666;
      padding: 20px;
      margin: 5px 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      ul {
        position: relative;
        padding-left: 17px;
      }
      li {
        position: relative;
        padding-left: 15px;
        line-height: 20px;
        margin: 10px 0;
      }
      li:after {
        position: absolute;
        top: 0;
        left: 0;
      }
      li:nth-child(1):after {
        content: "1.";
      }
      li:nth-child(2):after {
        content: "2.";
      }
      li:nth-child(3):after {
        content: "3.";
      }
      li:nth-child(4):after {
        content: "4.";
      }
      li:nth-child(5):after {
        content: "5.";
      }
    }
  }

  .privacy_box .privacy_section_comment table {
    width: 100%;
    border-top: 1px solid #000;
    margin: 5px 0 0;
    td {
      font-family: "NanumGothic";
      font-size: 14px;
      line-height: 20px;
      color: #666;
      padding: 20px;
      padding: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-width: 0 1px 1px 0;
      :nth-child(1) {
        width: 15%;
        border-left-width: 1px;
      }
      :nth-child(2) {
        width: 85%;
      }
    }
  }
  .list_open {
    cursor: pointer;
  }
  .list_close {
    cursor: pointer;
    display: none;
  }
  .partner_list {
    display: none;
  }
`;

const PrevTimfPassPrivacy = () => {
  const [value, setValue] = useState("시행일자 2023.05.02");
  return (
    <Section className="sub_contents_title" id="subsectionlink1">
      <CenterDiv className="centerdiv">
        <div className="title1">Privacy</div>
        <div className="title2">개인(위치)정보 처리방침</div>

        <SubSection className="sub_section">
          {/* <div className="privacy_title">(주)팀프레시 개인정보 처리방침</div>
          <div className="privacy_comment">
            (주)팀프레시 (이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라
            정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게
            처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을
            수립·공개합니다. 
          </div>*/}
          <div className="privacy_box">
            <div className="privacy_section_title">
              제1조(개인정보의 처리목적)
            </div>
            <div className="privacy_section_comment">
              “개인정보처리방침”이란 이용자가 안심하고 서비스를 이용할 수 있도록
              회사가 준수해야 할 지침을 의미하며, 팀프레시(이하 ‘당사’라 함)는
              정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및
              개인정보보호 규정, 개인위치정보보호 규정, 가이드라인을 준수하여
              개인정보 처리방침을 제공합니다.
              <br />
              당사는 이용자의 동의를 기반으로 개인정보 및 개인위치정보를
              수집・이용 및 제공하고 있으며, 본 개인정보처리방침은
              팀프패스(’TimF PASS’) 서비스(이하 ‘서비스’라 함)에 적용됩니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제2조(개인정보 수집 및 이용)
            </div>
            <div className="privacy_section_comment">
              당사는 서비스 제공을 위한 필요 최소한의 개인정보를 수집하고
              있습니다.
              <br />
              <ul>
                <li>
                  수집 및 이용하는 개인정보 항목
                  <br />
                  정보주체의 휴대폰 회선번호, 성명, 초상(肖像, 이하 ‘초상’이라
                  함), 개인위치정보, 센터 입출입 기록, 단말기정보(OS,
                  화면사이즈, 디바이스 아이디, 폰기종, 단말기 모델명), IP주소,
                  쿠키, 방문일시, 부정이용기록, 서비스 이용 기록 등
                </li>
                <li>
                  개인정보 수집 방법
                  <br />
                  개인정보를 수집하는 경우에는 원칙적으로 사전에 이용자에게 해당
                  사실을 알리고 동의를 구하고 있으며, 아래와 같은 방법을 통해
                  개인정보를 수집합니다.
                  <br />
                  - 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를
                  하고 직접 정보를 입력하는 경우
                  <br />
                  - 서비스의 웹/앱 이용 과정에서 자동 생성되어 수집되는 경우
                  <br />- 제휴 서비스 또는 정보 주체의 사용자 등으로 제3자로부터
                  개인정보를 제공받은 경우
                </li>
                <li>
                  개인정보 수집 및 이용 목적
                  <br />
                  수집된 개인정보는 서비스 제공 및 개선, 신규 서비스 개발 등을
                  위해 이용합니다.
                  <br />
                  - 정보 주체 식별 및 본인 확인, 부정이용 방지
                  <br />
                  - 근태 관리 및 정보 주체의 사용자와의 도급비용 정산
                  <br />
                  - 신규 서비스 개발, 다양한 서비스 제공, 문의사항 또는
                  불만처리, 공지사항 전달
                  <br />
                  - 서비스의 원활한 운영에 지장을 주는 행위(부정 이용 행위 등
                  포함)에 대한 방지 및 제재
                  <br />- 서비스 이용 기록, 이용 빈도 및 서비스 이용에 대한
                  통계, 프라이버시 보호 측면의 서비스 환경 구축, 서비스 개선에
                  활용
                </li>
                <li>
                  개인정보 제공
                  <br />
                  당사는 이용자의 별도 동의가 있거나 법령에 규정된 경우를
                  제외하고는 이용자의 개인정보를 제3자에게 제공하지 않습니다.
                </li>
              </ul>
            </div>
          </div>

          <div className="privacy_box">
            <div className="privacy_section_title">
              제3조(개인정보 보관 및 파기)
            </div>
            <div className="privacy_section_comment">
              개인정보는 수집 및 이용목적이 달성되면 지체없이 파기하며, 절차 및
              방법은 아래와 같습니다.
              <ul>
                <li>
                  수집된 개인정보는 제공일로부터 3년까지 보관 후 파기합니다.
                </li>
                <li>
                  수집 및 이용목적의 달성 또는 보관 기간 도래 등 파기 사유가
                  발생한 경우 개인정보의 형태를 고려하여 파기방법을 정합니다.
                  전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게
                  삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나
                  소각하여 파기합니다.
                </li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제4조(개인위치정보 수집 및 이용)
            </div>
            <div className="privacy_section_comment">
              당사는 서비스 제공을 위한 필요 최소한의 개인위치정보를 수집하고
              있으며, 위치정보의 보호 및 이용 등에 관한 법률(이하
              ‘위치정보법’)에 따라 다음과 같이 개인위치정보를 처리합니다.
              <ul>
                <li>
                  개인위치정보 처리 목적 및 보유기간
                  <br />
                  회사가 제공하는 위치기반서비스 및 개인위치정보의 보유목적과
                  기간은 아래와 같습니다.
                  <br />
                  - 위치기반서비스 이용 및 제공 목적 달성한 때에는 지체없이
                  개인위치정보를 파기합니다.
                  <br />
                  - 회사는 「위치정보의 보호 및 이용 등에 관한 법률」
                  제16조제2항에 따라 위치정보 이용 · 제공사실 확인자료를 자동
                  기록 · 보존하며, 해당 자료는 6개월간 보관합니다.
                  <br />
                  - 회사는 개인위치정보의 이용 또는 제공목적을 달성한 때에는
                  제3항의 위치정보 이용 · 제공사실 확인자료를 제외한
                  개인위치정보를 즉시 파기합니다. 다만, 다른 법률에 따라
                  보유하여야 하거나 회원이 개인위치정보의 보유에 별도로 동의한
                  경우에는 회원이 동의한 때로부터 최대 1년간 이를 보유할 수
                  있습니다.
                  <br />
                  <table cellPadding="0" cellSpacing="0">
                    <tbody>
                      <tr>
                        <td>서비스 명</td>
                        <td>서비스 내용 및 목적</td>
                      </tr>
                      <tr>
                        <td>
                          팀프패스
                          <br />
                          (TimF PASS)
                        </td>
                        <td>
                          개인 휴대전화의 위치 정보를 활용한 이용자의 출퇴근
                          내역 관리
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  개인위치정보 수집
                  <br />
                  서비스의 제공을 위해 개인위치정보를 수집할 수 있습니다.
                  <br />
                  - 이용자가 서비스를 이용하는 동안 위치정보가 함께 저장되어야
                  하는 경우 서비스 이용기간동안 개인위치정보가 보관됩니다.
                  <br />- 그 외 위치기반서비스 제공을 위해 필요한 경우 이용목적
                  달성을 위해 필요한 최소한의 기간 동안 개인위치정보를 수집 및
                  보관할 수 있습니다.
                </li>
                <li>
                  개인위치정보 보관 및 파기
                  <br />
                  개인위치정보의 수집 및 이용목적이 달성되면 지체없이
                  파기합니다.
                  <br />
                  - 수집 및 이용 목적의 달성 등 개인위치정보 처리목적이 달성된
                  경우, 개인위치정보를 복구 및 재생되지 않도록 안전하게
                  삭제합니다.
                  <br />
                  다만, 다른 법령에 따라 보관해야 하는 등 정당한 사유가 있는
                  경우에는 그에 따릅니다.
                  <br />- 위치정보법 제16조2항에 따라 이용자의 위치정보의
                  이용・제공사실 확인자료를 위치정보시스템에 6개월간 보관합니다.
                </li>
                <li>
                  개인위치정보 이용
                  <br />
                  팀프레시는 이용자의 동의 없이 개인위치정보를 제3자에게
                  제공하지 않으며, 제3자에게 제공하는 경우에는 제공받는 자 및
                  제공목적을 사전에 이용자에게 고지하고 동의를 받습니다.
                  <br />
                  이용자의 동의를 거쳐 개인위치정보를 제3자에게 제공하는 경우,
                  이용자에게 매회 이용자에게 제공받는 자, 제공일시 및 제공목적을
                  즉시 통지합니다.
                </li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제5조(개인정보 및 개인위치정보의 제3자 제공)
            </div>
            <div className="privacy_section_comment">
              당사는 원활한 서비스 제공을 위해 수집 목적과 합리적으로 관련된
              범위에서는 법령에 따라 이용자의 동의 없이 개인정보를 이용하거나
              아래와 같이 제3자에게 제공할 수 있습니다.
              <ul>
                <li>정보주체 소속 사업체의 사용자</li>
              </ul>
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">
              제6조(정보주체의 권리, 의무 및 행사방법)
            </div>
            <div className="privacy_section_comment">
              이용자(정보주체)는 회사에 대해 언제든지 다음 각 호의 개인정보 보호
              관련 권리를 행사할 수 있으며, 보다 구체적으로는 정보 주체의
              사용자를 통한 요청, 고객센터를 통해 서면, 전화 또는 이메일로
              요청하시면 지체 없이 조치하겠습니다.
              <ul>
                <li>개인정보 열람요구</li>
                <li>오류 등이 있을 경우 정정 요구</li>
                <li>삭제요구</li>
                <li>처리정지 요구</li>
                <li>개인정보 수집・이용 및 제공에 대한 동의 철회</li>
              </ul>
              <br />
              또한, 정보 주체는 개인정보 보호법 등 관계법령을 위반하여 회사가
              처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을
              침해하여서는 아니됩니다.
            </div>
          </div>
          <div className="privacy_box">
            <div className="privacy_section_title">제7조(기타)</div>
            <div className="privacy_section_comment">
              <ul>
                <li>
                  개인정보 보호를 위한 노력
                  <br />
                  당사는 이용자의 개인정보를 가장 소중한 가치로 여기고
                  개인정보의 안정성 확보를 위해 다음과 같은 조치를 취하고
                  있습니다.
                  <br />
                  - 관리적 조치 : 내부관리계획 수립・시행, 정기적 직원 교육 등
                  <br />
                  - 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리,
                  접근통제시스템 설치, 고유식별정보 등의 암호화
                  <br />- 물리적 조치 : 전산실, 자료보관실 등의 접근통제
                </li>
                <li>
                  개인정보보호 관련 문의
                  <br />
                  서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의,
                  불만, 조언이나 기타 사항은 개인정보 보호책임자 및 담당부서로
                  연락해 주시기 바랍니다. 당사는 여러분의 목소리에 귀 기울이고
                  신속하고 충분한 답변을 드릴 수 있도록 최선을 다하겠습니다.
                  <br />
                  <br />
                  ※ 개인정보 보호책임자(위치정보관리책임자 겸임)
                  <br />
                  &ensp;- 성명 : 서영락
                  <br />
                  &ensp;- 직책 : 기술지원실 실장
                  <br />
                  &ensp;- 연락처 : 02-423-0529
                  <br />
                  &ensp;- 팩스번호 : 02-3432-0525
                  <br />
                  <br />
                  ※ 개인정보 보호 및 개인정보 열람청수 접수, 처리 담당부서
                  <br />
                  &ensp;- 부서명 : 인사팀
                  <br />
                  &ensp;- 담당자 : 유두곤
                  <br />
                  &ensp;- 연락처 : 02-423-0529
                  <br />
                  &ensp;- 팩스번호 : 02-3432-0525
                  <br />
                  <br />
                  ※ 개인정보 관련 신고 또는 상담이 필요한 경우
                  <br />
                  &ensp;- 개인정보침해 신고센터
                  <br />
                  &ensp;&ensp;(국번없이)118
                  <br />
                  &ensp;&ensp;https://privacy.kisa.or.kr
                  <br />
                  <br />
                  &ensp;- 대검찰청 사이버수사과
                  <br />
                  &ensp;&ensp;(국번없이)1301
                  <br />
                  &ensp;&ensp;cid@spo.go.kr
                  <br />
                  <br />
                  &ensp;- 경찰청 사이버수사국
                  <br />
                  &ensp;&ensp;(국번없이)182
                  <br />
                  &ensp;&ensp;https://ecrm.cyber.go.kr
                </li>
                <li>
                  개인정보 처리방침 변경
                  <br />
                  <br />
                  당사는 법령이나 서비스의 변경사항을 반영하기 위한 목적 등으로
                  개인정보처리방침을 수정할 수 있습니다. 개인정보처리방침이
                  변경되는 경우 변경 사항을 사전에 안내하며, 변경된
                  개인정보처리방침은 게시한 날로부터 7일 후부터 효력이
                  발생합니다.
                  <br />
                  다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이
                  이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에
                  안내합니다.
                  <br />
                  <br />
                  - 공고일자 : 2023년 04월 25일
                  <br />- 시행일자 : 2023년 05월 02일
                </li>
              </ul>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              // label="Age"
              onChange={() => setValue(value)}
            >
              <MenuItem value="시행일자 2023.05.02">
                <Link to={routes.prevTimfPassPrivacy}>시행일자 2023.05.02</Link>
              </MenuItem>
              <MenuItem value="시행일자 2023.09.07">
                <Link to={routes.timfPassPrivacy}>시행일자 2023.09.07</Link>
              </MenuItem>
            </Select>
          </div>
        </SubSection>
      </CenterDiv>
    </Section>
  );
};

export default PrevTimfPassPrivacy;
