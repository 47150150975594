import React from "react";
import styled from "styled-components";
import { Form, Input100, Table } from "../../shared";

const TextArea = styled.textarea`
  border: 1px solid #dddddd;
  width: 100%;
  height: 99px;
  overflow: auto;
  line-height: 145%;
  padding: 3px;
`;

const Privacy = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: #666;
  width: 100%;
  padding: 15px;
  margin-top: 15px;
  border: 1px solid #dddddd;
  line-height: 140%;
  box-sizing: border-box;
`;

const Checkbox = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin: 20px 0;
  text-align: center;
  color: #000000;
`;

const SendBtn = styled.button`
  display: table;
  font-size: 30px;
  font-weight: 500;
  line-height: 31px;
  color: #fff;
  background: #02204a;
  margin: 10px auto 0;
  padding: 10px 60px 13px;
  border-radius: 35px 0 35px 0;
  cursor: pointer;
`;

const WarningBtn = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #787878;
  cursor: pointer;
  img {
    width: 20px;
    margin: 5px;
  }
`;

const onClickWarning = () => {
  alert(
    "비밀번호는 숫자+영문자+특수문자 조합으로 8자리 이상이어야하고,\n 같은문자를 4번이상 사용하실 수 없습니다."
  );
};

const InquiryCreate = ({
  serviceType,
  onChangeServiceType,
  formik,
  brandType,
  onChangeBrandType,
  insuaranceType,
  isSubmit,
  onChangeInsuaranceType,
}) => {
  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="franchise_comment">
        궁금하신 점을 남겨주시면 담당자가 확인 후 연락드리겠습니다.
      </div>
      &nbsp;
      <Table className="write_main_table">
        <tbody>
          <tr>
            <td className="write_left_td">서비스 유형*</td>
            <td className="write_right_td" colSpan={2}>
              <select
                name="categorySeq"
                className="write_input_select"
                value={serviceType}
                onChange={onChangeServiceType}
              >
                <option value="">유형을 선택 해주세요</option>
                <option value="2">새벽배송</option>
                <option value="3">풀필먼트</option>
                <option value="1">화물주선</option>
                <option value="4">식자재유통</option>
                <option value="5">프랜차이즈</option>
                <option value="6">보험</option>
                <option value="7">마케팅,홍보</option>
                <option value="12">기타</option>
              </select>
            </td>
          </tr>
          {/* 새벽배송 */}
          {serviceType === "2" ? (
            <>
              <tr>
                <td className="write_left_td">업체명*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="companyName"
                    placeholder="예시)주식회사000"
                    className="write_input_text"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">담당자/직급*</td>
                <td className="write_right_td" colSpan={2}>
                  <Input100
                    placeholder="예시)박민제/팀장"
                    type="text"
                    name="name"
                    size="11"
                    className="write_input_text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">연락처*</td>

                <td className="write_right_td" colSpan={2}>
                  <select
                    name="phone.first"
                    value={formik.values.phone.first}
                    className="write_input_select"
                    onChange={(e) =>
                      formik.setFieldValue("phone.first", e.target.value)
                    }
                  >
                    <option value=""></option>
                    <option value="010">010</option>
                    <option value="011">011</option>
                    <option value="016">016</option>
                    <option value="017">017</option>
                    <option value="018">018</option>
                    <option value="019">019</option>
                  </select>
                  &nbsp;-&nbsp;
                  <input
                    type="text"
                    size="6"
                    maxLength="4"
                    className="write_input_text"
                    name="phone.second"
                    value={formik.values.phone.second}
                    onChange={(e) =>
                      formik.setFieldValue("phone.second", e.target.value)
                    }
                  />
                  &nbsp;-&nbsp;
                  <input
                    type="text"
                    size="6"
                    maxLength="4"
                    className="write_input_text"
                    name="phone.third"
                    value={formik.values.phone.third}
                    onChange={(e) =>
                      formik.setFieldValue("phone.third", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">이메일*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="email"
                    value={formik.values.email}
                    placeholder="예시)min-jae.park@timf.co.kr"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">비밀번호*</td>

                <td className="write_right_td" colSpan={2}>
                  <WarningBtn onClick={onClickWarning} type="button">
                    <img
                      src={process.env.PUBLIC_URL + "/skin/images/warning.png"}
                      alt="유의사항 이미지"
                    />
                    <span>비밀번호 유의사항</span>
                  </WarningBtn>
                  <Input100
                    type="password"
                    name="password"
                    value={formik.values.password}
                    // placeholder="예시)min-jae.park@timf.co.kr"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">월 출고 건수*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="monthlyShipment"
                    value={formik.values.monthlyShipment}
                    placeholder="예시)월 10,000건"
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">취급상품</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="goods"
                    placeholder="예시)신선식품, 일반의류 등"
                    value={formik.values.goods}
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">픽업주소지</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="pickupAddress"
                    value={formik.values.pickupAddress}
                    placeholder="예시)서울시 송파구 위례성대로 12길 15-1, 1층"
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">판매채널</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="salesChannel"
                    value={formik.values.salesChannel}
                    size="11"
                    placeholder="예시)www.teamfresh.co.kr"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>

              <tr>
                <td className="write_left_td">문의사항</td>

                <td className="write_right_td" colSpan={2} align="center">
                  <div id="TnT_w_body_size" align="center">
                    <TextArea
                      name="contents"
                      value={formik.values.contents}
                      className="write_input_textarea"
                      onChange={formik.handleChange}
                      placeholder="예시)새벽배송 견적 부터 운영까지 어떻게 진행되는지 문의드립니다."
                    />
                  </div>
                </td>
              </tr>
            </>
          ) : null}
          {/* 풀필먼트 */}
          {serviceType === "3" ? (
            <>
              <tr>
                <td className="write_left_td">업체명*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    placeholder="예시)주식회사000"
                    type="text"
                    name="companyName"
                    className="write_input_text"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">담당자/직급*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    placeholder="예시)박민제/팀장"
                    type="text"
                    name="name"
                    size="11"
                    className="write_input_text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">연락처*</td>

                <td className="write_right_td" colSpan={2}>
                  <select
                    name="phone.first"
                    value={formik.values.phone.first}
                    className="write_input_select"
                    onChange={(e) =>
                      formik.setFieldValue("phone.first", e.target.value)
                    }
                  >
                    <option value=""></option>
                    <option value="010">010</option>
                    <option value="011">011</option>
                    <option value="016">016</option>
                    <option value="017">017</option>
                    <option value="018">018</option>
                    <option value="019">019</option>
                  </select>
                  &nbsp;-&nbsp;
                  <input
                    type="text"
                    size="6"
                    maxLength="4"
                    className="write_input_text"
                    name="phone.second"
                    value={formik.values.phone.second}
                    onChange={(e) =>
                      formik.setFieldValue("phone.second", e.target.value)
                    }
                  />
                  &nbsp;-&nbsp;
                  <input
                    type="text"
                    size="6"
                    maxLength="4"
                    className="write_input_text"
                    name="phone.third"
                    value={formik.values.phone.third}
                    onChange={(e) =>
                      formik.setFieldValue("phone.third", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">이메일*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="email"
                    placeholder="예시)min-jae.park@timf.co.kr"
                    value={formik.values.email}
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">비밀번호*</td>

                <td className="write_right_td" colSpan={2}>
                  <WarningBtn onClick={onClickWarning} type="button">
                    <img
                      src={process.env.PUBLIC_URL + "/skin/images/warning.png"}
                      alt="유의사항 이미지"
                    />
                    <span>비밀번호 유의사항</span>
                  </WarningBtn>
                  <Input100
                    type="password"
                    name="password"
                    value={formik.values.password}
                    // placeholder="예시)min-jae.park@timf.co.kr"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">월 출고 건수*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="monthlyShipment"
                    value={formik.values.monthlyShipment}
                    placeholder="예시)월 10,000건"
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">월 보관량*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="monthlyStorage"
                    placeholder="예시)상온|21,00PLT"
                    value={formik.values.monthlyStorage}
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">합포율(SKU)*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="sku"
                    value={formik.values.sku}
                    size="11"
                    placeholder="예시)평균 3sku"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">합포율(Unit)*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="unit"
                    value={formik.values.unit}
                    size="11"
                    className="write_input_text"
                    placeholder="예시)평균 5unit"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>

              <tr>
                <td className="write_left_td">기타 문의사항</td>

                <td className="write_right_td" colSpan={2} align="center">
                  <div id="TnT_w_body_size" align="center">
                    <TextArea
                      name="contents"
                      className="write_input_textarea"
                      value={formik.values.contents}
                      onChange={formik.handleChange}
                      placeholder="예시)B2B출고,새벽,택배배송희망,표준견적서 문의 등"
                    />

                    <span id="TnT_textarea_iframe">
                      <table width="100%" height="100%" border="0">
                        <tbody>
                          <tr id="button_EditorMode">
                            <td id="editortoolpanel">
                              <table border="0" width="100%">
                                <tbody>
                                  <tr>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td align="center"></td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                </td>
              </tr>
            </>
          ) : null}
          {/* 화물주선 */}
          {serviceType === "1" ? (
            <>
              <tr>
                <td className="write_left_td">업체명*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    placeholder="예시)주식회사000"
                    type="text"
                    name="companyName"
                    value={formik.values.companyName}
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">담당자/직급*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    placeholder="예시)박민제/팀장"
                    type="text"
                    name="name"
                    value={formik.values.name}
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">연락처*</td>

                <td className="write_right_td" colSpan={2}>
                  <select
                    name="phone.first"
                    value={formik.values.phone.first}
                    className="write_input_select"
                    onChange={(e) =>
                      formik.setFieldValue("phone.first", e.target.value)
                    }
                  >
                    <option value=""></option>
                    <option value="010">010</option>
                    <option value="011">011</option>
                    <option value="016">016</option>
                    <option value="017">017</option>
                    <option value="018">018</option>
                    <option value="019">019</option>
                  </select>
                  &nbsp;-&nbsp;
                  <input
                    type="text"
                    size="6"
                    maxLength="4"
                    className="write_input_text"
                    name="phone.second"
                    value={formik.values.phone.second}
                    onChange={(e) =>
                      formik.setFieldValue("phone.second", e.target.value)
                    }
                  />
                  &nbsp;-&nbsp;
                  <input
                    type="text"
                    size="6"
                    maxLength="4"
                    className="write_input_text"
                    name="phone.third"
                    value={formik.values.phone.third}
                    onChange={(e) =>
                      formik.setFieldValue("phone.third", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">이메일*</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="email"
                    placeholder="예시)min-jae.park@timf.co.kr"
                    value={formik.values.email}
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">비밀번호*</td>

                <td className="write_right_td" colSpan={2}>
                  <WarningBtn onClick={onClickWarning} type="button">
                    <img
                      src={process.env.PUBLIC_URL + "/skin/images/warning.png"}
                      alt="유의사항 이미지"
                    />
                    <span>비밀번호 유의사항</span>
                  </WarningBtn>
                  <Input100
                    type="password"
                    name="password"
                    value={formik.values.password}
                    // placeholder="예시)min-jae.park@timf.co.kr"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>

              <tr>
                <td className="write_left_td">취급 상품군</td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="goods"
                    placeholder="예시)신선식품, 일반의류 등"
                    value={formik.values.goods}
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">
                  상차지 주소 <br />/ 하차지 주소(*)
                </td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="loadingUnloadingAddress"
                    value={formik.values.loadingUnloadingAddress}
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                    placeholder="서울시 송파구 위례성대로 12길 15-1, 1층/ 서울시 송파구 위례성대로 12길 15-1, 2층"
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">
                  상차 시간 <br />/ 하차 시간
                </td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="loadingUnloadingTime"
                    value={formik.values.loadingUnloadingTime}
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                    placeholder="오전08:00/ 오후3:00"
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">
                  차량 톤수 <br />/ 필요 대수
                </td>

                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="carTonCnt"
                    value={formik.values.carTonCnt}
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                    placeholder="1t/1"
                  />
                </td>
              </tr>

              <tr>
                <td className="write_left_td">근무 일수</td>

                <td className="write_right_td" colSpan={2} align="center">
                  <div id="TnT_w_body_size" align="center">
                    <TextArea
                      name="workingDay"
                      value={formik.values.workingDay}
                      className="write_input_textarea"
                      onChange={formik.handleChange}
                      placeholder="0일"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="write_left_td">기타 특이 사항</td>

                <td className="write_right_td" colSpan={2} align="center">
                  <div id="TnT_w_body_size" align="center">
                    <TextArea
                      name="contents"
                      value={formik.values.contents}
                      placeholder="예시)상하차 수작업, 타코메타지 제출 필수 등"
                      className="write_input_textarea"
                      onChange={formik.handleChange}
                    />
                  </div>
                </td>
              </tr>
            </>
          ) : null}

          {/* 프랜차이즈 */}
          {serviceType === "5" && (
            <tr>
              <td className="write_left_td">브랜드*</td>
              <td className="write_right_td" colSpan={2}>
                <select
                  name="categorySeq"
                  className="write_input_select"
                  value={brandType}
                  onChange={onChangeBrandType}
                >
                  <option value="">브랜드를 선택 해주세요</option>
                  <option value="8">파스타집이야</option>
                  <option value="9">쌀국수집이야</option>
                  <option value="10">덮밥집이야</option>
                  <option value="11">돈까스집이야</option>
                </select>
              </td>
            </tr>
          )}

          {/* 보험 */}
          {serviceType === "6" && (
            <>
              <tr>
                <td className="write_left_td">보험종류*</td>
                <td className="write_right_td" colSpan={2}>
                  <select
                    name="categorySeq"
                    className="write_input_select"
                    value={insuaranceType}
                    onChange={onChangeInsuaranceType}
                  >
                    <option value="">보험종류를 선택 해주세요</option>
                    <option value="13">단체상해보험</option>
                    <option value="14">재산종합보험</option>
                    <option value="15">화재보험</option>
                    <option value="16">동산종합보험</option>
                    <option value="17">건설공사보험</option>
                    <option value="18">조립보험</option>
                    <option value="19">기계보험</option>
                    <option value="20">기관기계종합보험</option>
                    <option value="21">영업배상책임보험</option>
                    <option value="22">임원배상책임보험</option>
                    <option value="23">생산물배상책임보험</option>
                    <option value="24">전문인배상책임보험</option>
                    <option value="25">택배/퀵서비스보험</option>
                    <option value="26">기타보험</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="write_left_td">업체명*</td>
                <td className="write_right_td" colSpan={2}>
                  <Input100
                    placeholder="예시)주식회사000"
                    type="text"
                    name="companyName"
                    value={formik.values.companyName}
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>

              <tr>
                <td className="write_left_td">사업자등록번호</td>
                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="number"
                    name="bizRegNum"
                    value={formik.values.bizRegNum}
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>

              <tr>
                <td className="write_left_td">담당자*</td>
                <td className="write_right_td" colSpan={2}>
                  <Input100
                    placeholder="예시)박민제/팀장"
                    type="text"
                    name="name"
                    value={formik.values.name}
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>

              <tr>
                <td className="write_left_td">연락처*</td>
                <td className="write_right_td" colSpan={2}>
                  <select
                    name="phone.first"
                    value={formik.values.phone.first}
                    className="write_input_select"
                    onChange={(e) =>
                      formik.setFieldValue("phone.first", e.target.value)
                    }
                  >
                    <option value=""></option>
                    <option value="010">010</option>
                    <option value="011">011</option>
                    <option value="016">016</option>
                    <option value="017">017</option>
                    <option value="018">018</option>
                    <option value="019">019</option>
                  </select>
                  &nbsp;-&nbsp;
                  <input
                    type="text"
                    size="6"
                    maxLength="4"
                    className="write_input_text"
                    name="phone.second"
                    value={formik.values.phone.second}
                    onChange={(e) =>
                      formik.setFieldValue("phone.second", e.target.value)
                    }
                  />
                  &nbsp;-&nbsp;
                  <input
                    type="text"
                    size="6"
                    maxLength="4"
                    className="write_input_text"
                    name="phone.third"
                    value={formik.values.phone.third}
                    onChange={(e) =>
                      formik.setFieldValue("phone.third", e.target.value)
                    }
                  />
                </td>
              </tr>

              <tr>
                <td className="write_left_td">이메일*</td>
                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="email"
                    value={formik.values.email}
                    className="write_input_text"
                    placeholder="예시)min-jae.park@timf.co.kr"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">비밀번호*</td>

                <td className="write_right_td" colSpan={2}>
                  <WarningBtn onClick={onClickWarning} type="button">
                    <img
                      src={process.env.PUBLIC_URL + "/skin/images/warning.png"}
                      alt="유의사항 이미지"
                    />
                    <span>비밀번호 유의사항</span>
                  </WarningBtn>
                  <Input100
                    type="password"
                    name="password"
                    value={formik.values.password}
                    // placeholder="예시)min-jae.park@timf.co.kr"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">내용</td>
                <td className="write_right_td" colSpan={2} align="center">
                  <div id="TnT_w_body_size" align="center">
                    <TextArea
                      name="contents"
                      value={formik.values.contents}
                      className="write_input_textarea"
                      onChange={formik.handleChange}
                    />
                  </div>
                </td>
              </tr>
            </>
          )}

          {/* 그외 */}
          {serviceType === "4" ||
          serviceType === "5" ||
          serviceType === "7" ||
          serviceType === "12" ? (
            <>
              <tr>
                <td className="write_left_td">제목</td>
                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="title"
                    value={formik.values.title}
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>

              <tr>
                <td className="write_left_td">성명*</td>
                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="name"
                    value={formik.values.name}
                    size="11"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">연락처*</td>

                <td className="write_right_td" colSpan={2}>
                  <select
                    name="phone.first"
                    value={formik.values.phone.first}
                    className="write_input_select"
                    onChange={(e) =>
                      formik.setFieldValue("phone.first", e.target.value)
                    }
                  >
                    <option value=""></option>
                    <option value="010">010</option>
                    <option value="011">011</option>
                    <option value="016">016</option>
                    <option value="017">017</option>
                    <option value="018">018</option>
                    <option value="019">019</option>
                  </select>
                  &nbsp;-&nbsp;
                  <input
                    type="text"
                    size="6"
                    maxLength="4"
                    className="write_input_text"
                    name="phone.second"
                    value={formik.values.phone.second}
                    onChange={(e) =>
                      formik.setFieldValue("phone.second", e.target.value)
                    }
                  />
                  &nbsp;-&nbsp;
                  <input
                    type="text"
                    size="6"
                    maxLength="4"
                    className="write_input_text"
                    name="phone.third"
                    value={formik.values.phone.third}
                    onChange={(e) =>
                      formik.setFieldValue("phone.third", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">이메일*</td>
                <td className="write_right_td" colSpan={2}>
                  <Input100
                    type="text"
                    name="email"
                    value={formik.values.email}
                    className="write_input_text"
                    placeholder="예시)min-jae.park@timf.co.kr"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_left_td">비밀번호*</td>

                <td className="write_right_td" colSpan={2}>
                  <WarningBtn onClick={onClickWarning} type="button">
                    <img
                      src={process.env.PUBLIC_URL + "/skin/images/warning.png"}
                      alt="유의사항 이미지"
                    />
                    <span>비밀번호 유의사항</span>
                  </WarningBtn>
                  <Input100
                    type="password"
                    name="password"
                    value={formik.values.password}
                    // placeholder="예시)min-jae.park@timf.co.kr"
                    className="write_input_text"
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>

              <tr>
                <td className="write_left_td">내용</td>

                <td className="write_right_td" colSpan={2} align="center">
                  <div id="TnT_w_body_size" align="center">
                    <TextArea
                      name="contents"
                      value={formik.values.contents}
                      className="write_input_textarea"
                      onChange={formik.handleChange}
                    />
                  </div>
                </td>
              </tr>
            </>
          ) : null}
          <tr>
            <td colSpan={3} style={{ padding: 0 }} height={1}>
              <Privacy>
                <b>개인정보 수집 및 이용 동의</b>
                <br />
                <br />
                ○ 개인정보 수집/이용 목적 : 상담 및 견적문의 등의 다양한 정보
                제공
                <br />
                <br />
                ○ 수집 및 이용하는 개인정보의 항목 : 성명, 이메일 등 문의내역에
                포함하신 개인정보
                <br />
                <br />
                ○ 동의 거부권리 안내 : 신청인은 본 개인정보 수집에 대한 동의를
                거부하실 수 있으며, 이 경우 상담이 제한됩니다.
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;본인은 개인정보 처리에 관하여 고지
                받았으며, 이를 충분히 이해하고 동의합니다.
                <br />
                <br />○
                <b style={{ fontSize: "19.2px" }}>
                  {" "}
                  개인정보의 보유 및 이용기간
                </b>
                <br />
                <b>
                  &nbsp;&nbsp;&nbsp; - 보존기간은 1년이며, 정보 제공자가 삭제를
                  요청할 경우 즉시 파기합니다.
                  <br />
                  &nbsp;&nbsp;&nbsp; - 고객님의 정보는 개인정보 보호법에 따라
                  보호되며 위의 사항 외에 별도로 사용하지 않을 것을
                  약속드립니다.
                </b>
              </Privacy>
              <Checkbox>
                <input
                  type="checkbox"
                  name="agreePrivacy"
                  checked={formik.values.agreePrivacy}
                  onChange={formik.handleChange}
                />
                개인정보처리방침에 동의합니다.
              </Checkbox>
            </td>
          </tr>
          {/* <tr>
            <td colSpan={1}>
              <Checkbox>
                답변 완료
                <br />
                알림 신청
              </Checkbox>
            </td>
            <td colSpan={2} style={{ display: "flex", lineHeight: "45px" }}>
              <Checkbox style={{ marginRight: "50px" }}>
                <input
                  type="checkbox"
                  name="messageAlarm"
                  checked={formik.values.messageAlarm}
                  onChange={formik.handleChange}
                />
                문자
              </Checkbox>
              <Checkbox>
                <input
                  type="checkbox"
                  name="emailAlarm"
                  checked={formik.values.emailAlarm}
                  onChange={formik.handleChange}
                />
                이메일
              </Checkbox>
            </td>
          </tr> */}
          <tr>
            <td colSpan={3} style={{ paddingTop: "10px" }}>
              {isSubmit ? (
                <SendBtn type="submit" className="sendbtn">
                  문의하기
                </SendBtn>
              ) : (
                <SendBtn type="button" className="sendbtn" disabled>
                  문의하기
                </SendBtn>
              )}
              &nbsp; &nbsp;
            </td>
          </tr>
        </tbody>
      </Table>
    </Form>
  );
};

export default InquiryCreate;
